export const THEME_LOCAL_STORAGE = 'theming/theme';
export const THEME_CUSTOM_VARS = 'theming/customVars';

export const getFromLS = () => global.localStorage.getItem(THEME_LOCAL_STORAGE);
export const saveToLS = (theme) => {
    global.localStorage.setItem(THEME_LOCAL_STORAGE, theme);
};
export const removeFromLS = () => {
    global.localStorage.removeItem(THEME_LOCAL_STORAGE);
};
export const getCustomVarsFromLS = () => global.localStorage.getItem(THEME_CUSTOM_VARS);
export const saveCustomVarsToLS = (vars) => {
    global.localStorage.setItem(THEME_CUSTOM_VARS, JSON.stringify(vars));
};
export const removeCustomVarsFromLS = () => {
    global.localStorage.removeItem(THEME_CUSTOM_VARS);
};

export const setThemeVariables = ({ theme, vars = {}, updateComponents }) => {
    const customVars = JSON.parse(getCustomVarsFromLS());
    const variables = { ...vars, ...customVars };
    const variablesInline = JSON.stringify(variables).replace(/[{}/"]/g, '').replace(/,--/g, ';--');

    document.documentElement.setAttribute('data-theme', theme);
    document.documentElement.setAttribute('style', variablesInline);

    Object.keys(variables).forEach((key) => {
        if (typeof variables[key] === 'object') {
            if (typeof updateComponents === 'function') updateComponents(key, variables[key]);
        }
    });
};
