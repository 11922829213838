import React, { useState } from 'react';
import history from 'services/history';
import { PageVariant } from 'ui-library';
import { get } from 'lodash/fp';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import MTan from 'pages/pages/ForgotPassword/components/mtan';
import { SUCCESS } from 'static/requestResults';
import ServiceManager from 'services/ServiceManager';
import { UNEXPECTED_ERROR_MESSAGE } from 'error/commonErrors';
import AuthWrapper from 'components/AuthWrapper';
import { UsernameIdentity } from './components/UsernameIdentity/UsernameIdentity';
import {
    EMAIL_SENT, EMAIL_USER_IDENTITY, getViewToMessageXref, MTAN,
} from './static/views';
import { useNameSameAsEmail, useEmailForgotPasswordSubmit, useEmailForm } from './hooks';
import { getErrorModelByStatus } from './errors';
import { FormInput, ForgotPassMtan } from './types';


interface ForgotPasswordProps {
    location: { state: { changePassword: boolean | null | undefined } }
}

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({
    location: { state },
}) => {
    const [view, setView] = useState<number>(EMAIL_USER_IDENTITY);
    const [error, setError] = useState<string | undefined>(undefined);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);

    const { t } = useTranslation();
    const { data: isUserNameSameWithEmail } = useNameSameAsEmail({
        setLoading,
        setError,
    });

    const {
        handleFormSubmit,
        control,
        values,
        formErrors,
    } = useEmailForm(isUserNameSameWithEmail);

    const { handleSubmit, mTANLength, sessionId } = useEmailForgotPasswordSubmit({
        setError,
        setView,
        setSubmitting,
    });

    const handleSubmitMtan = (mtan: string) => {
        setLoading(true);

        ServiceManager.Security('forgotPasswordMtan', [{
            payload: {
                MTan: mtan,
                RedirectUrl: `${global.location.origin}/reset-password`,
                SessionId: sessionId,
            },
        }]).then((response: AxiosResponse<ForgotPassMtan>) => {
            const resultType = get('data.Result', response);
            const errorObj = getErrorModelByStatus(resultType);

            setLoading(false);
            if (error !== undefined) {
                setError(errorObj.message);
            } else if (resultType === SUCCESS) {
                setError(undefined);
                setView(EMAIL_SENT);
            } else {
                setError(errorObj.message);
            }
        }).catch(() => {
            setLoading(false);
            setError(UNEXPECTED_ERROR_MESSAGE);
        });
    };

    const handleChangeMtan = () => {
        setError(undefined);
    };

    const submitUsername = (validatedValues: FormInput) => handleSubmit(validatedValues);

    const gotoLogin = () => {
        history.push('/login');
    };

    if (view === MTAN) {
        return (
            <AuthWrapper>
                <MTan
                    error={error}
                    onCancel={gotoLogin}
                    onSubmit={handleSubmitMtan}
                    onChange={handleChangeMtan}
                    passcodeLength={mTANLength}
                    isLoading={isLoading}
                />
            </AuthWrapper>
        );
    }
    if (view === EMAIL_USER_IDENTITY) {
        return (
            <AuthWrapper>
                <UsernameIdentity
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    error={error}
                    isUserNameSameWithEmail={isUserNameSameWithEmail}
                    onSubmit={handleFormSubmit(submitUsername)}
                    control={control}
                    values={values}
                    formErrors={formErrors}
                    changePassword={state?.changePassword}
                />
            </AuthWrapper>
        );
    }

    const { title, type, message } = getViewToMessageXref(view);

    return (
        <AuthWrapper>
            <PageVariant
                title={t(title)}
                type={type}
                message={t(message)}
                actionButtonLabel={t('forgotPassword.backToLogin')}
                onActionButtonClick={gotoLogin}
            />
        </AuthWrapper>
    );
};
