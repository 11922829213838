import {
    validateNumber, validateData, percentIsZero,
} from 'utils/formatting';
import {
    getParent, roundAllocations, sum,
} from 'utils';
import { isLiquidity } from 'utils/portfolio';
import { sortAssets } from 'utils/sortingAllocation';

export const adaptPositions = (data, {
    portfolioValue,
    portfolioCurrency,
    nameLength,
    isNew,
    positionLink,
    getFormattedNumber,
    getFormattedCurrency,
    t,
}) => {
    if (!data) return [];
    const roundedAllocation = data.length && roundAllocations(data);
    const groupToValues = data.reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAlloc = isNew && +item.Allocation === 0
            ? 0 : roundedAllocation.find((n) => n.id === securityData.Id).value;
        const valueSecurity = portfolioValue * item.Allocation;
        const accParent = getParent(item.Security.AssetClass);
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);
        const nameProps = !isLiquidity(securityData) && positionLink
            ? { label: name, link: positionLink && `${positionLink}/${securityData.Id}` } : { value: name };

        accumulator[accParent.Name] = obj[accParent.Name] || [];
        accumulator[accParent.Name].push({
            parentId: accParent.Id,
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            typeId: securityData.Type.Id,
            url: '',
            nameNew: { maxLength: nameLength, isNew, ...nameProps },
            name: { maxLength: nameLength, ...nameProps },
            allocationData: roundedAlloc,
            allocation: `${getFormattedNumber(roundedAlloc)}%`,
            currency,
            latestPriceData: validateNumber(securityData.TradingPrice),
            latestPrice: getFormattedNumber(validateNumber(securityData.TradingPrice)),
            valuation: getFormattedCurrency(
                validateNumber(valueSecurity),
                { currency: portfolioCurrency },
            ),
            valuationInSecurityCurrency: getFormattedCurrency(
                validateNumber(valueSecurity),
                { currency: item.Security.Currency.CurrencyCode },
            ),
            valuationData: validateNumber(valueSecurity),
            new: { value: roundedAlloc.toFixed(2) },
            newAllocationData: roundedAlloc,
            newAllocation: `${getFormattedNumber(roundedAlloc)}%`,
            units: getFormattedNumber(securityData.Type.Id === 1
                ? 1 : Math.round(valueSecurity / securityData.TradingPrice), {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
            }),
            icon: {},
            performanceCCY: getFormattedNumber(validateNumber(item.MonetaryPerformance)),
            actions: {},
            isNew,
            fee: `${percentIsZero(0.1)}%`, // TODO: Remove hardcoded fee (AT-848)
        });

        return accumulator;
    }, {});

    let groups = Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];

        const value = sum(groupMembersData, 'valuationData');
        const allocationData = sum(groupMembersData, 'allocationData');

        return {
            id: groupMembersData[0].parentId,
            valuation: getFormattedCurrency(value, {
                currency: portfolioCurrency,
            }),
            value,
            allocation: `${getFormattedNumber(sum(groupMembersData, 'allocationData'))}%`,
            allocationData,
            newAllocationData: sum(groupMembersData, 'newAllocationRaw'),
            nameNew: { value: key, maxLength: 35 },
            name: key,
            children: groupMembersData,
            icon: '',
            actions: {},
        };
    });

    groups = groups.map((groupItem) => {
        const resultGroupItem = { ...groupItem };

        resultGroupItem.valuation = groupItem.valuation;

        resultGroupItem.children = resultGroupItem.children.map((groupMemberItem) => {
            const resultGroupMemberItem = groupMemberItem;

            resultGroupMemberItem.performance = (groupMemberItem.performance * 100);
            resultGroupMemberItem.valuation = (groupMemberItem.valuation);
            resultGroupMemberItem.icon = 'action';

            return resultGroupMemberItem;
        });

        return resultGroupItem;
    });

    return sortAssets(groups);
};
