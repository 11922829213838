import { checkNullData } from 'utils';
import { formatDate, getDatesDifference } from 'utils/datetime';
import { urgencyLabels } from 'constants/constants';

const getColor = ({ urgency }) => {
    if (urgency === 3) return 'red';
    if (urgency === 2) return 'yellow';

    return 'green';
};

export const adaptUrgencyFilters = (data, t) => (data || [])
    .sort((a, b) => b.Id - a.Id)
    .map((item) => t(urgencyLabels[item?.Name]));

export const adaptTasks = (data, severityLevels, quantity, t) => {
    if (checkNullData(data)) return [];

    const getSeverityLevel = ({ urgency }) => {
        const severityLevel = severityLevels.find(({ Id }) => Id === urgency);

        return severityLevel?.Name || undefined;
    };
    const now = new Date();
    let tasks = data
        .filter(({ categoryId }) => categoryId === 1)
        .filter(({ dueDate }) => getDatesDifference(dueDate, now) >= 0)
        .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

    if (quantity) tasks = tasks.slice(0, quantity);

    return tasks.map((item) => {
        const urgencyType = getSeverityLevel(item);

        return ({
            id: item.id,
            Subject: {
                title: undefined,
                subtitle: item.name.split('||')[0],
            },
            type: item.name.split('||')[1] || 'General',
            date: formatDate(item.dueDate),
            urgency: {
                type: urgencyType,
                text: t(urgencyLabels[urgencyType]),
                color: getColor(item),
            },
            contactId: item.contactId,
            action: item.action || null,
            link: item.link || null,
        });
    });
};

export const adaptWithContacts = (data, { list: contacts }) => data.map((item) => {
    const contact = contacts.find(({ Id }) => Id === item.contactId);

    return ({
        ...item,
        Subject: {
            ...item.Subject,
            title: contact ? `${contact.FirstName} ${contact.LastName}` : undefined,
        },
    });
}).filter((item) => item.Subject.title);
