import { atramDateFormat, formatDate } from 'utils/datetime';
import { NA, validateStringData } from 'utils/formatting';

export const getImgBase64Src = (contentBase64) => `data:image;base64,${contentBase64}`;

export const adaptClientInfo = (options = {}) => {
    const { data = {} } = (options || {});
    const t = options?.t || ((d) => d);
    const getFormattedPhoneNumber = options?.getFormattedPhoneNumber || ((d) => d);
    const getSchemaLabelById = options?.getSchemaLabelById || ((d) => d);
    const chosenRiskCategory = options?.ChosenRiskCategory || {};

    const {
        personalInformation,
        communicationMethods,
        relationshipInformation,
        investmentInformation,
    } = data;
    const phone = getFormattedPhoneNumber(`${communicationMethods?.primaryPhoneCountryCode?.label || ''}${communicationMethods?.primaryPhoneNumber || ''}`);
    const fullName = `${personalInformation?.firstName || ''} ${personalInformation?.middleName || ''} ${personalInformation?.lastName || ''}`;

    return {
        LastName: personalInformation?.lastName,
        FirstName: personalInformation?.firstName,
        Salutation: getSchemaLabelById(personalInformation?.salutation?.value),
        Name: fullName,
        IsProspect: relationshipInformation?.isProspect || false,
        clientProfile: [
            {
                Information: {
                    title: t('dashboard.profile.clientRiskProfile'),
                    subtitle: validateStringData(chosenRiskCategory?.Name),
                },
                Icon: { type: 'form', size: 20, href: 'risk-profile' },
            },
            {
                Information: {
                    title: t('dashboard.profile.totalPortfolios'),
                    subtitle: investmentInformation?.totalNumberOfPortfolios || 0,
                },
                Icon: { type: '' },
            },
        ],
        Street: communicationMethods?.primaryAddress?.primaryAddressLine1 ?? NA,
        StreetNumber: communicationMethods?.primaryAddress?.primaryAddressLine3 ?? NA,
        City: communicationMethods?.primaryAddress?.primaryAddressLine5 ?? NA,
        PostalCode: communicationMethods?.primaryAddress?.primaryAddressLine4 ?? NA,
        Country: getSchemaLabelById(communicationMethods?.primaryAddressCountry?.value) ?? NA,
        State: communicationMethods?.primaryAddress?.primaryAddressLine6 ?? '',
        PhoneNumber: phone ?? NA,
        ChosenRiskCategory: chosenRiskCategory?.Name || NA,
        Portfolios: investmentInformation?.totalNumberOfPortfolios || 0,
        Birthday: personalInformation?.dateOfBirth
            ? formatDate(new Date(personalInformation?.dateOfBirth), atramDateFormat)
            : NA,
        Email: communicationMethods?.primaryEmail || NA,
    };
};
