import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import {
    Icon, Paragraph, Title,
} from 'ui-library';
import Infobox from 'ui-library/dist/components/Infobox';
import { getClassNames } from 'utils';
import history from 'services/history';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import ContentBox from 'ui-library/dist/components/ContentBox';
import useClientProfile from 'prodivers/clientProfile/hooks/useClientProfile';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import { useKnowledgeExperience } from '../../hooks/useKnowledgeExperience';
import { useKnowledgeExperienceByProduct } from '../../hooks/useKnowledgeExperienceByProduct';

import './Results.css';

const Results = (props) => {
    const {
        location: { state },
    } = props;
    const { t } = useTranslation();
    const { clientId } = useClientProfile();
    const {
        results: data, resultsAdapted, errorResults, isLoadingResults, getQuestionnaireResults,
    } = useKnowledgeExperience({ contactId: clientId, productId: state?.productId });

    const {
        onUniverseComplete, errorUniverseComplete, continueDisabled,
    } = useKnowledgeExperienceByProduct({
        onNext: () => { history.push('/details'); },
        productId: state?.productId,
        productName: state?.productName,
        hasPositiveInstruments: resultsAdapted?.positive?.length > 0,
        hasNegativeInstruments: resultsAdapted?.negative?.length > 0,
    });

    useEffect(() => {
        getQuestionnaireResults();
    }, [getQuestionnaireResults]);

    // Callbacks
    const onExpand = () => {
        history.push('/knowledge-experience/expand-universe', state);
    };
    const onNextCLick = () => {
        onUniverseComplete();
    };

    // Renderers
    const renderInfobox = () => (
        <Infobox accent error>
            {errorUniverseComplete}
        </Infobox>
    );

    const renderHelpInfobox = () => (
        <Infobox accent>
            <span>{t('onBoarding.knowledgeAndExperienceHelpInfoBox')}</span>
        </Infobox>
    );

    const negativeResultsClassName = useMemo(() => getClassNames(
        'negative-results', { 'without-border': resultsAdapted?.positive.length === 0 },
    ), [resultsAdapted?.positive]);

    return (
        <ContentBox
            className="knowledge-and-experience-details-results"
        >
            <Preloader
                isLoading={isLoadingResults}
                error={errorResults}
            >
                <EmptyContent data={data?.Groups?.[0]} text={t('advisoryDashboard.noData')}>
                    <Title type={2}>{t('onBoarding.thankYouForAnswering')}</Title>
                    <div className="result-content">

                        {resultsAdapted?.positive.length > 0 && (
                            <div className="positive-results">
                                <div className="positive">
                                    <div className="icon">
                                        <Icon type="check-filled" size={16} />
                                    </div>
                                    <Paragraph>
                                        {t('onBoarding.resultKnowledgeAndExperience')}
                                    </Paragraph>
                                </div>
                                {(resultsAdapted?.positive || []).map((item) => (
                                    <div className="instrument-block">
                                        <Title type={4}>{item.Text}</Title>
                                        <Paragraph type="secondary">
                                            {`${t(`onBoarding.instrumentDescription${item.resource}`)}`}
                                        </Paragraph>
                                    </div>
                                ))}
                            </div>
                        )}
                        {resultsAdapted?.negative?.length > 0 && (
                            <div className={negativeResultsClassName}>
                                <div className="negative">
                                    <div className="icon">
                                        <div className="negative-icon" />
                                    </div>
                                    <Paragraph>
                                        {t('onBoarding.inOrderToOperateWith')}
                                    </Paragraph>
                                </div>
                                {(resultsAdapted?.negative || []).map((item) => (
                                    <div className="instrument-block">
                                        <Title type={4}>{item.Text}</Title>
                                        <Paragraph type="secondary">
                                            {`${t(`onBoarding.instrumentDescription${item.resource}`)}`}
                                        </Paragraph>
                                    </div>
                                ))}
                            </div>
                        )}
                        {resultsAdapted?.negative.length > 0 && renderHelpInfobox()}
                    </div>
                    <ButtonsBlockRow
                        additionalButton={resultsAdapted?.negative?.length > 0 && {
                            text: t('onBoarding.expandYourInstrumentUniverse'),
                            onClick: onExpand,
                        }}
                        primaryButton={{
                            text: t('confirmation.continue'),
                            onClick: onNextCLick,
                            disabled: continueDisabled,
                        }}
                    >
                        {errorUniverseComplete && renderInfobox()}
                    </ButtonsBlockRow>
                </EmptyContent>
            </Preloader>
        </ContentBox>
    );
};

Results.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            redoStep: PropTypes.bool,
            productId: PropTypes.string,
            productName: PropTypes.string,
            values: PropTypes.arrayOf(PropTypes.number),
            answers: PropTypes.arrayOf(PropTypes.shape({
                Answers: PropTypes.arrayOf(PropTypes.shape({
                    Id: PropTypes.number,
                    Text: PropTypes.string,
                })),
                Values: PropTypes.arrayOf(PropTypes.number),
                allAnswered: PropTypes.bool,
                Id: PropTypes.number,
            })),
        }),
    }).isRequired,
};

Results.defaultProps = {
};

export default Results;
