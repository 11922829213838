import { useContext } from 'react';
import OnBoardingContext from './context';

const useOnBoarding = () => {
    const context = useContext(OnBoardingContext);

    if (context.id === undefined) {
        throw new Error('useOnBoarding can only be used inside OnBoardingProvider');
    }

    return context;
};

export default useOnBoarding;
