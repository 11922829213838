import {
    ACCESS_DENIED,
    CONTACT_LOCKED,
    CONTACT_NOT_ACTIVATED,
    CONTACT_NOT_PASSWORD_SET,
    MTAN_INVALID,
    UNEXPECTED_ERROR,
    USERNAME_INVALID,
    INVALID_CREDENTIALS,
    UNEXPECTED_ERROR_OR_INVALID_PASSCODE, MTAN_VERIFICATION_FAILED,
} from '../static/requestResults';
import { ErrorModel } from './ErrorModel';
import { AUTH_ERROR } from './errorTypes';

export const COMMON_AUTH_ERROR = 'CommonAuthenticationError';
export const COMMON_ERROR = 'CommonError';

export const ACCESS_DENIED_MESSAGE = 'error.accessDenied';
export const USERNAME_INVALID_MESSAGE = 'error.invalidUsername';
export const INVALID_CREDENTIALS_MESSAGE = 'error.invalidLoginCredentials';
export const CONTACT_LOCKED_MESSAGE = 'error.yourAccountLocked';
export const CONTACT_NOT_ACTIVATED_MESSAGE = 'error.accountNotActivated';
export const CONTACT_NOT_PASSWORD_SET_MESSAGE = 'error.passwordNotSet';
export const UNEXPECTED_ERROR_MESSAGE = 'error.unexpectedError';
export const MTAN_INVALID_MESSAGE = 'error.invalidMtan';
export const UNEXPECTED_ERROR_OR_INVALID_PASSCODE_MESSAGE = 'error.invalidPasscodeOrUnexpectedError';

export default {
    [USERNAME_INVALID]: new ErrorModel({
        id: 3,
        type: COMMON_AUTH_ERROR,
        message: USERNAME_INVALID_MESSAGE,
    }),
    [INVALID_CREDENTIALS]: new ErrorModel({
        id: 26,
        type: COMMON_AUTH_ERROR,
        message: INVALID_CREDENTIALS_MESSAGE,
    }),
    [CONTACT_NOT_ACTIVATED]: new ErrorModel({
        id: 4,
        type: COMMON_AUTH_ERROR,
        message: CONTACT_NOT_ACTIVATED_MESSAGE,
    }),
    [CONTACT_LOCKED]: new ErrorModel({
        id: 7,
        type: COMMON_AUTH_ERROR,
        message: CONTACT_LOCKED_MESSAGE,
    }),
    [CONTACT_NOT_PASSWORD_SET]: new ErrorModel({
        id: 11,
        type: COMMON_AUTH_ERROR,
        message: CONTACT_NOT_PASSWORD_SET_MESSAGE,
    }),
    [ACCESS_DENIED]: new ErrorModel({
        id: 12,
        type: COMMON_AUTH_ERROR,
        message: ACCESS_DENIED_MESSAGE,
    }),
    [UNEXPECTED_ERROR]: new ErrorModel({
        id: 15,
        type: COMMON_ERROR,
        message: UNEXPECTED_ERROR_MESSAGE,
    }),
    [UNEXPECTED_ERROR_OR_INVALID_PASSCODE]: new ErrorModel({
        id: 27,
        type: COMMON_ERROR,
        message: UNEXPECTED_ERROR_OR_INVALID_PASSCODE_MESSAGE,
    }),
    [MTAN_INVALID]: new ErrorModel({
        id: 25,
        type: AUTH_ERROR,
        message: MTAN_INVALID_MESSAGE,
    }),
    [MTAN_VERIFICATION_FAILED]: new ErrorModel({
        id: 29, // LAST error id
        type: AUTH_ERROR,
        message: MTAN_INVALID_MESSAGE,
    }),
};
