import axios from 'axios';
import clarity from './clarity';

const axiosApiInstance = axios.create();
const getUrlOrigin = (url) => {
    const link = document.createElement('a');

    link.href = url;

    return link.origin;
};

export const checkUrlClarity = (url) => getUrlOrigin(url) === global.AppConfig.CLARITY_URL;

export const getAccessToken = async () => {
    const response = await clarity.generateToken();

    clarity.setBasicAuth(response?.data?.accessToken);

    return response?.data?.accessToken;
};

export const setClarityRefreshToken = () => {
    axios.interceptors.response.use((response) => response, async (error) => {
        const originalRequest = error.config;
        const isUrlClarity = checkUrlClarity(error.config?.url);

        // eslint-disable-next-line no-underscore-dangle
        if (isUrlClarity && error.response?.status === 401 && !originalRequest._retry) {
            await getAccessToken();

            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true;
            originalRequest.headers = clarity.generalSettings?.headers;

            return axiosApiInstance(originalRequest);
        }


        return Promise.reject(error);
    });
};
