import { useEffect, useState, useCallback } from 'react';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';

export const useTickets = (memberId) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isDeletingTask, setDeletingTask] = useState(false);
    const [data, setData] = useState([]);

    /* useEffect(() => {
        setLoading(true);
        ServiceManager.CustomLayerService('getPersonTasks', [memberId])
            .then((response) => {
                try {
                    setData(response.data);
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch(handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )).finally(() => {
                setLoading(false);
            });
    }, []); */


    const deleteTask = useCallback(async (ticketId) => {
        setError(null);
        setDeletingTask(true);

        /* ServiceManager.CustomLayerService('deleteTask', [ticketId])
            .then((response) => {
                try {
                    if (response?.status <= 204) {
                        setDeletingTask(false);
                    }
                } catch (err) {
                    setDeletingTask(false);
                    throw new AdapterError(err);
                }
            }).catch((err) => {
                setError(err);
            }).finally(() => {
                setDeletingTask(false);
            }); */

        setDeletingTask(false);
    }, []);

    return {
        data, isLoading, error, deleteTask, isDeletingTask,
    };
};
