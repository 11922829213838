import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import widget from '@clarity-ai/widget';
import { tokenSelector, useClaritySelector } from 'domain/Clarity';
import { useTheme } from 'theming';
import {
    customizationObject, MODULE, SCORES_IDS, SECTIONS, getCssUrl,
} from './constants';
import Preloader from '../Preloader';
import { useClarityWidget } from './hooks/useClarityWidget';
import './ClarityWidget.css';

const ClarityWidget = ({ data }) => {
    const { i18n: { language } } = useTranslation();
    const containerRef = useRef(null);
    const updateWidget = useCallback(() => {
        if (containerRef.current) {
            widget.refresh(containerRef.current, console.warn);
        }
    }, [containerRef]);
    const { theme } = useTheme();

    // Clarity Domain
    const token = useClaritySelector(tokenSelector);

    // Helper hook
    const { error, portfolioId } = useClarityWidget({ data, updateWidget });

    useEffect(() => {
        widget.load(global.AppConfig.CLARITY_DOMAIN);
        updateWidget();
    }, [updateWidget]);

    useEffect(() => {
        if (theme) updateWidget();
    }, [theme, updateWidget]);

    return (
        <Preloader isLoading={portfolioId === undefined} error={error}>
            <div
                className="ClarityWidget"
                data-clarity-widget
                data-lang={language === 'de' ? 'en' : language}
                data-token={token}
                data-portfolio={portfolioId}
                data-scores-ids={SCORES_IDS}
                data-sections={SECTIONS}
                data-module={MODULE}
                data-custom-css={getCssUrl(theme)}
                data-entities="portfolios"
                data-style-attributes-json={JSON.stringify(customizationObject)}
                ref={containerRef}
            />
        </Preloader>
    );
};

ClarityWidget.propTypes = {
    data: PropTypes.shape({
        securities: PropTypes.arrayOf(PropTypes.shape({
            isin: PropTypes.string,
            percentage: PropTypes.number,
        })),
        total: PropTypes.shape({
            value: PropTypes.number,
            currency: PropTypes.string,
        }),
    }),
};

ClarityWidget.defaultProps = {
    data: {
        securities: [],
        total: {
            value: undefined,
            currency: undefined,
        },
    },
};

export default ClarityWidget;
