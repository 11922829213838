import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, Infobox, Accordion, AccordionPanel as Panel, Table,
} from 'ui-library';
import EmptyContent from 'components/EmptyContent';
import { useTranslation } from 'react-i18next';
import Allocation from 'components/Allocation';
import AllocationCompare from 'components/AllocationCompare';
import Analysis from 'components/Analysis';
import Preloader from 'components/Preloader';
import { useFormatting } from 'locale';
import {
    goalCreationSelector,
    goalSummarySelector,
    onBoardingDataSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import { useModelPortfolio } from 'domain/Portfolio';
import { adaptModelPortfolio } from 'domain/Portfolio/adapters/adaptModelPortfolio';
import { formatDate } from 'utils/datetime';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { adaptPositions } from 'adaptors/adaptPositions';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import RiskBandwidthDisclaimer from 'components/RiskBandwidthDisclaimer';
import { getPositionsColumns, getComparePositionsColumns } from '../constants';
import OnBoardingBaseTemplate from '../../../components/OnBoardingBaseTemplate';
import { adaptReview } from '../adaptors/adaptReview';
import { adaptConstraints } from '../adaptors/adaptConstraints';
import { useGoalReach } from '../hooks/useGoalReach';
import { useCheckRiskProfile } from '../../../../Portfolios/hooks/useCheckRiskProfile';
import ReviewPageOverview from '../../../components/ReviewPageOverview';
import { useBaseModelPortfolio } from '../hooks/useBaseModelPortfolio';

function ReviewPageDiscretionary({
    onPageChange, onPrev, location: { pathname }, clientId,
}) {
    const { t } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();
    const [riskCategory, setRiskCategory] = useState(null);

    // OnBoarding Domain
    const {
        getGoalDetails,
        createdProposalId,
        saveProposalId,
        modifiedPositions,
        product,
        getGoalChanges,
        saveReviewData,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        data: modelPortfolioId, isLoading, error,
    } = useOnBoardingSelector(goalCreationSelector);
    const {
        saveProposal, isLoadingSave, errorSave,
        getProposal, data: dataProposal, isLoading: isLoadingProposal, error: errorProposal,
    } = useOnBoardingSelector(goalSummarySelector);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();

    // Base model portfolio
    const {
        dataRaw, isLoading: isLoadingBase, error: errorBase,
    } = useBaseModelPortfolio();

    // Data
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const isNoGoal = useMemo(() => goalDetails.selectedGoal === 'no-goal', [goalDetails.selectedGoal]);
    const proposalId = useMemo(() => typeof createdProposalId === 'number' && createdProposalId,
        [createdProposalId]);
    const projectionYears = useMemo(() => formatDate(goalDetails.targetDate, 'YYYY')
        - formatDate(new Date(), 'YYYY'), [goalDetails.targetDate]);
    const optionsForAdapt = useMemo(
        () => ({
            currency: goalDetails.selectedCurrency,
            portfolioCurrency: goalDetails.selectedCurrency?.label,
            portfolioValue: +goalDetails.initialInvestment,
            portfolioTotalValue: +goalDetails.initialInvestment,
            portfolioSecuritiesValue: +goalDetails.initialInvestment,
            recurringPayment: +goalDetails.recurringPayment,
            productName: product.name,
            projectionYears,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            nameLength: 35,
            positionLink: `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`,
        }),
        [
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            goalDetails.recurringPayment,
            projectionYears,
            product.name,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            pathname,
        ],
    );
    const data = useMemo(() => adaptModelPortfolio({ ...optionsForAdapt, data: dataRaw }),
        [dataRaw, optionsForAdapt]);
    const reviewData = useMemo(() => {
        const reviewDataAdapted = dataProposal ? adaptReview(
            modelPortfolio.Id
                ? { ...modelPortfolio, ModelPortfolioName: modelPortfolio.Name }
                : dataProposal,
            { ...optionsForAdapt, comparedAllocations: data.allocationGroups },
        ) : {};

        return {
            ...data,
            ...reviewDataAdapted,
            overview: {
                ...data?.overview,
                ...reviewDataAdapted?.overview,
                strategy: reviewDataAdapted?.overview?.strategy || data?.overview?.strategy,
                modelPortfolio: reviewDataAdapted?.overview?.modelPortfolio
                    || data?.overview?.modelPortfolio,
            },
        };
    }, [modelPortfolio, data, dataProposal, optionsForAdapt]);
    const isComparison = modifiedPositions?.positions?.length || goalChanges;
    const comparisonData = isComparison ? reviewData : null;
    const positions = useMemo(() => (isComparison && reviewData?.positionsRaw
        ? groupComparedPositions({
            positions: data?.Positions,
            modelPositions: reviewData?.positionsRaw,
            ...optionsForAdapt,
        })
        : adaptPositions(data?.Positions, optionsForAdapt)
    ), [isComparison, reviewData?.positionsRaw, data?.Positions, optionsForAdapt]);

    // Helpers hooks
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks({
        currencyId: goalDetails.selectedCurrency?.value,
        productId: +product?.id,
    }, isLoading, true);
    const {
        analysisData,
        isLoadingModel, errorModel, onPerformanceChange,
        performanceSelected, projectionResponse, volatility,
    } = useAnalysisDetails({
        portfolio: data,
        strategy: comparisonData,
        positions,
        isLoading,
        benchmark,
        isEdit: isComparison,
        additionalOptions: { benchmarkSelected, withPerformanceBenchmarks: true },
    });

    const {
        dataExternal, isGoalReach,
    } = useGoalReach({
        goalState: goalDetails,
        projectionResponse,
    });

    // Effects
    useEffect(() => {
        (async () => {
            if (goalChanges) {
                getModelPortfolio(goalChanges, { currencyId: goalDetails.selectedCurrency?.value });
            }
        })();
    }, [getModelPortfolio, product?.id, goalDetails.selectedCurrency?.value, goalChanges]);
    useEffect(() => {
        if (goalChanges) {
            setRiskCategory(modelPortfolio.RiskCategory);
        }
    }, [modelPortfolio, goalChanges]);

    useEffect(() => {
        if (proposalId) {
            getProposal(proposalId);
        }
    }, [proposalId, getProposal]);

    // Callbacks
    const onConfirm = () => {
        Modal.confirm({
            title: t('onBoarding.confirmInvestment'),
            content: t('onBoarding.confirmInvestmentText'),
            okText: t('onBoarding.confirm'),
            onOk: async () => {
                if (typeof createdProposalId !== 'number') {
                    const params = {
                        ModelPortfolioId: isComparison ? undefined : modelPortfolioId?.Id,
                        InstrumentAllocations: isComparison
                            ? reviewData?.investmentAllocations : undefined,
                        Name: goalDetails?.goalName,
                        RecommendedInvestment: +goalDetails?.initialInvestment,
                        ProductId: +product.id,
                        CurrencyId: goalDetails?.selectedCurrency.value,
                        OptimizationConstraints: adaptConstraints(reviewData?.constraints),
                    };
                    const savedProposalData = await saveProposal(params);

                    if (savedProposalData?.Id) {
                        saveProposalId(savedProposalData.Id);
                        saveReviewData({
                            modelPortfolioId: reviewData?.modelPortfolioId,
                            benchmarkId: reviewData?.benchmarkId,
                        });
                        onPageChange('summary');
                    }
                }

                onPageChange('summary');
            },
            cancelText: t('onBoarding.cancel'),
        });
    };

    const onNextClick = () => {
        if (!showRiskDisclaimer) {
            onConfirm();

            return;
        }

        Modal.confirm({
            title: t('overview.disclaimer'),
            content: t('changeStrategy.riskProfileContent'),
            okText: t('confirmation.yes'),
            cancelText: t('confirmation.no'),
            onOk: () => {
                onConfirm();
            },
            className: 'accept-risk-profile',
        });
    };
    const onStrategyChange = () => {
        onPageChange('change-strategy');
    };
    const onModelChange = () => {
        onPageChange('change-model');
    };
    const onOptimize = () => {
        onPageChange('optimize');
    };

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const {
        compareVolatility, clientRiskBandwidth,
    } = useCheckRiskProfile(clientId, +product?.id);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: riskCategory?.Id,
        riskCategoryName: riskCategory?.Name,
    }), [riskCategory]);

    useEffect(() => {
        setShowRiskDisclaimer(false);
        if (volatility && riskCategoryId) {
            compareVolatility(volatility, riskCategoryId).then((passed) => {
                setShowRiskDisclaimer(!passed);
            });
        }
    }, [compareVolatility, volatility, riskCategoryId]);
    // Renderers
    const isLoadingData = useMemo(() => isLoading || isLoadingMP || isLoadingProposal,
        [isLoading, isLoadingMP, isLoadingProposal]);
    const errorData = useMemo(() => error || errorMP || errorProposal,
        [error, errorMP, errorProposal]);
    const AllocationRender = useMemo(() => {
        const Component = comparisonData?.investmentAllocation ? AllocationCompare : Allocation;

        return (
            <Component
                data={data?.investmentAllocation}
                dataNew={comparisonData?.investmentAllocation}
                isLoading={isLoadingData}
                error={errorData}
            />
        );
    }, [
        data?.investmentAllocation,
        comparisonData?.investmentAllocation,
        isLoadingData,
        errorData,
    ]);

    const renderRiskDisclaimer = () => (
        <div className="risk-bandwidt-disclaimer">
            <RiskBandwidthDisclaimer
                risk={volatility}
                min={clientRiskBandwidth?.Min}
                max={clientRiskBandwidth?.Max}
                CRP={riskCategoryName}
            />
        </div>
    );

    return (
        <OnBoardingBaseTemplate
            title={goalDetails.goalName}
            error={errorData || errorBase}
            isLoading={isLoadingData || isLoadingBase}
            className="on-boarding_review goal-review"
            classNameContent="stepper-content-full single-portfolio"
            prevButton={{
                onClick: onPrev,
            }}
            additionalComponent={(
                <>
                    {!isNoGoal && (
                        <Button type="secondary" size="small" disabled={isLoading} onClick={onOptimize}>
                            {t('onBoarding.goal.optimize')}
                        </Button>
                    )}
                </>
            )}
            nextButton={{
                text: t('onBoarding.confirmInvestmentMix'),
                loading: isLoadingSave,
                disabled: isLoading,
                onClick: onNextClick,
            }}
            topInfoboxContent={!isGoalReach && !isLoadingModel && !isNoGoal && (
                <div className="goal-reached">
                    <Infobox accent>{t('onBoarding.cannotReachedGoal')}</Infobox>
                </div>
            )}
        >
            <EmptyContent data={data} text={t('advisoryDashboard.noData')}>
                <Preloader isLoading={isLoading} error={error}>
                    {showRiskDisclaimer && renderRiskDisclaimer()}
                    <ReviewPageOverview
                        data={reviewData.overview}
                        onStrategyChange={onStrategyChange}
                        onModelChange={onModelChange}
                    />
                    <Accordion defaultActiveKey={['2', '3', '4']}>
                        <Panel header={t('portfolios.allocation')} key="2" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('portfolios.analysis')} key="3" className="investment-performance">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                                oneProjectionColumn={!isComparison}
                                projectionDataExternal={dataExternal}
                                isPortfolioProposed
                                withPerformanceBenchmarks
                            />
                        </Panel>
                        <Panel header={t('portfolios.positions')} key="4" className="positions">
                            <Table
                                expandedColumn="name"
                                className="positions-table"
                                data={positions}
                                columns={isComparison
                                    ? getComparePositionsColumns(t)
                                    : getPositionsColumns(t, getFormattedNumber)
                                }
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                }}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </Preloader>
                {errorSave && <Infobox error>{errorSave.message}</Infobox>}
            </EmptyContent>
        </OnBoardingBaseTemplate>
    );
}

ReviewPageDiscretionary.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    onPrev: PropTypes.func,
    onPageChange: PropTypes.func,
    clientId: PropTypes.number.isRequired,
};

ReviewPageDiscretionary.defaultProps = {
    onPrev: () => {
    },
    onPageChange: () => {
    },
};

export default ReviewPageDiscretionary;
