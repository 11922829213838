import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui-library';
import history from 'services/history';

class ButtonLink extends Component {
    onClick = () => {
        const { href } = this.props;

        if (href !== null) history.push(href);
    };

    render() {
        const {
            type, children, href, ...props
        } = this.props;

        return (
            <Button type={type} {...props} onClick={this.onClick}>
                {children}
            </Button>
        );
    }
}

ButtonLink.propTypes = {
    children: PropTypes.string,
    type: PropTypes.string,
    href: PropTypes.string,
};

ButtonLink.defaultProps = {
    children: '',
    type: 'button',
    href: null,
};

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
