import {
    ACCESS_DENIED,
    CONTACT_LOCKED,
    CONTACT_NOT_ACTIVATED,
    CONTACT_NOT_PASSWORD_SET,
    DEVICE_ALREADY_ENROLLED,
    DEVICE_NOT_ENROLLED,
    EXPIRED,
    PASSCODE_INVALID,
    PASSWORD_INVALID,
    SESSION_ALREADY_ACTIVE,
    SESSION_EXPIRED,
    SESSION_INVALID,
    TWO_FACTOR_DISABLED,
    USERNAME_INVALID,
    INVALID_CREDENTIALS,
} from '../static/requestResults';
import commonErrorMessageToErrorModelXref, {
    ACCESS_DENIED_MESSAGE,
    CONTACT_LOCKED_MESSAGE,
    CONTACT_NOT_ACTIVATED_MESSAGE,
    CONTACT_NOT_PASSWORD_SET_MESSAGE,
    USERNAME_INVALID_MESSAGE,
    INVALID_CREDENTIALS_MESSAGE,
} from './commonErrors';
import { ErrorModel } from './ErrorModel';
import { AUTH_ERROR } from './errorTypes';

export const LOGIN_FAILED_UNEXPECTEDLY = 'LoginFailedUnexpectedly';

const LOGIN_FAILED_UNEXPECTEDLY_MESSAGE = 'error.unexpectedLoginFail';
const DEVICE_NOT_ENROLLED_MESSAGE = 'error.deviceNotEnrolledMsg';
const LOGIN_FAILED = 'error.loginFailed';
const PASSCODE_INVALID_MESSAGE = 'error.passCodeInvalidMsg';
const SESSION_EXPIRED_MESSAGE = 'error.sessionExpired';
const SESSION_INVALID_MESSAGE = 'error.sessionInvalid';
const TWO_FACTOR_DISABLED_MESSAGE = 'error.twoFactorDisabledMsg';
const DEVICE_ALREADY_ENROLLED_MESSAGE = 'error.deviceAlreadyEnrolledMsg';
const EXPIRED_MESSAGE = 'error.expiredMsg';
const PASSWORD_INVALID_MESSAGE = 'error.passwordInvalidMsg';
const SESSION_ALREADY_ACTIVE_MESSAGE = 'error.sessionAlreadyActiveMsg';

const errorMessageToErrorModelXref = {
    ...commonErrorMessageToErrorModelXref,
    [PASSCODE_INVALID]: new ErrorModel({
        id: 1,
        type: AUTH_ERROR,
        message: PASSCODE_INVALID_MESSAGE,
    }),
    [PASSWORD_INVALID]: new ErrorModel({
        id: 2,
        type: AUTH_ERROR,
        message: PASSWORD_INVALID_MESSAGE,
    }),
    [SESSION_EXPIRED]: new ErrorModel({
        id: 5,
        type: AUTH_ERROR,
        key: SESSION_EXPIRED,
        message: SESSION_EXPIRED_MESSAGE,
    }),
    [SESSION_ALREADY_ACTIVE]: new ErrorModel({
        id: 6,
        type: AUTH_ERROR,
        message: SESSION_ALREADY_ACTIVE_MESSAGE,
    }),
    [DEVICE_NOT_ENROLLED]: new ErrorModel({
        id: 8,
        type: AUTH_ERROR,
        message: DEVICE_NOT_ENROLLED_MESSAGE,
    }),
    [SESSION_INVALID]: new ErrorModel({
        id: 9,
        type: AUTH_ERROR,
        message: SESSION_INVALID_MESSAGE,
    }),
    [TWO_FACTOR_DISABLED]: new ErrorModel({
        id: 10,
        type: AUTH_ERROR,
        message: TWO_FACTOR_DISABLED_MESSAGE,
    }),
    [DEVICE_ALREADY_ENROLLED]: new ErrorModel({
        id: 13,
        type: AUTH_ERROR,
        message: DEVICE_ALREADY_ENROLLED_MESSAGE,
    }),
    [EXPIRED]: new ErrorModel({
        id: 14,
        type: AUTH_ERROR,
        message: EXPIRED_MESSAGE,
    }),
    [LOGIN_FAILED_UNEXPECTEDLY]: new ErrorModel({
        id: 24,
        type: AUTH_ERROR,
        message: LOGIN_FAILED_UNEXPECTEDLY_MESSAGE,
    }),
};

export const getErrorModelByStatus = (status) => errorMessageToErrorModelXref[status];

const reduxErrorByStatus = {
    [LOGIN_FAILED_UNEXPECTEDLY]: {
        _error: LOGIN_FAILED_UNEXPECTEDLY_MESSAGE,
    },
    [USERNAME_INVALID]: {
        username: USERNAME_INVALID_MESSAGE,
        _error: LOGIN_FAILED,
    },
    [INVALID_CREDENTIALS]: {
        _error: INVALID_CREDENTIALS_MESSAGE,
    },
    [CONTACT_LOCKED]: {
        username: CONTACT_LOCKED_MESSAGE,
        _error: LOGIN_FAILED,
    },
    [PASSWORD_INVALID]: {
        password: PASSWORD_INVALID_MESSAGE,
        _error: LOGIN_FAILED,
    },
    [CONTACT_NOT_PASSWORD_SET]: {
        password: CONTACT_NOT_PASSWORD_SET_MESSAGE,
        _error: LOGIN_FAILED,
    },
    [CONTACT_NOT_ACTIVATED]: {
        _error: CONTACT_NOT_ACTIVATED_MESSAGE,
    },
    [ACCESS_DENIED]: {
        _error: ACCESS_DENIED_MESSAGE,
    },
    [SESSION_ALREADY_ACTIVE]: {
        password: SESSION_ALREADY_ACTIVE_MESSAGE,
        _error: LOGIN_FAILED,
    },
    [DEVICE_NOT_ENROLLED]: {
        _error: {
            type: DEVICE_NOT_ENROLLED,
            message: DEVICE_NOT_ENROLLED_MESSAGE,
        },
    },
    [DEVICE_ALREADY_ENROLLED]: {
        _error: DEVICE_ALREADY_ENROLLED_MESSAGE,
    },
};

export const getReduxErrorByStatus = (status) => reduxErrorByStatus[status];
