import { createContext } from 'react';

const initialContext = {
    id: undefined,
    clientId: undefined,
    // logic from usePortfolioDetails hook
    portfolio: {
        data: {
            investmentAllocation: { chart: [{ data: { pie: {}, bar: {}, list: {} } }] },
            positions: [],
        },
        dataRaw: {},
        error: null,
        isLoading: false,
        getPortfolioDetails: () => {},
    },
    // logic from useModelPortfolio hook
    modelPortfolio: {
        data: {},
        dataRaw: {},
        error: null,
        isLoading: false,
        getModelPortfolio: () => {},
    },
    // logic from useOrderBook hook
    orderBook: {
        data: false,
        dataRaw: [],
        error: null,
        isLoading: false,
        getOrderBook: () => {},
    },
    // logic from useTradingCard hook
    tradingCard: {
        dataRaw: {},
        error: null,
        isLoading: false,
        errorDeleting: null,
        isDeleting: false,
        getTradingCard: () => {},
    },
    // logic from usePortfolioModify hook
    portfolioModify: {
        errorModify: null,
        isModifying: false,
        modifyPosition: () => {},
        dataRaw: {},
        error: null,
        isLoading: false,
        getModifiedPositions: () => {},
        risk: {},
        errorRisk: null,
        isLoadingRisk: false,
        getRisk: () => {},
    },
    // logic from usePortfolioQuickEdit hook
    portfolioQuickEdit: {
        initializePositions: () => {},
        positionsAdapted: [],
        adaptData: () => {},
        dataRaw: [],
        changes: [],
        changesManual: [],
        changesNonManual: [],
        dataSummary: {},
        risk: {},
        errorRisk: null,
        isLoadingRisk: false,
        onAllocationChange: () => {},
        optimizePositions: () => {},
        getRisk: () => {},
    },
    // logic from useTransactions hook
    transactions: {
        data: [],
        dataRaw: {},
        error: null,
        isLoading: false,
        getTransactions: () => {},
    },
    // logic from useCashFlows hook
    cashFlows: {
        data: [],
        dataRaw: {},
        error: null,
        isLoading: false,
        getCashFlows: () => {},
    },
    // logic from useProposalDetails hook
    proposal: {
        data: {
            investmentAllocation: { chart: [{ data: { pie: {}, bar: {}, list: {} } }] },
            positions: [],
        },
        dataRaw: {},
        error: null,
        isLoading: false,
        getProposalDetails: () => {},
    },
};

const PortfolioContext = createContext(initialContext);

export default PortfolioContext;
