import { gql } from '@apollo/client';

export const SECURITY_ATTRIBUTES = gql`
    query CommonInstrumentData($ids: [Int!], $take: Int! = 100) {
        securities(
            where: {
                id: { in: $ids }
            },
            take: $take
        ){
            items {
                id,
                uId
                name
                isin
                factor
                roundingLot
                tradingUnit
                tradingPrice
                isPricePercentage
                isInvestable
                isActive
                assetClass {  id uId name description isActive externalId }
                type { id name description externalId }
                sector { id uId name isActive }
                country { id uId isoCode name phoneCode continent { id name } }
                currency { id uId name symbol }
            }
        }
    }
`;
