import React from 'react';
import PropTypes from 'prop-types';
import { getClassNames } from 'utils';
import './Overview.css';

function OverviewPanel({ className, children }) {
    return (
        <div className={getClassNames('overview-panel', className)}>
            {children}
        </div>
    );
}

OverviewPanel.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

OverviewPanel.defaultProps = {
    className: null,
    children: null,
};

export default OverviewPanel;
