import { Redirect, Route, useLocation } from 'react-router-dom';
import React, { useMemo } from 'react';

export const HashLoginRoute = ({ ...props }) => {
    const location = useLocation();

    const isURLLogin = useMemo(() => (
        global.location.hash?.split('#token=')?.[1] !== undefined
    ), [location.hash]);

    return isURLLogin ? <Route {...props} /> : <Redirect to="/login" />;
};
