import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    ContentBox,
} from 'ui-library';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import WidgetError from 'components/WidgetError';
import PageHeader from 'components/PageHeader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import { CHANGE_MODEL } from 'constants/constants';
import history from 'services/history';
import { useModelChange } from 'hooks/useModelChange';
import { useModelPortfolio, portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';
import DetailedOrdersAccordion from '../../../components/DetailedOrdersAccordion';
import { useStrategyDocuments } from '../../../hooks/useStrategyDocuments';
import { useSecurityTransactions } from '../../common/hooks/useSecurityTransactions';
import { onPreventRedirect } from '../../common/utils';
import './DetailedOrders.css';
import { useExecuteStrategy } from '../../common/hooks/useExecuteStrategy';

function DetailedOrders(props) {
    const { match: { params: { portfolioId } } } = props;
    const clientId = useSelector(contactIdSelector);
    const { t } = useTranslation();
    const modelId = JSON.parse(sessionStorage.getItem(CHANGE_MODEL));

    // Portfolio Domain
    const { data, isLoading, error } = usePortfolioSelector(portfolioSelector);
    const {
        data: modelPortfolio, isLoading: isLoadingModel, error: errorModel, getModelPortfolio,
    } = useModelPortfolio();

    useEffect(() => {
        getModelPortfolio(modelId);
    }, [getModelPortfolio, modelId]);

    // Hooks
    const { onModelReset } = useModelChange();
    const {
        securityTransactionsData, isLoadingSecurityTransactions, errorSescurityTransactions,
    } = useSecurityTransactions({
        currencyId: data?.currencyId,
        investmentAllocations: modelPortfolio?.investmentAllocations,
        sourceAllocations: data?.investmentAllocations,
        amount: data?.portfolioValue,
        portfolioCurrency: data?.currency,
        portfolioId,
        productID: data?.productId,
        clientId,
        actionType: CHANGE_MODEL,
    });
    const orders = {
        isLoadingOrders: isLoadingSecurityTransactions,
        errorOrders: errorSescurityTransactions,
        data: securityTransactionsData,
    };
    const {
        documents: documentsData, errorDocuments, isLoadingDocuments,
    } = useStrategyDocuments(data.productId);
    const documents = { isLoadingDocuments, errorDocuments, data: documentsData };
    const { onAcceptExecute, isAccepting, errorAccepting } = useExecuteStrategy(
        clientId,
        portfolioId,
        CHANGE_MODEL,
    );

    // Callbacks
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onModelReset();
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const backURL = useMemo(() => `/portfolios/${portfolioId}`, [portfolioId]);
    const onBack = () => {
        history.push(`${backURL}/actions/change-model`);
    };
    const onExecuteChanges = () => {
        const executeParams = { modelPortfolioId: Number(modelId) };

        Modal.confirm({
            title: t('confirmation.executeChanges'),
            content: t('confirmation.executeChangesContent'),
            okText: t('confirmation.executeChanges'),
            onOk: () => {
                onModelReset();
                onAcceptExecute(executeParams);
            },
            cancelText: t('confirmation.cancel'),
            className: 'execute',
        });
    };

    return (
        <ContentBox className="change-model confirmation-page detailed-orders">
            <Preloader isLoading={isLoading || isLoadingModel} error={error || errorModel}>
                <PageHeader
                    title={t('confirmation.ordersToBeExecuted')}
                    breadCrumbsCurrent={t('confirmation.ordersToBeExecuted')}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            label: t('portfolios.title'),
                            onClick: (e) => onPreventRedirect(e, '/portfolios', onCancel),
                        },
                        {
                            to: `/portfolios/${portfolioId}`,
                            label: data.title,
                            onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                        },
                        {
                            to: `/portfolios/${portfolioId}/actions/change-model`,
                            label: t('overview.changeModel'),
                        },
                    ]}
                />
                <DetailedOrdersAccordion
                    orders={orders}
                    documents={documents}
                />
                <ButtonsBlockRow
                    leftButton={{
                        text: t('confirmation.back'),
                        onClick: onBack,
                    }}
                    primaryButton={{
                        text: t('confirmation.execute'),
                        loading: isAccepting,
                        disabled: !!errorSescurityTransactions,
                        onClick: onExecuteChanges,
                    }}
                >
                    {errorAccepting && (
                        <WidgetError accent error message={errorAccepting.message} />
                    )}
                </ButtonsBlockRow>
            </Preloader>
        </ContentBox>
    );
}

DetailedOrders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

DetailedOrders.defaultProps = {
};

export default DetailedOrders;
