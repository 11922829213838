import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Messages from './Messages';
import NewMessage from './pages/NewMessage';
import ReplyMessage from './pages/ReplyMessage';

const Router = (props) => {
    const {
        match: { path },
    } = props;

    return (
        <Switch>
            <Route exact path={`${path}`} component={Messages} />
            <Route exact path={`${path}/new`} component={NewMessage} />
            <Route exact path={`${path}/reply/:messageId`} component={ReplyMessage} />
            <Route exact path={`${path}/:messageId`} component={Messages} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.displayName = 'Router';

export default Router;
