import { useMemo } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

const ACCESS_INSTRUMENT = gql`
    query AccessInstrument($positionId: Int!) {
        securities(
            where: { id: { eq: $positionId } }
        ){
            items {
                isActive
                isInvestable
            }
        }
    }
`;

export const useInstrumentAccess = (options) => {
    const { instrumentId: instrumentIdOption = 14 } = options;

    // Callbacks
    const [
        getInstrumentAccess, { loading: isLoading, error, data: raw },
    ] = useLazyQuery(ACCESS_INSTRUMENT, { variables: { positionId: +instrumentIdOption } });

    // Data
    const data = useMemo(() => raw?.securities?.items?.[0] || {}, [raw]);

    return {
        data,
        error,
        isLoading,
        getInstrumentAccess,
    };
};
