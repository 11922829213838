import { sorterNumber, sorterString } from 'utils/sorting';
import StrategyWithWarning from 'components/StrategyWithWarning/StrategyWithWarning';

export const reviewPortfoliosColumns = (t) => [
    {
        key: 'Name',
        title: t('portfolios.columns.name'),
        sortable: true,
        sorter: sorterString('Name'),
    },
    {
        key: 'Product',
        title: t('portfolios.columns.product'),
        sorter: sorterString('Product'),
    },
    {
        key: 'Strategy',
        title: t('portfolios.columns.strategy'),
        sorter: sorterString('Strategy.strategyRiskName'),
        render: StrategyWithWarning,
    },
    {
        key: 'Value',
        title: t('portfolios.columns.value'),
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Value'),
    },
];
