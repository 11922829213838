import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import News from 'components/News';
import { useNews } from '../../hooks/useNews';
import { useLoadMoreNews } from '../../hooks/useLoadMoreNews';

function ExternalNews() {
    const { t } = useTranslation();
    const pageSize = 9;
    const {
        data, isLoading, error, getRssNews,
    } = useNews(undefined, { rssNews: pageSize, currentPage: 1 });

    const { nextArticles, newsList } = useLoadMoreNews({
        initialNews: data, pageSize, getNewsData: getRssNews,
    });

    return (
        <News
            title={t('news.externalNews')}
            data={newsList}
            isLoading={isLoading}
            error={error}
            pageSize={pageSize}
            nextArticles={nextArticles}
            nextArticlesSize={3}
            loadMore
        />
    );
}

ExternalNews.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ id: PropTypes.string }),
    }).isRequired,
};

ExternalNews.defaultProps = {};

export default ExternalNews;
