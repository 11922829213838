import {
    BondId, FutureId, OptionId, StructuredProductId,
} from 'constants/instrument';

export const calcRoundingLot = (data) => (Math.log10(data) < 0 ? Math.abs(Math.log10(data)) : 0);

export const calculatePrice = ({
    tradingPrice, isPricePercentage, factor, typeId,
}) => {
    if (!tradingPrice) return 0;

    let price = tradingPrice * (0.01 ** +(isPricePercentage || 0)) * (factor || 1);

    if ([OptionId, FutureId].includes(typeId)) {
        price = tradingPrice * (0.01 ** +(isPricePercentage || 0));
    }
    if ([BondId, StructuredProductId].includes(typeId)) {
        price *= 100;
    }

    return price;
};
