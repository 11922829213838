import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';

export const usePortfolioDetails = ({
    adaptPortfolioDetails, clientId, portfolioId, positionId, securityTypeId,
}) => {
    const [errorPortfolioDetails, setError] = useState(null);
    const [isLoadingPortfolioDetails, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [dataPortfolioDetails, setDataPortfolioDetails] = useState({ title: '', positions: [], overview: {} });
    const { i18n } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    useEffect(() => {
        setLoading(true);
        const params = { language: i18n.language };

        ServiceManager.portfolioManagement('getPortfolioDetails', [portfolioId, clientId, params])
            .then((response) => {
                try {
                    setData(response.data);
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [clientId, portfolioId, positionId, i18n.language]);

    useEffect(() => {
        try {
            setDataPortfolioDetails(adaptPortfolioDetails({
                data, positionId, getFormattedNumber, securityTypeId,
            }));
        } catch (err) {
            throw new AdapterError(err);
        }
    }, [adaptPortfolioDetails, data, positionId, getFormattedNumber, securityTypeId]);

    return { dataPortfolioDetails, isLoadingPortfolioDetails, errorPortfolioDetails };
};
