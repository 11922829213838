/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import loginReducer from './redux/auth/authReducers';
import enrollReducer from './redux/enroll/enrollReducers';
import sessionReducer from './redux/session/sessionReducers';
import history from './services/history';

export default combineReducers({
    router: connectRouter(history),
    auth: loginReducer,
    enroll: enrollReducer,
    form: formReducer,
    session: sessionReducer,
});
