import { Title, Button, Checkbox } from 'ui-library';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './RememberMe.css';

export class RememberMe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRememberMeChecked: false,
        };
    }

    onRememberMeChange = (event) => {
        const { checked } = event.currentTarget;
        const { onChange } = this.props;

        onChange(checked);
        this.setState({
            isRememberMeChecked: checked,
        });
    };

    onSubmit = () => {
        const { onSubmit } = this.props;
        const { isRememberMeChecked } = this.state;

        onSubmit(isRememberMeChecked);
    };

    render() {
        const { isRememberMeChecked } = this.state;
        const { isInline, title, t } = this.props;

        return (
            <div className="RememberMe content-pane">
                {title !== undefined ? <Title type={2}>{title}</Title> : null}
                <Checkbox
                    label={t('login.NoValidateSharedBrowser')}
                    checked={isRememberMeChecked}
                    onClick={this.onRememberMeChange}
                    name="rememberMe"
                />
                {isInline === false ? (
                    <div className="buttons buttons-inline button-group button-group--horiz">
                        <Button type="primary" htmltype="submit" onClick={this.onSubmit}>
                            {t('login.continue')}
                        </Button>
                    </div>
                ) : null}
            </div>
        );
    }
}

RememberMe.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isInline: PropTypes.bool,
    onChange: PropTypes.func,
    title: PropTypes.string,
    t: PropTypes.func.isRequired,
};

RememberMe.defaultProps = {
    isInline: true,
    onChange: () => {},
    title: undefined,
};

RememberMe.displayName = 'RememberMe';
