import { getfromDataObject, NA, validateStringData } from 'utils/formatting';
import { ORDER_TYPE } from '../constants';
import { isLiquidity } from '../utils';

export const adaptSecurityTransactions = (
    {
        data,
        isMobileSize,
        portfolioId,
        productID,
        actionType,
        proposalId,
        t,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
        linkToOverview,
    },
) => {
    const transactions = data.OrderBookEntries || [];
    const groupToValues = transactions.reduce(
        (
            obj,
            {
                CreateDate, FxRate, Security, OrderType, AmountSecurityCurrency, Quantity, Price,
            },
        ) => {
            const type = t(ORDER_TYPE[getfromDataObject(OrderType, 'Id')]);
            const accumulator = { ...obj };

            const link = proposalId
                ? `/portfolios/${portfolioId}/${actionType}/${proposalId}/position/${productID}/${Security.Id}/2`
                : `/portfolios/${portfolioId}/actions/${actionType}/position/${productID}/${Security.Id}/2${linkToOverview ? '/overview' : ''}`;

            const formatData = (val, digits = 2) => (val
                ? getFormattedNumber(val, {
                    maximumFractionDigits: digits,
                    minimumFractionDigits: digits,
                })
                : NA);

            accumulator[type] = obj[type] || [];
            accumulator[type].push({
                Name: {
                    label: validateStringData(Security.Name),
                    link: isLiquidity(Security) ? undefined : link,
                    maxLength: isMobileSize ? 15 : 35,
                },
                Date: getFormattedDate(CreateDate),
                Id: Security.Id,
                Number: formatData(Math.abs(Quantity)),
                Price: formatData(Price),
                Type: type,
                FxRate: formatData(FxRate, 4),
                Total: getFormattedCurrency(Math.abs(AmountSecurityCurrency), {
                    currency: Security.Currency.CurrencyCode,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                }),
                Fee: '0.1%', // TODO: Remove hardcoded fee (AT-1129)
            });

            return accumulator;
        },
        {},
    );

    return {
        confirmationData: Object.keys(groupToValues).map((key) => {
            const groupMembersData = groupToValues[key];

            return {
                Name: key,
                children: groupMembersData,
            };
        }),
    };
};
