import React, {
    createContext, useState, useCallback, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import ServiceManager from 'services/ServiceManager';
import { groups, keys } from 'static/globalSettings';

const GlobalSettingsContext = createContext({});

const { Provider } = GlobalSettingsContext;

const GlobalSettingsProvider = (options) => {
    const { children } = options;
    const mountedRef = useRef(true);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [settings, setSettings] = useState({
        isReportEnabled: false,
        isReportGenerating: false,
        hideReportToContactsList: [],
    });

    const lowerCaseFirstLetter = (str) => str.replace(/^./, (match) => match.toLowerCase());

    const adaptSettingValue = (key, value) => {
        switch (key) {
            case keys.IS_REPORT_ENABLED:
            case keys.IS_REPORT_GENERATING:
                return value === 'true';
            case keys.HIDE_REPORT_TO_CONTACTS_LIST:
                return (value ?? '').split(',').map((v) => parseInt(v, 10));
            default:
                return value;
        }
    };

    const getSettingByGroupAndKey = useCallback(async (group, key) => {
        try {
            setLoading(true);
            const response = await ServiceManager.commonService(
                'getGlobalSettingByGroupAndKey',
                [group, key],
            );
            const { Key, Value } = response.data;

            if (mountedRef.current) {
                setSettings((prevSettings) => ({
                    ...prevSettings,
                    [lowerCaseFirstLetter(Key)]: adaptSettingValue(Key, Value),
                }));
                setLoading(false);
            }
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    }, [lowerCaseFirstLetter, adaptSettingValue]);

    const getIsReportGeneratingSetting = useCallback(
        () => {
            getSettingByGroupAndKey(
                groups.CLIENT_COCKPIT,
                keys.IS_REPORT_GENERATING,
            );
        },
        [getSettingByGroupAndKey],
    );

    const getHideReportToContactsListSetting = useCallback(
        () => {
            getSettingByGroupAndKey(
                groups.CLIENT_COCKPIT,
                keys.HIDE_REPORT_TO_CONTACTS_LIST,
            );
        },
        [getSettingByGroupAndKey],
    );

    useEffect(
        () => {
            getSettingByGroupAndKey(
                groups.CLIENT_COCKPIT,
                keys.IS_REPORT_ENABLED,
            );

            getSettingByGroupAndKey(
                groups.CLIENT_COCKPIT,
                keys.HIDE_REPORT_TO_CONTACTS_LIST,
            );

            return () => {
                mountedRef.current = false;
            };
        },
        [],
    );

    return (
        <Provider value={{
            settings,
            error,
            isLoading,
            getSettingByGroupAndKey,
            getIsReportGeneratingSetting,
            getHideReportToContactsListSetting,
        }}
        >
            {children}
        </Provider>
    );
};

GlobalSettingsProvider.propTypes = {
    options: PropTypes.shape({
        pathname: PropTypes.string,
        children: PropTypes.node,
    }),
};

GlobalSettingsProvider.defaultProps = {
    options: {
        pathname: '',
        children: null,
    },
};

export {
    GlobalSettingsProvider,
    GlobalSettingsContext,
};
