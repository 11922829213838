import { find, isNil } from 'lodash';
import { validateStringData, validateName, NA } from 'utils/formatting';

import avatar from 'assets/images/avatar/avatar.png';
import { getImageMime } from 'utils';

const getPortfoliosCount = (data) => {
    const portfolios = find(data, { PortfolioType: 'Real' });

    return isNil(portfolios) ? NA : portfolios.Count;
};

export const adaptRMData = (data, t, getFormattedPhoneNumber, rmPicture) => {
    if (isNil(data)) {
        return {};
    }

    const getPhoneNumber = (phoneNumber) => {
        if (phoneNumber) {
            return `tel:${getFormattedPhoneNumber(phoneNumber)}`;
        }

        return null;
    };
    const validateRMEmail = (email) => ({
        Information: {
            title: t('dashboard.relationshipManager.email'),
            subtitle: validateStringData(email),
        },
        Icon: {
            type: 'envolope',
            size: 20,
            href: `mailto:${email}`,
        },
    });
    const validateRMPhone = (phoneNumber) => ({
        Information: {
            title: t('dashboard.relationshipManager.phone'),
            subtitle: validateStringData(phoneNumber),
        },
        Icon: {
            type: 'phone-call',
            size: 20,
            href: getPhoneNumber(phoneNumber),
        },
    });

    const validatedContacts = [];

    if (data.Email) {
        validatedContacts.push(validateRMEmail(data.Email));
    }

    if (data.Phone) {
        validatedContacts.push(validateRMPhone(data.Phone));
    }

    return {
        Img: rmPicture ? `data:${getImageMime(rmPicture)};base64,${rmPicture}` : avatar,
        Name: `${validateName(data.firstName)} ${validateName(data.lastName)}`,
        PhoneNumber: getPhoneNumber(data.phone),
        Contacts: validatedContacts,
        PhoneNumberBase: getFormattedPhoneNumber(data.phone),
        Email: data.email,
    };
};

export const adaptProfile = (data, members, t, getFormattedPhoneNumber) => ({
    clientProfile: [
        {
            Information: {
                title: t('dashboard.profile.clientRiskProfile'),
                subtitle: validateStringData(data?.ChosenRiskCategory?.Name),
            },
            Icon: { type: 'form', size: 20, href: 'risk-profile' },
        },
        {
            Information: {
                title: t('dashboard.profile.totalPortfolios'),
                subtitle: data?.investmentInformation?.totalNumberOfPortfolios || 0,
            },
            Icon: { type: '' },
        },
    ],
    RMProfile: adaptRMData(members[0], t, getFormattedPhoneNumber, members.rmPicture),
    lastLogin: data.LastLogin,
    isProspect: data?.relationshipInformation?.isProspect,
});
