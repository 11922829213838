import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, Modal, Paragraph, Title,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';

import './Preview.css';

function Preview({
    deletePreview,
    error,
    isLoading,
    item,
    showDelete,
    deleteText,
    deleteTitle,
    renderActions,
    renderMetadata,
    renderAttachments,
}) {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);

    const onCancel = () => {
        setShowModal(false);
    };
    const onDelete = () => {
        setShowModal(true);
    };
    const onOk = async () => {
        await deletePreview(item.id);
        setShowModal(false);
    };

    const renderContent = () => item && (
        <>
            <div className="preview-content__inner">
                <Title type={3}>{item.title}</Title>
                <div className="preview-content-metadata">{renderMetadata(item)}</div>
                {renderAttachments(item)}
                <Paragraph type="secondary" className="border-top border-bottom">{item.content}</Paragraph>
                <Modal
                    title={deleteTitle}
                    visible={showModal}
                    okText={t('messages.deleteMessage')}
                    cancelText={t('messages.cancel')}
                    onOk={() => onOk()}
                    onCancel={() => onCancel()}
                    className="delete-message-modal"
                >
                    {deleteText}
                </Modal>
            </div>
            <div className="text-sm-right button-group">
                {showDelete && (
                    <Button type="danger" size="small" onClick={onDelete}>
                        {t('messages.delete')}
                    </Button>
                )}
                {renderActions(item)}
            </div>
        </>
    );

    return (
        <div className="preview-content">
            <Preloader isLoading={isLoading} error={error}>
                <>{renderContent(item)}</>
            </Preloader>
        </div>
    );
}

Preview.propTypes = {
    deletePreview: PropTypes.func.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isLoading: PropTypes.bool.isRequired,
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string,
        content: PropTypes.string,
        attachments: PropTypes.arrayOf(
            PropTypes.shape({
                recipientFullName: PropTypes.string,
            }),
        ),
    }),
    showDelete: PropTypes.bool,
    deleteTitle: PropTypes.string,
    deleteText: PropTypes.string,
    renderActions: PropTypes.func,
    renderMetadata: PropTypes.func,
    renderAttachments: PropTypes.func,
};

Preview.defaultProps = {
    item: null,
    error: null,
    showDelete: false,
    deleteText: '',
    deleteTitle: '',
    renderActions: () => {},
    renderMetadata: () => {},
    renderAttachments: () => {},
};

export default Preview;
