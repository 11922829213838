import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';
import { adaptProposals } from '../adapters/adaptProposals';

export const useProposals = (clientId, portfolioId) => {
    const [errorProposals, setError] = useState(null);
    const [isLoadingProposals, setLoading] = useState(true);
    const [dataProposals, setData] = useState([]);
    const { i18n, t } = useTranslation();
    const { getFormattedDate } = useFormatting();

    useEffect(() => {
        setLoading(true);
        const params = { language: i18n.language };

        ServiceManager.portfolioManagement('getPortfolioProposals', [clientId, params])
            .then((response) => {
                try {
                    setData(adaptProposals(response.data, portfolioId, getFormattedDate, t));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [portfolioId, i18n.language]);

    return { dataProposals, isLoadingProposals, errorProposals };
};
