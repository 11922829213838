import { checkNullData } from 'utils';

const parseAnswers = (answers, calculations) => {
    const answersObj = answers.reduce((acc, val) => Object.assign(acc, val), {});
    const answersObject = Object.keys(answersObj).reduce((acc, key) => {
        const id = parseInt(key, 10) || key.split('_').pop();
        const answer = typeof answersObj[key] === 'object'
            ? answersObj[key].map((i) => +i)
            : (answersObj[key] && +answersObj[key]);

        return {
            ...acc,
            [id]: answer,
        };
    }, {});
    const lastStepAnswers = calculations.map((section) => ({
        [section.id]: section.result && section.result.id,
    })).reduce((acc, val) => Object.assign(acc, val), {});

    return { ...answersObject, ...lastStepAnswers };
};

export const adaptAnswersQuestionnaire = (rawData, answers, calculations) => {
    if (checkNullData(rawData)) return {};

    const answersParsed = parseAnswers(answers, calculations);
    const getQuestionAnswer = ({ Id, Type, Answers }) => {
        if (answersParsed[Id] === undefined) {
            return null;
        }
        if (Answers?.length !== 0 && answersParsed[Id]) {
            return { Ids: typeof answersParsed[Id] === 'object' ? answersParsed[Id] : [answersParsed[Id]] };
        }
        if (Type === 'FreeText') {
            return { Value: answersParsed[Id].toString() };
        }

        return null;
    };

    return {
        ...rawData,
        Groups: rawData.Groups.map((group) => ({
            ...group,
            Steps: group.Steps.map((step) => ({
                ...step,
                Questions: step.Questions.map((question) => {
                    const questionAnswer = getQuestionAnswer(question);

                    if (questionAnswer) {
                        return {
                            ...question,
                            Value: getQuestionAnswer(question),
                        };
                    }

                    return question;
                }),
            })),
        })),
    };
};
