import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptExchangeRates } from '../adapters/adaptExchangeRates';

export const useExchangeRates = (currency, portfolioCurrency) => {
    const [errorExchangeRate, setError] = useState(null);
    const [isLoadingExchangeRate, setLoading] = useState(true);
    const [exchangeRate, setData] = useState(null);

    const { i18n } = useTranslation();

    useEffect(() => {
        if (!portfolioCurrency || !currency) return;

        setError(null);
        setLoading(true);
        ServiceManager.tradingService('postCurrenciesExchangerates', [
            [
                {
                    BaseCurrencyCode: portfolioCurrency,
                    QuoteCurrencyCode: currency,
                },
            ],
        ])
            .then((response) => {
                try {
                    setData(adaptExchangeRates(response.data.CurrencyExchangeRates));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [i18n.language, currency, portfolioCurrency]);

    return { exchangeRate, isLoadingExchangeRate, errorExchangeRate };
};
