import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Questionnaire from './pages/Questionnaire';
import Results from './pages/Results';
import ExpandUniverse from './pages/ExpandUniverse';

const Router = (props) => {
    const { match: { path } } = props;

    const withProps = (Component) => (defProps) => (
        <Component {...props} {...defProps} />
    );

    return (
        <Switch>
            <Route exact path={`${path}/results`} render={withProps(Results)} />
            <Route exact path={`${path}/expand-universe`} render={withProps(ExpandUniverse)} />
            <Route exact path={`${path}/:group`} render={withProps(Questionnaire)} />
            <Redirect to={`${path}/0`} />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
