import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { object, string } from 'yup';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkNullData } from 'utils';

export const useInternationalForm = ({ defaultData, withoutIbanDefault = false } = {}) => {
    const { t } = useTranslation();

    const [withoutIban, setWithoutIban] = useState(withoutIbanDefault);

    const schema = useMemo(() => object().shape({
        iban: !withoutIban ? string().required(t('payments.validation.iban')) : string(),
        accountNumber: withoutIban ? string().required(t('payments.validation.accountNumber')) : string(),
        swiftCode: withoutIban ? string().required(t('payments.validation.swiftCode')) : string(),
        bankCode: string(),
        bankName: string().required(t('payments.validation.bankName')),
        bankPostCode: string().required(t('payments.validation.postCode')),
        bankCity: string().required(t('payments.validation.city')),
        bankCountry: object().required(t('payments.validation.country')),
        payeeName: string().required(t('payments.validation.payeeName')),
        payeeAddressLine: string(),
        payeePostCode: string().required(t('payments.validation.postCode')),
        payeeCity: string().required(t('payments.validation.city')),
        payeeCountry: object().required(t('payments.validation.country')),
        currency: object().required(t('payments.validation.currency')),
        amount: string().required(t('payments.validation.amount')),
        executionDate: string(),
        personalNote: string(),
        messageForPayee: string(),
        txnCost: object().required(t('payments.validation.txnCost')),
        debitAccount: object().required(t('payments.validation.debitAccount')),
    }), [t, withoutIban]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue,
        reset, control, watch, formState, trigger, setError,
    } = useForm({ resolver, reValidateMode: 'onChange', shouldUnregister: false });

    useEffect(() => {
        if (!checkNullData(defaultData)) {
            reset(defaultData);
        }
    }, [reset]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = useCallback((type) => (value) => {
        setValue(type, value);
        if (formState.isSubmitted) trigger(type);
    }, [setValue, trigger, formState.isSubmitted]);

    return {
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values: watch(),
        setError,
        withoutIban,
        setWithoutIban,
        reset,
    };
};
