import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import { getRandomString, getRandomNumber } from 'utils';
import { formatDate } from 'utils/datetime';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { useBankInfo } from '../../../hooks/useBankInfo';

export const useCreateAccount = (contactId) => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    const { bankInfo } = useBankInfo({ contactId });

    const createAccount = useCallback(async (options) => {
        if (!contactId && !options) return null;

        const { Name, CurrencyId, ProductId } = options;

        setLoading(true);
        setError(null);
        const accountNumberBase = getRandomNumber(); // TODO: change account number to real
        const IbanChars = getRandomString(); // TODO: change IBAN chars to real

        try {
            const accountParams = (accountType, accountNumber) => ({
                Name: `${Name} ${t(accountType === 1 ? 'onBoarding.cashAccount' : 'onBoarding.custodyAccount')}`,
                Iban: `${IbanChars}${accountNumber}`,
                AccountNumber: accountNumber,
                CurrencyId,
                AccountType: accountType,
                Product: ProductId,
                OpeningDate: formatDate(new Date()),
                IsActive: true,
                BranchId: bankInfo?.BankBranchId,
                Status: 1, // TODO: Remove hardcode. AT-849
            });

            const cashAccount = +accountNumberBase + 1;
            const cashAccountParams = accountParams(1, cashAccount);
            const { data: cashAccountResponse } = await SM.accountsService('createAccount', [contactId, cashAccountParams]);
            const custodyAccount = +accountNumberBase + 2;
            const custodyAccountParams = accountParams(2, custodyAccount);
            const { data: custodyAccountResponse } = await SM.accountsService('createAccount', [contactId, custodyAccountParams]);

            return { cashAccountResponse, custodyAccountResponse };
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [contactId, t, bankInfo]);

    return { isLoading, error, createAccount };
};
