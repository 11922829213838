import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import history from 'services/history';

import { checkSession as checkSessionAction } from '../redux/auth/authActions';
import { isAuthenticatedSelector } from '../redux/auth/authSelectors';
import {
    isCurrentSessionCheckedSelector,
    isSessionValidSelector,
} from '../redux/session/sessionSelectors';
import { withAutoLogout } from './withAutoLogout';
import { Loading } from '../components/Loading';

// eslint-disable-next-line import/prefer-default-export
export function withAuthentication(WrappedComponent) {
    // eslint-disable-next-line react/prefer-stateless-function
    class Authentication extends Component {
        componentDidMount() {
            const { checkSession, isCurrentSessionChecked } = this.props;

            if (isCurrentSessionChecked !== true) {
                checkSession();
            }
        }

        render() {
            const { isAuthenticated, isCurrentSessionChecked, isSessionValid } = this.props;

            if (
                isAuthenticated === true
                && isCurrentSessionChecked === true
                && isSessionValid === true
            ) {
                return <WrappedComponent {...this.props} />;
            }

            if (
                (isCurrentSessionChecked === true && isSessionValid === false)
                || (
                    isCurrentSessionChecked === true
                    && isSessionValid === true
                    && isAuthenticated === false
                )
            ) {
                return (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: history.location },
                        }}
                    />
                );
            }

            return <Loading />;
        }
    }

    Authentication.propTypes = {
        isAuthenticated: PropTypes.bool,
        isSessionValid: PropTypes.bool.isRequired,
        isCurrentSessionChecked: PropTypes.bool.isRequired,
        checkSession: PropTypes.func.isRequired,
    };

    Authentication.defaultProps = {
        isAuthenticated: false,
    };

    const mapStateToProps = (state) => ({
        isAuthenticated: isAuthenticatedSelector(state),
        isCurrentSessionChecked: isCurrentSessionCheckedSelector(state),
        isSessionValid: isSessionValidSelector(state),
    });

    const mapDispatchToProps = {
        checkSession: checkSessionAction,
    };

    return connect(mapStateToProps, mapDispatchToProps)(withAutoLogout(Authentication));
}
