import React from 'react';
import PropTypes from 'prop-types';
import Preloader from 'components/Preloader';
import { PageNotFound } from 'pages/pages/404';
import {
    ADVISORY,
    DISCRETIONARY,
    EXECUTION_ONLY,
    generateObject,
    ATRAM_DISCRETIONARY,
    ATRAM_DIRECTIONAL,
} from 'constants/portfolioProducts';
import { usePortfolio } from 'domain/Portfolio';
import ChangeModelAdvisory from './products/ChangeModelAdvisory';
import ChangeModelAtramDiscretionary from './products/ChangeModelAtramDiscretionary';
import ChangeModelDiscretionary from './products/ChangeModelDiscretionary';
import './ChangeModel.css';

const PortfolioByProduct = generateObject({
    [ADVISORY]: ChangeModelAdvisory,
    [DISCRETIONARY]: ChangeModelDiscretionary,
    [EXECUTION_ONLY]: PageNotFound,
    [ATRAM_DISCRETIONARY]: ChangeModelAtramDiscretionary,
    [ATRAM_DIRECTIONAL]: ChangeModelAtramDiscretionary, // TODO: update
});

function ChangeModel(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const PortfolioPage = PortfolioByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <PortfolioPage {...props} />
        </Preloader>
    );
}

ChangeModel.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ChangeModel.defaultProps = {
};

export default ChangeModel;
