import { sorterString, sorterByDate } from 'utils/sorting';
import { renderers } from 'ui-library';
import { CellPDFView } from '../renderers';

const { CellWithButtonIcon } = renderers;

export const instrumentDocumentsColumns = (t) => [
    {
        key: 'name',
        title: t('position.documents.name'),
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('name.value'),
    },
    {
        key: 'type',
        title: t('position.documents.type'),
        sortable: true,
        sorter: sorterString('type'),
    },
    {
        key: 'issuer',
        title: t('position.documents.issuer'),
    },
    {
        key: 'release',
        title: t('position.documents.release'),
        sortable: true,
        sorter: sorterByDate('release'),
    },
    {
        key: 'icon',
        title: '',
        className: 'text-right',
        render: CellWithButtonIcon,
        width: 30,
        minWidth: 30,
    },
];
