import {
    useCallback, useEffect, useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import { adaptRatingAgencies, adaptRatings } from '../adaptors/adaptRatings';

const initialState = {
    dataRatingAgencies: [],
    dataRatings: [],
    errorRatingAgencies: null,
    isLoadingRatingAgencies: false,
    errorRatings: null,
    isLoadingRatings: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setDataRatingAgencies':
            return { ...state, dataRatingAgencies: action.payload };
        case 'setDataRatings':
            return { ...state, dataRatings: action.payload };
        case 'setErrorRatingAgencies':
            return { ...state, errorRatingAgencies: action.payload };
        case 'setIsLoadingRatingAgencies':
            return { ...state, isLoadingRatingAgencies: action.payload };
        case 'setErrorRatings':
            return { ...state, errorRatings: action.payload };
        case 'setIsLoadingRatings':
            return { ...state, isLoadingRatings: action.payload };
        default:
            return state;
    }
};

export const useRatings = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { i18n: { language }, t } = useTranslation();
    // Callbacks
    const getRatingAgencies = useCallback(async () => {
        dispatch({ type: 'setErrorRatingAgencies', payload: null });
        dispatch({ type: 'setIsLoadingRatingAgencies', payload: true });

        try {
            const response = await ServiceManager.commonService('getRatingAgencies', [language]);

            try {
                dispatch({ type: 'setDataRatingAgencies', payload: adaptRatingAgencies(response?.data, t) });
                dispatch({ type: 'setIsLoadingRatingAgencies', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorRatingAgencies', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingRatingAgencies', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, t]);

    const getRatings = useCallback(async (ratingAgencyId) => {
        dispatch({ type: 'setErrorRatings', payload: null });
        dispatch({ type: 'setIsLoadingRatings', payload: true });
        try {
            const response = await ServiceManager.commonService('getRatings', [ratingAgencyId]);

            try {
                dispatch({ type: 'setDataRatings', payload: adaptRatings(response?.data) });
                dispatch({ type: 'setIsLoadingRatings', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorRatings', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingRatings', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, []);

    // Effects
    useEffect(() => {
        getRatingAgencies();
    }, [getRatingAgencies]);

    return {
        dataRatingAgencies: state.dataRatingAgencies,
        dataRatings: state.dataRatings,
        errorRatingAgencies: state.errorRatingAgencies,
        isLoadingRatingAgencies: state.isLoadingRatingAgencies,
        errorRatings: state.errorRatings,
        isLoadingRatings: state.isLoadingRatings,
        getRatingAgencies,
        getRatings,
    };
};
