export const customProperties = {
    '--body-font-family': 'Montserrat',
    '--accent-font-family': 'Montserrat',
    '--active-orange': '#FF6200',
    '--container-max-width': '1280px',
    '--background-base': '#F5F5F5',
    '--padding-base': '20px',
    '--title-grey': '#748091',
    '--error-red': '#C73C3C',
    '--table-label-grey': '#748091',
};
