import React from 'react';
import PropTypes from 'prop-types';
import { useOnBoardingData } from '../hooks/useOnBoardingData';
import { useProducts } from '../hooks/useProducts';
import { useKnowledgeExperience } from '../hooks/useKnowledgeExperience';
import { useGoalCreation } from '../hooks/useGoalCreation';
import { useGoalOptimize } from '../hooks/useGoalOptimize';
import { useGoalSummary } from '../hooks/useGoalSummary';
import { useProductFee } from '../hooks/useProductFee';
import { useProductOffers } from '../hooks/useProductOffers';
import OnBoardingContext from './context';

const OnBoardingProvider = (options) => {
    const {
        options: {
            clientId,
            productOffers: productOffersOptions,
            products: productsOptions,
            knowledgeExperience: knowledgeExperienceOptions,
            goalCreation: goalCreationOptions,
            goalOptimize: goalOptimizeOptions,
            goalSummary: goalSummaryOptions,
            productFee: productFeeOptions,
        },
        children,
    } = options;
    const onBoardingData = useOnBoardingData({ clientId });
    const productOffers = useProductOffers({ ...productOffersOptions, clientId });
    const products = useProducts({ ...productsOptions, clientId });
    const knowledgeExperience = useKnowledgeExperience({ ...knowledgeExperienceOptions, clientId });
    const goalCreation = useGoalCreation({ ...goalCreationOptions, clientId });
    const goalOptimize = useGoalOptimize({ ...goalOptimizeOptions, clientId });
    const goalSummary = useGoalSummary({ ...goalSummaryOptions, clientId });
    const productFee = useProductFee({ ...productFeeOptions, clientId, onBoardingData });
    const state = {
        onBoardingData,
        clientId,
        productOffers,
        products,
        knowledgeExperience,
        goalCreation,
        goalOptimize,
        goalSummary,
        productFee,
    };
    const getValue = (callback) => callback(state);

    return (
        <OnBoardingContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </OnBoardingContext.Provider>
    );
};

OnBoardingProvider.propTypes = {
    options: PropTypes.shape({
        clientId: PropTypes.number.isRequired,
    }).isRequired,
};

OnBoardingProvider.defaultProps = {};

export default OnBoardingProvider;
