import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useFormatting } from 'locale';
import { CURRENT_NEWS_CATEGORY } from 'constants/constants';
import { adaptResearch } from '../adapters/adaptResearch';
import { adaptResearches } from '../adapters/adaptResearches';

const initialState = {
    list: [],
    listRaw: [],
    errorList: null,
    isLoadingList: false,
    data: { instrument: {}, keyTakeaways: [] },
    dataRaw: {},
    error: null,
    isLoading: false,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setList':
            return { ...state, list: action.payload };
        case 'setListRaw':
            return { ...state, listRaw: action.payload };
        case 'setErrorList':
            return { ...state, errorList: action.payload };
        case 'setIsLoadingList':
            return { ...state, isLoadingList: action.payload };
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useResearch = (options = {}) => {
    const {
        researchId,
        adaptData = adaptResearch,
        adaptList = adaptResearches,
        loadInitially = false,
        loadListInitially = false,
        listSize,
        pageNumber,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedNumber, getFormattedDate } = useFormatting();

    // Callbacks
    const getResearches = useCallback(async ({ pageSize, page, skipLoading }) => {
        dispatch({ type: 'setErrorList', payload: null });
        if (!skipLoading) dispatch({ type: 'setIsLoadingList', payload: true });

        try {
            const { data: schemas } = await SM.engagementSchema('postAll', [{ pageNumber: 0, pageSize: 10 }]);
            const researchSchemaId = schemas?.schemaMinimalResults
                ?.find(({ name, isActive } = {}) => name === 'Research' && isActive)?.id;

            if (!researchSchemaId) {
                dispatch({ type: 'setIsLoadingList', payload: false });

                return [];
            }

            const { data: contents } = await SM.engagementContent('postSchemaLatest', [
                { schemaId: researchSchemaId, pageNumber: 0, pageSize: 0 },
            ]);
            const ids = (contents?.contentMinimalResults || []).map((item) => item?.id);
            let contentFor = (contents?.contentMinimalResults || [])
                .map((item) => item?.contentFor?.[CURRENT_NEWS_CATEGORY]).flat()
                .filter((item) => !!item);

            contentFor = [...new Set(contentFor)];
            let imagesResponses = [];

            try {
                imagesResponses = await Promise.allSettled(ids.map(async (id) => ({
                    id,
                    ...(await SM.engagementContent('getContentLatestImage', [id, language])).data,
                })));
            } catch (err) {
                // No need to display error
            }
            const images = imagesResponses.reduce((acc, item) => (item?.value
                ? { ...acc, [item.value?.id]: item.value?.content } : acc), {});
            const response = await SM.engagementContent('contentSearch', [{
                contentFor,
                categories: [CURRENT_NEWS_CATEGORY],
                schemaIds: [researchSchemaId],
                status: true,
                isPublished: true,
                validDateFrom: new Date(new Date().setUTCHours(0, 0, 0, 0)),
                page: page || 1,
                pageSize: pageSize || contentFor.length,
            }, language]);

            try {
                const adapted = adaptList({
                    data: (response.data?.contentMinimalResults || []).map((item) => ({
                        ...item, imageUri: images[item.id],
                    })),
                    schema: contents?.contentMinimalResults,
                    t,
                    language,
                });

                dispatch({ type: 'setList', payload: adapted });
                dispatch({ type: 'setListRaw', payload: response.data });
                if (!skipLoading) dispatch({ type: 'setIsLoadingList', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorList', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingList', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [adaptList, t]);
    const getResearch = useCallback(async (id = researchId) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const { data } = await SM.engagementContent('getContentLatest', [id]);
            let dataImage = null;
            let dataDocument = null;

            dataImage = (await SM.engagementContent('getContentLatestImage', [id, language])).data;
            dataDocument = (await SM.engagementContent('getContentLatestDocument', [id, language])).data;

            const security = null;

            try {
                const adapted = adaptData({
                    data: {
                        ...data,
                        imageUri: dataImage?.content,
                    },
                    dataDocument,
                    security,
                    securityPrice: 0,
                    securityPerformance: 0,
                    t,
                    getFormattedNumber,
                    getFormattedDate,
                    language,
                });

                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setDataRaw', payload: data });
                dispatch({ type: 'setIsLoading', payload: false });

                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [adaptData, getFormattedNumber, language, researchId, t]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoadingList', payload: loadListInitially });
    }, [loadListInitially]);
    useEffect(() => {
        if (loadListInitially) getResearches({ pageSize: listSize, page: pageNumber });
    }, [getResearches, loadListInitially, pageNumber, listSize]);

    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);
    useEffect(() => {
        if (loadInitially) getResearch();
    }, [getResearch, loadInitially]);

    return {
        getResearch,
        list: state.list,
        listRaw: state.listRaw,
        errorList: state.errorList,
        isLoadingList: state.isLoadingList,
        getResearches,
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
    };
};
