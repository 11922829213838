import {
    useCallback, useEffect, useReducer, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useFormatting } from 'locale';
import { adaptPortfolioDetails } from '../adapters/adaptPortfolioDetails';

const initialState = {
    data: {
        investmentAllocation: {
            chart: [{
                data: {
                    pie: {}, pieSa: {}, bar: {}, list: {},
                },
            }],
        },
        positions: [],
    },
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setUpdatePortfolioData':
            return { ...state, dataUpdatePortfolio: action.payload };
        case 'setUpdatePortfolioError':
            return { ...state, errorUpdatePortfolio: action.payload };
        case 'setUpdatePortfolioIsLoading':
            return { ...state, isLoadingUpdatePortfolio: action.payload };
        default:
            return state;
    }
};

export const usePortfolioDetails = (options = {}, modelPortfolio = {}) => {
    const {
        clientId,
        portfolioId,
        adaptData = adaptPortfolioDetails,
        loadInitially = false,
    } = options;
    const productId = useRef();
    const [state, dispatch] = useReducer(reducer, initialState);
    // External hook
    const { getModelPortfolio } = modelPortfolio;

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Callbacks
    const getPortfolioDetails = useCallback(async ({ adaptOptions } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });
        const params = { language };

        try {
            const response = await SM.portfolioManagement('getPortfolioDetails', [portfolioId, clientId, params]);
            const { ModelPortfolioId } = response.data;
            let modelData = {};

            if (ModelPortfolioId && typeof getModelPortfolio === 'function') {
                const modelPortfolioOptions = {
                    productID: response?.data?.Product?.Id,
                    portfolioValue: response?.data?.CurrentValue,
                    comparedAllocations: response?.data?.Positions,
                    portfolioSecuritiesValue: response?.data?.InvestedAmount,
                };

                modelData = await getModelPortfolio(ModelPortfolioId, {
                    adaptOptions: modelPortfolioOptions,
                });
            }
            try {
                const adapted = adaptData({ ...modelData, ...response.data }, {
                    clientId,
                    t,
                    getFormattedDate,
                    getFormattedNumber,
                    getFormattedCurrency,
                    ...adaptOptions,
                });

                productId.current = response.data?.Product?.Id;
                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setDataRaw', payload: response.data });
                dispatch({ type: 'setIsLoading', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [
        language,
        portfolioId,
        clientId,
        getModelPortfolio,
        adaptData,
        t,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    ]);
    const updatePortfolioDetails = useCallback(async ({
        portfolioIdOption = portfolioId, params,
    } = {}) => {
        dispatch({ type: 'setUpdatePortfolioError', payload: null });
        dispatch({ type: 'setUpdatePortfolioIsLoading', payload: true });

        try {
            const response = await SM.portfolioManagement('patchPortfolioDetails', [portfolioIdOption, clientId, params]);

            dispatch({ type: 'setUpdatePortfolioData', payload: response?.data });
            dispatch({ type: 'setUpdatePortfolioIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setUpdatePortfolioError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setUpdatePortfolioIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, portfolioId]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getPortfolioDetails();
    }, [getPortfolioDetails, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        productId: productId.current,
        error: state.error,
        isLoading: state.isLoading,
        getPortfolioDetails,
        dataUpdatePortfolio: state.dataUpdatePortfolio,
        errorUpdatePortfolio: state.errorUpdatePortfolio,
        isLoadingUpdatePortfolio: state.isLoadingUpdatePortfolio,
        updatePortfolioDetails,
    };
};
