export const adaptProductOffers = (data) => {
    const productOffers = data?.ProductOffers;

    return (productOffers || [])?.map((item) => ({
        id: item?.Id,
        name: item?.Name,
        description: item?.Description,
        image: item?.Picture ? `data:image/svg+xml;base64,${item?.Picture}` : null,
    }));
};
