export const adaptProducts = (data, options = {}) => {
    const productOfferId = options?.productOffer?.id;

    const { Products, Currencies } = (data?.ProductOffers || [])
        .find(({ Id }) => Id === Number(productOfferId)) || {};

    return (Products || []).map(({
        Id, Name, Image, MinimumInvestmentValue, Fees, Description,
    }) => ({
        id: Id,
        name: Name,
        description: Description,
        image: Image && `data:image;base64,${Image}`,
        minimumInvestmentValue: MinimumInvestmentValue,
        fees: Fees,
        currencies: Currencies,
    }));
};
