import { useContext, useState, useEffect } from 'react';
import ServiceManager from 'services/ServiceManager';

import { useTranslation } from 'react-i18next';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { groupObjectById } from 'adaptors/groupObjectById';
import { CommonAllocationContext } from '../context/CommonAllocationDataProvider';
import {
    INV_ALLOC_DATA_KEYS,
    ALLOCATION_TYPES,
    DEFAULT_ALLOCATION_VALUE,
} from '../constants/allocation';

import { adaptAllocationsData } from '../adapters/adaptAllocationsData';

export const useModifyAndEditAllocationData = ({
    clientId,
    portfolioId,
    investmentAllocations,
    isLoading,
    currency,
}) => {
    const {
        assetClasses, countries, currencies, sectors, securityTypes,
        isLoading: isCommonAllocationContextLoading,
    } = useContext(CommonAllocationContext);

    const [errorAllocation, setError] = useState(null);
    const [isLoadingAllocation, setLoadingAllocation] = useState(true);
    const [investmentAllocation, setData] = useState(DEFAULT_ALLOCATION_VALUE);
    const { i18n, t } = useTranslation();

    useEffect(() => {
        setLoadingAllocation(true);
        const allocation = {
            Allocations: investmentAllocations,
        };

        if (!isLoading && !!investmentAllocations && !isCommonAllocationContextLoading) {
            Promise.all([
                ServiceManager.portfolioManagement(
                    'getContactsPortfolioAllocation',
                    [
                        clientId,
                        portfolioId,
                        { language: i18n.language, includeLookThrough: false },
                    ],
                ),
                ServiceManager.performance(
                    'postPerfomanceAllocationsCalculate',
                    [
                        { includeLookThrough: false },
                        allocation,
                    ],
                ),
                ServiceManager.portfolioManagement(
                    'getContactsPortfolioAllocation',
                    [
                        clientId,
                        portfolioId,
                        { language: i18n.language, includeLookThrough: true },
                    ],
                ),
                ServiceManager.performance(
                    'postPerfomanceAllocationsCalculate',
                    [
                        { includeLookThrough: true },
                        allocation,

                    ],
                ),
                ServiceManager.portfolioManagement(
                    'getPortfolioDetails',
                    [
                        portfolioId,
                        clientId,
                        { language: i18n.language },
                    ],
                ),
            ])
                .then((response) => response.map((item) => item.data))
                .then((response) => {
                    const currentValue = response[4].CurrentValue;

                    try {
                        const listNames = [
                            assetClasses,
                            securityTypes,
                            currencies,
                            countries,

                            sectors,

                        ].map((array) => groupObjectById(array));

                        const currentAllocationDataWithout = response[0]?.Allocations;
                        const newAllocationDataWithout = response[1]?.PerformanceAllocations;
                        const currentAllocationDataWith = response[2]?.Allocations;
                        const newAllocationDataWith = response[3]?.PerformanceAllocations;

                        setData({
                            [INV_ALLOC_DATA_KEYS.withoutLookThroughtData]: {
                                [ALLOCATION_TYPES.CURRENT_ALLOCATION]: {
                                    chart: adaptAllocationsData(
                                        currentAllocationDataWithout,
                                        listNames,
                                        currency,
                                        t,
                                        currentValue,
                                    ),
                                },
                                [ALLOCATION_TYPES.NEW_ALLOCATION]: {
                                    chart: adaptAllocationsData(
                                        newAllocationDataWithout,
                                        listNames,
                                        currency,
                                        t,
                                        currentValue,
                                    ),
                                },
                            },
                            [INV_ALLOC_DATA_KEYS.withLookThroughtData]: {
                                [ALLOCATION_TYPES.CURRENT_ALLOCATION]: {
                                    chart: adaptAllocationsData(
                                        currentAllocationDataWith,
                                        listNames,
                                        currency,
                                        t,
                                        currentValue,
                                    ),
                                },
                                [ALLOCATION_TYPES.NEW_ALLOCATION]: {
                                    chart: adaptAllocationsData(
                                        newAllocationDataWith,
                                        listNames,
                                        currency,
                                        t,
                                        currentValue,
                                    ),
                                },
                            },
                        });
                        setLoadingAllocation(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch((err) => {
                    setData(DEFAULT_ALLOCATION_VALUE);
                    handlerRequestCanceling(
                        HandlerError({
                            setError,
                            setLoading: setLoadingAllocation,
                        }),
                    )(err);
                });
        }
    }, [
        clientId,
        portfolioId,
        investmentAllocations,
        isLoading,
        currency,
        i18n.language,
        assetClasses,
        countries,
        currencies,
        sectors,
        securityTypes,
    ]);

    return {
        alocationsData: investmentAllocation,
        isAllocationLoading: isLoadingAllocation,
        allocationError: errorAllocation,
    };
};
