import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    BANDWIDTH, LINE, MODIFY, PROJECTION, QUICK_EDIT, RISKRETURN, SUSTAINABILITY,
} from 'constants/constants';
import useFormatting from 'locale/useFormatting';
import BandwidthLabel from 'components/BandwidthLabel';
import { adaptBandwidth, adaptSustainability } from 'adaptors/adaptAnalysis';
import { useProjection } from './useProjection';
import { usePerformanceAndRiskCompare } from './usePerformanceAndRiskCompare';

export const useAnalysisDetails = ({
    clientId,
    portfolio = {},
    strategy,
    positions,
    positionsRaw,
    benchmark,
    actionType,
    defaultFilter,
    additionalOptions,
    riskProfileStrategyTitle,
    riskProfilePortfolioTitle,
    isLoadingBenchmark,
} = {
    actionType: null,
    useAmountPerformance: false,
}) => {
    const { t } = useTranslation();
    const { getFormattedNumber } = useFormatting();

    // Other hooks
    const isEdit = useMemo(() => [QUICK_EDIT, MODIFY].includes(actionType), []);
    const {
        performanceAndRisk,
        performanceSelected,
        onPerformanceChange,
        volatility,
        performanceDateRange,
    } = usePerformanceAndRiskCompare({
        clientId,
        portfolio,
        strategy,
        currencyId: portfolio.currencyId,
        benchmark,
        isLoading: isLoadingBenchmark,
        additionalOptions: {
            ...additionalOptions || {},
            defaultFilter,
            isEdit,
        },
        riskProfileStrategyTitle,
        riskProfilePortfolioTitle,
    });
    const {
        projection, isLoadingProjection, errorProjection, projectionResponse,
    } = useProjection({
        portfolio: portfolio?.projection,
        strategy: strategy?.projection,
        currency: portfolio.currency,
    });

    // Optional
    const numberFormat = useCallback((number) => getFormattedNumber(number.toFixed(0)),
        [getFormattedNumber]);
    const labelFormat = useCallback((label, values) => BandwidthLabel({
        label, values, getFormattedNumber, t,
    }), [BandwidthLabel, getFormattedNumber, t]);

    // Data
    const isLoadingModel = useMemo(() => performanceAndRisk[LINE].isLoading
        || performanceAndRisk[RISKRETURN].isLoading
        || isLoadingProjection,
    [
        performanceAndRisk[LINE].isLoading,
        performanceAndRisk[RISKRETURN].isLoading,
        isLoadingProjection,
    ]);
    const bandwidthData = useMemo(() => adaptBandwidth({
        portfolio, strategy, positions, numberFormat, t, labelFormat,
    }), [portfolio, strategy, positions, numberFormat, t, labelFormat]);
    const sustainabilityData = useMemo(() => adaptSustainability({
        data: positionsRaw,
        name: portfolio.title,
        currency: portfolio.currency,
        portfolioId: portfolio.esgPortfolioId,
    }), [positionsRaw, portfolio.title, portfolio.currency, portfolio.esgPortfolioId]);

    return {
        analysisData: {
            ...performanceAndRisk,
            [BANDWIDTH]: bandwidthData,
            [PROJECTION]: {
                ...projection,
                error: errorProjection,
                isLoading: isLoadingProjection,
            },
            [SUSTAINABILITY]: {
                data: sustainabilityData,
            },
        },
        volatility,
        isLoadingModel,
        performanceSelected,
        onPerformanceChange,
        projectionResponse,
        performanceDateRange,
    };
};
