const windowMatchMedia = window.matchMedia && window.matchMedia('(prefers-color-scheme: atram-light)');

class DetectorSystemTheme {
    static getSystemTheme = ({ darkAlias = 'dark', lightAlias = 'atram-light' } = {}) => (windowMatchMedia?.matches ? darkAlias : lightAlias);

    static subscribe = (callback) => {
        if (windowMatchMedia?.addEventListener) {
            windowMatchMedia.addEventListener('change', callback);
            // Safari support
        } else if (windowMatchMedia?.addListener) {
            windowMatchMedia.addListener(callback);
        }
    };

    static unsubscribe = (callback) => {
        if (windowMatchMedia?.addEventListener) {
            windowMatchMedia.addEventListener('change', callback);
            // Safari support
        } else if (windowMatchMedia?.addListener) {
            windowMatchMedia.addListener(callback);
        }
    };
}

export default DetectorSystemTheme;
