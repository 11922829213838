import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Infobox, Title } from 'ui-library';

import UserAndPassword from 'components/Form/UserAndPassword';
import { ErrorModel } from 'error/ErrorModel';

export const FORM_NAME = 'EnrollDeviceByUserAndPassword';

class EnrollDeviceByUserAndPassword extends Component {
    componentWillUnmount() {
        const { cleanEnrollment } = this.props;

        cleanEnrollment();
    }

    render() {
        const {
            t,
            error,
            submitting,
            handleSubmit,
            onSubmit,
            qrCode,
            login,
            isEnrollSuccess,
            isEnrollDeviceSuccess,
            isEnrollUnsuccess,
        } = this.props;

        if (isEnrollDeviceSuccess === true) {
            return (
                <div className="content-pane">
                    <Title type={2}>{t('enrollDevice.deviceHasBeenEnrolled')}</Title>
                    <p className="info-text">{t('enrollDevice.readyToUse')}</p>
                    <div className="buttons buttons-inline button-group button-group--horiz">
                        <Button type="primary" htmltype="submit" onClick={login}>
                            {t('enrollDevice.backToLogin')}
                        </Button>
                    </div>
                </div>
            );
        }

        let errorContent = null;

        if (error !== undefined && error !== null) {
            if (isEnrollUnsuccess === true) {
                errorContent = (
                    <div className="validation-summary-errors">
                        <Infobox error>{t(error.message)}</Infobox>
                    </div>
                );
            }
            if (typeof error === 'string') {
                errorContent = (
                    <div className="validation-summary-errors">
                        <Infobox error>{t(error)}</Infobox>
                    </div>
                );
            }
        }

        const info = (
            <div className="validation-summary-errors">
                <Infobox>
                    {t('enrollDevice.accountRequires2FA')}
                    <a
                        href="https://play.google.com/store/apps/details?id=com.futurae.mobileapp"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {` ${t('enrollDevice.googlePlay')} `}
                    </a>
                    {t('enrollDevice.storeDeviceOr')}
                    <a
                        href="https://itunes.apple.com/us/app/futurae/id1069858990?mt=8"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {` ${t('enrollDevice.appleStore')} `}
                    </a>
                    {t('enrollDevice.securityReasonsNotification')}
                </Infobox>
            </div>
        );

        if (isEnrollSuccess) {
            return (
                <div className="content-pane">
                    <h2 className="page-title paragraph-title t-center">
                        {t('enrollDevice.title')}
                    </h2>
                    <p className="info-text">{`${t('enrollDevice.scanQRCode')}:`}</p>
                    <div className="qr-code" style={{ backgroundImage: `url(${qrCode})` }} />
                </div>
            );
        }

        return (
            <UserAndPassword
                info={info}
                error={errorContent}
                submitLabel={t('common.CONTINUE_MSG')}
                submitting={submitting}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
            />
        );
    }
}

EnrollDeviceByUserAndPassword.propTypes = {
    t: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    cleanEnrollment: PropTypes.func.isRequired,
    qrCode: PropTypes.string.isRequired,
    isEnrollSuccess: PropTypes.bool.isRequired,
    isEnrollDeviceSuccess: PropTypes.bool.isRequired,
    error: PropTypes.objectOf(ErrorModel),
    login: PropTypes.func.isRequired,
    isEnrollUnsuccess: PropTypes.bool.isRequired,
};

EnrollDeviceByUserAndPassword.defaultProps = {
    error: undefined,
};

EnrollDeviceByUserAndPassword.displayName = 'EnrollDeviceByUserAndPassword';

export default EnrollDeviceByUserAndPassword;
