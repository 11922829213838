import React, { Suspense } from 'react';
import { useSurfly } from 'hooks/useSurfly';
import { useStore } from 'ui-library';
import { ADD_VARS } from 'ui-library/dist/hooks/useThemeStore';
import { Route, Router, Switch } from 'react-router-dom';
import LoginRouter from 'pages/pages/Login/Router';
import ErrorBoundary from 'components/ErrorBoundary';
import { useGA } from 'services/GoogleAnalytics/useGA';
import { isSurflySupport } from 'utils/support';
import { Loading } from './components/Loading';
import history from './services/history';
import withSignalR from './hocs/withSignalR';
import './styles';
import { ForgotPassword } from './pages/pages/ForgotPassword';
import { UserActivation } from './pages/pages/UserActivation';
import ResetPassword from './pages/pages/ResetPassword';
import AuthenticatedRouter from './AuthenticatedRouter';
import { ThemingProvider } from './theming';
import { themesVariables } from './utils/configureTheme';
import { HashLogin } from './pages/pages/HashLogin';
import { HashLoginRoute } from './components/HashLoginRoute';

const App = () => {
    useGA();

    if (isSurflySupport) useSurfly();

    const { dispatch: dispatchComponents } = useStore();
    const updateVars = (component, vars) => {
        dispatchComponents({ type: ADD_VARS, payload: { component, vars } });
    };

    return (
        <ThemingProvider
            themesVariables={themesVariables}
            updateComponents={updateVars}
            saveToLocalStorage
            defaultTheme="atram-light"
        >
            <Suspense fallback={<Loading />}>
                <ErrorBoundary>
                    <Router history={history}>
                        <Switch>
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                            <Route strict path="/login" component={LoginRouter} />
                            <Route exact path="/activation/:activationKey" component={UserActivation} />
                            <Route exact path="/reset-password/:activationKey" component={ResetPassword} />
                            <HashLoginRoute exact path="/" component={HashLogin} />
                            <Route path="/" component={AuthenticatedRouter} />
                        </Switch>
                    </Router>
                </ErrorBoundary>
            </Suspense>
        </ThemingProvider>
    );
};

// todo fix me
export default withSignalR(App);
