import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';

export const useKnowledgeExperienceByProduct = (options) => {
    const {
        productName,
        hasNegativeInstruments,
        hasPositiveInstruments,
        onNext = () => {},
    } = (options || {});
    const { t } = useTranslation();
    const [errorUniverseComplete, setErrorUniverseComplete] = useState(null);

    const { saveKnowledgeAndExperience } = useOnBoardingSelector(onBoardingDataSelector);

    // Memo
    const universeCompleteError = useMemo(() => generateObject({
        [ADVISORY]: hasNegativeInstruments ? t('onBoarding.advisoryKnowledgeAllInstruments') : null,
        [EXECUTION_ONLY]: hasPositiveInstruments ? null : t('onBoarding.executionOnlyEmptyInstruments'),
        [DISCRETIONARY]: hasPositiveInstruments ? null : t('onBoarding.discretionaryEmptyInstruments'),
        [DEPOSITS]: hasNegativeInstruments ? t('onBoarding.depositsKnowledgeAllInstruments') : null,
    }), [hasNegativeInstruments, hasPositiveInstruments, t]);

    // Callbacks
    const onUniverseComplete = useCallback(() => {
        if (universeCompleteError.getByName(productName)) {
            setErrorUniverseComplete(universeCompleteError.getByName(productName));

            return;
        }

        saveKnowledgeAndExperience({ hasPositiveInstruments });
        onNext();
    }, [onNext, productName, universeCompleteError, hasPositiveInstruments]);

    return {
        errorUniverseComplete,
        onUniverseComplete,
    };
};
