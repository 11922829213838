import { renderers } from 'ui-library';
import {
    CellSwitch,
} from 'components/renderers';
import { sorterNumber, sorterString, SORT_DIRECTION_DESC } from 'utils/sorting';

const { CellWithIcon, CellWithCurrentNewValue } = renderers;

export const MODIFY = 'modify';

export const modifyColumns = (t) => [
    {
        key: 'Name',
        title: t('portfolios.modifyColumns.name'),
        sortable: true,
        sorter: sorterString('Name'),
        width: 450,
        minWidth: 450,
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: SORT_DIRECTION_DESC,
    },
    {
        key: 'ISIN',
        title: t('portfolios.modifyColumns.isin'),
        hidden: true,
        sorter: sorterString('ISIN'),
    },
    {
        key: 'Currency',
        title: t('portfolios.modifyColumns.currency'),
        hidden: true,
        sorter: sorterString('Currency'),
    },
    {
        key: 'Units',
        title: t('portfolios.modifyColumns.units'),
        className: 'text-right',
        hidden: true,
        sorter: sorterNumber('Units'),
    },
    {
        key: 'LatestPrice',
        title: t('portfolios.modifyColumns.lastPrice'),
        className: 'text-right',
        hidden: true,
        sorter: sorterNumber('LatestPrice'),
    },
    {
        key: 'ValueData',
        title: t('portfolios.modifyColumns.value'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        minWidth: 250,
        sorter: sorterNumber('ValueData.currentValue'),
    },
    {
        key: 'AllocationD',
        title: t('portfolios.modifyColumns.allocation'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        sorter: sorterNumber('AllocationD.currentValue'),
    },
    {
        key: 'Icon.Icon',
        title: '',
        width: 30,
        minWidth: 30,
        render: CellWithIcon,
        titleType: 'columns',
    },
];

export const modifyMobileColumns = (t) => [
    {
        key: 'Name',
        title: t('portfolios.modifyColumns.name'),
        sortable: true,
        sorter: sorterString('Name'),
        width: 135,
        minWidth: 135,
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: SORT_DIRECTION_DESC,
    },
    {
        key: 'AllocationD',
        title: t('portfolios.modifyColumns.allocation'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        sortable: true,
        sorter: sorterNumber('AllocationD.currentValue'),
    },
];

export const securitiesColumns = (t) => [
    {
        key: 'Name',
        title: t('portfolios.securitiesColumns.name'),
        render: CellSwitch,
        width: 300,
        sorter: sorterString('Name.value'),
    },
    {
        key: 'Isin',
        title: t('portfolios.securitiesColumns.isin'),
        sorter: sorterString('Isin'),
    },
    {
        key: 'AssetClass',
        title: t('portfolios.securitiesColumns.assetClass'),
        sortable: true,
        sorter: sorterString('AssetClass'),
        filterable: true,
        width: 145,
        defaultSortOrder: 'ascend',
    },
    {
        key: 'SubAssetClass',
        title: t('portfolios.securitiesColumns.subAssetClass'),
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Type',
        title: t('portfolios.securitiesColumns.type'),
        sorter: sorterString('Type'),
    },
    {
        key: 'Currency',
        title: t('portfolios.securitiesColumns.currency'),
        width: 100,
        filterable: true,
        sorter: sorterString('Currency'),
    },
];
