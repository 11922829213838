import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { DispatchContext, StoreContext, PropsContext } from '../store-provider';
import { initialState, reducer } from '../reducer';
import ListPreview from '../components/ListPreview';
import { onFilter } from '../actions';

const StoreProvider = ({ onSearch, ...props }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <DispatchContext.Provider value={dispatch}>
            <StoreContext.Provider value={state}>
                <PropsContext.Provider value={props}>
                    <ListPreview
                        {...props}
                        filters={state.filters}
                        onSearch={onFilter(dispatch, state, onSearch)}
                    />
                </PropsContext.Provider>
            </StoreContext.Provider>
        </DispatchContext.Provider>
    );
};

StoreProvider.propTypes = {
    onSearch: PropTypes.func,
};

StoreProvider.defaultProps = {
    onSearch: () => {},
};

export default StoreProvider;
