export const collectAnswers = (data) => {
    const questions = data?.Groups?.[0]?.Steps?.[0]?.Questions || [];

    return questions.reduce((acc, item) => ({
        ...acc,
        [item.Id]: item?.Value?.Ids,
    }), {});
};

export const adaptResults = (data) => {
    const instruments = data?.Questions?.[0]?.Answers || [];
    const answers = data?.Questions?.[0]?.Value?.Ids || [];
    const positive = answers.map((id) => instruments.find((item) => item.Id === id));
    const negative = instruments.filter((item) => !positive.includes(item));

    return {
        id: data?.Questions?.[0]?.Id,
        positive: positive.map((item) => ({ ...item, resource: item.Header.replace(/[^A-Za-z0-9]/g, '') })),
        negative: negative.map((item) => ({ ...item, resource: item.Header.replace(/[^A-Za-z0-9]/g, '') })),
    };
};
