import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContentBox, Title } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';

import './AtramRelationshipManager.css';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';

function AtramRelationshipManager({
    rmData, iwcData, isLoading, error,
}) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();

    const renderManagerSection = (data, isRmSection = true) => (
        <div className="media-body">
            <div className="title">
                {isRmSection ? t('dashboard.relationshipManager.solutionsManager') : t('dashboard.relationshipManager.iwc') }
            </div>
            <div className="name">{data?.Name}</div>
            <div className="media-links">
                {isMobileSize ? (
                    <>
                        <Link
                            to={{ pathname: '/messages/new', search: `?back=relationshipManager${isRmSection ? '' : 'Atram'}` }}
                            className="Link Link_secondary"
                        >
                            {t('messages.sendMessage')}
                        </Link>
                        {data?.PhoneNumber && (
                            <a href={data?.PhoneNumber} className="Link Link_secondary call-now">
                                {t('dashboard.relationshipManager.callNow')}
                            </a>
                        )}
                    </>
                ) : (
                    <>
                        {data?.Email && (
                            <a href={`mailto:${data?.Email}`} className="Link Link_secondary">
                                {data?.Email}
                            </a>
                        )}
                        {data?.PhoneNumberBase && data?.PhoneNumber && (
                            <a href={data?.PhoneNumber} className="Link Link_secondary call-now">
                                {data?.PhoneNumberBase}
                            </a>
                        )}
                    </>
                )}

            </div>
        </div>
    );

    return (
        <PageSection>
            <ContentBox underline={false} className="relationship-manager atram">
                <Title type={2}>{t('dashboard.relationshipManager.atramContact')}</Title>
                <Preloader isLoading={isLoading} error={error}>
                    <div className="media atram">
                        {renderManagerSection(rmData)}
                        {renderManagerSection(iwcData, false)}
                    </div>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

AtramRelationshipManager.propTypes = {
    rmData: PropTypes.shape({
        Contacts: PropTypes.arrayOf(
            PropTypes.shape({
                Information: PropTypes.shape({
                    title: PropTypes.string,
                    subtitle: PropTypes.string,
                }),
                Icon: PropTypes.shape({
                    type: PropTypes.string,
                    href: PropTypes.string,
                    size: PropTypes.number,
                }),
            }),
        ),
        Img: PropTypes.string,
        Name: PropTypes.string,
        PhoneNumber: PropTypes.string,
        PhoneNumberBase: PropTypes.string,
        Email: PropTypes.string,
    }).isRequired,
    iwcData: PropTypes.shape({
        Contacts: PropTypes.arrayOf(
            PropTypes.shape({
                Information: PropTypes.shape({
                    title: PropTypes.string,
                    subtitle: PropTypes.string,
                }),
                Icon: PropTypes.shape({
                    type: PropTypes.string,
                    href: PropTypes.string,
                    size: PropTypes.number,
                }),
            }),
        ),
        Img: PropTypes.string,
        Name: PropTypes.string,
        PhoneNumber: PropTypes.string,
        PhoneNumberBase: PropTypes.string,
        Email: PropTypes.string,
    }),
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

AtramRelationshipManager.defaultProps = {
    error: undefined,
    iwcData: null,
};

export default AtramRelationshipManager;
