import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';

export const useCheckRiskProfile = (contactId, productId) => {
    const { i18n } = useTranslation();
    const [riskByVolatility, setRiskByVolatility] = useState([]);
    // const [clientRiskProfile, setClientRiskProfile] = useState('');
    const [clientRiskBandwidth, setClientRiskBandwidth] = useState({});
    const [isLoadingCompareVolatility, setIsLoadingCompareVolatility] = useState(false);

    const checkRiskProfile = useCallback(
        (portfolioRiskId, clientRiskId) => portfolioRiskId <= clientRiskId,
        [],
    );

    const getRiskCategories = useCallback(async () => {
        let riskCategoriesResp = [];

        if (productId) {
            riskCategoriesResp = await ServiceManager.commonService('getRiskCategoriesByProduct', [productId, i18n.language]);
        } else {
            riskCategoriesResp = await ServiceManager.commonService('getRiskCategories', [i18n.language]);
        }

        return riskCategoriesResp.data;
    }, [i18n.language, productId]);
    const compareVolatility = useCallback(async (volatility, clientRiskId) => {
        setIsLoadingCompareVolatility(true);

        const risks = await getRiskCategories();
        const portfolioRisk = risks.find(({ Min, Max }) => Min < volatility && volatility < Max);

        setClientRiskBandwidth(risks.find(({ Id }) => Id === clientRiskId));
        setRiskByVolatility(portfolioRisk?.Name);
        setIsLoadingCompareVolatility(false);

        return portfolioRisk?.Id <= clientRiskId;
    }, [getRiskCategories]);

    return {
        checkRiskProfile,
        compareVolatility,
        riskByVolatility,
        clientRiskBandwidth,
        isLoadingCompareVolatility,
    };
};
