import { createContext } from 'react';

const initialContext = {
    instrumentId: undefined,
    documents: {
        data: null,
        error: null,
        isLoading: false,
        dataDocument: null,
        errorDocument: null,
        isLoadingDocument: false,
    },
};

const InstrumentContext = createContext(initialContext);

export default InstrumentContext;
