import { sorterStringCommon } from 'utils/sorting';

export const adaptCountries = (data) => (data || []).map((item) => ({
    value: item.ThreeLetterIsoCode,
    label: item.Name,
})).sort(sorterStringCommon('label'));

export const adaptCountriesWithCode = (data) => (data || []).map((item) => ({
    value: item.ThreeLetterIsoCode,
    label: item.Name,
    code: item.CountryCode,
})).sort(sorterStringCommon('label'));
