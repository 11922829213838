import React from 'react';
import PropTypes from 'prop-types';
import { Infobox } from 'ui-library';
import './WidgetError.css';

const WidgetError = ({ message, ...props }) => (
    <Infobox {...props}>
        <div>{message}</div>
    </Infobox>
);

WidgetError.propTypes = {
    message: PropTypes.string,
};

WidgetError.defaultProps = {
    message: '',
};

export default WidgetError;
