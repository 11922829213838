import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Infobox } from 'ui-library';
import history from 'services/history';
import EmptyContent from 'components/EmptyContent';
import { formDataSelector, questionnaireSelector, useRiskProfileSelector } from 'domain/RiskProfile';
import QuestionnaireGroup from 'components/QuestionnaireGroup';
import { adaptAnswers } from 'adaptors/adaptAnswers';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import './RiskProfileQuestionnaire.css';

const RiskProfileQuestionnaire = (props) => {
    const {
        match: { params: { group: groupStr } },
        location: { state },
        onPrev,
    } = props;
    const group = +groupStr;
    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Risks Profile Domain
    const {
        data, isLoading, error, getQuestionnaire, validateQuestionnaire,
        isLoadingPost, errorPost, postQuestionnaire,
    } = useRiskProfileSelector(questionnaireSelector);
    const {
        data: formData, initializeData, saveData, clearData, clearSelectedRisk,
    } = useRiskProfileSelector(formDataSelector);

    // Data
    const isNextReview = useMemo(() => state?.redoStep || data?.Groups?.length === group + 1,
        [data, group, state]);
    const groupId = useMemo(() => data?.Groups?.[group]?.Id,
        [data, group]);
    const {
        isValid: allQuestionsFilled, errors,
    } = useMemo(() => validateQuestionnaire({ groupId, answers: formData }),
        [formData, groupId, validateQuestionnaire]);

    // Callbacks
    const onChange = useCallback(({ questionId, value }) => {
        saveData({ groupId, questionId, value });
    }, [groupId, saveData]);
    const onBack = useCallback(() => {
        if (group === 0) {
            onPrev();
            clearData();
            clearSelectedRisk();
        } else {
            history.push(`/onboarding/step/2/${group - 1}`);
        }
    }, [group, onPrev, clearData, clearSelectedRisk]);
    const onContinue = useCallback(async () => {
        setIsSubmitted(true);

        if (allQuestionsFilled) {
            const answers = adaptAnswers(data, formData);

            await postQuestionnaire({ answers });
            setIsSubmitted(false);
            history.push(`/onboarding/step/2/${isNextReview ? 'review' : group + 1}`);
        }
    }, [allQuestionsFilled, data, formData, group, isNextReview, postQuestionnaire]);

    // Effects
    useEffect(() => {
        if (groupId) global.scrollTo({ top: 0, behavior: 'smooth' });
    }, [groupId]);
    useEffect(() => {
        getQuestionnaire();
    }, [getQuestionnaire]);
    useEffect(() => {
        if (!formData && data?.Groups?.length) {
            const groupIds = data?.Groups.map(({ Id }) => Id);

            initializeData({ groupIds });
        }
    }, [data, formData, initializeData]);

    return (
        <OnBoardingBaseTemplate
            isLoading={isLoading}
            error={error}
            className="risk-profile"
            prevButton={{
                text: t('riskProfile.back'),
                loading: isLoadingPost,
                onClick: onBack,
            }}
            nextButton={{
                text: t('confirmation.continue'),
                loading: isLoadingPost,
                onClick: onContinue,
            }}
            buttonsRowError={errorPost && <Infobox error>{errorPost.message}</Infobox>}
        >
            <EmptyContent data={data} text={t('dashboard.noData')}>
                <QuestionnaireGroup
                    data={data?.Groups?.[group]}
                    title={data?.Groups?.[group]?.Name}
                    value={formData?.[groupId] || {}}
                    errors={isSubmitted && errors}
                    showError={!allQuestionsFilled && isSubmitted}
                    onChange={onChange}
                />
            </EmptyContent>
        </OnBoardingBaseTemplate>
    );
};

RiskProfileQuestionnaire.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            group: PropTypes.string,
        }),
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            redoStep: PropTypes.bool,
        }),
    }).isRequired,
    onPrev: PropTypes.func.isRequired,
};

RiskProfileQuestionnaire.defaultProps = {
};

export default RiskProfileQuestionnaire;
