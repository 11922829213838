import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    ContentBox, Modal, Accordion, AccordionPanel as Panel,
} from 'ui-library';
import history from 'services/history';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import {
    orderBookSelector, portfolioSelector, usePortfolioSelector, modelPortfolioSelector,
} from 'domain/Portfolio';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { useFormatting } from 'locale';
import Analysis from 'components/Analysis/Analysis';
import RiskDisclaimer from 'components/RiskDisclaimer';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import AccordionWrapper from 'components/AccordionWrapper';
import PageHeader from 'components/PageHeader';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import Positions from 'components/PositionsCompare';
import StrategyOverview from 'components/StrategyOverview';
import { useRebalanceAndChangeModelAllocationData } from 'pages/pages/Portfolios/hooks/useRebalanceAndChangeModelAllocationData';
import { AllocationCompareWithITL } from 'components/AllocationCompareWithITL/AllocationCompareWithITL';
import { REBALANCE } from '../../SinglePortfolioView/pages/PositionDetails/constants';
import { onPreventRedirect, redirectToReadOnlyPositionDetails } from '../../common/utils';
import { useCheckRiskProfile } from '../../../hooks/useCheckRiskProfile';


function RebalanceAdvisory(props) {
    const {
        contactId: clientId,
        match: { params: { portfolioId } },
    } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: modelPortfolio, dataRaw: modelPortfolioRaw,
        isLoading: isLoadingModelPortfolio, error: errorModelPortfolio,
    } = usePortfolioSelector(modelPortfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook();
    }, []);

    // Data
    const baseUrl = `/portfolios/${portfolioId}/actions/rebalance/position/${data?.productId}`;
    const positions = useMemo(() => groupComparedPositions({
        positions: dataRaw.Positions,
        modelPositions: modelPortfolioRaw.Positions || dataRaw.Positions,
        portfolioCurrency: data.currency || data.Currency?.CurrencyCode,
        baseUrl,
        t,
        portfolioTotalValue: data.investedAmount,
        redirectToReadOnlyPositionDetails,
        getFormattedNumber,
        getFormattedCurrency,
    }), [
        data.Currency?.CurrencyCode,
        data.InvestedAmount,
        data.currency,
        dataRaw.Positions,
        modelPortfolioRaw.Positions,
        baseUrl,
        getFormattedCurrency,
        getFormattedNumber,
        t,
    ]);

    // Hooks
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, isLoading: isLoadingModel, errorModel,
        onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        strategy: modelPortfolio,
        positions,
        isLoading,
        benchmark,
        isLoadingBenchmark,
        actionType: REBALANCE,
        additionalOptions: {
            usePortfolioAllocations: true,
        },
    });

    const {
        alocationsData,
        isAllocationLoading,
        allocationError,
    } = useRebalanceAndChangeModelAllocationData({
        clientId,
        portfolioId,
        modelPortfolioId: modelPortfolio.modelPortfolioId,
        isLoading,
        currency: modelPortfolio?.currency,
    });

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const { checkRiskProfile } = useCheckRiskProfile(clientId);
    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: dataRaw?.RiskCategory?.Id,
        riskCategoryName: dataRaw?.RiskCategory?.Name,
    }), [dataRaw?.RiskCategory]);

    useEffect(() => {
        setShowRiskDisclaimer(false);
        if (modelPortfolio?.riskCategory) {
            setShowRiskDisclaimer(
                !checkRiskProfile(modelPortfolio?.riskCategory, riskCategoryId),
            );
        }
    }, [modelPortfolio?.riskCategory, riskCategoryId]);

    // Renderers
    const AllocationRender = useMemo(
        () => (
            <AllocationCompareWithITL
                data={alocationsData}
                isLoading={isLoading || isAllocationLoading}
                error={error || allocationError}
            />

        ),
        [
            alocationsData,
            isAllocationLoading,
            allocationError,
            isLoading,
            error,
        ],
    );
    const renderRiskDisclaimer = () => (
        <RiskDisclaimer PRP={modelPortfolio.overview?.strategy} CRP={riskCategoryName} />
    );

    // Callbacks
    const onConfirm = () => {
        if (!showRiskDisclaimer) {
            history.push(`/portfolios/${portfolioId}/actions/rebalance/orders`);

            return;
        }

        Modal.confirm({
            title: t('overview.disclaimer'),
            content: t('changeStrategy.riskProfileContent'),
            okText: t('confirmation.yes'),
            cancelText: t('confirmation.no'),
            onOk: () => {
                history.push(`/portfolios/${portfolioId}/actions/rebalance/orders`);
            },
            className: 'accept-risk-profile',
        });
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onDiscardChanges = () => {
        onCancel(`/portfolios/${portfolioId}`);
    };

    return (
        <Preloader
            isLoading={isLoading || isLoadingModelPortfolio || isLoadingOrderBook}
            error={error || errorModelPortfolio || errorOrderBook}
        >
            <ContentBox className="rebalance with-mb" underline={false}>
                <PageHeader
                    title={t('portfolios.rebalance')}
                    breadCrumbsCurrent={t('portfolios.rebalance')}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            onClick: (e) => onPreventRedirect(e, '/portfolios', onCancel),
                            label: t('portfolios.title'),
                        },
                        {
                            to: `/portfolios/${portfolioId}`,
                            onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                            label: data?.title,
                        },
                    ]}
                />
                {showRiskDisclaimer && !isLoading && renderRiskDisclaimer()}
                <div className="mb-0 overview">
                    <StrategyOverview
                        data={data.overview}
                        dataStrategy={modelPortfolio.overview}
                        isLoading={isLoadingModelPortfolio}
                        error={errorModelPortfolio}
                    />
                </div>
                <AccordionWrapper className="confirmation">
                    <Accordion defaultActiveKey={['1', '2', '3', '4']}>
                        <Panel header={t('confirmation.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('confirmation.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                            />
                        </Panel>
                        <Panel header={t('confirmation.positions')} className="positions" key="3">
                            <Positions
                                data={positions}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </AccordionWrapper>
                <ButtonsBlockRow
                    leftButton={{
                        type: 'danger',
                        text: t('confirmation.cancel'),
                        onClick: onDiscardChanges,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        disabled: orderBook,
                        onClick: onConfirm,
                    }}
                />
            </ContentBox>
        </Preloader>
    );
}

RebalanceAdvisory.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

RebalanceAdvisory.defaultProps = {};

export default RebalanceAdvisory;
