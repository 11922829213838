import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { adaptRssNews } from '../adapters/adaptNews';

export const useNews = (newsItem, params) => {
    const { rssNews, currentPage } = (params || {});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);
    const { i18n } = useTranslation();

    const getRssNews = useCallback(async ({ pageSize, page }) => {
        try {
            const { data: rawRssNews } = (pageSize === undefined || pageSize > 0)
                && await ServiceManager.rssService('rssFeed', [i18n.language, pageSize ? { pageSize, page: page || 0 } : {}]);

            try {
                const adapted = adaptRssNews(rawRssNews?.result || rawRssNews);

                setNewsList(adapted);
                setLoading(false);

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));
            setLoading(false);

            return null;
        }
    }, [rssNews, currentPage, i18n.language]);

    useEffect(() => {
        if (newsItem === undefined) {
            setLoading(true);
            setError(null);

            getRssNews({ pageSize: rssNews, page: currentPage });
        } else {
            setLoading(false);
            setNewsList([newsItem]);
        }
    }, [newsItem, i18n.language, rssNews, currentPage]);

    return {
        data: newsList, isLoading, error, getRssNews,
    };
};
