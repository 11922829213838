import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Title, ContentBox, Table } from 'ui-library';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { getClassNames } from 'generalUtils';
import { tasks, tasksSorting } from 'constants/constants';
import { useFormatting } from 'locale';
import history from 'services/history';

import './Tasks.css';

function Tasks({
    data: dataSource, isLoading, error, mini, fullPage, urgencyFilters, disableLinks, noDataText,
}) {
    const { getFormattedDate } = useFormatting();
    const { t, i18n } = useTranslation();
    const className = getClassNames('tasks', { 'tasks-mini': mini });

    const data = useMemo(() => {
        if (!mini) return dataSource;

        return dataSource.map((item) => ({
            ...item,
            Subject: { ...item.Subject, maxLength: 15 },
        }));
    }, [dataSource, mini]);

    const [total, setTotal] = useState(data?.length ?? 0);

    const onChange = useCallback((_, __, ___, { updatedTotal }) => {
        setTotal(updatedTotal);
    }, [setTotal]);

    const titleComponent = useMemo(() => {
        const titleLink = !disableLinks ? '/tasks' : null;
        const titleText = !fullPage
            ? <Title type={2} link={titleLink}>{t('tasks.title')}</Title>
            : <Title type={2} className="underlined">{t('tasks.title')}</Title>;


        return titleText;
    }, [fullPage, i18n.language, disableLinks]);

    const onRowClick = useCallback((record) => ({
        onClick: () => {
            if (record?.action === 'redirect' && record.link) {
                history.push(`${record.link}/0`, { ticketId: record.id });
            }
        },
    }), []);

    return (
        <PageSection className={className}>
            <ContentBox className="resize-widget table-without-last-row-padding tasks-widget">
                {titleComponent}
                <Preloader isLoading={isLoading} error={error}>
                    <EmptyContent data={data} text={t(noDataText)}>
                        <Table
                            data={data}
                            columns={tasks(t, getFormattedDate, fullPage, urgencyFilters, mini)}
                            sorting={fullPage ? null : tasksSorting}
                            mini={!fullPage}
                            onChange={onChange}
                            headerControls={{
                                sortByLabel: t('headerControls.sortBy'),
                            }}
                            paging={{
                                total,
                                pageSize: 9,
                                currentPage: 1,
                            }}
                            onRow={onRowClick}
                        />
                    </EmptyContent>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Tasks.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.shape({
                title: PropTypes.string,
                subtitle: PropTypes.string,
            }),
            type: PropTypes.string,
            date: PropTypes.string,
            urgency: PropTypes.shape({
                text: PropTypes.string,
                color: PropTypes.string,
            }),
        }),
    ),
    urgencyFilters: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
    fullPage: PropTypes.bool,
    mini: PropTypes.bool,
    disableLinks: PropTypes.bool,
    noDataText: PropTypes.string,
};

Tasks.defaultProps = {
    data: [],
    urgencyFilters: [],
    isLoading: true,
    error: null,
    fullPage: false,
    mini: false,
    disableLinks: false,
    noDataText: 'tasks.noData',
};

export default React.memo(Tasks);
