import {
    CellSwitch,
} from 'components/renderers';
import { sorterString } from 'utils/sorting';

export const getSecuritiesColumns = (t) => ([
    {
        key: 'Name',
        title: t('portfolios.columns.name'),
        render: CellSwitch,
        width: 300,
        sorter: sorterString('Name.value'),
    },
    {
        key: 'Isin',
        title: t('position.columns.isin'),
        sortable: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'AssetClass',
        title: t('portfolios.securitiesColumns.assetClass'),
        sortable: true,
        filterable: true,
        width: 145,
        defaultSortOrder: 'ascend',
        sorter: sorterString('AssetClass'),
    },
    {
        key: 'SubAssetClass',
        title: t('portfolios.securitiesColumns.subAssetClass'),
        sortable: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Type',
        title: t('position.columns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Currency',
        title: t('position.columns.currency'),
        width: 100,
        filterable: true,
        sortable: true,
        sorter: sorterString('Currency'),
    },
]);
