import { checkNullData } from 'utils';

export const adaptListCurrenciesByCode = (data) => {
    if (checkNullData(data)) return [];

    return [
        ...data
            .map(({ Id, CurrencyCode }) => ({ label: CurrencyCode, value: Id }))
            .sort((a, b) => a.label.localeCompare(b.label)),
    ];
};
