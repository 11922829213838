import React from 'react';
import { Label as LabelUI } from 'ui-library';
import PropTypes from 'prop-types';

const BandwidthLabel = ({
    label, values = {}, getFormattedNumber, t,
}) => {
    const valuesFormatted = values.new
        ? `${getFormattedNumber(values?.new)}% (${t('confirmation.toDate')} ${getFormattedNumber(values?.curr)}%)`
        : `${getFormattedNumber(values?.curr)}%`;

    return (
        <>
            <LabelUI label={label} labelInfobox />
            {valuesFormatted}
        </>
    );
};

BandwidthLabel.propTypes = {
    t: PropTypes.func,
    getFormattedNumber: PropTypes.func,
    label: PropTypes.string,
    values: PropTypes.objectOf(PropTypes.object),
};

BandwidthLabel.defaultProps = {
    getFormattedNumber: (v) => v,
    t: (v) => v,
    label: '',
    values: null,
};

export default BandwidthLabel;
