import React, { memo, useCallback, useEffect } from 'react';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';
import { jwtAccessTokenSelector, isAuthenticatedSelector } from 'redux/auth/authSelectors';
import { generateAccessToken, NOTIFICATION_SCOPE } from 'services/accessToken';
import SM from 'services/ServiceManager';
import { useNotificationConext } from 'additiv-react-signalr';

const ON_UNAUTHORIZED_THROTTLE_TIME = 5000;
const DEBUG_LEVEL = process.env.NODE_ENV === 'development' ? 2 : 5;
const EXPIRED_TOKEN_CODE = 5;

const withSignalR = (Component) => {
    const ComponentMemo = memo(Component);

    return (props) => {
        const jwt = useSelector(jwtAccessTokenSelector);
        const isAuthenticated = useSelector(isAuthenticatedSelector);
        const {
            start,
            stop,
        } = useNotificationConext();

        const onUnauthorized = useCallback(throttle(async () => {
            try {
                stop();

                const response = await SM.Security('refreshConnectHubToken');

                start({
                    url: global?.AppConfig?.NOTIFICATION_HUB,
                    accessToken: response.data?.result?.accessToken,
                    debugLevel: DEBUG_LEVEL,
                    onUnauthorized,
                });
            } catch (error) {
                console.error('Error of refreshing notification access token. Original message:', error);
            }
        }, ON_UNAUTHORIZED_THROTTLE_TIME), []);

        useEffect(() => {
            if (isAuthenticated) {
                generateAccessToken({
                    type: NOTIFICATION_SCOPE,
                    access_Token: jwt,
                }).then((data) => {
                    if (data?.apiError?.code === EXPIRED_TOKEN_CODE) onUnauthorized();
                    else if (data?.result?.accessToken) {
                        start({
                            url: global?.AppConfig?.NOTIFICATION_HUB,
                            accessToken: data?.result?.accessToken,
                            debugLevel: DEBUG_LEVEL,
                            onUnauthorized,
                        });
                    }
                }).catch((error) => {
                    console.error('Error of getting notification access token. Original message:', error);
                });
            } else {
                stop();
            }
        }, [isAuthenticated, onUnauthorized]);

        return <ComponentMemo {...props} />;
    };
};

export default withSignalR;
