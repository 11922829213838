import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    Modal, Accordion, AccordionPanel as Panel, Infobox, ContentBox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { formatDate, yearFormat } from 'utils/datetime';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import Analysis from 'components/Analysis';
import Allocation from 'components/AllocationCompare';
import StrategyOverview from 'components/StrategyOverview';
import PositionsCompare from 'components/PositionsCompare';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import {
    goalCreationSelector, goalSummarySelector,
    onBoardingDataSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import { useModelPortfolio } from 'domain/Portfolio';
import { adaptModelPortfolio } from 'domain/Portfolio/adapters/adaptModelPortfolio';
import { CHANGE_STRATEGY } from 'constants/constants';
import { useStrategyList } from 'hooks/useStrategyList';
import { useModelList } from 'hooks/useModelList';
import { useModelChange } from 'hooks/useModelChange';
import RiskDisclaimer from 'components/RiskDisclaimer';
import { useChangeStrategyConfig } from 'hooks/useChangeStrategyConfig';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { adaptConstraints } from '../../adaptors/adaptConstraints';
import { useCheckRiskProfile } from '../../../../../Portfolios/hooks/useCheckRiskProfile';
import { useGoalReach } from '../../hooks/useGoalReach';
import './ChangeStrategy.css';

const ChangeStrategy = (props) => {
    const { onPageChange, location: { pathname } } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const [errorSameStrategy, setErrorSameStrategy] = useState(false);
    const [riskCategory, setRiskCategory] = useState(null);
    const [strategy, setStrategy] = useState(JSON.parse(sessionStorage.getItem(CHANGE_STRATEGY)));

    // OnBoarding Domain
    const {
        product, getGoalDetails, getRiskCategory, saveGoalChanges, clearGoalChanges, saveProposalId,
        getGoalChanges, clearCreatedProposalId, saveReviewData, createdProposalId,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        isLoading, error, getModelPortfolioId,
    } = useOnBoardingSelector(goalCreationSelector);
    const {
        saveProposal, isLoadingSave, errorSave,
        updateProposal, isLoadingUpdate, errorUpdate,
    } = useOnBoardingSelector(goalSummarySelector);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();

    // Data
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate],
    );
    const baseUrl = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;
    const optionsForAdapt = useMemo(
        () => ({
            productID: +product.id,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            portfolioTotalValue: +goalDetails.initialInvestment,
            portfolioSecuritiesValue: +goalDetails.initialInvestment,
            recurringPayment: +goalDetails.recurringPayment,
            projectionYears,
            positionLink: `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        }),
        [
            product.id,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            goalDetails.recurringPayment,
            projectionYears,
            pathname,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        ],
    );
    const data = useMemo(() => adaptModelPortfolio({ ...optionsForAdapt, data: modelPortfolio }),
        [modelPortfolio, optionsForAdapt]);
    const dataForMP = useMemo(() => ({
        ...data, projectionYears, recurringPayment: +goalDetails.recurringPayment,
    }), [data, projectionYears, goalDetails.recurringPayment]);

    // Helpers hooks
    const {
        strategyList, isLoadingStrategyList, errorStrategyList,
    } = useStrategyList(+product?.id);
    const {
        data: modelList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList({ productId: data?.productId, riskCategoryId: strategy });
    const {
        modelData, positions, modelId,
        isLoadingModelData, errorModelData, onModelChange, onModelReset,
    } = useModelChange({
        portfolio: dataForMP, portfolioPositions: data?.Positions, baseUrl,
    });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks({
        currencyId: goalDetails.selectedCurrency?.value,
        productId: +product?.id,
    }, isLoading, true);
    const strategyForAnalysis = useMemo(() => (modelData?.overview ? modelData
        : null), [modelData]);
    const {
        analysisData,
        isLoadingModel, errorModel, onPerformanceChange, performanceSelected, projectionResponse,
    } = useAnalysisDetails({
        portfolio: data,
        strategy: data.currencyId ? strategyForAnalysis : null,
        positions,
        isLoading,
        benchmark,
        actionType: CHANGE_STRATEGY,
        riskProfileStrategyTitle: t('onBoarding.chosen'),
        riskProfilePortfolioTitle: t('onBoarding.suggested'),
    });

    const {
        dataExternal, isGoalReach,
    } = useGoalReach({
        goalState: goalDetails,
        projectionResponse,
    });

    // Effects
    useEffect(() => {
        (async () => {
            if (goalChanges) {
                getModelPortfolio(goalChanges, { currencyId: goalDetails.selectedCurrency?.value });
            } else {
                const risk = await getRiskCategory();

                setRiskCategory(risk);
                const riskCategoryId = risk?.Id;

                const modelObj = await getModelPortfolioId(
                    { productId: +product?.id, riskCategoryId },
                );

                getModelPortfolio(modelObj?.Id,
                    { currencyId: goalDetails.selectedCurrency?.value });
            }
        })();
    }, [
        getRiskCategory,
        getModelPortfolioId,
        getModelPortfolio,
        product?.id,
        goalDetails.selectedCurrency?.value,
        goalChanges,
    ]);
    useEffect(() => {
        if (goalChanges) {
            setRiskCategory(modelPortfolio.RiskCategory);
        }
    }, [modelPortfolio?.RiskCategory, goalChanges]);
    useEffect(() => {
        if (modelList.length) {
            if (modelList.find(({ value }) => value === modelId)) return;
            onModelChange(modelList[0]?.value);
        }
    }, [modelList, modelId, onModelChange]);

    // Renderers
    const isLoadingData = useMemo(() => isLoading || isLoadingMP || isLoadingStrategyList,
        [isLoading, isLoadingMP, isLoadingStrategyList]);
    const errorData = useMemo(() => error || errorMP || errorStrategyList,
        [error, errorMP, errorStrategyList]);
    const AllocationRender = useMemo(() => (
        <Allocation
            data={data.investmentAllocation}
            dataNew={modelData?.investmentAllocation || data.investmentAllocation}
            isLoading={isLoadingData}
            error={errorData}
        />
    ), [
        data.investmentAllocation,
        modelData?.investmentAllocation,
        isLoadingData,
        errorData,
    ]);

    // Callbacks
    const onStrategyChange = (val) => {
        setStrategy(val);
        setErrorSameStrategy(false);
        sessionStorage.setItem(CHANGE_STRATEGY, JSON.stringify(val));
    };
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onBackClick = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onStrategyReset();
                clearGoalChanges();
                clearCreatedProposalId();
                onPageChange('');
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    // Risk Profile Check
    const [showRiskDisclaimer, setShowRiskDisclaimer] = useState(false);
    const { checkRiskProfile } = useCheckRiskProfile();

    const { riskCategoryId, riskCategoryName } = useMemo(() => ({
        riskCategoryId: riskCategory?.Id,
        riskCategoryName: riskCategory?.Name,
    }), [riskCategory]);

    useEffect(() => {
        setShowRiskDisclaimer(false);
        if (modelData?.riskCategory) {
            setShowRiskDisclaimer(
                !checkRiskProfile(modelData.riskCategory, riskCategoryId),
            );
        }
    }, [modelData?.riskCategory, riskCategoryId]);

    const onConfirm = useCallback(async () => {
        if (data?.modelPortfolioId === +modelId || !modelId) {
            setErrorSameStrategy(true);

            return;
        }

        const isUpdate = typeof createdProposalId === 'number';

        if (isUpdate) {
            const params = {
                ModelPortfolioId: {
                    DoUpdate: true,
                    Value: modelData.modelPortfolioId,
                },
                OptimizationConstraints: {
                    DoUpdate: true,
                    Value: adaptConstraints(modelData?.constraints),
                },
            };

            await updateProposal(createdProposalId, params);
        } else {
            const params = {
                ModelPortfolioId: modelId,
                Name: goalDetails?.goalName,
                RecommendedInvestment: +goalDetails?.initialInvestment,
                ProductId: +product.id,
                CurrencyId: goalDetails?.selectedCurrency.value,
                OptimizationConstraints: adaptConstraints(modelData?.constraints),
            };
            const savedProposalData = await saveProposal(params);

            if (savedProposalData?.Id) {
                saveProposalId(savedProposalData.Id);
            }
        }
        saveReviewData({
            modelPortfolioId: modelData?.modelPortfolioId,
            benchmarkId: benchmarkSelected || modelData?.benchmarkId,
        });
        onStrategyReset();
        saveGoalChanges(modelId);
        onPageChange('');
    }, [data?.modelPortfolioId,
        modelId,
        goalDetails?.goalName,
        goalDetails?.initialInvestment,
        product.id,
        goalDetails?.selectedCurrency.value,
        modelData?.constraints,
        saveProposal,
    ]);

    const onNextClick = () => {
        if (!showRiskDisclaimer) {
            onConfirm();

            return;
        }

        Modal.confirm({
            title: t('overview.disclaimer'),
            content: t('changeStrategy.riskProfileContent'),
            okText: t('confirmation.yes'),
            cancelText: t('confirmation.no'),
            onOk: onConfirm,
            className: 'accept-risk-profile',
        });
    };

    const renderRiskDisclaimer = () => (
        <RiskDisclaimer PRP={modelData?.overview?.strategy} CRP={riskCategoryName} />
    );

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('overview.changeStrategy')}`}
            error={errorData}
            isLoading={isLoadingData}
            className="goal-review goal-change-strategy"
            classNameContent="stepper-content-full"
            prevButton={{
                loading: isLoadingSave || isLoadingUpdate,
                disabled: isLoading,
                onClick: onBackClick,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                loading: isLoadingSave || isLoadingUpdate,
                disabled: isLoading,
                onClick: onNextClick,
            }}
            topInfoboxContent={!isGoalReach && !isLoadingModel && !isLoadingBenchmark && (
                <div className="goal-reached">
                    <Infobox accent>{t('onBoarding.cannotReachedGoal')}</Infobox>
                </div>
            )}
            buttonsRowError={(
                <>
                    {errorSave && <Infobox error>{errorSave.message}</Infobox>}
                    {errorUpdate && <Infobox error>{errorUpdate.message}</Infobox>}
                    {errorSameStrategy && <Infobox error>{t('onBoarding.errorChangeSameStrategy')}</Infobox>}
                </>
            )}
        >
            <EmptyContent data={{ test: 'test' }} text={t('advisoryDashboard.noData')}>
                <ContentBox underline={false}>
                    {showRiskDisclaimer && renderRiskDisclaimer()}
                    <StrategyOverview
                        data={data.overview}
                        isLoading={isLoadingStrategyList}
                        error={errorStrategyList}
                        isLoadingModel={isLoadingModelList}
                        errorModel={errorModelList}
                        selectedStrategy={strategy}
                        strategyList={strategyList}
                        onStrategyChange={onStrategyChange}
                        selectedModel={modelId}
                        modelList={modelList}
                        onModelChange={onModelChange}
                    />
                </ContentBox>
                <Preloader isLoading={isLoadingModelData} error={errorModelData}>
                    <div className="content-wrapper">
                        <Accordion defaultActiveKey={['1', '2', '3']}>
                            <Panel header={t('portfolios.allocation')} key="1" className="allocation">
                                {AllocationRender}
                            </Panel>
                            <Panel header={t('portfolios.analysis')} key="2" className="analysis">
                                <Analysis
                                    data={analysisData}
                                    onFilterChange={onPerformanceChange}
                                    benchmarkOptions={benchmarkOptions}
                                    benchmarkSelected={benchmarkSelected}
                                    onBenchmarkChange={onBenchmarkChange}
                                    isLoadingBenchmarks={isLoadingBenchmark}
                                    performanceSelected={performanceSelected}
                                    projectionDataExternal={dataExternal}
                                    isLoading={isLoadingModel}
                                    error={errorModel}
                                    isPortfolioProposed
                                />
                            </Panel>
                            <Panel header={t('portfolios.positions')} className="positions" key="3">
                                <PositionsCompare
                                    data={positions}
                                    isLoading={isLoading}
                                    error={error}
                                    defaultExpandAllRows
                                />
                            </Panel>
                        </Accordion>
                    </div>
                </Preloader>
            </EmptyContent>
        </OnBoardingBaseTemplate>
    );
};

ChangeStrategy.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    onPageChange: PropTypes.func,
};

ChangeStrategy.defaultProps = {
    onPageChange: () => {},
};

export default ChangeStrategy;
