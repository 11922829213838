import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import InfoboxWithButton from 'components/InfoboxWithButton';
import { Icon, Paragraph, Title } from 'ui-library';
import Infobox from 'ui-library/dist/components/Infobox';
import { getClassNames } from 'utils';
import {
    knowledgeExperienceSelector,
    onBoardingDataSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import { adaptResults } from '../../adapters/adaptKnowledgeExperience';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useKnowledgeExperienceByProduct } from '../../hooks/useKnowledgeExperienceByProduct';
import './Results.css';

const Results = (props) => {
    const {
        onPageChange, onNext, changeStep,
    } = props;
    const { t } = useTranslation();

    // OnBoarding Domain
    const {
        product,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        results: data, errorResults, isLoadingResults, getQuestionnaireResults,
    } = useOnBoardingSelector(knowledgeExperienceSelector);

    // Data
    const resultsAdapted = useMemo(() => adaptResults(data?.Groups?.[0]?.Steps?.[0]),
        [data]);

    // Helpers hooks
    const {
        onUniverseComplete, errorUniverseComplete, continueDisabled,
    } = useKnowledgeExperienceByProduct({
        onNext,
        productId: product?.id,
        productName: product?.name,
        hasPositiveInstruments: resultsAdapted?.positive?.length > 0,
        hasNegativeInstruments: resultsAdapted?.negative?.length > 0,
    });

    // Effects
    useEffect(() => {
        getQuestionnaireResults({ productId: +product?.id });
    }, [getQuestionnaireResults]);

    // Callbacks
    const onExpand = () => {
        onPageChange('expand-universe');
    };
    const onNextCLick = () => {
        onUniverseComplete();
    };

    // Renderers
    const renderInfobox = () => (
        <InfoboxWithButton accent buttonText={t('onBoarding.changeProduct')} buttonProps={{ onClick: () => { changeStep(1, 'product'); } }}>
            {errorUniverseComplete}
        </InfoboxWithButton>
    );

    const renderHelpInfobox = () => (
        <Infobox accent>
            <span>{t('onBoarding.knowledgeAndExperienceHelpInfoBox')}</span>
        </Infobox>
    );

    const negativeResultsClassName = useMemo(() => getClassNames(
        'negative-results', { 'without-border': resultsAdapted?.positive.length === 0 },
    ), [resultsAdapted?.positive]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.thankYouForAnswering')}
            error={errorResults}
            isLoading={isLoadingResults}
            className="knowledge-and-experience-results"
            additionalButton={resultsAdapted?.negative?.length > 0 && {
                text: t('onBoarding.expandYourInstrumentUniverse'),
                onClick: onExpand,
            }}
            nextButton={{
                disabled: continueDisabled,
                onClick: onNextCLick,
            }}
        >
            <EmptyContent data={data?.Groups?.[0]} text={t('advisoryDashboard.noData')}>
                {resultsAdapted?.positive.length > 0 && (
                    <div className="positive-results">
                        <div className="positive">
                            <div className="icon">
                                <Icon type="check-filled" size={16} />
                            </div>
                            <Paragraph>
                                {t('onBoarding.resultKnowledgeAndExperience')}
                            </Paragraph>
                        </div>
                        {(resultsAdapted?.positive || []).map((item) => (
                            <div className="instrument-block">
                                <Title type={4}>{item.Text}</Title>
                                <Paragraph type="secondary">
                                    {`${t(`onBoarding.instrumentDescription${item.resource}`)}`}
                                </Paragraph>
                            </div>
                        ))}
                    </div>
                )}
                {resultsAdapted?.negative?.length > 0 && (
                    <div className={negativeResultsClassName}>
                        <div className="negative">
                            <div className="icon">
                                <div className="negative-icon" />
                            </div>
                            <Paragraph>
                                {t('onBoarding.inOrderToOperateWith')}
                            </Paragraph>
                        </div>
                        {(resultsAdapted?.negative || []).map((item) => (
                            <div className="instrument-block">
                                <Title type={4}>{item.Text}</Title>
                                <Paragraph type="secondary">
                                    {`${t(`onBoarding.instrumentDescription${item.resource}`)}`}
                                </Paragraph>
                            </div>
                        ))}
                    </div>
                )}
                {resultsAdapted?.negative.length > 0 && renderHelpInfobox()}
            </EmptyContent>
            {errorUniverseComplete && renderInfobox()}
        </OnBoardingBaseTemplate>
    );
};

Results.propTypes = {
    onPageChange: PropTypes.func,
    onNext: PropTypes.func,
    changeStep: PropTypes.func,
};

Results.defaultProps = {
    onPageChange: () => { },
    onNext: () => { },
    changeStep: () => { },
};

export default Results;
