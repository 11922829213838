const ASSETS_IDS = {
    Liquidity: 1,
    Equity: 3,
    MoneyMarket: 9,
    FixedIncome: 11,
    Alternatives: 37,
};

export const ASSETS_IDS_ORDER = [
    ASSETS_IDS.Liquidity,
    ASSETS_IDS.FixedIncome,
    ASSETS_IDS.Equity,
    ASSETS_IDS.Alternatives,
];

export const sortAssets = (allocations, key = 'id') => allocations
    .sort((a, b) => ASSETS_IDS_ORDER.indexOf(key ? a[key] : a)
        - ASSETS_IDS_ORDER.indexOf(key ? b[key] : b));

const sortAssetsCompared = (allocations, compared) => {
    const intersection = sortAssets(allocations)
        .filter(({ name }) => compared.indexOf(name) !== -1)
        .map(({ name }) => name);
    const getIndex = (target) => (
        intersection.indexOf(target) === -1 ? intersection.length : intersection.indexOf(target)
    );

    return allocations.sort((a, b) => getIndex(a.name) - getIndex(b.name));
};

export const sortAllocations = (key, allocations, compared) => {
    if (key === 'asset' || key === 'ParentAssetClass') {
        return compared ? sortAssetsCompared(allocations, compared) : sortAssets(allocations);
    }
    if (compared?.length) {
        const allocationsArr = (allocations || []).map(({ name }) => name);
        const intersection = (compared || [])
            .filter((val) => allocationsArr.includes(val?.name || val))
            .sort((prev, cur) => prev.toLowerCase().localeCompare(cur.toLowerCase()));
        const getIndex = (target) => (intersection.indexOf(target) === -1
            ? intersection.length : intersection.indexOf(target));

        return allocations.sort((a, b) => getIndex(a.name) - getIndex(b.name));
    }

    return allocations.sort((prev, cur) => prev.name.toLowerCase()
        .localeCompare(cur.name.toLowerCase()));
};
