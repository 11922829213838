import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion, AccordionPanel as Panel, Column, Modal, Row, Table, Infobox, ContentBox,
} from 'ui-library';
import history from 'services/history';
import { useFormatting } from 'locale';
import { formatDate, yearFormat } from 'utils/datetime';
import WidgetError from 'components/WidgetError';
import Preloader from 'components/Preloader';
import { OverviewItem, OverviewPanel } from 'components/Overview';
import {
    ADVISORY, DISCRETIONARY, EXECUTION_ONLY, generateObject, DEPOSITS,
} from 'constants/portfolioProducts';
import { portfolioTypeIds } from 'constants/portfolioTypeIds';
import {
    goalSummarySelector,
    onBoardingDataSelector,
    productFeeSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import { useModelPortfolio } from 'domain/Portfolio';
import { TYPE_PERCENT } from 'utils/formatting';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useSummaryDocuments } from './hooks/useSummaryDocuments';
import { getTradesColumns, getDocumentsColumns } from './constants';
import { adaptSummary } from './adaptors/adaptSummary';
import { adaptProductFee } from './adaptors/adaptProductFee';
import './Summary.css';

function Summary(props) {
    const {
        onNext, onPageChange, location: { pathname },
    } = props;
    const { t } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();
    const [strategy, setStrategy] = useState('');

    // OnBoarding Domain
    const {
        product, getGoalDetails, getGoalChanges, saveCreatedGoal,
        createdProposalId, getRiskCategory, saveIban, riskCategory,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        data: dataFee, isLoading: isLoadingFee, error: errorFee, getProductFee,
    } = useOnBoardingSelector(productFeeSelector);
    const {
        data, error: errorSummary, isLoading: isLoadingSummary, getProposal,
        errorAccept, isLoadingAccept, acceptGoalAndPortfolio,
    } = useOnBoardingSelector(goalSummarySelector);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();

    // Helpers hooks
    const { documents, errorDocuments, isLoadingDocuments } = useSummaryDocuments(+product.id);

    // Data
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const proposalId = useMemo(() => typeof createdProposalId === 'number' && createdProposalId,
        [createdProposalId]);
    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate],
    );
    const optionsForAdapt = useMemo(
        () => ({
            productId: +product.id,
            productName: product.name,
            goalName: goalDetails.goalName,
            targetDate: goalDetails.targetDate,
            targetValue: +goalDetails.targetValue,
            selectedGoal: +goalDetails.selectedGoal,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            projectionYears,
            positionLink: `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`,
            nameLength: 35,
            proposalId: createdProposalId,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
        }),
        [
            product.id,
            product.name,
            goalDetails.goalName,
            goalDetails.targetDate,
            goalDetails.targetValue,
            goalDetails.selectedGoal,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            projectionYears,
            pathname,
            createdProposalId,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
        ],
    );
    const fee = useMemo(() => adaptProductFee(dataFee, +goalDetails.initialInvestment),
        [dataFee, goalDetails.initialInvestment]);
    const summaryData = useMemo(() => adaptSummary(goalChanges
        ? modelPortfolio : data, optionsForAdapt),
    [goalChanges, modelPortfolio, data, optionsForAdapt]);
    const goalParams = useMemo(() => ({
        CurrencyId: goalDetails?.selectedCurrency?.value,
        GoalAttributes: {
            Name: goalDetails.goalName,
            DueDate: goalDetails.targetDate,
            TargetAmount: +goalDetails.targetValue,
            GoalTemplateId: goalDetails.selectedGoal,
        },
        PortfolioAttributes: {
            RiskCategoryId: !goalChanges ? riskCategory?.Id : null,
            ModelPortfolioId: +goalChanges || null,
            PortfolioProposalId: !goalChanges ? proposalId : null,
            Name: goalDetails.goalName,
            ProductId: +product.id,
            InitialInvestmentAmount: +goalDetails.initialInvestment,
        },
    }), [
        goalChanges,
        goalDetails.goalName,
        goalDetails.initialInvestment,
        goalDetails.selectedCurrency?.value,
        goalDetails.selectedGoal,
        goalDetails.targetDate,
        goalDetails.targetValue,
        product.id,
        proposalId,
        riskCategory,
    ]);
    const accountParams = useMemo(() => ({
        CurrencyId: goalDetails?.selectedCurrency?.value,
        Name: goalDetails.goalName,
        ProductId: +product.id,
    }), [goalDetails?.goalName, goalDetails?.selectedCurrency?.value, product?.id]);

    // Data by product
    const showStrategy = useMemo(() => generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(product?.name), [product?.name]);
    const hasGoal = useMemo(() => goalDetails.selectedGoal !== 'no-goal' && generateObject({
        [ADVISORY]: true,
        [DISCRETIONARY]: true,
        [EXECUTION_ONLY]: false,
        [DEPOSITS]: true,
    }).getByName(product?.name), [product?.name]);
    const paramsToAccept = useMemo(() => (hasGoal ? goalParams : {
        CurrencyId: goalParams.CurrencyId,
        ...goalParams.PortfolioAttributes,
    }), [hasGoal, goalParams]);

    // Effects
    useEffect(() => {
        getProductFee(+product?.id);
    }, [getProductFee, product?.id]);
    useEffect(() => {
        if (goalChanges) {
            getModelPortfolio(goalChanges);
        } else if (proposalId) {
            getProposal(proposalId);
        }
    }, [proposalId, getProposal, getModelPortfolio, goalChanges]);
    useEffect(() => {
        (async () => {
            const riskCategoryName = (await getRiskCategory())?.Name;

            setStrategy(riskCategoryName);
        })();
    }, [getRiskCategory]);

    // Callbacks
    const saveGoal = useCallback((dataAccept) => {
        const {
            investmentAllocations, currencyId, productId, productName,
        } = summaryData;
        const { Portfolios, Id: NewPortfolioId } = dataAccept;
        const PortfolioId = (Portfolios || [])
            .filter((item) => item.PortfolioTypeId === portfolioTypeIds.MAIN)
            .map(({ Id }) => Id)
            .slice(-1)?.[0] || NewPortfolioId;
        const createdGoal = {
            Years: projectionYears,
            ProductId: productId,
            ProductName: productName,
            CurrencyId: currencyId,
            Allocations: investmentAllocations,
            ModelPortfolioId: data?.ModelPortfolioId,
            PortfolioProposalId: createdProposalId,
            PortfolioId,
        };

        saveCreatedGoal(createdGoal);
    }, [summaryData, projectionYears, saveCreatedGoal, createdProposalId]);
    const handleAccept = async () => {
        const response = await acceptGoalAndPortfolio({
            hasGoal, params: paramsToAccept, accountParams,
        });

        if (response?.cashAccountResponse?.Account?.Iban) {
            await saveIban(response?.cashAccountResponse?.Account?.Iban);
        }
        await saveGoal(response);
        onNext();
    };
    const onAcceptSummary = () => {
        Modal.confirm({
            title: t('onBoarding.accept'),
            content: t('onBoarding.acceptInvestmentText'),
            okText: t('onBoarding.accept'),
            onOk: handleAccept,
            cancelText: t('onBoarding.cancel'),
        });
    };
    const prevAction = useMemo(() => generateObject({
        [ADVISORY]: () => onPageChange(goalChanges ? 'change-strategy' : ''),
        [DISCRETIONARY]: () => onPageChange(goalChanges ? 'change-strategy' : ''),
        [EXECUTION_ONLY]: () => { history.push('/onboarding/step/4/modify'); },
        [DEPOSITS]: () => onPageChange(goalChanges ? 'change-strategy' : ''),
    }).getByName(product?.name), [goalChanges, onPageChange, product?.name]);
    const onPrevPage = () => {
        prevAction();
    };

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.reviewYourInvestment')}
            error={errorMP || errorSummary || errorFee}
            isLoading={isLoadingMP || isLoadingSummary || isLoadingFee}
            className="goal-review summary"
            classNameContent="stepper-content-full"
            prevButton={{
                disabled: isLoadingAccept,
                onClick: onPrevPage,
            }}
            nextButton={{
                text: t('onBoarding.accept'),
                disabled: isLoadingSummary || isLoadingAccept,
                onClick: onAcceptSummary,
            }}
        >
            <div className="content-wrapper">
                <ContentBox underline={false}>
                    <OverviewPanel>
                        <Row>
                            <Column size={['sm-6', 'lg-3']}>
                                <OverviewItem
                                    title={hasGoal ? t('onBoarding.goal.yourGoal') : t('onBoarding.portfolioName')}
                                    value={goalDetails.goalName}
                                />
                            </Column>
                            <Column size={['sm-6', 'lg-3']}>
                                <OverviewItem
                                    title={t('onBoarding.product')}
                                    value={product.name}
                                />
                            </Column>
                            <Column size={['sm-6', 'lg-3']}>
                                <OverviewItem
                                    title={t('onBoarding.fees')}
                                    value={fee}
                                    type={TYPE_PERCENT}
                                />
                            </Column>
                            {showStrategy && (
                                <Column size={['sm-6', 'lg-3']}>
                                    <OverviewItem
                                        title={t('overview.strategy')}
                                        value={summaryData?.overview?.strategy || strategy}
                                    />
                                </Column>
                            )}
                        </Row>
                    </OverviewPanel>
                </ContentBox>
                <Accordion defaultActiveKey={['1', '2']}>
                    <Panel header={t('onBoarding.listOfTrades')} key="1">
                        <Infobox accent>{t('onBoarding.review.tradesDisclaimer')}</Infobox>
                        <Table
                            className="positions-table"
                            data={summaryData?.positions}
                            columns={getTradesColumns(t)}
                            expandedColumn="name"
                            defaultExpandAllRows
                        />
                    </Panel>
                    <Panel header={t('onBoarding.documents')} key="2" className="documents">
                        <Preloader
                            isLoading={isLoadingDocuments}
                            error={errorDocuments}
                        >
                            <Table
                                columns={getDocumentsColumns(t)}
                                data={documents}
                            />
                        </Preloader>
                    </Panel>
                </Accordion>
            </div>
            {errorAccept && <WidgetError accent error message={errorAccept.message} />}
        </OnBoardingBaseTemplate>
    );
}

Summary.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    onNext: PropTypes.func,
    onPageChange: PropTypes.func,
};

Summary.defaultProps = {
    onNext: () => {
    },
    onPageChange: () => {
    },
};

export default Summary;
