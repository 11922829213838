module.exports = {
    customProperties: {
        '--primary-default': '#FFFFFF',
        '--primary-lighter': '#767676',
        '--primary-light': '#767676',
        '--primary-dark': '#FFF',
        '--primary-darker': '#FFF',
        '--secondary-default': '#767676',
        '--secondary-lighter': '#FFFFFF',
        '--secondary-light': '#767676',
        '--secondary-dark': '#FFF',
        '--secondary-darker': '#FFF',
        '--background-base': '#000',
        '--background-content': '#000',
        '--border-color': '#767676',
        '--content-box-shadow': '0 0 0 1px #767676',
        '--btn-primary-disabled-color-theme': '#FFF',
        '--btn-secondary-color-theme': '#767676',
        '--btn-secondary-hover-color-theme': '#fff',
        '--btn-secondary-hover-border-color-theme': '#fff',
        '--infobox-bg-theme': '#FFF',
        '--infobox-color-theme': '#000',
        '--infobox-border-color-theme': '#FFF',
        '--infobox-accent-bg-theme': '#FFF',
        '--infobox-accent-color-theme': '#000',
        '--language-select-btn-color': '#fff',
        '--language-select-btn-border-color': '#fff',
        '--table-row-selected-bg-theme': '#FFFFFF',
        '--table-row-selected-color-theme': '#000',
        '--datepicker-weekend-color-theme': '#000',
        '--datepicker-weekend-bg-color-theme': '#fff',
        '--bandwidth-chart-plot-color-theme': '#e1e1ff',
        '--bandwidth-chart-color-0-theme': '#e4e8ee',
        '--bandwidth-chart-color-3-theme': '#8282b2',
        '--bar-chart-color-4-theme': '#8282b2',
        '--line-chart-colors-3-theme': '#e4e8ee',
        '--pie-chart-colors-0-theme': '#e4e8ee',
        '--pie-chart-colors-3-theme': '#c8c8e8',
        '--pie-chart-colors-6-theme': '#8282b2',
        '--pie-chart-single-fill-theme': '#e1e1ff',
        '--projection-chart-color-4-theme': '#8282b2',
        '--projection-chart-color-5-theme': '#c8c8e8',
        '--projection-chart-color-6-theme': '#e4e8ee',
        '--projection-chart-stroke-3-theme': '#e4e8ee',
        '--projection-chart-legend-external-color-theme': '#8282b2',
        '--projection-chart-positiveNegative-color-2-theme': '#e1e1ff',
        '--projection-chart-positiveNegative-color-3-theme': '#e1e1ff',
        '--projection-chart-positiveNegative-color-4-theme': '#e1e1ff',
        '--projection-chart-positiveNegative-color-5-theme': '#e1e1ff',
        '--projection-chart-positiveNegative-color-6-theme': '#8282b2',
        '--projection-chart-positiveNegative-color-7-theme': '#8282b2',
        '--projection-chart-positiveNegative-stroke-2-theme': '#e4e8ee',
        '--projection-chart-positiveNegative-stroke-3-theme': '#e4e8ee',
        '--risk-return-chart-color-2-theme': '#8282b2',
        '--risk-return-axis-stroke-theme': '#c8c8e8',
        '--risk-return-grid-stroke-theme': '#e1e1ff',
        '--line-chart-tooltip-stroke-theme': '#FFF',
        '--projection-chart-tooltip-stroke-theme': '#FFF',
        '--risk-return-tooltip-stroke-theme': '#FFF',
        '--badge-text-color': '#000',
    },
};
