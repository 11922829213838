import TermsCondition from 'assets/documents/Terms&Condition.pdf';
import Pricing from 'assets/documents/Pricing.pdf';
import SpecialRiskInSecurityTrading from 'assets/documents/SpecialRiskInSecurityTrading.pdf';
import AdvisoryDescription from 'assets/documents/AdvisoryDescription.pdf';
import DiscretionaryDescription from 'assets/documents/DiscretionaryDescription.pdf';
import ExecutionOnlyDescription from 'assets/documents/ExecutionOnlyDescription.pdf';

const getProductDescription = {
    6: AdvisoryDescription,
    4: ExecutionOnlyDescription,
    7: DiscretionaryDescription,
};

// TODO: Add proper link for documents and avoid documents hardcode
export const adaptSummaryDocuments = (data, { productId, downloadDocument, t }) => [
    {
        icon: {
            type: 'document',
            onClickIcon: downloadDocument(TermsCondition, t('onBoarding.termsConditions')),
        },
        name: {
            link: '#',
            value: t('onBoarding.termsConditions'),
            onDownload: downloadDocument(TermsCondition, t('onBoarding.termsConditions')),
        },
    },
    {
        icon: {
            type: 'document',
            onClickIcon: downloadDocument(Pricing, t('onBoarding.pricing')),
        },
        name: {
            link: '#',
            value: t('onBoarding.pricing'),
            onDownload: downloadDocument(Pricing, t('onBoarding.pricing')),
        },
    },
    {
        icon: {
            type: 'document',
            onClickIcon: downloadDocument(getProductDescription[productId], t('onBoarding.productDescription')),
        },
        name: {
            link: '#',
            value: t('onBoarding.productDescription'),
            onDownload: downloadDocument(getProductDescription[productId], t('onBoarding.productDescription')),
        },
    },
    {
        icon: {
            type: 'document',
            onClickIcon: downloadDocument(SpecialRiskInSecurityTrading, t('onBoarding.specialRiskInSecurityTrading')),
        },
        name: {
            link: '#',
            value: t('onBoarding.specialRiskInSecurityTrading'),
            onDownload: downloadDocument(SpecialRiskInSecurityTrading, t('onBoarding.specialRiskInSecurityTrading')),
        },
    },
];
