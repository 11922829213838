import { TYPE_CURRENCY } from '../../../utils/formatting';
import { OVERVIEW_SIZE_SMALL } from '../../Overview';

export const adaptChangeModelOverview = ({
    portfolioValue, currency, t, modelPortfolio, showModel, onModelChange, renderModelSelect,
}) => [{
    columnsData: [
        {
            overviewItemsInfo: [{
                title: t('overview.portfolioValue'),
                value: portfolioValue,
                type: TYPE_CURRENCY,
                currency,
            }],
        },
        {
            overviewItemsInfo: [{
                title: t('overview.modelPortfolio'),
                value: modelPortfolio,
                size: OVERVIEW_SIZE_SMALL,
            }],
            emptyColumn: !showModel,
        },
        {
            overviewItemsInfo: [{
                customRender: renderModelSelect,
            }],
            emptyColumn: !onModelChange,
        },
    ],
},
];
