export const ADVISORY = 'advisory';
export const DISCRETIONARY = 'discretionary';
export const ATRAM_DISCRETIONARY = 'atram_discretionary';
export const ATRAM_DIRECTIONAL = 'atram_directional';
export const EXECUTION_ONLY = 'execution';
export const DEPOSITS = 'deposits';

export const ADVISORY_ID = [6, 8];
export const DISCRETIONARY_ID = [3, 9];
export const ATRAM_DISCRETIONARY_ID = [...Array(60).keys()].slice(2);
export const ATRAM_DIRECTIONAL_ID = [1];
export const EXECUTION_ONLY_ID = [2, 4, 10];
export const DEPOSITS_ID = [12];

export const CUSTOM_FLOW_PRODUCT_IDS = [
    ...ATRAM_DISCRETIONARY_ID,
    ...ATRAM_DIRECTIONAL_ID,
    ...ATRAM_DISCRETIONARY_ID.map((v) => v.toString()),
    ...ATRAM_DIRECTIONAL_ID.map((v) => v.toString()),
];

export const generateObject = (obj) => {
    const {
        [ADVISORY]: advisory,
        [DISCRETIONARY]: discretionary,
        [EXECUTION_ONLY]: executionOnly,
        [DEPOSITS]: deposits,
        [ATRAM_DISCRETIONARY]: atramDiscretionary,
        [ATRAM_DIRECTIONAL]: atramDirectional,
    } = obj;
    const avdisoryIds = ADVISORY_ID
        .reduce((acc, id) => ({ ...acc, [id]: advisory }), {});
    const discretionaryIds = DISCRETIONARY_ID
        .reduce((acc, id) => ({ ...acc, [id]: discretionary }), {});
    const executionOnlyIds = EXECUTION_ONLY_ID
        .reduce((acc, id) => ({ ...acc, [id]: executionOnly }), {});
    const depositsIds = DEPOSITS_ID
        .reduce((acc, id) => ({ ...acc, [id]: deposits }), {});
    const atramDiscretionaryIds = ATRAM_DISCRETIONARY_ID
        .reduce((acc, id) => ({ ...acc, [id]: atramDiscretionary }), {});
    const atramDirectionalIds = ATRAM_DIRECTIONAL_ID
        .reduce((acc, id) => ({ ...acc, [id]: atramDirectional }), {});
    const getByName = (name) => {
        if (!name) return null;
        if (name.toLowerCase().includes(ADVISORY)) return advisory;
        if (name.toLowerCase().includes(DISCRETIONARY)) return discretionary;
        if (name.toLowerCase().includes(EXECUTION_ONLY)) return executionOnly;
        if (name.toLowerCase().includes(DEPOSITS)) return deposits;
        if (name.toLowerCase().includes(ATRAM_DISCRETIONARY)) return atramDiscretionary;
        if (name.toLowerCase().includes(ATRAM_DIRECTIONAL)) return atramDirectional;

        return null;
    };

    return {
        ...avdisoryIds,
        ...discretionaryIds,
        ...depositsIds,
        ...executionOnlyIds,
        ...atramDiscretionaryIds,
        ...atramDirectionalIds,
        getByName,
    };
};
