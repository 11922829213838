import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TabPane, Tabs } from 'ui-library';
import { ListTable } from 'templates/ListPreview';
import { MESSAGES_COLUMNS } from '../constants/constants';

const ListComponent = ({
    tab, changeTab, needUpdate, useDynamicSorting, onNewMessage, ...props
}) => {
    const { t } = useTranslation();

    return (
        <Tabs activeKey={tab} defaultActiveKey="1" onChange={changeTab}>
            <TabPane tab={t('messages.inbox')} key="inbox">
                <ListTable
                    noData={t('messages.noData')}
                    columns={MESSAGES_COLUMNS}
                    needUpdate={needUpdate}
                    active={tab === 'inbox'}
                    useDynamicSorting={useDynamicSorting}
                    noDataButtonText={t('messages.newMessage')}
                    noDataOnClick={onNewMessage}
                    {...props}
                />
            </TabPane>
            <TabPane tab={t('messages.sent')} key="sentbox">
                <ListTable
                    noData={t('messages.noData')}
                    columns={MESSAGES_COLUMNS}
                    needUpdate={needUpdate}
                    active={tab === 'sentbox'}
                    useDynamicSorting={useDynamicSorting}
                    noDataButtonText={t('messages.newMessage')}
                    noDataOnClick={onNewMessage}
                    {...props}
                />
            </TabPane>
        </Tabs>
    );
};

ListComponent.propTypes = {
    tab: PropTypes.string.isRequired,
    needUpdate: PropTypes.bool.isRequired,
    changeTab: PropTypes.func.isRequired,
    useDynamicSorting: PropTypes.bool,
    onNewMessage: PropTypes.func.isRequired,
};

ListComponent.defaultProps = {
    useDynamicSorting: false,
};

export default ListComponent;
