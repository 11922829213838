export const adaptGoalsTemplates = (data, pictures) => {
    let customAttributes = {};
    const picturesAdapted = (pictures || []).reduce((acc, item) => ({
        ...acc,
        [item.Id]: item.ImageContentBase64,
    }), {});

    return (data || []).map((item) => {
        try {
            customAttributes = JSON.parse(item?.CustomAttributes || '{}');
        } catch (e) {
            // Do not throw error
        }

        return {
            id: item.Id,
            name: item.Name,
            image: picturesAdapted[item.Id] && `data:image;base64,${picturesAdapted[item.Id]}`,
            description: item.Description,
            ...customAttributes,
        };
    });
};
