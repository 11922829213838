import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Select } from 'ui-library';
import Preloader from 'components/Preloader';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { useChangeStrategyConfig } from 'hooks/useChangeStrategyConfig';
import LoadingOverlap from '../LoadingOverlap';
import { adaptStrategyOverview } from './adapters/adaptStrategyOverview';
import { OverviewInformation } from '../OverviewInformation';

function StrategyOverview(props) {
    const {
        data: { portfolioValue, strategy, currency },
        isLoading,
        error,
        isLoadingModel,
        errorModel,
        onStrategyChange,
        strategyList,
        selectedStrategy,
        onModelChange,
        modelList,
        selectedModel,
        showStrategy,
    } = props;
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const colSize = onStrategyChange ? 'sm-3' : 'sm-4';
    const { showModelPortfolio } = useChangeStrategyConfig();

    // Renderers
    const renderStrategySelect = () => (
        <div className="overview-item">
            <Select
                options={strategyList}
                value={selectedStrategy}
                label={t('overview.newStrategy')}
                width={isMobileSize ? '100%' : 190}
                withInfobox={false}
                placeholder={t('overview.selectNewStrategy')}
                onChange={onStrategyChange}
            />
        </div>
    );
    const renderModelSelect = () => (
        <div className="overview-item">
            <LoadingOverlap isLoading={isLoadingModel}>
                <Select
                    options={modelList}
                    value={selectedModel}
                    label={t('overview.newModel')}
                    withInfobox={false}
                    placeholder={t('overview.selectNewModel')}
                    disabled={!selectedStrategy}
                    onChange={onModelChange}
                />
            </LoadingOverlap>
        </div>
    );

    const strategyOverviewRows = useMemo(() => adaptStrategyOverview(
        {
            portfolioValue,
            strategy,
            currency,
            t,
            showStrategy,
            onStrategyChange,
            renderStrategySelect,
            renderModelSelect,
            showModelPortfolio,
        },
    ), [
        portfolioValue,
        strategy,
        currency,
        t,
        showStrategy,
        onStrategyChange,
        renderStrategySelect,
        renderModelSelect,
    ]);

    return (
        <Preloader isLoading={isLoading} error={error || errorModel}>
            <OverviewInformation panelClassName="strategy-overview-panel" rowsData={strategyOverviewRows} columnSize={colSize} />
        </Preloader>
    );
}

StrategyOverview.propTypes = {
    data: PropTypes.shape({
        currency: PropTypes.string,
        portfolioValue: PropTypes.number,
        strategy: PropTypes.string,
    }),
    selectedStrategy: PropTypes.number,
    onStrategyChange: PropTypes.func,
    strategyList: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    selectedModel: PropTypes.number,
    onModelChange: PropTypes.func,
    modelList: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    })),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isLoadingModel: PropTypes.bool,
    errorModel: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    showStrategy: PropTypes.bool,
};

StrategyOverview.defaultProps = {
    data: {},
    selectedStrategy: undefined,
    selectedModel: undefined,
    isLoading: true,
    isLoadingModel: false,
    onStrategyChange: undefined,
    strategyList: [],
    onModelChange: undefined,
    modelList: [],
    error: null,
    errorModel: null,
    showStrategy: true,
};

export default StrategyOverview;
