import { useCallback, useEffect, useState } from 'react';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptPlotsChart } from 'adaptors/adaptPlotsChart';
import { adaptSecuritiesHistory } from 'adaptors/adaptSecuritiesHistory';
import { useDetectedMobileDevice } from '../../../hooks/useDetectedMobileDevice';

export const usePriceHistory = (options) => {
    const {
        positionId, currencyId, startDate, endDate, adaptValuePrice, adaptValuePerformance,
    } = options;
    const { isMobileSize } = useDetectedMobileDevice();

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const getPriceData = useCallback(async () => {
        if (currencyId) {
            setError(null);
            setLoading(true);

            const params = {
                fromDate: startDate,
                toDate: endDate || new Date(),
            };

            try {
                const [{ data: priceData }, { data: perfData }] = await Promise.all([
                    SM.instrumentsService('postPriceHistory', [positionId, params]),
                    SM.instrumentsService('postPerformanceHistory', [positionId, params]),
                ]);

                try {
                    setData({
                        performance: [{
                            data: adaptPlotsChart({
                                data: adaptSecuritiesHistory(perfData?.historicalData),
                                adaptValue: adaptValuePerformance,
                                isMobileSize,
                            }),
                        }],
                        price: [{
                            data: adaptPlotsChart({
                                data: adaptSecuritiesHistory(priceData?.historicalData),
                                adaptValue: adaptValuePrice,
                                isMobileSize,
                            }),
                        }],
                    });
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                )(err);

                throw err.type !== undefined ? err : new ServerError(err);
            }
        }
    }, [
        adaptValuePerformance,
        adaptValuePrice,
        currencyId,
        isMobileSize,
        positionId,
        startDate,
        endDate,
    ]);

    useEffect(() => {
        getPriceData();
    }, [getPriceData]);

    return {
        data,
        error,
        isLoading,
    };
};
