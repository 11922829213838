export const adaptConstraints = (data) => {
    const adapted = { ...data };

    adapted.AssetClassConstraints = (adapted?.AssetClassConstraints || [])
        .map(({ AssetClass, ...item }) => ({ ...item, AssetClassId: AssetClass.Id }));
    adapted.CurrencyConstraints = (adapted?.CurrencyConstraints || [])
        .map(({ Currency, ...item }) => ({ ...item, CurrencyId: Currency.Id }));
    adapted.SecuritySectorConstraints = (adapted?.SecuritySectorConstraints || [])
        .map(({ SecuritySector, ...item }) => ({ ...item, SecuritySectorId: SecuritySector.Id }));
    adapted.SecurityConstraints = (adapted?.SecurityConstraints || [])
        .map(({ Security, ...item }) => ({ ...item, SecurityId: Security.Id }));
    adapted.SecurityTypeConstraints = (adapted?.SecurityTypeConstraints || [])
        .map(({ SecurityType, ...item }) => ({ ...item, SecurityTypeId: SecurityType.Id }));
    adapted.ContinentConstraints = (adapted?.ContinentConstraints || [])
        .map(({ Continent, ...item }) => ({ ...item, ContinentId: Continent.Id }));
    adapted.EconomicalZoneConstraints = (adapted?.EconomicalZoneConstraints || [])
        .map(({ EconomicalZone, ...item }) => ({ ...item, EconomicalZoneId: EconomicalZone.Id }));
    adapted.CountryConstraints = (adapted?.CountryConstraints || [])
        .map(({ Country, ...item }) => ({ ...item, CountryId: Country.Id }));

    return adapted;
};
