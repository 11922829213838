import { createSelector } from 'reselect';

export const idSelector = (state) => (state.id);
export const clientIdSelector = (state) => (state.clientId);

export const portfolioSelector = (state) => (state.portfolio);
export const portfolioDataSelector = createSelector(
    portfolioSelector,
    (portfolio) => (portfolio.data),
);
export const portfolioDataRawSelector = createSelector(
    portfolioSelector,
    (portfolio) => (portfolio.dataRaw),
);
export const portfolioIsLoadingSelector = createSelector(
    portfolioSelector,
    (portfolio) => (portfolio.isLoading),
);
export const portfolioErrorSelector = createSelector(
    portfolioSelector,
    (portfolio) => (portfolio.error),
);
export const getPortfolioDetailsSelector = createSelector(
    portfolioSelector,
    (portfolio) => (portfolio.getPortfolioDetails),
);

export const modelPortfolioSelector = (state) => (state.modelPortfolio);
export const modelPortfolioDataSelector = createSelector(
    modelPortfolioSelector,
    (modelPortfolio) => (modelPortfolio.data),
);
export const modelPortfolioDataRawSelector = createSelector(
    modelPortfolioSelector,
    (modelPortfolio) => (modelPortfolio.dataRaw),
);
export const modelPortfolioIsLoadingSelector = createSelector(
    modelPortfolioSelector,
    (modelPortfolio) => (modelPortfolio.isLoading),
);
export const modelPortfolioErrorSelector = createSelector(
    modelPortfolioSelector,
    (modelPortfolio) => (modelPortfolio.error),
);
export const getModelPortfolioSelector = createSelector(
    modelPortfolioSelector,
    (modelPortfolio) => (modelPortfolio.getModelPortfolio),
);

export const orderBookSelector = (state) => (state.orderBook);
export const orderBookDataSelector = createSelector(
    orderBookSelector,
    (orderBook) => (orderBook.data),
);
export const orderBookDataRawSelector = createSelector(
    orderBookSelector,
    (orderBook) => (orderBook.dataRaw),
);
export const orderBookIsLoadingSelector = createSelector(
    orderBookSelector,
    (orderBook) => (orderBook.isLoading),
);
export const orderBookErrorSelector = createSelector(
    orderBookSelector,
    (orderBook) => (orderBook.error),
);
export const getOrderBookSelector = createSelector(
    orderBookSelector,
    (orderBook) => (orderBook.getOrderBook),
);

export const tradingCardSelector = (state) => (state.tradingCard);
export const tradingCardDataRawSelector = createSelector(
    tradingCardSelector,
    (tradingCard) => (tradingCard.dataRaw),
);
export const tradingCardIsLoadingSelector = createSelector(
    tradingCardSelector,
    (tradingCard) => (tradingCard.isLoading),
);
export const tradingCardErrorSelector = createSelector(
    tradingCardSelector,
    (tradingCard) => (tradingCard.error),
);
export const getTradingCardSelector = createSelector(
    tradingCardSelector,
    (tradingCard) => (tradingCard.getTradingCard),
);

export const portfolioModifySelector = (state) => (state.portfolioModify);
export const portfolioModifyIsModifyingSelector = createSelector(
    portfolioModifySelector,
    (portfolioModify) => (portfolioModify.isModifying),
);
export const portfolioModifyErrorModifySelector = createSelector(
    portfolioModifySelector,
    (portfolioModify) => (portfolioModify.errorModify),
);
export const modifyPositionSelector = createSelector(
    portfolioModifySelector,
    (portfolioModify) => (portfolioModify.modifyPosition),
);
export const portfolioModifyDataRawSelector = createSelector(
    portfolioModifySelector,
    (portfolioModify) => (portfolioModify.dataRaw),
);
export const portfolioModifyErrorSelector = createSelector(
    portfolioModifySelector,
    (portfolioModify) => (portfolioModify.error),
);
export const portfolioModifyIsLoadingSelector = createSelector(
    portfolioModifySelector,
    (portfolioModify) => (portfolioModify.isLoading),
);
export const getModifiedPositionsSelector = createSelector(
    portfolioModifySelector,
    (portfolioModify) => (portfolioModify.getModifiedPositions),
);

export const portfolioQuickEditSelector = (state) => (state.portfolioQuickEdit);

export const transactionsSelector = (state) => (state.transactions);

export const cashFlowsSelector = (state) => (state.cashFlows);

export const proposalSelector = (state) => (state.proposal);
