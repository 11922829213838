import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PasswordInput } from '../PasswordInput';

const PasswordField = ({
    input,
    label,
    id,
    meta: {
        touched, error, submitting,
    },
}) => {
    const { t } = useTranslation();

    return (
        <PasswordInput
            {...input}
            label={label}
            placeholder={label}
            error={touched === true ? t(error) : undefined}
            id={id}
            disabled={submitting}
        />
    );
};

PasswordField.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.string,
        onFocus: PropTypes.func,
        onDrop: PropTypes.func,
        onDragStart: PropTypes.func,
        onChange: PropTypes.func,
        onBlur: PropTypes.func,
        name: PropTypes.string,
        checked: PropTypes.bool,
    }),
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        warning: PropTypes.string,
        submitting: PropTypes.bool,
    }),
};

PasswordField.defaultProps = {
    meta: PropTypes.shape({}),
    input: PropTypes.shape({}),
};

export default React.memo(PasswordField);
