import { checkNullData } from 'utils';
import { adaptPlotsChart } from 'adaptors/adaptPlotsChart';

export const adaptPlot = (data, options) => {
    const { useAmountPerformance, isMobileSize } = (options || {});
    const adaptValue = (item, { valueToDivide, firstValue }) => {
        const divider = valueToDivide || (useAmountPerformance && firstValue);
        const value = divider ? item.Value / divider.Value - 1 : item.Value;

        return Math.round(value * 10000) / 100;
    };

    return {
        data: adaptPlotsChart({
            data: data?.HistoricalPerformance?.[0]?.Values || [],
            chartStartDate: data?.startDate,
            chartEndDate: data?.endDate,
            adaptValue,
            isMobileSize,
        }),
        title: data?.title,
    };
};

const adaptRiskReturn = (portfolio, strategy, {
    benchmark, t, portfolioTitle, strategyTitle,
} = {}) => {
    const data = [];

    if (!checkNullData(portfolio)) {
        data.push({
            name: portfolioTitle || t('confirmation.currentPortfolio'),
            data: [portfolio],
        });
    }

    if (!checkNullData(strategy)) {
        data.push({
            name: strategyTitle || t('confirmation.newPortfolio'),
            data: [strategy],
        });
    }

    if (!checkNullData(benchmark)) {
        data.push({
            name: benchmark.name,
            data: [...(benchmark.data || [])],
        });
    }

    return data;
};

const getOptionalRiskReturn = (portfolio, strategy, benchmark, tooltipFormat, t) => {
    if (checkNullData(portfolio)) {
        return {
            xLabels: [t('confirmation.low'), t('confirmation.expectedRisk'), t('confirmation.high')],
            yLabels: [t('confirmation.low'), t('confirmation.expectedReturn'), t('confirmation.high')],
            tooltipFormat,
        };
    }

    const middleX = portfolio?.[0] || 0;
    const middleY = portfolio?.[1] || 0;
    let maxX = Math.max(
        strategy ? strategy[0] + 1 : 0,
        benchmark ? benchmark.data?.[0]?.[0] + 1 : 0,
        middleX * 2,
    );
    let maxY = Math.max(
        strategy ? strategy[1] + 1 : 0,
        benchmark ? benchmark.data?.[0]?.[1] + 1 : 0,
        middleY * 2,
    );
    let minX = Math.min(
        strategy ? strategy[0] - 1 : 0,
        benchmark ? benchmark.data?.[0]?.[0] - 1 : 0,
        0,
    );
    let minY = Math.min(
        strategy ? strategy[1] - 1 : 0,
        benchmark ? benchmark.data?.[0]?.[1] - 1 : 0,
        0,
    );

    if (minX < 0) maxX = middleX + (middleX - minX);
    else minX = middleX * 2 - maxX;

    if (minY < 0) maxY = middleY + (middleY - minY);
    else minY = middleY * 2 - maxY;

    return {
        minX,
        minY,
        maxX,
        maxY,
        xLabels: [t('confirmation.low'), t('confirmation.expectedRisk'), t('confirmation.high')],
        yLabels: [t('confirmation.low'), t('confirmation.expectedReturn'), t('confirmation.high')],
        tooltipFormat,
    };
};

export const adaptRiskCompare = (portfolio, strategy, {
    tooltipFormat,
    t,
    getFormattedNumber,
    benchmark,
    strategyTitle,
    portfolioTitle,
    matrix,
}) => {
    const riskReturnStrategy = strategy?.Volatility && strategy?.ExpectedReturn
        ? [+(strategy.Volatility * 100), +(strategy.ExpectedReturn * 100)]
        : null;
    const optional = getOptionalRiskReturn(
        portfolio?.riskReturn, riskReturnStrategy, benchmark, tooltipFormat, t,
    );
    const minMatrixX = getFormattedNumber(matrix?.x[0] || 0);
    const maxMatrixX = getFormattedNumber(Math.min(matrix?.x[1] || 0, optional.maxX));

    return {
        title: t('confirmation.riskReturn'),
        data: adaptRiskReturn(portfolio?.riskReturn, riskReturnStrategy, {
            benchmark,
            t,
            portfolioTitle: portfolioTitle || portfolio?.riskReturnTitle,
            strategyTitle,
        }),
        optional: {
            ...optional,
            matrix: matrix?.x ? {
                ...matrix,
                name: (minMatrixX !== 'NaN' && maxMatrixX !== 'NaN')
                    && `${t('confirmation.riskBandwidth')} (${minMatrixX}% - ${maxMatrixX}%)`,
            } : undefined,
        },
    };
};
