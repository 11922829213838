export const fileToString = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (readerError) => reject(readerError);
});

export const adaptFiles = async (contactId, files) => {
    try {
        const file = files[0];
        const { filename, name } = file;
        const fileString = await fileToString(file);
        const splits = fileString.split(';base64,');

        return {
            originalFilename: filename || name,
            retrieveFileAsName: filename || name,
            Filename: filename || name,
            ownerIds: [contactId],
            metadataReferenceId: 'IdentificationDocument',
            file: splits[1],
        };
    } catch (error) {
        throw error;
    }
};
