import React from 'react';
import { Link } from 'react-router-dom';
import LogoLight from 'assets/images/logo-light.png';
import LogoDark from 'assets/images/logo-dark.png';
import { useTheme } from 'theming';
import { useTranslation } from 'react-i18next';
import './AuthWrapper.css';

interface AuthWrapperProps {
    children?: React.ReactNode | string;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => (
    <div className="container container-small detail_page Auth">
        <div className="detail_content content-pane">
            <div className="content-pane-wrapper">{children}</div>
        </div>
    </div>
);

AuthWrapper.defaultProps = {
    children: '',
};

export default AuthWrapper;
