import React, { useState, useEffect } from 'react';
import {
    ContentBox, Title, Button, Row, Column,
} from 'ui-library';
import { useTranslation } from 'react-i18next';

import useTheme from 'theming/useTheme';
import { removeCustomVarsFromLS, saveCustomVarsToLS } from 'theming/utils';
import ColorSettings from './components/ColorSettings';
import { getInitialValuesFromRoot, adaptColorsToSave, setValuesToRoot } from './utils';
import { CUSTOM_PROPS } from './constants';

import './Settings.css';

function Settings() {
    const { t } = useTranslation();
    const { theme, setTheme } = useTheme();
    const [active, setActive] = useState(undefined);
    const [customProps, setCustomProps] = useState(getInitialValuesFromRoot(CUSTOM_PROPS));

    const setNewGroup = (name, values) => {
        setCustomProps((prev) => ({
            ...prev,
            [name]: values,
        }));
    };

    useEffect(() => {
        setCustomProps(getInitialValuesFromRoot(CUSTOM_PROPS));
    }, [theme]);

    const handleSave = () => {
        setActive('');
        // Edit this method if you want customize smt except colors

        const colors = adaptColorsToSave(customProps.colors);

        setValuesToRoot(colors);
        saveCustomVarsToLS(colors);
    };

    const handleReset = () => {
        setActive('');
        removeCustomVarsFromLS();

        setTheme(theme);

        setCustomProps(getInitialValuesFromRoot(CUSTOM_PROPS));
    };

    return (
        <ContentBox className="settings-page">
            <Title>{t('settings.title')}</Title>

            <ColorSettings
                colors={customProps.colors}
                set={setNewGroup}
                active={active}
                setActive={setActive}
            />

            <Row className="buttons">
                <Column size={12} className="text-sm-right">
                    <Button type="default" onClick={handleReset}>
                        {t('settings.reset')}
                    </Button>
                    <Button type="primary" onClick={handleSave}>
                        {t('settings.save')}
                    </Button>
                </Column>
            </Row>
        </ContentBox>
    );
}

export default Settings;
