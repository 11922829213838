import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';
import { clarityWidgetSelector, useClaritySelector } from 'domain/Clarity';

const DEFAULT_CURRENCY = 'USD';

export const useClarityWidget = ({ data: dataParams, updateWidget }) => {
    const {
        name,
        portfolioId: portfolioIdParam,
        securities,
        total,
    } = dataParams || {};
    const paramsToCreate = useMemo(() => ({
        name, securitiesWeightDistribution: 'PERCENTAGE', securities, total,
    }), [name, securities, total]);
    const [portfolioId, setPortfolioId] = useState();

    // Portfolio Domain
    const {
        errorUpdatePortfolio: errorUpdate,
        isLoadingUpdatePortfolio: isLoadingUpdate,
        updatePortfolioDetails,
    } = usePortfolioSelector(portfolioSelector);

    // Clarity Domain
    const {
        error, isLoading, getPortfolios,
        errorPortfolio, isLoadingPortfolio, getPortfolio,
        errorDeletePortfolio, isLoadingDeletePortfolio, deletePortfolio,
        errorUpdatePortfolio, isLoadingUpdatePortfolio, updatePortfolio,
        errorCreatePortfolio, isLoadingCreatePortfolio, createPortfolio,
    } = useClaritySelector(clarityWidgetSelector);

    // Callbacks
    const updatePortfolioAttribute = useCallback(async (esgId) => {
        const paramsToUpdateDetails = {
            CustomAttributes: { DoUpdate: true, Value: `{ "esg": "${esgId}" }` },
        };

        await updatePortfolioDetails({ params: paramsToUpdateDetails });
    }, [updatePortfolioDetails]);
    const getESGPortfolioByName = useCallback(async () => {
        const esgPortfolios = await getPortfolios();

        return (esgPortfolios.portfolios || []).find((item) => item.name === name)?.id;
    }, [getPortfolios, name]);
    const compareNames = useCallback(async () => {
        const esgPortfolio = await getPortfolio({ portfolioId: portfolioIdParam });
        const isNameEqual = esgPortfolio.name === name;

        if (!isNameEqual) {
            await deletePortfolio({ portfolioId: portfolioIdParam });
        }

        return isNameEqual;
    }, [deletePortfolio, getPortfolio, name, portfolioIdParam]);
    const createESGAndUpdateDFS = useCallback(async () => {
        let esgData;

        try {
            esgData = await createPortfolio({ params: paramsToCreate });
        } catch (e) {
            if (e.error.response?.data?.code === 2014) {
                const paramsWithCurrency = {
                    ...paramsToCreate,
                    total: { ...paramsToCreate.total, currency: DEFAULT_CURRENCY },
                };

                esgData = await createPortfolio({ params: paramsWithCurrency });
            }
        }

        await updatePortfolioAttribute(esgData.id);

        return esgData.id;
    }, [createPortfolio, paramsToCreate, updatePortfolioAttribute]);
    const updateESGPortfolio = useCallback(async (portfolioIdExisting) => {
        await updatePortfolio({
            portfolioId: portfolioIdExisting || portfolioIdParam, params: paramsToCreate,
        });

        return portfolioIdExisting || portfolioIdParam;
    }, [paramsToCreate, portfolioIdParam, updatePortfolio]);
    const getESGPortfolioId = useCallback(async () => {
        let esgId;

        if (portfolioIdParam) {
            // is name the same as in the ESG
            const isNameEqual = await compareNames();

            if (isNameEqual) {
                // update ESG only
                esgId = await updateESGPortfolio();
            } else {
                const existingPortfolio = await getESGPortfolioByName();

                if (existingPortfolio) {
                    // update ESG + update DFS
                    esgId = await updateESGPortfolio(existingPortfolio);
                    await updatePortfolioAttribute(esgId);
                } else {
                    // create ESG portfolio and update DFS
                    esgId = await createESGAndUpdateDFS();
                }
            }
        } else {
            // exist the portfolio with the same name in ESG portfolios
            const existingPortfolio = await getESGPortfolioByName();

            if (existingPortfolio) {
                // update ESG + update DFS
                esgId = await updateESGPortfolio(existingPortfolio);
                await updatePortfolioAttribute(esgId);
            } else {
                // create ESG portfolio and update DFS
                esgId = await createESGAndUpdateDFS();
            }
        }

        return esgId;
    }, [
        compareNames,
        createESGAndUpdateDFS,
        getESGPortfolioByName,
        portfolioIdParam,
        updateESGPortfolio,
        updatePortfolioAttribute,
    ]);

    useEffect(() => {
        (async () => {
            const esgId = await getESGPortfolioId();

            setPortfolioId(esgId);
            updateWidget();
        })();
    }, [getESGPortfolioId, updateWidget]);

    return {
        portfolioId,
        error: error || errorCreatePortfolio
            || errorUpdatePortfolio || errorUpdate
            || errorPortfolio || errorDeletePortfolio,
        isLoading: isLoading || isLoadingCreatePortfolio
            || isLoadingUpdatePortfolio || isLoadingUpdate
            || isLoadingPortfolio || isLoadingDeletePortfolio,
    };
};
