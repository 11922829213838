import {
    useCallback, useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useApolloClient, gql } from '@apollo/client';
import ServiceManager from 'services/ServiceManager';
import { useSecurityTypeIndex } from 'hooks/useSecurityTypeIndex';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptBenchmark } from 'adaptors/adaptBenchmark';
import { adaptBenchmarks } from 'adaptors/adaptBenchmarks';
import ServerError from '../errors/ServerError';

const GET_BENCHMARK_DATA = gql`
    query GetBenchmarkData($positionId: Int!) {
        securities(
            where: { id: { eq: $positionId } }
        ){
            items {
                id
                name
                tradingPrice
            }
        }
    }
`;

export const useBenchmarks = (portfolio, isLoading, showFirst, loadInitially = true) => {
    const client = useApolloClient();
    const { t, i18n: { language } } = useTranslation();
    const [errorBenchmarks, setError] = useState(null);
    const [isLoadingBenchmark, setLoading] = useState(true);
    const [benchmarkOptions, setOptions] = useState([
        { label: t('confirmation.selectBenchmark'), value: '' },
    ]);
    const [benchmarks, setBenchmarks] = useState({});
    const [selected, setSelected] = useState('');
    const { typeId, isLoadingTypeId, errorTypeId } = useSecurityTypeIndex(loadInitially);

    const product = useMemo(() => portfolio.product || portfolio.productId, [portfolio]);

    const getBenchmark = useCallback(async (value) => {
        try {
            const paramsKPI = {
                InstrumentSet: {
                    CurrencyId: portfolio.currencyId,
                    Allocations: [{ InstrumentId: +value, Allocation: 1 }],
                    AllocationType: 'Percentage',
                },
                Years: 10,
                CalculationInterval: 'Monthly',
            };
            const responses = await Promise.all([
                client.query({ query: GET_BENCHMARK_DATA, variables: { positionId: +value } }),
                ServiceManager.performance('calculateKPIPerformance', [paramsKPI]),
            ]);
            const securityData = responses[0].data?.securities?.items?.[0];
            const { Volatility, ExpectedReturn } = responses[1].data || {};

            return { ...securityData, Volatility, ExpectedReturn };
        } catch (err) {
            throw err;
        }
    }, [portfolio.currencyId, client]);

    const onBenchmarkChange = useCallback((value) => {
        (async () => {
            try {
                setError(null);
                setLoading(true);
                setSelected(value);

                if (benchmarks[value] || value === '') {
                    setLoading(false);

                    return benchmarks[value];
                }

                const benchmarkOption = benchmarkOptions.find((item) => item.value === value);

                if (benchmarkOption) {
                    setBenchmarks((data) => ({
                        ...data, [value]: { id: value, name: benchmarkOption.label },
                    }));
                }

                const response = await getBenchmark(value);

                try {
                    setBenchmarks((data) => ({
                        ...data, [value]: adaptBenchmark(response, { language }),
                    }));
                    setLoading(false);

                    return adaptBenchmark(response, { language });
                } catch (err) {
                    throw new AdapterError(err);
                }
            } catch (err) {
                handlerRequestCanceling(
                    HandlerError({
                        setError,
                        setLoading,
                    }),
                )(err);

                throw err.type !== undefined ? err : new ServerError(err);
            }
        })();
    }, [benchmarks, getBenchmark, benchmarkOptions, language]);

    const benchmark = useMemo(() => benchmarks[selected], [benchmarks, selected]);

    const searchSecuritiesByType = useCallback(async (securityTypeId = typeId) => {
        if (!securityTypeId || !product) return null;
        let adaptedBenchmark;

        try {
            setLoading(true);
            const filter = { SecurityTypeIds: [securityTypeId], Page: 1, PageSize: 1000 };
            const params = { language };

            const response = await ServiceManager.instrumentsService('searchSecurities', [product, filter, params]);

            try {
                const adapted = adaptBenchmarks(response.data, t);

                setOptions(adapted);

                if (!portfolio?.benchmarkId) setLoading(false);

                if (showFirst || portfolio?.benchmarkId) {
                    const portfolioBenchmark = adapted
                        ?.find((item) => item?.value === portfolio?.benchmarkId);

                    if (portfolioBenchmark) {
                        adaptedBenchmark = await onBenchmarkChange(portfolioBenchmark?.value);
                    }
                } else {
                    setLoading(false);
                }

                return {
                    list: adapted,
                    first: adapted?.[1]?.value,
                    benchmark: adaptedBenchmark,
                };
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError,
                    setLoading,
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [product, language, typeId, portfolio?.benchmarkId, showFirst, t]);

    useEffect(() => {
        if (!isLoading && !isLoadingTypeId && loadInitially) {
            searchSecuritiesByType();
        }
    }, [searchSecuritiesByType, loadInitially, isLoadingTypeId, isLoading]);

    useEffect(() => {
        if (errorTypeId !== null) {
            setError(errorTypeId);
            setLoading(false);
        }
    }, [errorTypeId]);

    return {
        benchmark,
        benchmarkOptions,
        isLoadingBenchmark,
        errorBenchmarks,
        onBenchmarkChange,
        benchmarkSelected: selected,
        searchSecuritiesByType,
    };
};
