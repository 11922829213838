import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import { CURRENT_PAGE, PAGE_SIZE_TRANSACTIONS } from 'constants/constants';
import { useFormatting } from 'locale';
import { gql, useLazyQuery } from '@apollo/client';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { adaptTransactions } from '../adapters/adaptTransactions';

const SEC_QUERY = gql`
    query Securities(
        $where: SecurityFilterInput!
        $take: Int! = 100
    ) {
        securities(where: $where, take: $take) {
            items {
                Id: id
                Isin: isin
                Type: type {
                    Name: name
                }
                AssetClass: assetClass {
                    Name: name
                }
                Country: country {
                    Name: name
                    Continent: continent {
                        Name: name
                    }
                }
                Sector: sector {
                    Name: name
                }
                Currency: currency {
                    Name: name
                    CurrencyCode: threeLetterIsoCode
                }
            }
        }
    }
`;

const initialState = {
    data: [],
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};


export const useTransactions = (options = {}) => {
    const {
        clientId,
        portfolioId,
        adaptData = adaptTransactions,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const [getSecurities] = useLazyQuery(SEC_QUERY);

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate, getFormattedNumber, getFormattedCurrency } = useFormatting();

    // Callbacks
    const getTransactions = useCallback(async ({ optionsParams, adaptOptions } = {}) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const params = {
                SecurityType: optionsParams?.SecurityType || 'Instruments',
                TransactionType: optionsParams?.TransactionType,
                Page: optionsParams?.Page || CURRENT_PAGE,
                PageSize: PAGE_SIZE_TRANSACTIONS,
            };
            const response = await SM.portfolioManagement('getPortfolioTrnsactions', [clientId, portfolioId, params]);
            let securityIds = [];

            try {
                securityIds = (response?.data?.Transactions || []).map((item) => item.SecurityId);
            } catch (err) {
                throw new AdapterError(err);
            }

            if (securityIds.length > 0) {
                const { data: securitiesData } = await getSecurities({
                    variables: {
                        where: {
                            id: {
                                in: [...new Set(securityIds)],
                            },
                        },
                    },
                });

                try {
                    const adapted = adaptData(response.data, {
                        securities: securitiesData?.securities?.items,
                        t,
                        getFormattedDate,
                        getFormattedNumber,
                        getFormattedCurrency,
                        currency: adaptOptions?.currency,
                        baseUrl: adaptOptions?.baseUrl,
                    });

                    dispatch({ type: 'setData', payload: adapted });
                    dispatch({ type: 'setDataRaw', payload: response.data });
                    dispatch({ type: 'setIsLoading', payload: false });

                    return adapted;
                } catch (err) {
                    throw new AdapterError(err);
                }
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        } finally {
            dispatch({ type: 'setIsLoading', payload: false });
        }
    }, [
        clientId,
        portfolioId,
        language,
        adaptData,
        t,
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    ]);

    // Effects
    useEffect(() => {
        if (loadInitially) getTransactions();
    }, [getTransactions, loadInitially]);

    return {
        data: state.data,
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getTransactions,
    };
};
