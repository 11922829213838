import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { login2FACaptcha } from 'redux/auth/authActions';
import LoginForm, { FORM_NAME } from './LoginForm';
import { validate } from '../validation';

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({
    onSubmit: login2FACaptcha,
});

const Translated = withTranslation()(LoginForm);

const WrappedIntoReduxForm = reduxForm({
    form: FORM_NAME,
    validate,
})(Translated);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedIntoReduxForm);
