import { dateTimeFormats } from 'locale/formatTypes';
import _ from 'lodash';

export const formatOriginalMessage = (data, getFormattedDate) => {
    let originalMessage = '';
    const timeOptions = {
        hour: dateTimeFormats.TWO_DIGIT,
        minute: dateTimeFormats.TWO_DIGIT,
    };

    data.forEach((item) => {
        originalMessage += `Sender: ${item.SenderFullName} \nDate: ${getFormattedDate(
            item.CreateDate,
            timeOptions,
        )}
        \n${_.unescape(item.Text)} \n ----------------------------------------------------------- \n`;
    });

    return originalMessage;
};
