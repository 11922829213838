import React, { memo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RootRouter } from 'pages';
import RiskProfileRouter from 'pages/pages/RiskProfile/Router';
import PortfoliosActionsRouter from 'pages/pages/Portfolios/PortfoliosActionsRouter';
import { useSelector } from 'react-redux';
import { withAuthentication } from './hocs';
import './styles';
import OnBoarding from './pages/pages/OnBoarding/Router';
import Payments from './pages/pages/Payments/Router';
import { ClientProfileProvider } from './prodivers/clientProfile';
import { GlobalSettingsProvider } from './prodivers/globalSettings';
import { contactIdSelector } from './redux/auth/authSelectors';
import ResetPassword from './pages/pages/ResetPassword';
import { withContinuousRenewAccessToken } from './hocs/withContinuousRenewAccessToken';

const App = () => {
    const clientId = useSelector(contactIdSelector);

    return (
        <GlobalSettingsProvider>
            <ClientProfileProvider clientId={clientId}>
                <Switch>
                    <Route path="/risk-profile" component={RiskProfileRouter} />
                    <Route strict path="/portfolios/:portfolioId/actions" component={PortfoliosActionsRouter} />
                    <Route path="/onboarding" component={OnBoarding} />
                    <Route path="/payments" component={Payments} />
                    <Route path="/change-password" component={ResetPassword} />
                    <Route path="/" component={RootRouter} />
                </Switch>
            </ClientProfileProvider>
        </GlobalSettingsProvider>
    );
};

export default withAuthentication(withContinuousRenewAccessToken(memo(App)));
