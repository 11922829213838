import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Column, TabPane, Tabs, Row, Label as LabelUI, Select, Infobox,
} from 'ui-library';
import { useTranslation } from 'react-i18next';
import LoadingOverlap from 'components/LoadingOverlap';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import ClarityWidget from 'components/ClarityWidget';
import CustomRangeSelect from 'components/CustomRangeSelect';
import { getClassNames } from 'utils';
import { formatBigNumber } from 'utils/formatting';
import {
    investorPerformanceSelectValues,
    investorProposedPerformanceSelectValues,
} from 'filters/investementPerformanceFilters';
import {
    LINE, RISKRETURN, BANDWIDTH, PROJECTION, SUSTAINABILITY,
} from 'constants/constants';
import ChartType from 'components/ChartType';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';

import './Analysis.css';

function Analysis({
    data,
    compareTitles,
    benchmarkOptions,
    benchmarkSelected,
    performanceSelected,
    onBenchmarkChange,
    onFilterChange,
    isLoading,
    isLoadingBenchmarks,
    error,
    oneProjectionColumn,
    showSustainabilityTab,
    isPortfolioProposed,
    projectionDataExternal,
    isCustomFlow,
}) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const [chartView, setChartView] = useState(LINE);
    const investorPerformanceOptions = [
        ...(isPortfolioProposed
            ? investorProposedPerformanceSelectValues
            : investorPerformanceSelectValues).map((item) => ({
            ...item,
            label: t(`dashboard.assetDevelopment.filters.${item.value}`),
        })),
    ];
    const labelFormat = useCallback((label, values = {}) => {
        const valuesFormatted = values.new !== undefined
            ? `${formatBigNumber(values.new)}% (${t('confirmation.toDate')} ${formatBigNumber(
                values.curr,
            )}%)`
            : `${formatBigNumber(values.curr)}%`;

        return [<LabelUI label={label} labelInfobox />, valuesFormatted];
    }, [t]);
    const ProjectionRender = useMemo(
        () => (
            <>
                <Column size={['sm-6']} className={oneProjectionColumn ? 'column-center' : ''}>
                    {!oneProjectionColumn && (
                        <div className="title-3">{compareTitles[0] || t('confirmation.current')}</div>
                    )}
                    <Preloader isLoading={false} error={data[PROJECTION].error}>
                        <LoadingOverlap isLoading={data[PROJECTION].isLoading}>
                            <EmptyContent
                                data={data[PROJECTION].charts[0]}
                                text={t('confirmation.noData')}
                            >
                                <ChartType
                                    data={data[PROJECTION].charts[0]}
                                    chartView={PROJECTION}
                                    yAxisFormat={data[PROJECTION].yAxisFormat}
                                    dataExternal={
                                        projectionDataExternal}
                                    {...data[PROJECTION].optional}
                                    t={t}
                                />
                            </EmptyContent>
                        </LoadingOverlap>
                    </Preloader>
                </Column>
                {!oneProjectionColumn && (
                    <Column size={['sm-6']}>
                        <div className="title-3">{compareTitles[1] || t('confirmation.new')}</div>
                        <Preloader isLoading={false} error={data[PROJECTION].error}>
                            <LoadingOverlap isLoading={data[PROJECTION].isLoading}>
                                <EmptyContent
                                    data={data[PROJECTION].charts[1]}
                                    text={t('confirmation.noData')}
                                >
                                    <ChartType
                                        data={data[PROJECTION].charts[1]}
                                        chartView={PROJECTION}
                                        yAxisFormat={data[PROJECTION].yAxisFormat}
                                        {...data[PROJECTION].optional}
                                        dataExternal={projectionDataExternal}
                                        t={t}
                                    />
                                </EmptyContent>
                            </LoadingOverlap>
                        </Preloader>
                    </Column>
                )}
                <Column size="12">
                    <Infobox accent>{t('confirmation.projectionDisclaimer')}</Infobox>
                </Column>
            </>
        ),
        [compareTitles, data, oneProjectionColumn, t, projectionDataExternal],
    );
    const tabBarExtraContent = useMemo(() => (
        <div className={getClassNames('extra-select', { selects__more: chartView === LINE })}>
            {chartView === LINE && (
                <div id="allocationCustomRangeSelect">
                    <CustomRangeSelect
                        width={!isMobileSize ? 160 : undefined}
                        value={performanceSelected}
                        options={investorPerformanceOptions}
                        onChange={onFilterChange}
                    />
                </div>
            )}
            {[RISKRETURN, LINE].includes(chartView) && (
                <div id="allocationBenchmarkSelect">
                    <Select
                        onChange={onBenchmarkChange}
                        options={benchmarkOptions}
                        value={benchmarkSelected || null}
                        width={!isMobileSize ? 160 : undefined}
                        placeholder={t('confirmation.selectBenchmark')}
                    />
                </div>
            )}
        </div>
    ), [
        chartView,
        isMobileSize,
        onBenchmarkChange,
        benchmarkOptions,
        benchmarkSelected,
        onFilterChange,
        investorPerformanceOptions,
        performanceSelected,
    ]);

    const isCalcError = useCallback((errObj) => {
        const errorObj = errObj?.error?.response?.data;

        return !!(errorObj?.Code === 'ValidationFailed' && errorObj.Properties?.ValidationErrors?.find(
            (item) => item?.Message?.includes('The monthly returns cannot be calculated'),
        ));
    }, [error]);

    return (
        <Tabs
            className="analysis"
            defaultActiveKey="1"
            tabBarExtraContent={(chartView === LINE || chartView === RISKRETURN)
                && tabBarExtraContent}
            onChange={(key) => setChartView(key)}
        >
            <TabPane tab={t('confirmation.performance')} key={LINE}>
                { isCalcError(data[LINE]?.error) ? (
                    <p className="widget__empty">{t('position.performanceNoHistory')}</p>
                ) : (
                    <Preloader isLoading={isLoading} error={error || data[LINE].error}>
                        <LoadingOverlap isLoading={data[LINE].isLoading}>
                            <EmptyContent
                                data={data[LINE].isLoading ? {} : data[LINE].data?.[0]?.data}
                                text={t('confirmation.noData')}
                            >
                                <ChartType
                                    data={data[LINE].data}
                                    chartView={LINE}
                                    labelFormat={labelFormat}
                                    {...data[LINE].optional}
                                    legendEnabled
                                    t={t}
                                />
                            </EmptyContent>
                        </LoadingOverlap>
                    </Preloader>
                )
                }
            </TabPane>
            {!isCustomFlow && (
                <>
                    <TabPane tab={t('confirmation.riskReturn')} key={RISKRETURN}>
                        { isCalcError(data[RISKRETURN]?.error) ? (
                            <p className="widget__empty">{t('position.performanceNoHistory')}</p>
                        ) : (
                            <Preloader error={error || data[RISKRETURN].error}>
                                <LoadingOverlap isLoading={data[RISKRETURN].isLoading || isLoadingBenchmarks}>
                                    <EmptyContent
                                        data={data[RISKRETURN].isLoading ? {} : data[RISKRETURN].data}
                                        text={t('confirmation.noData')}
                                    >
                                        <ChartType
                                            data={data[RISKRETURN].data}
                                            chartView={RISKRETURN}
                                            labelFormat={labelFormat}
                                            {...data[RISKRETURN].optional}
                                            legendEnabled
                                            t={t}
                                        />
                                    </EmptyContent>
                                </LoadingOverlap>
                            </Preloader>
                        )
                        }
                    </TabPane>
                    <TabPane tab={t('confirmation.bandwidth')} key={BANDWIDTH}>
                        <Preloader isLoading={isLoading} error={error || data[BANDWIDTH].error}>
                            <LoadingOverlap isLoading={data[BANDWIDTH].isLoading}>
                                <EmptyContent
                                    data={data[BANDWIDTH].isLoading ? {} : data[BANDWIDTH].data}
                                    text={t('confirmation.noData')}
                                >
                                    <ChartType
                                        data={data[BANDWIDTH].data}
                                        chartView={BANDWIDTH}
                                        labelFormat={labelFormat}
                                        {...data[BANDWIDTH].optional}
                                        legendEnabled
                                        t={t}
                                    />
                                </EmptyContent>
                            </LoadingOverlap>
                        </Preloader>
                    </TabPane>
                    <TabPane tab={t('confirmation.projection')} key={PROJECTION}>
                        <Row className="mb-0">
                            {ProjectionRender}
                        </Row>
                    </TabPane>
                </>
            )}
            {!isCustomFlow && showSustainabilityTab ? (
                <TabPane tab={t('confirmation.sustainability')} key={SUSTAINABILITY}>
                    <ClarityWidget data={data[SUSTAINABILITY].data} />
                </TabPane>
            ) : null}
        </Tabs>
    );
}

Analysis.propTypes = {
    data: PropTypes.shape({
        [LINE]: PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
                }),
            ),
        }),
        [RISKRETURN]: PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
                }),
            ),
        }),
        [BANDWIDTH]: PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    data: PropTypes.shape({
                        curr: PropTypes.number,
                        new: PropTypes.number,
                    }),
                    range: PropTypes.shape({
                        min: PropTypes.number,
                        max: PropTypes.number,
                    }),
                }),
            ),
        }),
        [PROJECTION]: PropTypes.shape({
            name: PropTypes.string,
            data: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    data: PropTypes.arrayOf(
                        PropTypes.oneOfType([
                            PropTypes.number,
                            PropTypes.shape({
                                [PropTypes.string]: PropTypes.number,
                            }),
                            PropTypes.arrayOf(PropTypes.number),
                        ]),
                    ),
                }),
            ),
        }),
    }),
    compareTitles: PropTypes.arrayOf(PropTypes.string),
    benchmarkOptions: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    ),
    benchmarkSelected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    performanceSelected: PropTypes.string,
    isLoading: PropTypes.bool,
    oneProjectionColumn: PropTypes.bool,
    showSustainabilityTab: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    isLoadingBenchmarks: PropTypes.bool,
    onFilterChange: PropTypes.func,
    onBenchmarkChange: PropTypes.func,
    isPortfolioProposed: PropTypes.bool,
    projectionDataExternal: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
            data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
        }),
    ),
    isCustomFlow: PropTypes.bool,
};

Analysis.defaultProps = {
    data: { chart: [] },
    compareTitles: [],
    benchmarkOptions: [],
    benchmarkSelected: undefined,
    performanceSelected: undefined,
    isLoading: false,
    oneProjectionColumn: false,
    showSustainabilityTab: false,
    error: null,
    isLoadingBenchmarks: true,
    onFilterChange: () => {},
    onBenchmarkChange: () => {},
    isPortfolioProposed: false,
    projectionDataExternal: undefined,
    isCustomFlow: false,
};

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);
const AnalysisWithMemo = React.memo(Analysis, areEqual);

export default AnalysisWithMemo;
