import React from 'react';
import PropTypes from 'prop-types';

const DetailsCustomContent = (props) => {
    const {
        schema,
    } = props;

    return (
        <div className="custom-components custom-form">
            {(schema?.components || []).map(({ key, renderComponent }) => (
                <div key={key} className={`custom-component custom-component-panel custom-component-${key}`}>
                    {renderComponent}
                </div>
            ))}
        </div>
    );
};

DetailsCustomContent.propTypes = {
    schema: PropTypes.shape({
        [PropTypes.string]: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.shape,
        ]),
    }).isRequired,
};

DetailsCustomContent.defaultProps = {
};

export default DetailsCustomContent;
