import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { transformOptions } from 'utils';

export const useModelList = ({ productId, riskCategoryId }) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const { i18n } = useTranslation();

    useEffect(() => {
        if (productId && riskCategoryId) {
            setLoading(true);
            const params = { language: i18n.language, riskCategoryId };

            ServiceManager.portfolioManagement('getModelPortfolios', [productId, params])
                .then((response) => {
                    try {
                        setData(transformOptions(response.data || []));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        }
    }, [productId, riskCategoryId, i18n.language]);

    return { data, isLoading, error };
};
