export const passwordPolicies: Array<{ Name: string, Description: string }> = [
    {
        Name: 'uppercasechars',
        // Description: 'The password must contain at least 1 upper-case character',
        Description: 'error.uppercasechars',
    },
    {
        Name: 'lowercasechars',
        // Description: 'The password must contain at least 1 lower-case character',
        Description: 'error.lowercasechars',
    },
    {
        Name: 'digits',
        // Description: 'The password must contain at least 1 digit',
        Description: 'error.digits',
    },
    {
        Name: 'specialchars',
        /** Description: 'The password must contain at least one of the following characters:
          * \\|¬¦`!"£$%^&*()_+-=[]{};:\'@#~<>,./? ',
         * */
        Description: 'error.specialchars',
    },
    {
        Name: 'minlength',
        // Description: 'The password must be at least 3 characters long',
        Description: 'error.minlength',
    },
    {
        Name: 'passwordhistory',
        // Description: 'The password must not match to the last 3 passwords',
        Description: 'error.passwordhistory',
    },
    {
        Name: 'regularexpression',
        /** Description: 'The password must match the following pattern:
         * ^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
         * */
        Description: 'error.regularexpression',
    },
];
