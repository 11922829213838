import { useCallback, useEffect, useReducer } from 'react';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { adaptContactGroups } from '../adaptors/adaptContactGroups';

const initialState = {
    dataRaw: {},
    data: [],
    error: null,
    isLoading: false,
    errorDeleting: null,
    isDeleting: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useContactGroups = (options = {}) => {
    const {
        contactId, contactGroupId,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const getContactGroups = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const response = await SM.OOTBServices('getContactGroup', [contactGroupId]);

            try {
                dispatch({ type: 'setDataRaw', payload: response?.data });
                dispatch({ type: 'setData', payload: adaptContactGroups(response?.data) });
                dispatch({ type: 'setIsLoading', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [contactId, contactGroupId]);

    // Effects
    useEffect(() => {
        getContactGroups();
    }, [getContactGroups]);

    return {
        dataRaw: state.dataRaw,
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getContactGroups,
    };
};
