import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Portfolios from './Portfolios';
import PortfolioRouter from './PortfolioRouter';

const Router = (props) => {
    const {
        match: { path },
    } = props;

    const withProps = (Component) => (defProps) => (
        <Component {...props} {...defProps} />
    );

    return (
        <Switch>
            <Route exact path={`${path}`} component={withProps(Portfolios)} />
            <Route path={`${path}/:portfolioId`} component={withProps(PortfolioRouter)} />
            <Redirect from="*" to="/404" />
        </Switch>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
