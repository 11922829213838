import { useContext } from 'react';
import { GlobalSettingsContext } from './GlobalSettingsContext';

export default () => {
    const context = useContext(GlobalSettingsContext);

    if (context === undefined) throw new Error('useGlobalSettings must be used within a ManagerProfileProvider');

    return context;
};
