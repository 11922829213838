import { useMemo } from 'react';
import { useTickets } from 'hooks/useTickets';
import { useTasks } from 'hooks/useTasks';
import { adaptTasks } from '../adaptors/adaptTasks';


export const useClientTasks = (clientId) => {
    const tickets = useTickets(clientId);
    const tasks = useTasks(tickets);

    return useMemo(() => ({
        tasks: {
            ...tasks,
            data: adaptTasks(tasks?.data),
        },
    }), [tasks]);
};
