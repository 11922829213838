import React from 'react';
import PropTypes from 'prop-types';
import { Column, Row } from 'ui-library';

function PageSection({ children, className }) {
    return (
        <Row>
            <Column className={className} size="12">
                {children}
            </Column>
        </Row>
    );
}

PageSection.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

PageSection.defaultProps = {
    children: null,
    className: null,
};

export default PageSection;
