import React from 'react';
import { Title, ContentBox } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { OverviewPanel } from 'components/Overview';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import PropTypes from 'prop-types';
import { Column } from 'ui-library/dist/components/Grid';
import { TYPE_RATIO } from 'utils/formatting';
import { atramDateFormat, formatDate } from 'utils/datetime';
import OverviewItem from './OverviewItem';

function Overview({
    data, isLoading, error, additionalData, valuationDate,
}) {
    const { t } = useTranslation();

    return (
        <PageSection>
            <ContentBox underline={false} className="overview-widget-container">
                <Title type={2} className="overview__header">
                    {t('overview.title')}
                </Title>
                <Preloader isLoading={isLoading} error={error}>
                    <OverviewPanel>
                        {valuationDate && (
                            <Column size="12" className="additional-data valuation-date">
                                {formatDate(new Date(valuationDate), atramDateFormat)}
                            </Column>
                        )}
                        <OverviewItem
                            currency={data.currency}
                            type={TYPE_RATIO}
                            value={data.totalValue || 0}
                            valueClassName="value-container"
                        />
                        {additionalData && (
                            <Column size="12" className="additional-data">
                                {additionalData()}
                            </Column>
                        )}
                    </OverviewPanel>
                </Preloader>
            </ContentBox>
        </PageSection>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        securityValue: PropTypes.number,
        cashAmount: PropTypes.number,
        performanceAmount: PropTypes.number,
        totalValue: PropTypes.number,
        performanceValue: PropTypes.number,
        currency: PropTypes.string,
    }).isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    additionalData: PropTypes.func,
    valuationDate: PropTypes.string,
};

Overview.defaultProps = {
    error: undefined,
    additionalData: () => {},
    valuationDate: undefined,
};

export default Overview;
