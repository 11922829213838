import { useState, useCallback } from 'react';
import FileSaver from 'file-saver';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';

const channel = 'ClientCockpit';

export const useDownloadPortfolioFile = (contactId, portfolioId = null) => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const downloadFile = useCallback((extraParams) => {
        setIsLoading(true);
        const params = {
            channel,
            portfolioId,
            ...extraParams,
        };

        ServiceManager.CustomLayerService('downloadPortfoliosReport', [contactId, params])
            .then((response) => {
                try {
                    const byteString = atob(response?.data?.content);
                    const buffer = new ArrayBuffer(byteString.length);
                    const data = new Uint8Array(buffer);

                    for (let i = 0; i < byteString.length; i += 1) {
                        data[i] = byteString.charCodeAt(i);
                    }
                    const newBlob = new Blob(
                        [buffer],
                        {
                            type: response?.data?.mimeType,
                        },
                    );

                    FileSaver.saveAs(newBlob, response?.data?.fileName);

                    setIsLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err);
                setIsLoading(false);
            });
    }, [contactId, portfolioId]);

    return {
        error,
        isLoading,
        downloadFile,
    };
};
