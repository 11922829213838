import React from 'react';
import PropTypes from 'prop-types';
import { NumberInput, renderers } from 'ui-library';
import './CellNameAndInput.css';

const { CellLinked, CellTooltiped } = renderers;

const CellNameAndInput = (numberSeparators) => (props) => {
    const name = props.label === undefined
        ? <CellTooltiped value={props?.value || props} maxLength={props?.maxLength || 15} />
        : (CellLinked({
            value: props.label,
            link: props.link,
            maxLength: props.maxLength,
        }));
    const input = props?.input?.value && props?.input?.onChange ? (
        <NumberInput
            addonAfter="%"
            {...numberSeparators}
            validation={{
                minValue: 0,
                maxValue: 100,
            }}
            value={props.input?.value}
            onChange={props.input?.onChange}
            disabled={props.input?.disabled}
            className="table-cell_input"
        />
    ) : (
        <b>{props?.input}</b>
    );

    return (
        <div className="cell-name-input">
            {name}
            {input}
        </div>
    );
};

CellNameAndInput.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
    disabled: PropTypes.bool,
};

CellNameAndInput.defaultProps = {
    label: '',
    link: '',
    disabled: false,
};

export default CellNameAndInput;
