import {
    formatPercentage, formatWithDate, getfromDataObject, validateNumber,
} from 'utils/formatting';
import { adaptAllocationGroups, buildAllocationGroup } from 'adaptors/adaptAllocationGroups';
import { getAllocations, adaptProjection } from 'adaptors/adaptAllocations';
import { checkNullData } from 'utils';
import { getTimestamp } from 'utils/datetime';
import { groupPositions } from './groupPositions';

const adaptPerformance = (data, getFormattedNumber) => {
    if (checkNullData(data) || checkNullData(data?.Values)) return {};

    return ({
        data: data?.Values.map(({ Value, Date }) => ([
            getTimestamp(Date),
            Math.round(Value * 10000) / 100,
        ])),
        tooltipFormat: (number, date) => (getFormattedNumber
            ? formatWithDate(formatPercentage(getFormattedNumber(number)), date)
            : formatWithDate(formatPercentage(number), date)),
    });
};

export const adaptModelPortfolio = ({
    data, productID, portfolioValue, t, portfolioSecuritiesValue, comparedAllocations,
    getFormattedCurrency, getFormattedNumber, productName, positionLink,
    bandwidthTitle, riskReturnTitle, performanceTitle, isProposed,
    projectionYears, recurringPayment,
}) => {
    let positions = [];
    let currency;
    let currencyId;

    if (data?.Currency) {
        currency = data?.Currency.CurrencyCode;
        currencyId = data?.Currency.Id;
    } else if (data?.BaseCurrency) {
        currency = data?.BaseCurrency.CurrencyCode;
        currencyId = data?.BaseCurrency.Id;
    }

    if (data && data?.Positions) {
        positions = data?.Positions;
    } else if (data && data?.ConsolidatedPositions) {
        positions = data?.ConsolidatedPositions;
    }

    return {
        backTitle: getfromDataObject(data, 'Name'),
        title: getfromDataObject(data, 'Name'),
        isProposed,
        currency,
        currencyId,
        portfolioValue,
        benchmarkId: data?.BenchmarkId,
        modelPortfolioId: data?.ModelPortfolioId || data?.Id,
        product: getfromDataObject(data, 'Product.Id'),
        performance: adaptPerformance(
            data?.HistoricalPerformance ? data?.HistoricalPerformance[0] : null, getFormattedNumber,
        ),
        securitiesValue: data?.SecuritiesValue || portfolioValue || 0,
        investedAmount: data?.InvestedAmount || portfolioValue || 0,
        InvestedAmount: data?.InvestedAmount || portfolioValue || 0,
        overview: {
            currency,
            portfolioValue,
            strategy: getfromDataObject(data, 'RiskCategory.Name'),
            product: getfromDataObject(data, 'Product.Name') || productName,
            sharpRatio: data?.SharpeRatio,
            expectedReturn: data?.CalculatedExpectedReturn * 100,
            expectedRisk: data?.CalculatedRisk * 100,
            modelPortfolio: data?.Name,
        },
        riskCategory: getfromDataObject(data, 'RiskCategory.Id'),
        productId: productID,
        investmentAllocations: getAllocations(data?.Positions || []),
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyId,
            ProjectionYears: projectionYears || 5,
            InitialInvestmentAmount: portfolioValue,
            MonthlyContributionAmount: recurringPayment,
        }),
        constraints: data?.OptimizationConstraints || {},
        investmentAllocation: {
            chart: adaptAllocationGroups({
                positions,
                currency,
                t,
                getFormattedCurrency,
                securitiesValue: data?.SecuritiesValue || portfolioSecuritiesValue,
                comparedAllocations,
            }),
            currency,
        },
        riskReturn: [+(data?.CalculatedRisk * 100), +(data?.CalculatedExpectedReturn * 100)],
        riskReturnMatrix: {
            x: [validateNumber(data?.RiskCategory?.Min) * 100,
                validateNumber(data?.RiskCategory?.Max) * 100],
        },
        allocationGroups: buildAllocationGroup(
            positions, currency, t, data?.SecuritiesValue || portfolioSecuritiesValue,
        ),
        Positions: positions,
        positions: groupPositions(data?.Positions, {
            portfolioCurrency: currency,
            baseUrl: positionLink,
            isProposed,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        }),
        performanceTitle,
        bandwidthTitle,
        riskReturnTitle,
    };
};
