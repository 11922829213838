// TODO: move this logic to action.
/* eslint-disable import/no-cycle */
import { logout } from 'redux/auth/authActions';
import store from 'store';

export const checkStatus = (status) => {
    if (status === 401 || status === 400) {
        store.dispatch(logout());
    }
};
