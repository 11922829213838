import uniq from 'lodash/uniq';

export const getDocumentsPortfolios = ({
    portfolios, documents,
}) => {
    const filteredPortfolios = [];

    (documents?.results || []).forEach(({ contractId }) => {
        const filter = contractId && portfolios.find(({ UId }) => UId === contractId);

        if (filter) {
            filteredPortfolios.push(filter);
        }
    });

    return { Portfolios: uniq(filteredPortfolios) };
};
