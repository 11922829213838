/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Infobox, Title } from 'ui-library';
import Passcode from 'components/Passcode';
import './MTanPasscode.css';
import { useTranslation } from 'react-i18next';

export const MTanPasscode = ({
    onSubmit,
    onChange,
    onCancel,
    isLoading,
    error,
    infoText,
    passcodeLength,
}) => {
    let intervalId;
    const [passcodeTimer, setPasscodeTimer] = useState(30);
    const [passcode, setPasscode] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        intervalId = setInterval(() => {
            const newPasscodeTime = passcodeTimer - 1;

            if (newPasscodeTime === 0) {
                clearInterval(intervalId);
            }
            setPasscodeTimer(newPasscodeTime);
        }, 1000);
    }, []);

    const changeTanHandler = (value) => {
        setPasscode(value);
        onChange(value);
    };

    const submit = (event) => {
        event.preventDefault();
        onSubmit(passcode);
    };

    return (
        <div className="MTanPasscode content-pane">
            <form onSubmit={submit}>
                <Title type={2}>{t('login.smsSecurityCode')}</Title>

                {typeof error === 'string' && (
                    <div className="validation-summary-errors">
                        <Infobox error>{t(error)}</Infobox>
                    </div>
                )}

                <p className="info-text">{t(infoText)}</p>

                <Passcode onChange={changeTanHandler} passcodeLength={passcodeLength} />

                <Title type={3}>{t('login.noSMS')}</Title>
                <p>{t('login.noSMSExplanation')}</p>
                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button
                        type="primary"
                        htmltype="submit"
                        disabled={isLoading || passcode.length !== passcodeLength}
                    >
                        {isLoading ? t('login.validating') : t('login.confirm')}
                    </Button>
                    <a href="javascript:void(0);" onClick={onCancel}>
                        {t('login.cancel2FA')}
                    </a>
                </div>
            </form>
        </div>
    );
};

MTanPasscode.propTypes = {
    passcodeLength: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    infoText: PropTypes.string,
};

MTanPasscode.defaultProps = {
    passcodeLength: 6,
    onChange: () => {},
    onCancel: () => {},
    error: undefined,
    isLoading: false,
    infoText: 'login.enterTheCode',
};
