import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { adaptSummaryDocuments } from '../adaptors/adaptSummaryDocuments';

export const useSummaryDocuments = (productId) => {
    const { i18n, t } = useTranslation();
    const [documents, setDocuments] = useState([]);
    const [errorDocuments, setErrorDocuments] = useState(null);
    const [isLoadingDocuments, setLoadingDocuments] = useState(false);

    const downloadDocument = useCallback((pdf, name) => () => {
        const link = document.createElement('a');

        link.href = pdf;
        link.download = `${name}.pdf`;
        // eslint-disable-next-line no-undef
        link.dispatchEvent(new MouseEvent('click'));
    }, []);

    useEffect(() => {
        setLoadingDocuments(true);
        setErrorDocuments(null);
        setDocuments(adaptSummaryDocuments([], { downloadDocument, productId, t }));
        setLoadingDocuments(false);
    }, [i18n.language, productId, downloadDocument, t]);

    return { documents, errorDocuments, isLoadingDocuments };
};
