import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'ui-library';
import { useTranslation } from 'react-i18next';
import ButtonWithIcon from '../renderers/ButtonWithIcon/ButtonWithIcon';

import './StrategyWithWarning.css';

const StrategyWithWarning = ({
    size = 18, currentRiskName, strategyRiskName,
}) => {
    const { t } = useTranslation();

    return (
        <div className="table-cell_icon strategy-with-icon">
            <div className="table-cell_action-with-icon-text">
                {!strategyRiskName ? '-' : <span className="table-cell-text-before-icon">{strategyRiskName}</span>}
                {(strategyRiskName && currentRiskName !== strategyRiskName) && (
                    <Tooltip
                        title={t('portfolio.strategyWarning')}
                        overlayClassName="ant-tooltip-table"
                        className="table-tooltip"
                        trigger="hover"
                        placement="topRight"
                        disabled
                    >
                        <ButtonWithIcon>
                            <Icon className="icon__tooltipped" size={size} type="warning" />
                        </ButtonWithIcon>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

StrategyWithWarning.propTypes = {
    currentRiskName: PropTypes.string,
    strategyRiskName: PropTypes.string,
    size: PropTypes.number,
};

StrategyWithWarning.defaultProps = {
    size: 18,
    currentRiskName: undefined,
    strategyRiskName: undefined,
};

export default StrategyWithWarning;
