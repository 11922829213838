export const adaptRatings = (data) => (data?.Ratings || []).map(
    ({ Id, Value }) => ({ label: Value, value: Id, ratingAgencyId: data?.RatingAgencyId }),
);

export const adaptRatingAgencies = (data, t) => {
    const options = (data || []).map(
        ({ Id, Name }) => ({ label: Name, value: Id }),
    );

    return [
        { label: t('position.pleaseSelect'), value: '' },
        ...options,
    ];
};
