import { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import ServiceManager from 'services/ServiceManager';
import { groups, keys } from 'static/globalSettings';
import { isUserNameSameWithEmailSelector } from '../selectors';
import { GlobalSettingsReturn, UseNameSameAsEmailReturn } from '../types';


interface UseNameSameAsEmailProps {
    setLoading: (value: boolean) => void
    setError: (value: string) => void
}

export const useNameSameAsEmail = (
    { setLoading, setError }: UseNameSameAsEmailProps,
): UseNameSameAsEmailReturn => {
    const [data, setData] = useState<boolean | null>(null);

    useEffect(() => {
        setLoading(true);

        ServiceManager.commonService('getGlobalSettingByGroupAndKey', [
            groups.CONTACT_MANAGEMENT,
            keys.IS_USER_NAME_SAME_WITH_EMAIL,
        ])
            .then((response: AxiosResponse<GlobalSettingsReturn>) => {
                try {
                    setData(isUserNameSameWithEmailSelector(response.data));
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err: any) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, []);

    return { data };
};
