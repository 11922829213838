import { mapOrderTypeName } from '../constants';

export const adaptTradingCardPosition = (data, { positionId }) => {
    if (data.length === 0) return [];

    return data
        .map(({
            Security, Quantity, OrderType, tradeData, ...rest
        }) => ({
            id: Security.Id,
            quantity: tradeData.Quantity,
            order: mapOrderTypeName(OrderType.Id),
            orderType: tradeData.OrderType,
            limit: tradeData.LimitPrice,
            stop: tradeData.StopPrice,
            fundingAccount: tradeData.FundingAccountId,
            validity: tradeData.OrderValidity,
            Security,
            tradeData,
            ...rest,
        }))
        .find(({ id }) => id === +positionId);
};
