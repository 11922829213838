import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import {
    Modal, Accordion, AccordionPanel as Panel, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import Allocation from 'components/AllocationCompare';
import PositionsCompare from 'components/PositionsCompare';
import ChangeModelOverview from 'components/ChangeModelOverview';
import Analysis from 'components/Analysis';
import { useModelPortfolio } from 'domain/Portfolio';
import { adaptModelPortfolio } from 'domain/Portfolio/adapters/adaptModelPortfolio';
import { useFormatting } from 'locale';
import { formatDate, yearFormat } from 'utils/datetime';
import { useModelList } from 'hooks/useModelList';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useModelChange } from 'hooks/useModelChange';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { CHANGE_STRATEGY } from 'constants/constants';
import {
    goalCreationSelector,
    goalSummarySelector,
    onBoardingDataSelector,
    useOnBoardingSelector,
} from 'domain/OnBoarding';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useGoalReach } from '../../hooks/useGoalReach';
import { adaptConstraints } from '../../adaptors/adaptConstraints';
import './ChangeModel.css';

function ChangeModel(props) {
    const { onPageChange, contactId, location: { pathname } } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();
    const [errorSameStrategy, setErrorSameStrategy] = useState(false);
    const [riskCategory, setRiskCategory] = useState(null);
    const baseUrl = `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`;

    // OnBoarding Domain
    const {
        product, getGoalDetails, getRiskCategory, saveGoalChanges, clearGoalChanges, saveProposalId,
        getGoalChanges, clearCreatedProposalId, saveReviewData,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        isLoading, error, getModelPortfolioId,
    } = useOnBoardingSelector(goalCreationSelector);
    const {
        saveProposal, isLoadingSave, errorSave,
    } = useOnBoardingSelector(goalSummarySelector);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();

    // Data
    const goalChanges = useMemo(() => (Number.isInteger(+getGoalChanges())
        ? getGoalChanges() : undefined), [getGoalChanges]);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate],
    );
    const optionsForAdapt = useMemo(
        () => ({
            productId: +product.id,
            currency: goalDetails.selectedCurrency,
            portfolioValue: +goalDetails.initialInvestment,
            portfolioTotalValue: +goalDetails.initialInvestment,
            portfolioSecuritiesValue: +goalDetails.initialInvestment,
            recurringPayment: +goalDetails.recurringPayment,
            projectionYears,
            positionLink: `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        }),
        [
            product.id,
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            goalDetails.recurringPayment,
            projectionYears,
            pathname,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        ],
    );
    const data = useMemo(() => adaptModelPortfolio({ ...optionsForAdapt, data: modelPortfolio }),
        [modelPortfolio, optionsForAdapt]);
    const dataForMP = useMemo(() => ({
        ...data, projectionYears, recurringPayment: +goalDetails.recurringPayment,
    }), [data, projectionYears, goalDetails.recurringPayment]);

    // Helpers hooks
    const {
        data: modelList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList({ productId: +product.id, riskCategoryId: riskCategory?.Id });
    const {
        modelData, positions, modelId,
        isLoadingModelData, errorModelData, onModelChange, onModelReset,
    } = useModelChange({
        portfolio: dataForMP, portfolioPositions: modelPortfolio?.Positions, baseUrl,
    });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks({
        currencyId: goalDetails.selectedCurrency?.value,
        productId: +product?.id,
    }, isLoading, true);
    const strategy = useMemo(() => (modelData?.modelPortfolioId && data?.modelPortfolioId
        ? modelData : null),
    [modelData?.modelPortfolioId, data?.modelPortfolioId, modelData]);
    const {
        analysisData,
        isLoadingModel, errorModel, onPerformanceChange, performanceSelected, projectionResponse,
    } = useAnalysisDetails({
        portfolio: data,
        strategy,
        positions,
        isLoading,
        benchmark,
        actionType: CHANGE_STRATEGY,
        riskProfileStrategyTitle: t('onBoarding.chosen'),
        riskProfilePortfolioTitle: t('onBoarding.suggested'),
    });

    // Effects
    useEffect(() => {
        (async () => {
            if (goalChanges) {
                getModelPortfolio(goalChanges, { currencyId: goalDetails.selectedCurrency?.value });
            } else {
                const risk = await getRiskCategory();

                setRiskCategory(risk);
                const riskCategoryId = risk?.Id;

                const modelObj = await getModelPortfolioId(
                    { productId: +product?.id, riskCategoryId },
                );

                getModelPortfolio(modelObj?.Id,
                    { currencyId: goalDetails.selectedCurrency?.value });
            }
        })();
    }, [
        getRiskCategory,
        getModelPortfolioId,
        getModelPortfolio,
        product?.id,
        goalDetails.selectedCurrency?.value,
        goalChanges,
    ]);
    useEffect(() => {
        if (goalChanges) {
            setRiskCategory(modelPortfolio.RiskCategory);
        }
    }, [modelPortfolio, goalChanges]);
    const { dataExternal, isGoalReach } = useGoalReach({
        goalState: goalDetails, projectionResponse,
    });

    useEffect(() => {
        (async () => {
            if (goalChanges) {
                setRiskCategory(modelPortfolio?.RiskCategory);
            } else {
                const risk = await getRiskCategory();

                setRiskCategory(risk);
            }
        })();
    }, [getRiskCategory, modelPortfolio, goalChanges]);

    // Renderers
    const isLoadingData = useMemo(() => isLoading || isLoadingMP || isLoadingModelList,
        [isLoading, isLoadingMP, isLoadingModelList]);
    const errorData = useMemo(() => error || errorMP || errorModelList,
        [error, errorMP, errorModelList]);
    const AllocationRender = useMemo(() => (
        <Allocation
            data={data.investmentAllocation}
            dataNew={modelData?.investmentAllocation || data.investmentAllocation}
            isLoading={isLoadingData}
            error={errorData}
        />
    ), [data.investmentAllocation, modelData, isLoadingData, errorData]);

    // Callbacks
    const onStrategyReset = () => {
        sessionStorage.removeItem(CHANGE_STRATEGY);
        onModelReset();
    };
    const onModelChangeFunc = (val) => {
        onModelChange(val);
        setErrorSameStrategy(false);
    };
    const onBackClick = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                clearGoalChanges();
                onStrategyReset();
                clearCreatedProposalId();
                onPageChange('');
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onConfirm = useCallback(async () => {
        if (data?.modelPortfolioId === +modelId || !modelId) {
            setErrorSameStrategy(true);

            return;
        }

        const params = {
            ModelPortfolioId: modelId,
            Name: goalDetails?.goalName,
            RecommendedInvestment: +goalDetails?.initialInvestment,
            ProductId: +product.id,
            CurrencyId: goalDetails?.selectedCurrency.value,
            OptimizationConstraints: adaptConstraints(modelData?.constraints),
        };
        const savedProposalData = await saveProposal(params);

        if (savedProposalData?.createdProposalId) {
            saveProposalId(savedProposalData.createdProposalId);
        }
        saveReviewData({
            modelPortfolioId: modelData?.modelPortfolioId,
            benchmarkId: benchmarkSelected || modelData?.benchmarkId,
        });
        saveGoalChanges(modelId);
        onPageChange('');
    }, [
        contactId,
        modelId,
        onPageChange,
        saveGoalChanges,
        saveProposal,
        saveProposalId,
        modelData,
    ]);

    return (
        <OnBoardingBaseTemplate
            error={error}
            isLoading={isLoading}
            title={`${goalDetails.goalName} - ${t('overview.changeModel')}`}
            className="goal-review goal-change-strategy"
            classNameContent="stepper-content-full"
            prevButton={{
                loading: isLoadingSave,
                disabled: isLoading,
                onClick: onBackClick,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                loading: isLoadingSave,
                disabled: isLoading,
                onClick: onConfirm,
            }}
            topInfoboxContent={!isGoalReach && !isLoadingModel && !isLoadingBenchmark && (
                <div className="goal-reached">
                    <Infobox accent>{t('onBoarding.cannotReachedGoal')}</Infobox>
                </div>
            )}
            buttonsRowError={(
                <>
                    {errorSave && <Infobox error>{errorSave.message}</Infobox>}
                    {errorSameStrategy && <Infobox error>{t('onBoarding.errorChangeSameModel')}</Infobox>}
                </>
            )}
        >
            <ChangeModelOverview
                data={data.overview}
                selectedModel={modelId}
                isLoading={isLoadingModelList}
                error={errorModelList}
                modelList={modelList}
                onModelChange={onModelChangeFunc}
            />
            <Preloader isLoading={isLoading || isLoadingModelData} error={error || errorModelData}>
                <div className="content-wrapper">
                    <Accordion defaultActiveKey={['1', '2', '3']}>
                        <Panel header={t('portfolios.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('portfolios.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                projectionDataExternal={dataExternal}
                                isLoading={isLoadingModel || isLoadingModelData}
                                error={errorModel || errorModelData}
                                isPortfolioProposed
                            />
                        </Panel>
                        <Panel header={t('portfolios.positions')} className="positions" key="3">
                            <PositionsCompare
                                data={positions}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </div>
            </Preloader>
        </OnBoardingBaseTemplate>
    );
}

ChangeModel.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
};

ChangeModel.defaultProps = {
    onPageChange: () => {},
};

export default ChangeModel;
