import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { ALL_PAGES, PAGE_SIZE } from 'constants/constants';
import { usePagination } from 'hooks/usePagination';
import { proposalsColumns } from '../constants';

function Proposals({ data, isLoading, error }) {
    const { t } = useTranslation();

    const total = data?.length;

    const { page, onPaginationChange } = usePagination(ALL_PAGES);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('portfolios.noProposalsFound')}>
                <Table
                    data={data}
                    columns={proposalsColumns(t)}
                    onChange={(pagination) => {
                        if (pagination.currentPage) onPaginationChange(pagination);
                    }}
                    paging={{
                        total,
                        currentPage: page,
                        pageSize: PAGE_SIZE,
                    }}
                />
            </EmptyContent>
        </Preloader>
    );
}

Proposals.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            Name: PropTypes.string,
            Date: PropTypes.string,
            Status: PropTypes.string,
            Creator: PropTypes.string,
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

Proposals.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
};

export default Proposals;
