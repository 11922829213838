import { useCallback, useState } from 'react';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';
import { adaptMessageDetails, adaptMessages } from '../adaptors/messagesAdapt';

const defParams = {
    SortOrder: 'desc',
    SortColumn: 'CreateDate',
};

const sortColumnMessaging = {
    Date: 'CreateDate',
    Subject: 'Subject',
};

export const useMessaging = (contactId) => {
    const [tab, setTab] = useState('inbox');
    const [data, setData] = useState([]);
    const [isGetting, setIsGetting] = useState(false);
    const [needUpdate, setNeedUpdate] = useState(true);
    const { getFormattedDate } = useFormatting();

    const getData = useCallback((params) => new Promise((resolve, reject) => {
        const method = 'postContactMessages';
        const args = tab === 'sentbox'
            ? [
                {
                    ...defParams,
                    Text: (params?.filters || []).join(' '),
                    PageNumber: params?.currentPage || 1,
                    PageSize: params?.pageSize || 10,
                    TextLength: 30,
                    SortOrder: params?.sortOrder,
                    SortColumn: sortColumnMessaging[params?.sortColumn],
                    SenderIds: [contactId],
                },
                contactId,
            ]
            : [
                {
                    ...defParams,
                    Text: (params?.filters || []).join(' '),
                    PageNumber: params?.currentPage || 1,
                    PageSize: params?.pageSize || 10,
                    TextLength: 60,
                    SortOrder: params?.sortOrder,
                    SortColumn: sortColumnMessaging[params?.sortColumn],
                    contactId,
                    RecipientIds: [contactId],
                },
                contactId,
            ];

        setIsGetting(true);
        ServiceManager.OOTBServices(method, args)
            .then((response) => {
                try {
                    const listOfEmails = adaptMessages(response.data.Data, contactId);

                    setData(listOfEmails);
                    setIsGetting(false);
                    resolve({
                        data: listOfEmails,
                        total: response.data.Total,
                        filters: params?.filters,
                        currentPage: params?.currentPage,
                        sorting: {
                            sortOrder: params?.sortOrder || 'desc',
                            sortColumn: params?.sortColumn || 'Date',
                        },
                    });
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                reject(err.type !== undefined ? err : new ServerError(err));
            });
    }), [contactId, tab]);
    const getDataCallback = () => {
        setNeedUpdate(false);
    };
    const onSearch = (value, params) => new Promise((resolve, reject) => {
        setIsGetting(true);
        try {
            getData(params).then((response) => {
                setIsGetting(false);
                resolve({
                    data: response.data,
                    total: response.total,
                    filters: response.filters,
                });
            });
        } catch (err) {
            reject(new AdapterError(err));
        }
    });
    const getPreview = (id) => new Promise((resolve, reject) => {
        if (needUpdate || isGetting) return;
        setIsGetting(true);
        ServiceManager.messageService('getMessageContent', [id, contactId])
            .then((response) => {
                const message = adaptMessageDetails(response.data, contactId, getFormattedDate);

                if (message.Subject.isRead === false) {
                    ServiceManager.messageService('readMessage', [id, contactId])
                        .then(() => {
                            setIsGetting(false);
                            resolve({
                                ...message,
                                Subject: { ...message.Subject, isRead: true },
                            });
                        })
                        .catch((err) => {
                            reject(err.type !== undefined ? err : new ServerError(err));
                        });
                } else {
                    setIsGetting(false);
                    resolve(message);
                }
            })
            .catch((err) => {
                reject(err.type !== undefined ? err : new ServerError(err));
            });
    });
    const changeTab = (key) => {
        if (isGetting === false) {
            setTab(key);
            setNeedUpdate(true);
        }
    };
    const deleteItem = (id) => new Promise((resolve, reject) => {
        ServiceManager.messageService('deleteMessage', [id, contactId])
            .then(() => {
                resolve(id);
            })
            .catch((err) => {
                reject(new ServerError(err));
            });
    });

    return {
        tab,
        needUpdate,
        getData,
        onSearch,
        changeTab,
        getPreview,
        deleteItem,
        getDataCallback,
        data,
    };
};
