import { setup } from 'additiv-services';
// todo import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    AUTH_COMPLETE,
    GET_USER_PROFILE_SUCCESS,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    PRE_AUTH_SUCCESS,
    PRE_AUTH_CAPTCHA_SUCCESS,
    PRE_AUTH_WITH_2FA_COMPLETE,
    RESET_LOGIN,
    LOGIN_WITH_URL_START,
    LOGIN_WITH_URL_SUCCESS,
    RESET2FA,
    SESSION_CHECKED, RENEW_ACCESS_TOKEN,
    RENEW_ACCESS_TOKEN_PROCESS_STARTED,
} from './authActions';

const defaultState = {
    user: undefined,
    isAuthenticated: false,
    isFistStepDone: false,
    isJWTBaseLogin: false,
    isRenewAccessTokenProcessStarted: false,
};

const initState = defaultState;

export default function reducer(state = initState, action = {}) {
    let nextState = {};

    switch (action.type) {
        case RESET2FA:
            return {
                ...state,
                isFistStepDone: false,
            };
        case RESET_LOGIN:
            return {
                ...state,
                isFistStepDone: false,
                user: {
                    ...state.user,
                },
            };
        case SESSION_CHECKED:
            return {
                ...state,
                isAuthenticated: action.payload.isSessionValid,
            };
        case PRE_AUTH_SUCCESS:
            nextState = {
                ...state,
                user: {
                    ...state.user,
                    isDeviceEnrolled: true,
                    isActive: action.payload.CampaignContact.IsActive,
                    contactId: action.payload.CampaignContact.ContactId,
                    passcodeLength: action.payload.Length,
                    isTwoFactorEnabled: action.payload.CampaignContact.IsTwoFactorEnabled,
                    statusFor2FA: action.payload.Status,
                    username: action.payload.username,
                    factor: action.payload.Factor,
                    provider: action.payload.Provider,
                    rememberMe: action.payload.rememberMe,
                    preAuthenticateSessionId: action.payload.PreAuthenticateSessionId,
                },
            };

            return nextState;
        case PRE_AUTH_CAPTCHA_SUCCESS:
            nextState = {
                ...state,
                user: {
                    ...state.user,
                    isDeviceEnrolled: true,
                    isActive: action.payload.campaignContact.isActive,
                    contactId: action.payload.campaignContact.contactId,
                    passcodeLength: action.payload.length,
                    isTwoFactorEnabled: action.payload.campaignContact.isTwoFactorEnabled,
                    statusFor2FA: action.payload.status,
                    username: action.payload.username,
                    factor: action.payload.factor,
                    provider: action.payload.provider,
                    rememberMe: action.payload.rememberMe,
                    preAuthenticateSessionId: action.payload.preAuthenticateSessionId,
                },
            };

            return nextState;
        case RENEW_ACCESS_TOKEN_PROCESS_STARTED:
            nextState = {
                ...state,
                isRenewAccessTokenProcessStarted: true,
            };

            return nextState;
        case RENEW_ACCESS_TOKEN:
        case AUTH_COMPLETE:
            nextState = {
                ...state,
                user: {
                    ...state.user,
                    sessionId: action.payload.sessionId,
                    jwtAccessToken: action.payload.jwtAccessToken,
                    jwt: btoa(`${action.payload.sessionId}:${action.payload.jwtAccessToken}`),
                    accessTokenTimestamp: +new Date(),
                },
                isFistStepDone: true,
                isAuthenticated: true,
            };

            setup({ bearerAccessToken: `Bearer ${action.payload.jwtAccessToken}` });

            return nextState;
        case PRE_AUTH_WITH_2FA_COMPLETE:
            nextState = {
                ...state,
                isFistStepDone: true,
            };

            return nextState;
        case LOGIN_SUCCESS:
            nextState = {
                ...state,
                user: {
                    ...state.user,
                    contactId: action.payload.CampaignContact.ContactId,
                    contactTypeId: action.payload.CampaignContact.Contact.ContactTypeId,
                    contactFirstName: action.payload.CampaignContact.Contact.FirstName,
                    contactLasName: action.payload.CampaignContact.Contact.LastName,
                    username: action.payload.username,
                    password: action.payload.password,
                },
                isFistStepDone: true,
            };

            return nextState;
        case LOGIN_WITH_URL_START:
            nextState = {
                ...state,
                isFistStepDone: false,
                user: {
                    ...state.user,
                    contactId: action.payload.contactId,
                    jwt: action.payload.jwt,
                    jwtAccessToken: action.payload.jwt,
                    accessTokenTimestamp: +new Date(),
                },
                isJWTBaseLogin: true,
            };

            setup({ bearerAccessToken: `Bearer ${action.payload.jwt}` });

            return nextState;
        case LOGIN_WITH_URL_SUCCESS:
            nextState = {
                ...state,
                isAuthenticated: true,
            };

            return nextState;
        case GET_USER_PROFILE_SUCCESS:
            // TODO: remove comments
            nextState = {
                ...state,
                user: {
                    ...state.user,
                    // contactTypeId: action.payload.ContactType.Id,
                    relationshipManagerId: action.payload.members[0].Id,
                    relationshipManagerPhone: action.payload.members[0].phone,
                    // contactFirstName: action.payload.FirstName,
                    // contactLasName: action.payload.LastName,
                    // isTwoFactorEnabled: action.payload.IsTwoFactorEnabled,
                },
            };

            return nextState;
        case LOGOUT_SUCCESS:
            setup({ bearerAccessToken: undefined });

            return defaultState;
        default:
            return state;
    }
}
