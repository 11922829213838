import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Accordion, AccordionPanel as Panel, Row, Column, ContentBox,
} from 'ui-library';
import { useFormatting } from 'locale';
import Analysis from 'components/Analysis';
import PositionsCompare from 'components/PositionsCompare';
import Allocation from 'components/AllocationCompare';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { OverviewItem, OverviewPanel } from 'components/Overview';
import { TYPE_CURRENCY } from 'utils/formatting';
import { formatDate, yearFormat } from 'utils/datetime';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { useModelPortfolio } from 'domain/Portfolio';
import { adaptModelPortfolio } from 'domain/Portfolio/adapters/adaptModelPortfolio';
import {
    goalCreationSelector, onBoardingDataSelector, useOnBoardingSelector,
} from 'domain/OnBoarding';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import { useComparePortfolio } from './hooks/useComparePortfolio';
import { getCompareColumns } from './constants';

const Compare = (props) => {
    const { contactId, onPageChange, location: { pathname } } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency, getFormattedDate } = useFormatting();
    // const [positions, setPositions] = useState([]);

    // OnBoarding Domain
    const {
        getGoalDetails, product, getRiskCategory,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const { getModelPortfolioId } = useOnBoardingSelector(goalCreationSelector);

    // Portfolio Domain
    const {
        dataRaw: modelPortfolio, isLoading: isLoadingMP, error: errorMP, getModelPortfolio,
    } = useModelPortfolio();

    // Data
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const projectionYears = useMemo(
        () => formatDate(goalDetails.targetDate, yearFormat) - formatDate(new Date(), yearFormat),
        [goalDetails.targetDate, formatDate],
    );
    const optionsForAdapt = useMemo(
        () => ({
            currency: goalDetails.selectedCurrency,
            portfolioCurrency: goalDetails.selectedCurrency?.label,
            portfolioValue: +goalDetails.initialInvestment,
            portfolioTotalValue: +goalDetails.initialInvestment,
            portfolioSecuritiesValue: +goalDetails.initialInvestment,
            productId: +product.id,
            productName: product.name,
            targetDate: goalDetails.targetDate,
            targetValue: +goalDetails.targetValue,
            projectionYears,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            nameLength: 35,
            positionLink: `${pathname}${pathname[pathname.length - 1] === '/' ? '' : '/'}position`,
            bandwidthTitle: t('onBoarding.3rdPartyPortfolio'),
            riskReturnTitle: t('onBoarding.3rdPartyPortfolio'),
            performanceTitle: t('onBoarding.3rdPartyPortfolio'),
        }),
        [
            goalDetails.selectedCurrency,
            goalDetails.initialInvestment,
            projectionYears,
            product.name,
            product.id,
            goalDetails.targetDate,
            goalDetails.targetValue,
            t,
            getFormattedDate,
            getFormattedNumber,
            getFormattedCurrency,
            pathname,
        ],
    );
    const strategyData = useMemo(() => adaptModelPortfolio({
        ...optionsForAdapt,
        data: modelPortfolio,
        bandwidthTitle: t('onBoarding.proposedPortfolio'),
        riskReturnTitle: t('onBoarding.proposedPortfolio'),
        performanceTitle: t('onBoarding.proposedPortfolio'),
    }), [modelPortfolio, optionsForAdapt, t]);

    // Helpers hooks
    const {
        data, isLoading, error,
    } = useComparePortfolio(contactId, product?.id, optionsForAdapt);
    const positions = useMemo(() => groupComparedPositions({
        positions: data.Positions,
        modelPositions: strategyData?.Positions,
        ...optionsForAdapt,
    }), [data.Positions, strategyData?.Positions]);
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks({ productId: +product?.id }, isLoading || isLoadingMP, true);
    const {
        analysisData, isLoadingModel, errorModel, onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        portfolio: data,
        strategy: strategyData,
        positions,
        isLoading: isLoading || isLoadingMP,
        benchmark,
    });

    // Effects
    useEffect(() => {
        (async () => {
            const riskCategoryId = (await getRiskCategory())?.Id;
            const modelObj = await getModelPortfolioId({ productId: +product?.id, riskCategoryId });

            getModelPortfolio(modelObj?.Id, { currencyId: goalDetails.selectedCurrency?.value });
        })();
    }, [
        getRiskCategory,
        getModelPortfolioId,
        getModelPortfolio,
        product?.id,
        goalDetails.selectedCurrency?.value,
    ]);

    // Callbacks
    const AllocationRender = useMemo(() => (
        <Allocation
            data={data.investmentAllocation}
            dataNew={strategyData?.investmentAllocation || data.investmentAllocation}
            isLoading={isLoading || isLoadingMP}
            titles={[t('onBoarding.3rdPartyPortfolio'), t('onBoarding.proposedPortfolio')]}
            error={error || errorMP}
        />
    ), [
        data.investmentAllocation,
        strategyData?.investmentAllocation,
        isLoading,
        isLoadingMP,
        error,
        errorMP,
        t,
    ]);
    const onPrev = () => {
        onPageChange('compare-positions');
    };
    const onContinue = () => {
        onPageChange('');
    };

    return (
        <OnBoardingBaseTemplate
            title={`${goalDetails.goalName} - ${t('onBoarding.compare')}`}
            error={error || errorMP}
            isLoading={isLoading || isLoadingMP}
            className="goal-review on-boarding_modify"
            prevButton={{
                onClick: onPrev,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                disabled: isLoading,
                onClick: onContinue,
            }}
        >
            <div className="content-wrapper">
                <ContentBox underline={false}>
                    <OverviewPanel>
                        <Row>
                            <Column size={['sm-3']}>
                                <OverviewItem
                                    title={t('overview.portfolioValue')}
                                    value={data.portfolioValue}
                                    type={TYPE_CURRENCY}
                                    currency={data.currency}
                                />
                            </Column>
                        </Row>
                    </OverviewPanel>
                </ContentBox>
                <Accordion defaultActiveKey={['1', '2', '3']}>
                    <Panel header={t('portfolios.allocation')} key="1" className="allocation">
                        {AllocationRender}
                    </Panel>
                    <Panel header={t('portfolios.analysis')} key="2" className="analysis">
                        <Analysis
                            data={analysisData}
                            onFilterChange={onPerformanceChange}
                            benchmarkOptions={benchmarkOptions}
                            benchmarkSelected={benchmarkSelected}
                            onBenchmarkChange={onBenchmarkChange}
                            isLoadingBenchmarks={isLoadingBenchmark}
                            performanceSelected={performanceSelected}
                            isLoading={isLoadingModel || isLoadingMP}
                            compareTitles={[t('onBoarding.3rdPartyPortfolio'), t('onBoarding.proposedPortfolio')]}
                            error={errorModel || errorMP}
                            isPortfolioProposed
                        />
                    </Panel>
                    <Panel header={t('portfolios.positions')} className="positions" key="3">
                        <PositionsCompare
                            data={positions}
                            columns={getCompareColumns(t)}
                            isLoading={isLoading}
                            error={error}
                            defaultExpandAllRows
                        />
                    </Panel>
                </Accordion>
            </div>
        </OnBoardingBaseTemplate>
    );
};

Compare.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    onPageChange: PropTypes.func,
};

Compare.defaultProps = {
    onPageChange: () => {},
};

export default Compare;
