import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import AdapterError from 'errors/AdapterError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const initialState = {
    dataRaw: {},
    error: null,
    isLoading: false,
    errorDeleting: null,
    isDeleting: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setIsDeleting':
            return { ...state, isDeleting: action.payload };
        case 'setErrorDeleting':
            return { ...state, errorDeleting: action.payload };
        default:
            return state;
    }
};

export const useTradingCard = (options = {}) => {
    const {
        clientId,
        portfolioId,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { i18n: { language } } = useTranslation();

    // Callbacks
    const getTradingCard = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });
        const params = { language };

        try {
            const tradingResp = await SM.portfolioManagement('getTradingCard', [portfolioId, clientId, params]);
            const Trades = tradingResp?.data?.PortfolioTradingCart?.Items;
            const response = await SM.portfolioManagement('tradingCardSimulate', [clientId, portfolioId, params, { Trades }]);

            try {
                dispatch({ type: 'setDataRaw', payload: response?.data });
                dispatch({ type: 'setIsLoading', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language, portfolioId]);
    const deleteTradingCard = useCallback(async () => {
        dispatch({ type: 'setIsDeleting', payload: true });
        dispatch({ type: 'setErrorDeleting', payload: null });

        try {
            const response = await SM.portfolioManagement('deleteTradingCartOrder', [clientId, portfolioId]);

            try {
                dispatch({ type: 'setIsDeleting', payload: false });

                return response?.data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorDeleting', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsDeleting', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, portfolioId]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getTradingCard();
    }, [getTradingCard, loadInitially]);

    return {
        dataRaw: state.dataRaw,
        error: state.error,
        isLoading: state.isLoading,
        getTradingCard,
        isDeleting: state.isDeleting,
        errorDeleting: state.errorDeleting,
        deleteTradingCard,
    };
};
