import React, { useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { contactIdSelector } from 'redux/auth/authSelectors';
import { ContentBox, Table, Button } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';
import { portfoliosColumns, portfoliosMobileColumns } from 'constants/constants';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import PageHeader from 'components/PageHeader';
import { useDownloadPortfolioFile } from 'domain/Portfolio/hooks/useDownloadPortfolioFile';
import { getPeriodByFilter } from 'utils';
import { YTD } from 'filters/investementPerformanceFilters';
import { useGlobalSettings } from 'prodivers/globalSettings';
import PageSection from '../PageSection';
import Preloader from '../Preloader';
import EmptyContent from '../EmptyContent';

function Portfolios({
    data, isLoading, error, pageSize,
}) {
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedNumber } = useFormatting();
    const contactId = useSelector(contactIdSelector);
    const periodRange = getPeriodByFilter(YTD);
    const { isLoading: isLoadingFile, downloadFile } = useDownloadPortfolioFile(contactId);

    const downloadAll = useCallback(
        () => downloadFile({
            fromDate: periodRange?.startDate,
            toDate: periodRange?.endDate,
        }),
        [downloadFile, periodRange],
    );

    const {
        settings: {
            isReportEnabled,
            isReportGenerating,
            hideReportToContactsList,
        },
        getIsReportGeneratingSetting,
    } = useGlobalSettings();

    useEffect(() => getIsReportGeneratingSetting(), []);

    const isDownloadButtonDisabled = useMemo(
        () => isReportGenerating || _.isEmpty(data) || data?.length === 0,
        [_, data, isReportGenerating],
    );

    return (
        <PageSection>
            <ContentBox className="portfolios">
                <PageHeader title={t('portfolios.title')}>
                    {
                        isReportEnabled
                        && !hideReportToContactsList.includes(contactId) && (
                            <Button
                                type="primary"
                                size="small"
                                className="download-button"
                                loading={isLoadingFile}
                                onClick={downloadAll}
                                disabled={isDownloadButtonDisabled}
                            >
                                {t(isReportGenerating ? 'clientDashboard.portfolio.report.generating' : 'clientDashboard.portfolio.downloadAll')}
                            </Button>
                        )}
                </PageHeader>
                <div className="portfolios-table">
                    <Preloader isLoading={isLoading} error={error}>
                        <EmptyContent data={data} text={t('portfolios.noPortfoliosFound')}>
                            <Table
                                data={data}
                                columns={
                                    isMobileSize
                                        ? portfoliosMobileColumns(t, getFormattedNumber)
                                        : portfoliosColumns(t, getFormattedNumber)
                                }
                                headerControls={{
                                    selectControlsLabel: {
                                        selectAll: t('headerControls.selectAll'),
                                        reset: t('headerControls.reset'),
                                    },
                                    sortByLabel: t('headerControls.sortBy'),
                                }}
                                paging={{
                                    pageSize,
                                }}
                                fixedLastColumn
                            />
                        </EmptyContent>
                    </Preloader>
                </div>
            </ContentBox>
        </PageSection>
    );
}

Portfolios.propTypes = {
    data: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                Name: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
                Product: PropTypes.string,
                Profile: PropTypes.string,
                Value: PropTypes.string,
                Performance: PropTypes.number,
                Icon: PropTypes.string,
            }),
        ),
        PropTypes.shape({
            Name: PropTypes.shape({ label: PropTypes.string, link: PropTypes.string }),
            Product: PropTypes.string,
            Profile: PropTypes.string,
            Value: PropTypes.string,
            Performance: PropTypes.number,
            Icon: PropTypes.string,
        }),
    ]),
    isLoading: PropTypes.bool,
    error: PropTypes.string,
    pageSize: PropTypes.number,
};

Portfolios.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    pageSize: 9,
};

export default Portfolios;
