import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const initialState = {
    data: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useProductFee = (options = {}) => {
    const {
        productId: productIdOption,
        loadInitially = false,
        onBoardingData,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    const { i18n: { language } } = useTranslation();
    const { productOffer } = onBoardingData;

    // Callbacks
    const getProductFee = useCallback(async (
        productId = productIdOption,
    ) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const productData = await SM.commonService('getProductsDetails', [productId, language]);

            dispatch({ type: 'setData', payload: productData });
            dispatch({ type: 'setIsLoading', payload: false });

            return productData;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [language, productOffer?.id]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getProductFee();
    }, [getProductFee, loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getProductFee,
    };
};
