import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
    Accordion, AccordionPanel as Panel, Infobox, Tabs, TabPane, Table, Button,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import { YTD } from 'filters/investementPerformanceFilters';
import InfoboxWithButton from 'components/InfoboxWithButton';
import Analysis from 'components/Analysis';
import {
    cashFlowsSelector,
    orderBookSelector,
    portfolioSelector,
    transactionsSelector,
    usePortfolioSelector,
    useTransactions,
} from 'domain/Portfolio';
import { useDownloadPortfolioFile } from 'domain/Portfolio/hooks/useDownloadPortfolioFile';
import AccordionWrapper from 'components/AccordionWrapper';
import ContentBox from 'ui-library/dist/components/ContentBox';
import PageHeader from 'components/PageHeader';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { usePagination } from 'hooks/usePagination';
import { PORTFOLIO_BLOCKED_ID } from 'constants/portfolioStatuses';
import { useContactGroups } from 'hooks/useContactGroups';
import AllocationWithILT from 'components/AllocationWithILT/AllocationWithILT';
import { CUSTOM_FLOW_PRODUCT_IDS } from 'constants/portfolioProducts';
import { formatDate, atramDateFormat } from 'utils/datetime';
import { useGlobalSettings } from 'prodivers/globalSettings';
import CashFlows from '../components/CashFlows';
import Overview from '../components/Overview';
import Positions from '../components/Positions';
import Proposals from '../components/Proposals';
import Transactions from '../components/Transactions';
import TransactionsCash from '../components/TransactionsCash';
import { useProposals } from '../hooks/useProposals';
import { getPendingTradeColumns } from '../constants';
import { useAllocationsData } from '../hooks/useAllocationsData';
import { useAccounts } from '../pages/PositionDetails/hooks/useAccounts';
import { adaptPositions } from '../adapters/adaptPositions';
import { usePortfolioDetailsPerformance } from '../hooks/usePortfolioDetailsPerformance';

function PortfolioDirectionalAtram(props) {
    const {
        contactId: clientId,
        match: { params: { portfolioId } },
        location: { state },
    } = props;
    const { t } = useTranslation();
    const [isCustomFlow, setIsCustomFlow] = useState(false);
    const {
        data, isLoading, dataRaw, error,
    } = usePortfolioSelector(portfolioSelector);

    const {
        settings: {
            isReportEnabled,
            isReportGenerating,
            hideReportToContactsList,
        },
        getIsReportGeneratingSetting,
    } = useGlobalSettings();

    useEffect(() => getIsReportGeneratingSetting(), []);

    const {
        data: orderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    const valuationDate = useMemo(
        () => data?.HistoricalPerformance?.[0]?.Values?.pop()?.Date,
        [data],
    );

    useEffect(() => {
        if (data?.productId) {
            getOrderBook({ adaptOptions: { productId: data?.productId } });
            setIsCustomFlow(CUSTOM_FLOW_PRODUCT_IDS.includes(data?.productId));
        }
    }, [data?.productId, getOrderBook]);

    const {
        investmentAllocations,
        isLoading: isAllocationLoading,
        error: allocationError,
    } = useAllocationsData({
        clientId, portfolioId: data.id, isLoading, currency: data.overview.currency,
    });

    // Hooks
    const {
        dataProposals, isLoadingProposals, errorProposals,
    } = useProposals(clientId, portfolioId);
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const {
        analysisData, onPerformanceChange, performanceSelected, performanceDateRange,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        positions: data.positions,
        positionsRaw: dataRaw.Positions,
        isLoading,
        benchmark,
        defaultFilter: YTD,
        isLoadingBenchmark,
        additionalOptions: { usePortfolioPerformance: true },
    });
    const {
        data: accessPolicyContacts,
        isLoading: isLoadingAccessPolicyContacts,
        error: errorAccessPolicyContacts,
    } = useContactGroups({ contactId: clientId, contactGroupId: data?.contactGroupId });

    // Proposals logic
    const proposal = useRef();
    const [activeKey, setActiveKey] = useState(['2', '3', '5']);
    const reviewProposals = useCallback(() => {
        if (!activeKey.includes('4')) setActiveKey((arr) => [...arr, '4']);
        // eslint-disable-next-line no-unused-expressions
        proposal.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
        });
    }, []);

    useEffect(() => {
        if (dataProposals.length > 0) setActiveKey(['1', '2', '3', '4', '5']);
    }, [dataProposals]);
    useEffect(() => {
        if (state?.scrollToProposals && proposal.current) {
            reviewProposals();
        }
    }, [state, proposal.current, reviewProposals]);

    // Transactions logic TR
    const { page: TRPage, onPaginationChange: TROnPaginationChange } = usePagination(3);
    const TROptionsParams = useMemo(() => ({
        page: TRPage,
        PageSize: 10,
        SecurityType: 'Instruments',
    }), [TRPage]);
    const { data: portfolio } = usePortfolioSelector(portfolioSelector);
    const {
        data: TRData, isLoading: TRIsLoading, error: TRError, getTransactions: TRGetTransactions,
    } = usePortfolioSelector(transactionsSelector);
    const TRAdaptOptions = useMemo(() => ({
        baseUrl: `/portfolios/${portfolioId}/position/${portfolio?.productId}`,
    }), [portfolio.productId, portfolioId]);

    useEffect(() => {
        TRGetTransactions({ optionsParams: TROptionsParams, adaptOptions: TRAdaptOptions });
    }, [TRGetTransactions, TROptionsParams, TRAdaptOptions]);

    // Transactions cash logic TRC
    const { page: TRCPage, onPaginationChange: TRCOnPaginationChange } = usePagination(3);
    const {
        data: TRCData,
        isLoading: TRCIsLoading,
        error: TRCError,
        getTransactions: TRCGetTransactions,
    } = useTransactions({ clientId, portfolioId });
    const TRCOptionsParams = useMemo(() => ({
        page: TRCPage,
        PageSize: 10,
        SecurityType: 'Liquidity',
    }), [TRCPage]);
    const TRCAdaptOptions = useMemo(() => ({
        page: TRCPage,
        PageSize: 10,
        baseUrl: `/portfolios/${portfolioId}/position/${portfolio?.productId}`,
    }), [TRCPage, portfolio.productId, portfolioId]);

    useEffect(() => {
        TRCGetTransactions({ optionsParams: TRCOptionsParams, adaptOptions: TRCAdaptOptions });
    }, [TRCGetTransactions, TRCOptionsParams, TRCAdaptOptions]);

    // Cash flows CF
    const {
        data: CFData,
        isLoading: CFIsLoading,
        error: CFError,
        getCashFlows: CFGetCashFlows,
    } = usePortfolioSelector(cashFlowsSelector);
    const CFAdaptOptions = useMemo(() => ({
        historicalPerformance: portfolio?.HistoricalPerformance?.[0]?.Values,
    }), [portfolio]);

    useEffect(() => {
        if (CFAdaptOptions?.historicalPerformance) {
            CFGetCashFlows({ adaptOptions: CFAdaptOptions });
        }
    }, [CFGetCashFlows, CFAdaptOptions]);

    const {
        dataRaw: accounts,
    } = useAccounts(clientId, portfolioId);


    const accountAttributes = useMemo(() => {
        let attributes = {};

        if (accounts.length) {
            const encodedAttributes = accounts
                .find(({ Account }) => Account?.Attributes)?.Account?.Attributes;

            if (encodedAttributes) {
                attributes = JSON.parse(encodedAttributes);
            }
        }


        return attributes;
    }, [accounts]);

    // Renderers
    const renderInfobox = () => (
        <InfoboxWithButton buttonText={t('dashboard.review')} buttonProps={{ onClick: reviewProposals }}>
            {t('portfolio.newProposalMessage')}
        </InfoboxWithButton>
    );

    const isBlocked = useMemo(
        () => data?.portfolioStatusId === PORTFOLIO_BLOCKED_ID,
        [data?.portfolioStatusId],
    );

    const headerDescription = useMemo(() => (
        <>
            {(accessPolicyContacts || []).map(
                (item) => (
                    <span className="access-policy">
                        <span>{item?.contactName}</span>
                        <span>{`(${item?.accessPolicy})`}</span>
                    </span>
                ),
            )}
            <span className="access-policy">
                {valuationDate && formatDate(new Date(valuationDate), atramDateFormat)}
            </span>

        </>
    ), [accessPolicyContacts, valuationDate]);

    const positions = useMemo(
        () => adaptPositions(data.positions),
        [data?.positions],
    );

    const { lastPerformance } = usePortfolioDetailsPerformance(
        clientId,
        portfolioId,
        YTD,
    );
    const lastPerformanceValue = useMemo(() => lastPerformance, [lastPerformance]);

    const {
        isLoading: isLoadingFile,
        downloadFile,
    } = useDownloadPortfolioFile(clientId, portfolioId);

    const onDownloadFileClick = useCallback(
        () => downloadFile(performanceDateRange),
        [downloadFile, performanceDateRange],
    );

    return (
        <ContentBox underline={false} className="single-portfolio">
            <Preloader
                isLoading={isLoading || isLoadingAccessPolicyContacts}
                error={error || errorOrderBook || errorAccessPolicyContacts}
            >
                {orderBook && <Infobox>{t('portfolios.orderBookMessage')}</Infobox>}
                {(!isBlocked && dataProposals?.length > 0) && renderInfobox()}
                <PageHeader
                    title={data?.title}
                    breadCrumbsCurrent={data.backTitle}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            label: t('portfolios.title'),
                        },
                    ]}
                    description={headerDescription}
                >
                    {
                        isReportEnabled
                        && !hideReportToContactsList.includes(clientId)
                        && (
                            <Button
                                type="primary"
                                size="small"
                                loading={isLoadingFile}
                                onClick={onDownloadFileClick}
                                disabled={isReportGenerating}
                            >
                                {t(isReportGenerating
                                    ? 'clientDashboard.portfolio.report.generating'
                                    : 'clientDashboard.portfolio.download')}
                            </Button>
                        )}
                </PageHeader>
                <Overview
                    portfolioId={portfolioId}
                    productId={data?.productId}
                    data={data.overview}
                    disableChangeStrategy
                    disableChangeModel
                    accountAttributes={accountAttributes}
                    lastPerformanceValue={lastPerformanceValue}
                />
                <AccordionWrapper>
                    <Accordion activeKeys={activeKey} onChange={setActiveKey}>
                        <Panel
                            header={t('portfolios.allocation')}
                            key="2"
                            className="allocation"
                        >
                            <AllocationWithILT
                                data={investmentAllocations}
                                isLoading={isAllocationLoading}
                                error={allocationError}
                                isCustomFlow={isCustomFlow}
                            />
                        </Panel>
                        <Panel
                            header={t('portfolios.analysis')}
                            key="3"
                            className="performance"
                        >
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                oneProjectionColumn
                                showSustainabilityTab
                                isCustomFlow={isCustomFlow}
                            />
                        </Panel>
                        {!isBlocked && (
                            <Panel
                                header={t('portfolios.proposals')}
                                className="proposals"
                                key="4"
                                count={dataProposals.length || undefined}
                            >
                                <div ref={proposal}>
                                    <Proposals
                                        data={dataProposals}
                                        isLoading={isLoadingProposals}
                                        error={errorProposals}
                                    />
                                </div>
                            </Panel>
                        )}
                        <Panel
                            header={t('portfolios.portfolioHoldings')}
                            className="positions"
                            key="5"
                        >
                            <Positions
                                data={positions}
                                isLoading={isLoading}
                                error={error}
                                defaultExpandAllRows
                                productId={data?.productId}
                            />
                            <h3 className="disclosure">*net cash includes accounts receivables, accounts payables, indicative trades and cash in other currencies.</h3>
                        </Panel>
                        <Panel
                            header={t('portfolios.transactionsAndCashFlows')}
                            className="security-transactions"
                            key="6"
                        >
                            <Tabs className="transactions_cash-flows" defaultActiveKey="transactions">
                                <TabPane tab={t('portfolios.securityTransactions')} key="transactions">
                                    <Transactions
                                        isLoading={TRIsLoading}
                                        page={TRPage}
                                        onPaginationChange={TROnPaginationChange}
                                        data={TRData}
                                        error={TRError}
                                        portfolio={portfolio}
                                    />
                                </TabPane>
                                <TabPane tab={t('portfolios.cashTransactions')} key="cash-transactions">
                                    <TransactionsCash
                                        isLoading={TRCIsLoading}
                                        page={TRCPage}
                                        onPaginationChange={TRCOnPaginationChange}
                                        data={TRCData}
                                        error={TRCError}
                                        portfolio={portfolio}
                                    />
                                </TabPane>
                                <TabPane tab={t('portfolios.cashFlows')} key="cashFlows">
                                    <CashFlows
                                        isLoading={CFIsLoading}
                                        data={CFData}
                                        error={CFError}
                                        portfolio={portfolio}
                                    />
                                </TabPane>
                            </Tabs>
                        </Panel>
                        <Panel header={t('portfolios.pendingTrades')} className="pending-order" key="7">
                            <Table
                                className="positions-table"
                                data={orderBook || []}
                                columns={getPendingTradeColumns(t)}
                                expandedColumn="name"
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </AccordionWrapper>
            </Preloader>
        </ContentBox>
    );
}

PortfolioDirectionalAtram.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            fromReview: PropTypes.bool,
            scrollToProposals: PropTypes.bool,
        }),
    }).isRequired,
};

PortfolioDirectionalAtram.defaultProps = {
};

export default PortfolioDirectionalAtram;
