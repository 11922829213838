import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting, useLocale } from 'locale';
import { getPeriodByFilter } from 'utils';
import { adaptPortfolioDetailsPerformance } from '../adapters/adaptPortfolioDetailsPerformance';

export const usePortfolioDetailsPerformance = (clientId, portfolioId, filter) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([{}]);
    const [lastPerformance, setLastPerformance] = useState(null);
    const { i18n } = useTranslation();
    const { getFormattedNumber, getFormattedDate } = useFormatting();
    const { locale } = useLocale();

    useEffect(() => {
        const { startDate, endDate } = getPeriodByFilter(filter);
        const params = startDate === undefined
            ? {}
            : {
                from: startDate,
                to: endDate,
            };

        setLoading(true);
        ServiceManager.portfolioManagement('getPortfolioDetailsPerformance', [
            portfolioId,
            clientId,
            {
                language: i18n.language,
                ...params,
            },
        ])
            .then((response) => {
                try {
                    const adaptedData = adaptPortfolioDetailsPerformance(
                        response.data.Values,
                        getFormattedNumber,
                        getFormattedDate,
                    );

                    setData(adaptedData);
                    setLastPerformance(adaptedData?.[0]?.data?.pop()?.[1]);
                    setLoading(false);
                } catch (err) {
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setError(err.type !== undefined ? err : new ServerError(err));
                setLoading(false);
            });
    }, [clientId, portfolioId, filter, i18n.language, locale]);

    return {
        data, isLoading, error, lastPerformance,
    };
};
