import { useEffect } from 'react';
import GoogleAnalytics from './googleAnalitics';
import SM from '../ServiceManager';

const styles = {
    title: 'color: brown; font-size: 16px; font-weight: bold;',
    error: 'color: brown',
};

export const useGA = () => {
    useEffect(() => {
        const initialize = async () => {
            try {
                const config = await SM.middleware('getGoogleAnalytics');
                const GA_TAG = config?.data?.ga_tag;

                if (GA_TAG) {
                    globalThis.googleAnalytics = new GoogleAnalytics(GA_TAG, { disableUrls: ['/auth/.*'] });
                    globalThis.googleAnalytics.start();
                }
            } catch (err) {
                console.error(`%c⚠️ Error by GA initialize: %c ${err.message}`, styles.title, styles.error);
            }
        };

        initialize();
    }, []);
};
