import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { keyData } from 'constants/instrument';
import useFormatting from 'locale/useFormatting';
import Preloader from 'components/Preloader';
import { getClassNames } from 'utils';
import { useMetrics } from './useMetrics';
import './Metrics.css';

function Metrics(props) {
    const { positionId, additionalData } = props;
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedDate } = useFormatting();
    const { isLoading, data, error } = useMetrics({ positionId, additionalData });

    const metrics = keyData({
        data,
        t,
        getFormattedNumber,
        getFormattedDate,
    });

    const countOfRows = useMemo(() => {
        const elements = metrics?.length;
        const defaultRows = 3;

        if (elements <= defaultRows) {
            return elements;
        }

        if (elements > 9) {
            return 4;
        }

        return defaultRows;
    }, [metrics]);

    const countOfColumns = useMemo(() => {
        const elements = metrics?.length;
        const defaultColumns = 3;

        if (elements <= defaultColumns) {
            return 1;
        }

        if (elements >= defaultColumns && elements <= 6) {
            return 2;
        }

        if (elements >= 7 && elements <= 9) {
            return 3;
        }

        if (elements > 9) {
            return 4;
        }

        return defaultColumns;
    }, [metrics]);

    const tabletLastItem = useMemo(() => {
        const elements = metrics?.length;

        return Math.ceil(elements / 2);
    }, [metrics]);

    const className = useMemo(() => getClassNames(
        'metrics',
        `metricsRowsDesktop${countOfRows}`,
        `tabletLastItem${tabletLastItem}`,
        `metricsColumnsDesktop${countOfColumns}`,
    ),
    [countOfRows, tabletLastItem, countOfColumns]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <div className={className}>
                {metrics?.map((item) => (
                    <div className="field" key={item.name}>
                        <span className="field-name">{item.name}</span>
                        <span className="field-value">{item.value}</span>
                    </div>
                ))}
            </div>
        </Preloader>
    );
}

Metrics.propTypes = {
    additionalData: PropTypes.shape(
        {
            calculatedRisk: PropTypes.number,
            calculatedExpectedReturn: PropTypes.number,
            sharpeRatio: PropTypes.number,
            tradingPriceOfUnderlying: PropTypes.number,
            calculatedRiskOfUnderlying: PropTypes.number,
            name: PropTypes.string,
        },
    ).isRequired,
    positionId: PropTypes.number.isRequired,
};

export default Metrics;
