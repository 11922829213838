import ServiceManager from 'services/ServiceManager';
import { resourceAdapt } from '../adaptors/resourceAdapt';

class Backend {
    constructor() {
        this.resourceName = null;
        this.lang = {};
        this.saveManager = {};
        this.resourceAdapt = resourceAdapt;
    }

    init(services, backendOptions) {
        this.resourceName = backendOptions.resourceName;
        if (typeof backendOptions.SaveManager === 'function') {
            const nameSpace = services.resourceStore?.options?.ns?.[0] ?? 'translation';

            this.saveManager = new backendOptions.SaveManager(nameSpace);
        }
    }

    read(language, namespace, callback) {
        this.lang[language] = {};
        // const localResource = this.resourcesFromSessionStorage?.[language] ?? {};

        // if (Object.keys(localResource).length !== 0) {
        //     callback(null, this.resourcesFromSessionStorage[language]);
        // } else {
        //     this.loadResource(language, namespace, callback);
        // }
        this.loadResource(language, namespace, callback);
    }

    saveToSessionStorage(resources) {
        if (this.saveManager?.save) {
            this.saveManager.save({
                ...this.resourcesFromSessionStorage,
                ...resources,
            });
        }
    }

    get resourcesFromSessionStorage() {
        if (this.saveManager?.load) {
            return this.saveManager.load();
        }

        return {};
    }

    loadResource(language, namespace, callback) {
        ServiceManager.OOTBServices('getResourceSets', [this.resourceName, language])
            .then(({ data }) => {
                this.lang[language] = this.resourceAdapt(data);
                callback(null, this.lang[language]);
                this.saveToSessionStorage({ [language]: this.lang[language] });
            })
            .catch((e) => {
                callback(e, false);
            });
    }
}

Backend.type = 'backend';

export default Backend;
