import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { useFormatting } from 'locale';
import { useClientProfile } from 'prodivers/clientProfile';
import { adaptRMData } from '../adapters/adaptProfile';
import { getGeneralRoleKey } from '../../../../utils';
import { GENERAL_ROLE_KEYS, RM_ROLE_ALIASES } from '../../../../constants/constants';

export const useWithClientProfile = (clientId) => {
    const [errorMember, setError] = useState(null);
    const [isLoadingMember, setLoading] = useState(true);
    const [rmMemberData, setRmMember] = useState({});
    const [iwcMemberData, setIwcMember] = useState(null);
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { getFormattedPhoneNumber } = useFormatting();

    const { data: dataClient, isLoading, error } = useClientProfile();
    const data = useMemo(() => ({
        ...dataClient,
        RMProfile: rmMemberData,
        IWCProfile: iwcMemberData,
    }),
    [dataClient, rmMemberData, iwcMemberData]);

    const getMemberInfo = useCallback(async () => {
        let responseMemberPicture;

        try {
            const { data: allMembersData } = await ServiceManager.CustomLayerService('getMembersByRole', []);
            const { data: responseMember } = await ServiceManager.contactManagement('getContactMembers', [clientId]);

            const contactMemberIds = responseMember?.advisors?.map(({ id }) => id);
            const contactMembersData = allMembersData?.filter?.(
                ({ memberId }) => contactMemberIds.includes(memberId),
            );

            const iwcMemberId = contactMembersData?.find(
                ({ roleKey }) => GENERAL_ROLE_KEYS.iwc === getGeneralRoleKey(roleKey),
            )?.memberId;

            if (iwcMemberId) {
                try {
                    responseMemberPicture = await ServiceManager
                        .OOTBServices('clientServicesGetContactsAdvisorProfilePicture', [clientId, iwcMemberId]);
                } catch (err) {
                    // console.log('🚀 -> getMemberInfo -> error:', err);
                }

                const memberToDisplay = responseMember.advisors?.find(
                    ({ id }) => iwcMemberId === id,
                ) || {};

                try {
                    setIwcMember(adaptRMData(
                        memberToDisplay,
                        t,
                        getFormattedPhoneNumber,
                        responseMemberPicture?.data?.ImageContentBase64
                            || responseMemberPicture?.data?.imageContentBase64,
                    ));
                } catch (err) {
                    throw new AdapterError(err);
                }
            }

            const rmMemberId = contactMembersData?.filter(
                ({ roleKey }) => RM_ROLE_ALIASES.includes(getGeneralRoleKey(roleKey)),
            )?.[0]?.memberId;

            if (rmMemberId) {
                try {
                    responseMemberPicture = await ServiceManager
                        .OOTBServices('clientServicesGetContactsAdvisorProfilePicture', [clientId, rmMemberId]);
                } catch (err) {
                    // console.log('🚀 -> getMemberInfo -> err:', err);
                }
            }
            try {
                const memberToDisplay = responseMember.advisors?.find(
                    ({ id }) => rmMemberId === id,
                ) || responseMember?.advisors?.[0];

                setRmMember(adaptRMData(
                    memberToDisplay,
                    t,
                    getFormattedPhoneNumber,
                    responseMemberPicture?.data?.ImageContentBase64
                        || responseMemberPicture?.data?.imageContentBase64,
                ));
                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));
            setLoading(false);
        }
    }, [clientId, getFormattedPhoneNumber, i18n.language, t]);

    useEffect(() => {
        setError(null);
        setLoading(true);

        getMemberInfo();
    }, [getMemberInfo]);

    return {
        data,
        isLoading: isLoading || isLoadingMember,
        error: error || errorMember,
    };
};
