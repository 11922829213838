import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Table,
    ContentBox,
    Button,
    Infobox,
    Modal,
} from 'ui-library';
import history from 'services/history';
import PageSection from 'components/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import {
    orderBookSelector, portfolioSelector, usePortfolioSelector, tradingCardSelector,
    portfolioModifySelector,
} from 'domain/Portfolio';
import PageHeader from 'components/PageHeader';
import { modifyColumns, modifyMobileColumns } from '../constants';
import { MODIFY } from '../../SinglePortfolioView/pages/PositionDetails/constants';
import { onPreventRedirect } from '../../common/utils';
import { useModify } from '../hooks/useModify';

function ModifyPortfolioExecutionOnly(props) {
    const {
        contactId: clientId,
        match: { params: { portfolioId } },
    } = props;
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();
    const [isLoadingModify, setIsLoading] = useState(true);
    const [noChangesError, setNoChangesError] = useState(false);
    const [negativeCashAmount, setNegativeCashAmount] = useState(false);

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        deleteTradingCard, isDeleting, errorDeleting,
    } = usePortfolioSelector(tradingCardSelector);
    const {
        dataRaw: dataTradingCard, isLoading: isLoadingTradingCard, error: errorTradingCard,
        getModifiedPositions,
    } = usePortfolioSelector(portfolioModifySelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        Promise.all([getOrderBook(), getModifiedPositions()]).then(() => {
            setIsLoading(false);
        });
    }, [getOrderBook, getModifiedPositions]);


    // Data
    const baseUrl = `/portfolios/${portfolioId}/actions/modify/position/${data?.productId}`;
    const { modifiedData } = useModify({
        portfolio: data,
        portfolioPositions: dataRaw?.Positions,
        positionsModified: dataTradingCard?.OrderBookEntries,
        baseUrl,
    });

    useEffect(() => {
        if (!modifiedData?.isPositiveCashAmount) {
            setNegativeCashAmount(true);
        } else {
            setNegativeCashAmount(false);
        }
    }, [modifiedData?.isPositiveCashAmount, setNegativeCashAmount]);

    // Callbacks
    const onAddPosition = () => {
        history.push(`/portfolios/${portfolioId}/actions/modify/add-position`, {
            portfolioName: data.title,
            productId: data.productId,
        });
    };
    const onContinue = () => {
        if (dataTradingCard?.OrderBookEntries?.length === 0) {
            setNoChangesError(true);

            return;
        }
        if (!modifiedData?.isPositiveCashAmount) {
            return;
        }

        sessionStorage.setItem(MODIFY, JSON.stringify({
            positions: modifiedData.positionsAllocation,
        }));
        history.push(`/portfolios/${portfolioId}/actions/modify/confirmation`);
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                sessionStorage.removeItem(MODIFY);
                deleteTradingCard(clientId, portfolioId);
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    // Renderers
    const disableSubmitButton = orderBook;
    const isLoadingCommon = isLoading || isLoadingModify
        || isLoadingTradingCard || isDeleting || isLoadingOrderBook;

    return (
        <PageSection className="single-portfolio-edit">
            <Preloader
                isLoading={isLoadingCommon}
                error={error || errorTradingCard || errorDeleting || errorOrderBook}
            >
                <ContentBox underline={false}>
                    <PageHeader
                        breadCrumbsChildren={[
                            {
                                to: '/portfolios',
                                onClick: (e) => onPreventRedirect(e, '/portfolios/', onCancel),
                                label: t('portfolios.title'),
                            },
                            {
                                to: `/portfolios/${portfolioId}`,
                                onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                                label: data?.title,
                            },
                        ]}
                        title={t('portfolios.modify')}
                        breadCrumbsCurrent={t('portfolios.modify')}
                    >
                        <div className="buttons-block">
                            <Button type="secondary" size="small" onClick={onAddPosition}>
                                {t('portfolios.addPosition')}
                            </Button>
                        </div>
                    </PageHeader>
                    <EmptyContent data={data.positions} text={t('portfolios.noDataFound')}>
                        <div className="content-wrapper">
                            <Table
                                columns={isMobileSize ? modifyMobileColumns(t) : modifyColumns(t)}
                                data={modifiedData.positions}
                                expandedColumn="Name"
                                defaultExpandAllRows
                            />
                        </div>
                        <ButtonsBlockRow
                            leftButton={{
                                type: 'danger',
                                text: t('portfolios.cancel'),
                                onClick: () => onCancel(`/portfolios/${portfolioId}`),
                            }}
                            primaryButton={{
                                text: t('portfolios.continue'),
                                disabled: disableSubmitButton,
                                onClick: onContinue,
                            }}
                        >
                            {negativeCashAmount && (<Infobox error>{t('portfolios.infoTextModify')}</Infobox>)}
                            {noChangesError && (<Infobox error>{t('portfolios.modify.noChangesError')}</Infobox>)}
                        </ButtonsBlockRow>
                    </EmptyContent>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

ModifyPortfolioExecutionOnly.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

ModifyPortfolioExecutionOnly.defaultProps = {};

export default ModifyPortfolioExecutionOnly;
