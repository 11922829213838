import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Table, ContentBox, Modal,
} from 'ui-library';
import history from 'services/history';
import PageHeader from 'components/PageHeader';
import PageSection from 'components/PageSection';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import WidgetError from 'components/WidgetError';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useFormatting, useLocale } from 'locale';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import {
    orderBookSelector, portfolioSelector, usePortfolioSelector, portfolioQuickEditSelector,
} from 'domain/Portfolio';
import { quickColumns, quickMobileColumns, QUICK_EDIT } from '../constants';
import { onPreventRedirect } from '../../common/utils';
import { adaptPositions, adaptPositionsMaxLength, adaptQuickEditPositions } from '../adapters/adaptQuickEditPositions';

function QuickEditAdvisory(props) {
    const {
        match: { params: { portfolioId } },
    } = props;
    const { t } = useTranslation();
    const { getFormattedCurrency, getFormattedNumber } = useFormatting();
    const { numberSeparators } = useLocale();
    const { isMobileSize } = useDetectedMobileDevice();
    const storedPositions = useMemo(() => JSON.parse(sessionStorage.getItem(QUICK_EDIT)), []);

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        positionsAdapted, dataSummary, changes: positionsChanged,
        initializePositions, optimizePositions,
    } = usePortfolioSelector(portfolioQuickEditSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook();
    }, [getOrderBook]);

    const baseUrl = `/portfolios/${portfolioId}/actions/quick-edit/position/${data?.productId}`;
    const positionsForQuickEdit = useMemo(() => adaptPositions({
        positions: dataRaw?.Positions,
        baseUrl,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    }), [baseUrl, dataRaw.Positions, getFormattedCurrency, getFormattedNumber, t]);
    const positionsMaxLength = useMemo(() => adaptPositionsMaxLength({
        positions: positionsAdapted?.positions,
        maxLength: isMobileSize ? 20 : 35,
    }), [positionsAdapted?.positions, isMobileSize]);
    const adaptQuickEdit = useMemo(() => adaptQuickEditPositions({
        portfolioValue: data?.portfolioValue, baseUrl, t, getFormattedNumber, getFormattedCurrency,
    }), [baseUrl, data.portfolioValue, getFormattedCurrency, getFormattedNumber, t]);

    useEffect(() => {
        if (positionsForQuickEdit) {
            initializePositions({
                changes: storedPositions,
                positions: positionsForQuickEdit,
                adaptData: adaptQuickEdit,
            });
        }
    }, [initializePositions, positionsForQuickEdit, adaptQuickEdit, storedPositions]);

    // Callbacks
    const total = +dataSummary?.New?.toFixed(2);
    const disableOptimizeButton = total === 100;
    const disableSubmitButton = orderBook || total !== 100;
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                sessionStorage.removeItem(QUICK_EDIT);
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onContinue = () => {
        if (total === 100) {
            sessionStorage.setItem(QUICK_EDIT, JSON.stringify(positionsChanged));
            history.push(`/portfolios/${portfolioId}/actions/quick-edit/confirmation`);
        }
    };
    const tableColumns = isMobileSize
        ? quickMobileColumns(isMobileSize, t, numberSeparators)
        : quickColumns(t, getFormattedNumber, numberSeparators);

    return (
        <PageSection className="single-portfolio-edit">
            <Preloader isLoading={isLoading || isLoadingOrderBook} error={error || errorOrderBook}>
                <ContentBox underline={false}>
                    <PageHeader
                        breadCrumbsChildren={[
                            {
                                to: '/portfolios',
                                onClick: (e) => onPreventRedirect(e, '/portfolios/', onCancel),
                                label: t('portfolios.title'),
                            },
                            {
                                to: `/portfolios/${portfolioId}`,
                                onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                                label: data?.title,
                            },
                        ]}
                        title={t('portfolios.quickEdit')}
                        breadCrumbsCurrent={t('portfolios.quickEdit')}
                    />
                    <EmptyContent data={positionsAdapted.positions} text={t('portfolios.noDataFound')}>
                        <div className="content-wrapper">
                            <Table
                                columns={tableColumns}
                                data={positionsMaxLength}
                                footer={positionsAdapted.footer}
                                expandedColumn="Name"
                                defaultExpandAllRows
                            />
                        </div>
                        <ButtonsBlockRow
                            leftButton={{
                                type: 'danger',
                                text: t('portfolios.cancel'),
                                onClick: () => onCancel(`/portfolios/${portfolioId}`),
                            }}
                            additionalButton={{
                                text: t('portfolios.portfolioEdit.optimize'),
                                disabled: disableOptimizeButton,
                                onClick: optimizePositions,
                            }}
                            primaryButton={{
                                text: t('portfolios.continue'),
                                disabled: disableSubmitButton,
                                onClick: onContinue,
                            }}
                        >
                            {total !== 100 && (
                                <WidgetError accent error message={t('portfolios.errorSum')} />
                            )}
                        </ButtonsBlockRow>
                    </EmptyContent>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

QuickEditAdvisory.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

QuickEditAdvisory.defaultProps = {};

export default QuickEditAdvisory;
