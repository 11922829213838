import { OVERVIEW_SIZE_LAGRE } from '../../Overview';
import { TYPE_PERCENT, TYPE_RATIO } from '../../../utils/formatting';

export const adaptPositionOverview = ({
    price, priceType, currency, isin, t, portfolioData,
}) => [
    {
        columnsData: [
            {
                overviewItemsInfo: [
                    {
                        title: t('overview.price'),
                        value: price,
                        type: priceType,
                        currency: currency?.isoCode,
                        additionalValue: portfolioData.price,
                        currencySize: OVERVIEW_SIZE_LAGRE,
                    },
                    {
                        title: t('overview.isin'),
                        value: isin,
                    },
                ],
            },
            {
                overviewItemsInfo: [
                    {
                        title: t('overview.costPrice'),
                        value: portfolioData.costPrice,
                        type: priceType,
                        currency: portfolioData.currency,
                        additionalValue: portfolioData.costPriceInCurrency,
                        currencySize: OVERVIEW_SIZE_LAGRE,
                    },
                    {
                        title: t('overview.units'),
                        value: portfolioData.units,
                    },
                ],
            },
            {
                overviewItemsInfo: [
                    {
                        title: t('overview.totalValue'),
                        value: portfolioData.totalValue,
                        currency: currency?.isoCode,
                        type: TYPE_RATIO,
                        additionalValue: portfolioData.totalValueInCurrency,
                        currencySize: OVERVIEW_SIZE_LAGRE,
                    },
                    {
                        title: t('overview.portfolioAllocation'),
                        value: portfolioData.allocation,
                        type: TYPE_PERCENT,
                    },
                ],
            },
            {
                overviewItemsInfo: [
                    {
                        title: t('overview.absolutePerformanceSI'),
                        value: portfolioData.performanceAbsolute,
                        type: TYPE_RATIO,
                        currency: portfolioData.currency,
                        colored: true,
                        currencySize: OVERVIEW_SIZE_LAGRE,
                    },
                    {
                        title: t('overview.performanceSI'),
                        value: portfolioData.performanceSi,
                        type: TYPE_PERCENT,
                        colored: true,
                    },
                ],
            },
        ],
    },
];
