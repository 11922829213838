import React from 'react';
import { useTranslation } from 'react-i18next';
import LogoLight from 'assets/images/logo-light.png';
import LogoDark from 'assets/images/logo-dark.png';
import LogoLightMobile from 'assets/images/logo-light-mobile.png';
import LogoDarkMobile from 'assets/images/logo-dark-mobile.png';
import { useTheme } from 'theming';
import './Logo.css';
import { useDetectedMobileDevice } from '../../hooks/useDetectedMobileDevice';

export const Logo = () => {
    const { t } = useTranslation();

    const { theme } = useTheme();
    const { isMobileSize } = useDetectedMobileDevice();

    const themeLogo = theme.includes('dark') ? LogoDark : LogoLight;
    const mobileThemeLogo = theme.includes('dark') ? LogoDarkMobile : LogoLightMobile;

    return (
        <div>
            <img
                src={isMobileSize ? mobileThemeLogo : themeLogo}
                alt={t('login.title')}
                className="logo-icon"
            />
        </div>
    );
};
