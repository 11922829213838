import {
    CHANGE_STRATEGY, MODIFY, PROPOSAL,
    QUICK_EDIT, REBALANCE, CHANGE_MODEL,
} from 'constants/constants';
import {
    formatValueByType, TYPE_PERCENT, TYPE_RATIO,
} from '../utils/formatting';


export const mapActionTypeName = (action, t) => {
    const actionsMap = {
        [CHANGE_STRATEGY]: t('overview.changeStrategy'),
        [CHANGE_MODEL]: t('overview.changeModel'),
        [QUICK_EDIT]: t('portfolios.quickEdit'),
        [MODIFY]: t('portfolios.modify'),
        [REBALANCE]: t('portfolios.rebalance'),
        [PROPOSAL]: t('proposal.reviewProposal'),
    };

    return actionsMap[action] || null;
};

const getMoneyness = (moneyness, t) => {
    if (moneyness > 0) { return t('position.keyData.moneyness.in'); }
    if (moneyness === 0) { return t('position.keyData.moneyness.at'); }
    if (moneyness < 0) { return t('position.keyData.moneyness.out'); }

    return moneyness;
};

export const LiquidityId = 1;
export const MortgageId = 8;
export const IndexId = 10;

export const BondId = 2;
export const EquityId = 3;
export const FundId = 4;
export const OptionId = 5;
export const FutureId = 6;
export const CommodityId = 7;
export const MoneyMarketId = 9;
export const StructuredProductId = 11;

export const CoreTypeId = 12;
export const SatelliteTypeId = 13;

export const keyDataEquity = (data, t, getFormattedNumber) => ([
    { name: t('position.keyData.expReturn'), value: formatValueByType(data?.expReturn, TYPE_PERCENT, false, getFormattedNumber) },
    { name: t('position.keyData.expRisk'), value: formatValueByType(data?.expRisk, TYPE_PERCENT, false, getFormattedNumber) },
    { name: t('position.keyData.sharpeRatio'), value: formatValueByType(data?.sharpeRatio, TYPE_RATIO, false, getFormattedNumber) },
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataBond = (data, t, getFormattedNumber, getFormattedDate) => ([
    { name: t('position.keyData.nominalValue'), value: `${formatValueByType(data?.nominalValue, TYPE_RATIO, false, getFormattedNumber)} ${data?.currency}` },
    { name: t('position.keyData.coupon'), value: formatValueByType(data?.coupon, TYPE_PERCENT, false, getFormattedNumber) },
    { name: t('position.keyData.couponFrequency'), value: data?.couponFrequency },
    { name: t('position.keyData.maturityDate'), value: getFormattedDate(data?.bondMaturityDate) },
    { name: t('position.keyData.issuerType'), value: data?.issuerType },
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataFund = (data, t, getFormattedNumber) => ([
    { name: t('position.keyData.expReturn'), value: formatValueByType(data?.expReturn, TYPE_PERCENT, false, getFormattedNumber) },
    { name: t('position.keyData.expRisk'), value: formatValueByType(data?.expRisk, TYPE_PERCENT, false, getFormattedNumber) },
    { name: t('position.keyData.sharpeRatio'), value: formatValueByType(data?.sharpeRatio, TYPE_RATIO, false, getFormattedNumber) },
    { name: t('position.keyData.fundDomicile'), value: data?.fundDomicile },
    { name: t('position.keyData.fundCurrency'), value: data?.fundCurrency },
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataOption = (data, t, getFormattedNumber, getFormattedDate) => ([
    { name: t('position.keyData.strikePrice'), value: `${formatValueByType(data?.strikePrice, TYPE_RATIO, false, getFormattedNumber)} ${data?.currency}` },
    { name: t('position.keyData.securityType'), value: data?.optionType ? t('position.keyData.securityType.put') : t('position.keyData.securityType.call') },
    { name: t('position.keyData.style'), value: data?.style },
    { name: t('position.keyData.underlying'), value: data?.underlying },
    { name: t('position.keyData.maturityDate'), value: getFormattedDate(data?.optionMaturityDate) },
    { name: t('position.keyData.moneyness'), value: getMoneyness(data?.moneyness, t) },
    { name: t('position.keyData.volatilityOfUnderlying'), value: formatValueByType(data?.volatilityOfUnderlying, TYPE_PERCENT, false, getFormattedNumber) },
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataFuture = (data, t, getFormattedNumber, getFormattedDate) => ([
    { name: t('position.keyData.underlying'), value: data?.underlying },
    { name: t('position.keyData.maturityDate'), value: getFormattedDate(data?.futureMaturityDate) },
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataCommodity = (data, t) => ([
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataMoneyMarket = (data, t) => ([
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataStructuredProduct = (data, t) => ([
    { name: t('position.keyData.riskCategory'), value: data?.riskCategory },
]);

export const keyDataByType = {
    [EquityId]: keyDataEquity,
    [FundId]: keyDataFund,
    [BondId]: keyDataBond,
    [OptionId]: keyDataOption,
    [FutureId]: keyDataFuture,
    [CommodityId]: keyDataCommodity,
    [MoneyMarketId]: keyDataMoneyMarket,
    [StructuredProductId]: keyDataStructuredProduct,
};

export const keyData = ({
    data, t, getFormattedNumber, getFormattedDate,
}) => {
    const keyDataObj = keyDataByType[data?.typeId];

    return keyDataObj
        ? keyDataObj(data, t, getFormattedNumber, getFormattedDate) : null;
};

export const PERCENTAGE = 'percentage';
export const INTEGER = 'integer';
