import { useCallback, useReducer } from 'react';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';

const initialState = {
    data: null,
    error: null,
    isLoading: false,
    dataDocument: null,
    errorDocument: null,
    isLoadingDocument: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setDataDocument':
            return { ...state, dataDocument: action.payload };
        case 'setErrorDocument':
            return { ...state, errorDocument: action.payload };
        case 'setIsLoadingDocument':
            return { ...state, isLoadingDocument: action.payload };
        default:
            return state;
    }
};

export const useDocuments = (options) => {
    const { instrumentId: instrumentIdOption } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const getDocuments = useCallback(async (params) => {
        const {
            instrumentUId,
        } = params;

        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const config = {
                contractId: instrumentUId,
                page: 1,
                pageSize: 100,
                isActive: true,
                searchBy: 'IncludeShared',
            };
            const response = await SM.documents('postDocumentsList', [config]);

            dispatch({ type: 'setData', payload: response?.data });
            dispatch({ type: 'setIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [instrumentIdOption]);
    const getDocumentById = useCallback(async (params) => {
        const {
            documentId,
        } = params;

        dispatch({ type: 'setErrorDocument', payload: null });
        dispatch({ type: 'setIsLoadingDocument', payload: true });

        try {
            const response = await SM.documents('getDocumentById', [documentId]);

            dispatch({ type: 'setDataDocument', payload: response?.data });
            dispatch({ type: 'setIsLoadingDocument', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setErrorDocument', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoadingDocument', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [instrumentIdOption]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getDocuments,
        dataDocument: state.dataDocument,
        errorDocument: state.errorDocument,
        isLoadingDocument: state.isLoadingDocument,
        getDocumentById,
    };
};
