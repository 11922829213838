import { useContext } from 'react';
import RiskProfileContext from './context';

const useSelector = (callback) => {
    const { getValue } = useContext(RiskProfileContext);

    if (getValue === undefined) {
        throw new Error('useOnBoarding can only be used inside RiskProfileProvider');
    }

    return getValue(callback);
};

export default useSelector;
