import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { contactIdSelector } from 'redux/auth/authSelectors';
import Preloader from 'components/Preloader';
import { usePortfolio } from 'domain/Portfolio';
import {
    ADVISORY,
    DISCRETIONARY,
    EXECUTION_ONLY,
    generateObject,
    DEPOSITS,
    ATRAM_DISCRETIONARY,
    ATRAM_DIRECTIONAL,
} from 'constants/portfolioProducts';
import { PageNotFound } from 'pages/pages/404';
import ModifyPortfolioAdvisory from './products/ModifyPortfolioAdvisory';
import ModifyPortfolioExecutionOnly from './products/ModifyPortfolioExecutionOnly';
import './ModifyPortfolio.css';

const ModifyPortfolioByProduct = generateObject({
    [ADVISORY]: ModifyPortfolioAdvisory,
    [DISCRETIONARY]: PageNotFound,
    [EXECUTION_ONLY]: ModifyPortfolioExecutionOnly,
    [DEPOSITS]: ModifyPortfolioAdvisory,
    [ATRAM_DISCRETIONARY]: PageNotFound,
    [ATRAM_DIRECTIONAL]: PageNotFound,
});

function ModifyPortfolio(props) {
    const { portfolio: { productId, error } } = usePortfolio();
    const ModifyPortfolioPage = ModifyPortfolioByProduct[productId] || (() => '');

    return (
        <Preloader isLoading={productId === undefined} error={error}>
            <ModifyPortfolioPage {...props} />
        </Preloader>
    );
}

ModifyPortfolio.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ModifyPortfolio.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(ModifyPortfolio);
