import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import {
    ContentBox,
    Row,
    Column,
    Modal,
    Accordion,
    AccordionPanel as Panel,
    Table,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import { contactIdSelector } from 'redux/auth/authSelectors';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import WidgetError from 'components/WidgetError';
import Preloader from 'components/Preloader';
import { portfolioSelector, usePortfolioSelector } from 'domain/Portfolio';
import AccordionWrapper from 'components/AccordionWrapper';
import PageHeader from 'components/PageHeader';
import { mapActionTypeName } from 'constants/instrument';
import { getDocumentsColumns } from '../../common/constants';
import { useExecuteStrategy } from '../../common/hooks/useExecuteStrategy';
import Transactions from '../../common/components/Transactions';
import { useSecurityTransactions } from '../../common/hooks/useSecurityTransactions';
import { useStrategyDocuments } from '../../../hooks/useStrategyDocuments';
import { QUICK_EDIT } from '../constants';
import { useQuickEdit } from '../hooks/useQuickEdit';
import { onPreventRedirect } from '../../common/utils';

function DetailedOrders(props) {
    const {
        contactId: clientId,
        match: {
            params: { portfolioId },
        },
    } = props;
    const { t } = useTranslation();

    // Portfolio Domain
    const {
        data, isLoading, error, dataRaw,
    } = usePortfolioSelector(portfolioSelector);


    const baseUrlPosition = `/portfolios/${portfolioId}/actions/quick-edit/position/${data?.productId}`;
    const positionsEdited = useMemo(() => JSON.parse(sessionStorage.getItem(QUICK_EDIT)), []);

    const { modelData, clearQuickEditLocally } = useQuickEdit({
        portfolio: data,
        portfolioPositions: dataRaw?.Positions,
        positionsEdited,
        baseUrl: baseUrlPosition,
    });

    // Hooks
    const {
        securityTransactionsData,
        isLoadingSecurityTransactions,
        errorSescurityTransactions,
    } = useSecurityTransactions({
        currencyId: data?.currencyId,
        investmentAllocations: modelData?.investmentAllocations,
        sourceAllocations: data?.investmentAllocations,
        amount: data?.portfolioValue,
        portfolioCurrency: data?.currency,
        portfolioId,
        productID: data?.productId,
        clientId,
        actionType: QUICK_EDIT,
    });
    const { documents, errorDocuments, isLoadingDocuments } = useStrategyDocuments(
        data.productId,
    );

    const { onAcceptExecute, isAccepting, errorAccepting } = useExecuteStrategy(
        clientId,
        portfolioId,
        QUICK_EDIT,
    );

    // Callbacks
    const baseURL = `/portfolios/${portfolioId}`;

    const onBack = () => {
        history.push(`${baseURL}/actions/quick-edit/confirmation`);
    };
    const onExecuteChanges = () => {
        const executeParams = { allocations: modelData.investmentAllocations };

        Modal.confirm({
            title: t('confirmation.executeChanges'),
            content: t('confirmation.executeChangesContent'),
            okText: t('confirmation.executeChanges'),
            onOk: () => {
                clearQuickEditLocally();
                onAcceptExecute(executeParams);
            },
            cancelText: t('confirmation.cancel'),
            className: 'execute',
        });
    };

    const onCancel = (link) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                clearQuickEditLocally();
                history.push(link);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    return (
        <Row className="change-strategy">
            <Column size="12">
                <Preloader isLoading={isLoading} error={error}>
                    <ContentBox underline={false}>
                        <PageHeader
                            title={t('confirmation.ordersToBeExecuted')}
                            breadCrumbsCurrent={t('confirmation.ordersToBeExecuted')}
                            breadCrumbsChildren={[
                                {
                                    to: '/portfolios',
                                    onClick: (e) => onPreventRedirect(e, '/portfolios', onCancel),
                                    label: t('portfolios.title'),
                                },
                                {
                                    to: `/portfolios/${portfolioId}`,
                                    onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                                    label: data?.title,
                                },
                                {
                                    to: `/portfolios/${portfolioId}/actions/quick-edit`,
                                    label: mapActionTypeName(QUICK_EDIT, t),
                                },
                            ]}
                        />
                        <AccordionWrapper className="confirmation">
                            <Accordion defaultActiveKey={['1', '2']}>
                                <Panel
                                    header={t('confirmation.ordersToBeExecuted')}
                                    className="security-transactions"
                                    key="1"
                                >
                                    <Transactions
                                        data={securityTransactionsData}
                                        isLoading={isLoadingSecurityTransactions}
                                        error={errorSescurityTransactions}
                                        defaultExpandAllRows
                                    />
                                </Panel>
                                <Panel
                                    header={t('confirmation.documents')}
                                    className="documents"
                                    key="2"
                                >
                                    <Preloader
                                        isLoading={isLoadingDocuments}
                                        error={errorDocuments}
                                    >
                                        <Table columns={getDocumentsColumns(t)} data={documents} />
                                    </Preloader>
                                </Panel>
                            </Accordion>
                            {errorAccepting && (
                                <WidgetError accent error message={errorAccepting.message} />
                            )}
                        </AccordionWrapper>
                        <ButtonsBlockRow
                            leftButton={{
                                text: t('confirmation.back'),
                                onClick: onBack,
                            }}
                            primaryButton={{
                                text: t('confirmation.execute'),
                                loading: isAccepting,
                                onClick: onExecuteChanges,
                            }}
                        />
                    </ContentBox>
                </Preloader>
            </Column>
        </Row>
    );
}

DetailedOrders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
    contactId: PropTypes.number.isRequired,
};

DetailedOrders.defaultProps = {};

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
});

export default connect(mapStateToProps)(DetailedOrders);
