export const groups = {
    CONTACT_MANAGEMENT: 'ContactManagement',
    AUTHENTICATION: 'Authentication',
    MOBILE_ADVISOR: 'MobileAdvisor',
    CLIENT_COCKPIT: 'ClientCockpit',
};

export const keys = {
    // Belong to "ContactManagement" group
    IS_USER_NAME_SAME_WITH_EMAIL: 'IsUserNameSameWithEmail',
    CONTACT_ACCESS_TOKEN_LIFETIME: 'ContactAccessTokenLifetime',
    IS_REPORT_ENABLED: 'IsReportEnabled',
    IS_REPORT_GENERATING: 'IsReportGenerating',
    HIDE_REPORT_TO_CONTACTS_LIST: 'HideReportToContactsList',
};
