import commonErrorMessageToErrorModelXref from 'error/commonErrors';
import {
    EMAIL_INVALID,
    EMAIL_MULTIPLE_CONTACTS,
    USERNAME_AND_EMAIL_INVALID,
} from 'static/requestResults';
import { ErrorModel } from 'error/ErrorModel';

export const FORGOT_PASSWORD_ERROR = 'ForgotPasswordError';

const USERNAME_AND_EMAIL_INVALID_MESSAGE = 'error.invalidUsernameAndEmailMessage';
const EMAIL_INVALID_MESSAGE = 'error.invalidEmail';
const EMAIL_MULTIPLE_CONTACTS_MESSAGE = 'error.multipleContactsMessage';

const errorMessageToErrorModelXref = {
    ...commonErrorMessageToErrorModelXref,
    [USERNAME_AND_EMAIL_INVALID]: new ErrorModel({
        id: 17,
        type: FORGOT_PASSWORD_ERROR,
        message: USERNAME_AND_EMAIL_INVALID_MESSAGE,
    }),
    [EMAIL_INVALID]: new ErrorModel({
        id: 18,
        type: FORGOT_PASSWORD_ERROR,
        message: EMAIL_INVALID_MESSAGE,
    }),
    [EMAIL_MULTIPLE_CONTACTS]: new ErrorModel({
        id: 19,
        type: FORGOT_PASSWORD_ERROR,
        message: EMAIL_MULTIPLE_CONTACTS_MESSAGE,
    }),
};

export const getErrorModelByStatus = (status) => errorMessageToErrorModelXref[status];
