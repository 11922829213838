import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import { Button, Infobox, Title } from 'ui-library';
import { contactIdSelector, preAuthenticateSessionIdSelector, usernameSelector } from 'redux/auth/authSelectors';
import { PENDING, SUCCESS } from 'static/requestResults';
import ServiceManager from 'services/ServiceManager';
import { status2FASuccess } from 'redux/auth/authActions';
import { getErrorModelByStatus, LOGIN_FAILED_UNEXPECTEDLY } from 'error/authErrors';
import { withTranslation } from 'react-i18next';
import { RememberMe } from '../RememberMe/RememberMe';

const REMEMBER_ME = 'REMEMBER_ME';
const QRCode = 'QRCode';

export class LoginQRCode extends Component {
    #timeoutID;

    constructor(props) {
        super(props);

        this.state = {
            qrCode: undefined,
            view: REMEMBER_ME,
            error: undefined,
            isTrustedDevice: false,
        };
        this.cancelAuthenticate = () => {};
    }

    componentWillUnmount() {
        this.cancelAuthenticate();
        clearInterval(this.#timeoutID);
    }

    authenticate = (isTrustedDevice) => {
        const { contactId, preAuthenticateSessionId } = this.props;

        this.setState({
            view: QRCode,
            isTrustedDevice,
        });
        ServiceManager.Security('authenticate', [{
            urlParams: { contactId },
            payload: {
                SetTrusted: isTrustedDevice,
                PreAuthenticateSessionId: preAuthenticateSessionId,
            },
        }])
            .then((response) => {
                const authStatusSessionId = get('data.AuthStatusSessionId', response);
                const resultType = get('data.Result', response);
                const error = getErrorModelByStatus(resultType);

                if (error !== undefined) {
                    this.setState({
                        error: error.message,
                    });
                } else if (resultType === SUCCESS) {
                    this.setState({
                        qrCode: get('data.AsynchronousParameters.QrCodeUrl', response),
                    });
                    this.#timeoutID = setTimeout(
                        this.authenticateStatusCheck,
                        3000,
                        authStatusSessionId,
                    );
                } else {
                    this.setState({
                        error: getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY).message,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    error: getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY).message,
                });
            });
    };

    authenticateStatusCheck = async (authStatusSessionId) => {
        const { username, onSuccess, qrCodeLoginSuccess } = this.props;

        const [promise, cancel] = await ServiceManager.Security('authenticateStatus', [{
            payload: {
                UserName: username,
                AuthStatusSessionId: authStatusSessionId,
            },
        }]);

        this.cancelAuthenticate = cancel;
        promise
            .then((response) => {
                const resultType = get('data.Result', response);
                const error = getErrorModelByStatus(resultType);

                if (error !== undefined) {
                    this.setState({
                        error: error.message,
                    });
                } else if (resultType === SUCCESS) {
                    clearInterval(this.#timeoutID);

                    qrCodeLoginSuccess(response.data);
                    onSuccess();
                } else if (resultType === PENDING) {
                    this.#timeoutID = setTimeout(
                        this.authenticateStatusCheck,
                        3000,
                        authStatusSessionId,
                    );
                } else {
                    this.setState({
                        error: getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY).message,
                    });
                }
            })
            .catch(() => {
                this.setState({
                    error: getErrorModelByStatus(LOGIN_FAILED_UNEXPECTEDLY).message,
                });
            });
    };

    activateOfflineMode = () => {
        const { activateOfflineMode } = this.props;
        const { isTrustedDevice } = this.state;

        activateOfflineMode({
            isTrustedDevice,
        });
    };

    render() {
        const { qrCode, error, view } = this.state;
        const { t } = this.props;

        let errorContent = null;

        if (error !== undefined && error !== null) {
            errorContent = (
                <div className="validation-summary-errors">
                    <Infobox error>{t(error)}</Infobox>
                </div>
            );
        }

        return view === QRCode ? (
            <div className="content-pane">
                <Title type={2}>{t('enrollDevice.twoFactorActivation')}</Title>
                {errorContent}
                <div className="info-text">
                    {t('enrollDevice.scanQRCode')}
                    <br />
                    {t('enrollDevice.ifYourMobileIsOffline')}
                    <br />
                    <br />
                    <div className="buttons buttons-inline button-group button-group--horiz">
                        <Button type="primary" htmltype="submit" onClick={this.activateOfflineMode}>
                            {t('enrollDevice.clickHere')}
                        </Button>
                    </div>
                </div>
                <div className="qr-code" style={{ backgroundImage: `url(${qrCode})` }} />
            </div>
        ) : (
            <RememberMe
                title={t('enrollDevice.twoFactorApproval')}
                isInline={false}
                onSubmit={this.authenticate}
                t={t}
            />
        );
    }
}

LoginQRCode.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    activateOfflineMode: PropTypes.func.isRequired,
    contactId: PropTypes.number.isRequired,
    qrCodeLoginSuccess: PropTypes.func.isRequired,
    preAuthenticateSessionId: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

LoginQRCode.defaultProps = {};

LoginQRCode.displayName = 'LoginQRCode';

const mapStateToProps = (state) => ({
    contactId: contactIdSelector(state),
    username: usernameSelector(state),
    preAuthenticateSessionId: preAuthenticateSessionIdSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    qrCodeLoginSuccess: (data) => dispatch(status2FASuccess(data)),
});

const Translated = withTranslation()(LoginQRCode);

export default connect(mapStateToProps, mapDispatchToProps)(Translated);
