import React from 'react';
import { useTranslation } from 'react-i18next';

export const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <div className="detail_page">
            <div className="detail_content content-pane t-center">
                <h1>{t('common.PAGE_NOT_FOUND')}</h1>
            </div>
        </div>
    );
};
