/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { TextInput } from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// TODO: deal with unused vars
export const FieldRenderer = ({
    input,
    label,
    type,
    id,
    // eslint-disable-next-line no-unused-vars
    name,
    // eslint-disable-next-line no-unused-vars
    autoComplete,
    // eslint-disable-next-line no-unused-vars
    meta: {
        touched, error, submitting,
    },
}) => {
    const { t } = useTranslation();

    return (
        <TextInput
            {...input}
            label={label}
            placeholder={label}
            error={touched === true ? t(error) : undefined}
            id={id}
            type={type}
            disabled={submitting}
        />
    );
};

FieldRenderer.propTypes = {
    autoComplete: PropTypes.string,
    input: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
        warning: PropTypes.string,
        submitting: PropTypes.bool,
    }),
};

FieldRenderer.defaultProps = {
    autoComplete: 'on',
    name: null,
    meta: PropTypes.shape({}),
};

FieldRenderer.displayName = 'FieldRenderer';
