const getCustomPropValue = (prop) => (
    window.getComputedStyle(document.documentElement).getPropertyValue(prop)
);

const setCustomPropValue = (key, val) => document.documentElement.style.setProperty(key, val);

export const adaptColorsToSave = (values) => {
    const colors = {};

    Object.keys(values).forEach((group) => {
        const groupValues = values[group];

        Object.keys(groupValues).forEach((value) => {
            colors[`--${value}`] = groupValues[value];
        });
    });

    return colors;
};

export const setValuesToRoot = (values) => {
    Object.keys(values).forEach((i) => {
        setCustomPropValue(i, values[i]);
    });
};

export const getInitialValuesFromRoot = (values) => {
    const state = {};

    Object.keys(values).forEach((group) => {
        const groupValues = values[group];
        const groupName = group;

        state[groupName] = {};

        Object.keys(groupValues).forEach((value) => {
            state[groupName][value] = {};

            groupValues[value].forEach((i) => {
                state[groupName][value][i] = getCustomPropValue(`--${i}`);
            });
        });
    });

    return state;
};
