import { renderers } from 'ui-library';
import { CellPDFView } from 'components/renderers';
import { sorterString } from 'utils/sorting';

const { CellWithButtonIcon } = renderers;

export const getDocumentsColumns = (t) => [
    {
        key: 'icon',
        title: '',
        render: CellWithButtonIcon,
        width: 30,
        minWidth: 30,
    },
    {
        key: 'name',
        title: t('clientDashboard.documents.name'),
        render: CellPDFView,
        sortable: true,
        sorter: sorterString('name.value'),
        lastSorter: true,
    },
];
