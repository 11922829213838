import { useContext } from 'react';
import { UnreadMessagesContext } from './UnreadMessagesContext';

export default () => {
    const context = useContext(UnreadMessagesContext);

    if (context === undefined) throw new Error('useUnreadMessages must be used within a UnreadMessagesProvider');

    return context;
};
