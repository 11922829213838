export const useChangeStrategyConfig = () => {
    const maxRisk = Number.POSITIVE_INFINITY;
    const minRisk = Number.NEGATIVE_INFINITY;
    const showCreateProposal = true;
    const showDocuments = true;
    const showExecute = true;
    const showInteractions = true;
    const showOnlyStrategiesWithinRiskBandwidths = true;
    const showModelPortfolio = true;

    const isStrategyWithinRiskBandwidth = (strategyId: number): boolean => (
        strategyId >= minRisk && strategyId <= maxRisk
    );

    return {
        isStrategyWithinRiskBandwidth,
        showCreateProposal,
        showDocuments,
        showExecute,
        showInteractions,
        showOnlyStrategiesWithinRiskBandwidths,
        showModelPortfolio,
    };
};
