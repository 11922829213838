import { getCustomVarsFromLS } from 'theming/utils';
import { customProperties as darkVariables } from 'ui-library/dist/styles/themes/dark';
import { customProperties as modernVariables } from 'ui-library/dist/styles/themes/modern';
import { customProperties as darkContrastVariables } from 'styles/themes/dark-contrast';
import { customProperties as lightContrastVariables } from 'styles/themes/light-contrast';
import { customProperties as atramLightVariables } from 'styles/themes/atram-light/themeVariables';

export const themesVariables = {
    dark: darkVariables,
    light: modernVariables,
    'dark-contrast': { ...darkVariables, ...darkContrastVariables },
    'light-contrast': { ...modernVariables, ...lightContrastVariables },
    'atram-light': { ...modernVariables, ...atramLightVariables },
};

export const getVariables = (theme) => {
    const customVars = JSON.parse(getCustomVarsFromLS());

    return {
        ...themesVariables[theme],
        ...customVars,
    };
};
