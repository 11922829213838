import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Paragraph, Title, Infobox,
} from 'ui-library';
import Preloader from 'components/Preloader';
import {
    goalCreationSelector, onBoardingDataSelector, useOnBoardingSelector,
} from 'domain/OnBoarding';
import RadioButtonsGroup from 'components/RadioBlocks';
import { adaptGoalsTemplates } from './adaptors/adaptGoalData';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import GoalForm from '../../component/GoalForm';
import NoGoalForm from '../../component/NoGoalForm';
import './CalculatingGoal.css';

const CalculatingGoal = (props) => {
    const {
        onPrev,
        onNext,
        onPageChange,
    } = props;
    const { t } = useTranslation();
    const [formState, setFormState] = useState({});
    const [mergedFields, setMergedFields] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // OnBoarding Domain
    const {
        product, saveGoalDetails, getGoalDetails,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const {
        dataGoalTemplates, dataGoalPictures, isLoadingGoalTemplates, errorGoalTemplates,
        getGoalTemplates,
    } = useOnBoardingSelector(goalCreationSelector);
    const {
        dataCurrencies, isLoadingCurrencies, errorCurrencies, getCurrencyCodes,
    } = useOnBoardingSelector(goalCreationSelector);

    const [selectedGoal, setSelectedGoal] = useState(goalDetails.selectedGoal);

    // Data
    const noGoalTemplate = useMemo(() => ({
        value: 'no-goal', label: { id: 'no-goal', name: t('onBoarding.goal.noGoal') },
    }), [t]);
    const goalTemplates = useMemo(() => adaptGoalsTemplates(dataGoalTemplates, dataGoalPictures),
        [dataGoalTemplates, dataGoalPictures]);
    const goalOptions = useMemo(() => [
        ...goalTemplates.map((item) => ({ value: item.id.toString(), label: item })),
        noGoalTemplate,
    ], [goalTemplates, noGoalTemplate]);
    const placeholders = useMemo(() => goalTemplates.find(({ id }) => +id === +selectedGoal),
        [goalTemplates, selectedGoal]);
    const isNoGoalSelected = useMemo(() => selectedGoal === 'no-goal', [selectedGoal]);

    // Effects
    useEffect(() => {
        getGoalTemplates({ productId: +product?.id });
    }, [getGoalTemplates, product.id]);
    useEffect(() => {
        getCurrencyCodes({ productId: +product?.id });
    }, [getCurrencyCodes, product.id]);
    useEffect(() => {
        if (goalDetails) setMergedFields(goalDetails);
    }, [goalDetails]);

    // Callbacks
    const onChangeGoal = useCallback((value) => {
        setSelectedGoal(value);
        setIsSubmitted(false);
        setMergedFields((fields) => ({ ...fields, ...formState.goalState }));
    }, [formState.goalState]);
    const onOptimizeClick = useCallback(() => {
        saveGoalDetails({ ...formState.goalState, selectedGoal });
        onPageChange('optimize');
    }, [saveGoalDetails, formState.goalState, selectedGoal, onPageChange]);
    const onFormChange = useCallback((data) => {
        setFormState(data);
        setIsSubmitted(false);
    }, []);
    const onSubmit = useCallback((_, isPositiveGoal = true) => {
        if (isPositiveGoal) onNext();
    }, [onNext]);
    const onNextClick = useCallback(() => {
        setIsSubmitted(true);
        saveGoalDetails({ ...formState.goalState, selectedGoal });
    }, [formState.goalState, saveGoalDetails, selectedGoal]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.goal.title')}
            className="goals-selection"
            prevButton={{
                onClick: onPrev,
            }}
            additionalButton={!isNoGoalSelected && {
                text: t('onBoarding.goal.optimize'),
                disabled: !formState.optimizeDisabled,
                onClick: onOptimizeClick,
            }}
            nextButton={{
                text: t('onBoarding.continue'),
                onClick: onNextClick,
            }}
            buttonsRowError={(isSubmitted && !selectedGoal) && (
                <Infobox error>{t('onBoarding.goal.validation.goalTemplate')}</Infobox>
            )}
        >
            <div className="goals-selection">
                <div className="select-goal">
                    <div className="group-title">
                        <Title type={3}>{t('onBoarding.goal.subTitle')}</Title>
                        <Paragraph type="secondary">{t('onBoarding.goal.text')}</Paragraph>
                    </div>
                    <Preloader isLoading={isLoadingGoalTemplates} error={errorGoalTemplates}>
                        <RadioButtonsGroup
                            name="selectedGoal"
                            value={selectedGoal}
                            legend={t('onBoarding.goal.subTitle')}
                            options={goalOptions}
                            onChange={onChangeGoal}
                        />
                    </Preloader>
                </div>
                <Preloader isLoading={selectedGoal && isLoadingCurrencies} error={errorCurrencies}>
                    <NoGoalForm
                        active={isNoGoalSelected}
                        dataCurrencies={dataCurrencies}
                        isSubmitted={isSubmitted}
                        productId={+product?.id}
                        defaultData={mergedFields}
                        onChange={onFormChange}
                        onSubmit={onSubmit}
                    />
                    <GoalForm
                        active={!!selectedGoal && !isNoGoalSelected}
                        dataCurrencies={dataCurrencies}
                        isSubmitted={isSubmitted}
                        placeholders={placeholders}
                        productId={+product?.id}
                        defaultData={mergedFields}
                        onChange={onFormChange}
                        onSubmit={onSubmit}
                    />
                </Preloader>
            </div>
        </OnBoardingBaseTemplate>
    );
};

CalculatingGoal.propTypes = {
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

CalculatingGoal.defaultProps = {
};

export default CalculatingGoal;
