import {
    BAR, LIST, PIE, PIE_SA,
} from 'constants/constants';
import { isEmpty } from 'lodash/fp';
import { adaptAllocationGroups } from 'adaptors/adaptAllocationGroups';
import { adaptPositions } from 'adaptors/adaptPositions';
import { adaptProjection, getAllocations } from 'adaptors/adaptAllocations';

export const adaptReview = (data, {
    currency: currencyInit,
    portfolioValue,
    projectionYears,
    comparedAllocations,
    t,
    positionLink,
    getFormattedNumber,
    getFormattedCurrency,
    recurringPayment,
}) => {
    if (isEmpty(data)) return {};

    const currency = currencyInit?.label;
    let positions;

    if (data && data.Positions) {
        positions = data.Positions;
    } else if (data && data.ConsolidatedPositions) {
        positions = data.ConsolidatedPositions;
    }

    return {
        proposalId: data?.Id,
        overview: {
            sharpRatio: data?.SharpeRatio,
            expectedReturn: data?.CalculatedExpectedReturn * 100,
            expectedRisk: data?.CalculatedRisk * 100,
            strategy: data?.RiskCategory?.Name,
            modelPortfolio: data?.ModelPortfolioName,
        },
        bandwidthTitle: t('clientDashboard.portfolio.new'),
        riskReturnTitle: t('clientDashboard.portfolio.new'),
        projection: adaptProjection({
            data: positions,
            CurrencyId: currencyInit?.value,
            ProjectionYears: projectionYears || 5,
            InitialInvestmentAmount: portfolioValue,
            MonthlyContributionAmount: recurringPayment,
        }),
        investmentAllocations: getAllocations(positions),
        investmentAllocation: {
            chart: adaptAllocationGroups({
                securitiesValue: portfolioValue,
                comparedAllocations,
                chartTypesList: [PIE, PIE_SA, BAR, LIST],
                positions,
                currency,
                t,
                getFormattedNumber,
                getFormattedCurrency,
            }),
            currency,
        },
        positions: adaptPositions(data?.Positions, {
            currency,
            portfolioValue,
            isNew: false,
            nameLength: 35,
            positionLink,
            getFormattedNumber,
            getFormattedCurrency,
            t,
        }),
        positionsRaw: positions,
    };
};
