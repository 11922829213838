import { useCallback, useEffect, useMemo } from 'react';
import { object, date } from 'yup';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { checkNullData } from 'utils';
import { getDatesDifference } from 'utils/datetime';

export const useCustomRange = (defaultData) => {
    const { t } = useTranslation();

    const schema = useMemo(() => object().shape({
        startDate: date().required(t('validation.mandatoryField')).test('from-before-to', t('customRange.validation.fromBeforeTo'), function (value) {
            return value && this?.parent?.endDate
                ? getDatesDifference(this?.parent?.endDate, value) >= 0
                : true;
        }),
        endDate: date().required(t('validation.mandatoryField')).test('to-after-before', t('customRange.validation.toAfterBefore'), function (value) {
            return value && this?.parent?.startDate
                ? getDatesDifference(value, this?.parent?.startDate) >= 0
                : true;
        }),
    }), [t]);
    const resolver = useYupValidationResolver(schema);
    const {
        register, handleSubmit, errors, setValue, reset, control, watch, formState, trigger,
    } = useForm({ resolver, reValidateMode: 'onChange', shouldUnregister: false });

    useEffect(() => {
        if (!checkNullData(defaultData)) {
            reset(defaultData);
        }
    }, [defaultData, reset]);

    // Validation
    const formErrors = Object.keys(errors || {}).reduce((acc, key) => ({
        ...acc, [key]: errors[key].message,
    }), {});

    // Callbacks
    const onChange = useCallback((type) => (value) => {
        setValue(type, value);
        if (formState.isSubmitted) trigger(['startDate', 'endDate']);
    }, [setValue, trigger, formState.isSubmitted]);

    return {
        control,
        register,
        formErrors,
        handleSubmit,
        onChange,
        values: watch(),
    };
};
