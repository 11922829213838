import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import ServerError from 'errors/ServerError';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import { useCreateAccount } from './useCreateAccount';

const PROPOSAL_TIMEOUT = 1500;

const initialState = {
    data: null,
    error: null,
    isLoading: false,
    dataSave: null,
    errorSave: null,
    isLoadingSave: false,
    dataUpdate: null,
    errorUpdate: null,
    isLoadingUpdate: false,
    dataAccept: null,
    errorAccept: null,
    isLoadingAccept: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        case 'setSaveData':
            return { ...state, dataSave: action.payload };
        case 'setSaveError':
            return { ...state, errorSave: action.payload };
        case 'setSaveIsLoading':
            return { ...state, isLoadingSave: action.payload };
        case 'setUpdateData':
            return { ...state, dataUpdate: action.payload };
        case 'setUpdateError':
            return { ...state, errorUpdate: action.payload };
        case 'setUpdateIsLoading':
            return { ...state, isLoadingUpdate: action.payload };
        case 'setAcceptData':
            return { ...state, dataAccept: action.payload };
        case 'setAcceptError':
            return { ...state, errorAccept: action.payload };
        case 'setAcceptIsLoading':
            return { ...state, isLoadingAccept: action.payload };
        default:
            return state;
    }
};

export const useGoalSummary = (options = {}) => {
    const {
        clientId,
        proposalId: proposalIdOption,
        loadInitially = false,
    } = options;
    const [state, dispatch] = useReducer(reducer, initialState);

    // External hook
    const { createAccount } = useCreateAccount(clientId);

    const { i18n: { language } } = useTranslation();

    // Callbacks
    const getProposal = useCallback(async (
        proposalId = proposalIdOption,
    ) => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            setTimeout(async () => {
                const response = await SM.portfolioManagement('getPortfolioProposal', [clientId, proposalId, { language }]);

                dispatch({ type: 'setData', payload: response?.data });
                dispatch({ type: 'setIsLoading', payload: false });

                return response?.data;
            }, PROPOSAL_TIMEOUT);
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language, proposalIdOption]);
    const saveProposal = useCallback(async (
        params,
    ) => {
        dispatch({ type: 'setSaveError', payload: null });
        dispatch({ type: 'setSaveIsLoading', payload: true });

        try {
            const response = await SM.portfolioManagement('postPortfolioProposal', [clientId, language, params]);

            dispatch({ type: 'setSaveData', payload: response?.data });
            dispatch({ type: 'setSaveIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setSaveError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setSaveIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, language]);
    const updateProposal = useCallback(async (
        proposalId = proposalIdOption,
        params,
    ) => {
        dispatch({ type: 'setUpdateError', payload: null });
        dispatch({ type: 'setUpdateIsLoading', payload: true });

        try {
            const response = await SM.portfolioManagement('patchPortfolioProposal', [clientId, proposalId, params]);

            dispatch({ type: 'setUpdateData', payload: response?.data });
            dispatch({ type: 'setUpdateIsLoading', payload: false });

            return response?.data;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setUpdateError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setUpdateIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, proposalIdOption]);
    const acceptGoalAndPortfolio = useCallback(async ({
        hasGoal = false, params, accountParams,
    } = {}) => {
        dispatch({ type: 'setAcceptError', payload: null });
        dispatch({ type: 'setAcceptIsLoading', payload: true });

        try {
            const {
                cashAccountResponse, custodyAccountResponse,
            } = await createAccount(accountParams);
            const Accounts = [
                {
                    Account: { Id: cashAccountResponse.Account.Id, IsActive: true },
                    IsDefaultCustodyAccount: false,
                    IsPayoutAccount: true,
                    IsPayinAccount: true,
                },
                {
                    Account: { Id: custodyAccountResponse.Account.Id, IsActive: true },
                    IsDefaultCustodyAccount: true,
                    IsPayoutAccount: false,
                    IsPayinAccount: false,
                },
            ];

            const paramsToSend = hasGoal ? {
                ...params,
                GoalAttributes: {
                    ...params?.GoalAttributes,
                    FirstInvestmentDate: new Date(),
                    Status: 1,
                    GoalType: 1,
                },
                PortfolioAttributes: {
                    ...params?.PortfolioAttributes,
                    Accounts,
                    RebalancingType: 'Automatic',
                },
            } : {
                ...params,
                Accounts,
                PortfolioType: 'Real',
                PortfolioStatus: 'NotImplemented',
                RebalancingType: 'Automatic',
            };

            let response = null;

            if (hasGoal) {
                response = await SM.goalsService('createGoalAndPortfolios', [clientId, paramsToSend]);
            } else {
                response = await SM.portfolioManagement('createPortfolio', [clientId, { language }, paramsToSend]);
            }

            dispatch({
                type: 'setAcceptData',
                payload: {
                    ...response?.data,
                    cashAccountResponse,
                    custodyAccountResponse,
                },
            });
            dispatch({ type: 'setAcceptIsLoading', payload: false });

            return {
                ...response?.data,
                cashAccountResponse,
                custodyAccountResponse,
            };
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setAcceptError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setAcceptIsLoading', payload: val }),
                }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, createAccount, language]);

    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
    }, [loadInitially]);

    useEffect(() => {
        if (loadInitially) getProposal();
    }, [getProposal, loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getProposal,
        dataSave: state.dataSave,
        errorSave: state.errorSave,
        isLoadingSave: state.isLoadingSave,
        saveProposal,
        dataUpdate: state.dataUpdate,
        errorUpdate: state.errorUpdate,
        isLoadingUpdate: state.isLoadingUpdate,
        updateProposal,
        dataAccept: state.dataAccept,
        errorAccept: state.errorAccept,
        isLoadingAccept: state.isLoadingAccept,
        acceptGoalAndPortfolio,
    };
};
