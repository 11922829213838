export const adaptBenchmarks = (data, t) => {
    const option = { label: t('confirmation.selectOption'), value: '', disabled: true };

    if (data.TotalCount === 0) return [option];

    return (data.Results || []).map(({ Id, Name }) => ({
        label: Name,
        value: Id,
    }));
};
