import { isTemporaryHidden } from 'constants/constants';
import { renderers } from 'ui-library';
import { CellSwitch, CellWithMoreActions } from 'components/renderers';
import { sorterByDate, sorterString, sorterNumber } from 'utils/sorting';

const { CellWithIcon } = renderers;

export const mapIdToProposalStatus = {
    1: 'proposals.created',
    2: 'proposals.awaitingFeedback',
    3: 'proposals.accepted',
    4: 'proposals.rejected',
    5: 'proposals.deleted',
};

export const cashFlowsColumns = (t, currency) => [
    {
        key: 'KeyValue',
        title: '',
        width: '25%',
    },
    {
        key: 'CurrentMonth',
        title: `${t('portfolios.cashFlowsColumns.currentMonth')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
        hidden: true,
    },
    {
        key: 'YTD',
        title: `${t('portfolios.cashFlowsColumns.YTD')}`,
        width: '25%',
    },
    {
        key: 'SinceInception',
        title: `${t('portfolios.cashFlowsColumns.sinceInception')}${currency ? ` (${currency})` : ''}`,
        width: '25%',
        className: 'text-right',
        hidden: true,
    },
];
export const cashFlowsRowMarginIdx = [0, 1, 2, 3, 4, 5, 6];

export const positionsColumns = (t, isDirectional = false) => [
    {
        key: 'name',
        title: t('position.columns.security'),
        sortable: true,
        className: 'normal-wrap-header',
        sorter: sorterString('name'),
        render: (data) => (data.label
            ? CellSwitch({ ...data, hideLink: isTemporaryHidden })
            : CellSwitch(data, 100)),
        width: 180,
        minWidth: 180,
    },
    {
        title: t('position.columns.quantityUnits'),
        key: 'units',
        width: 85,
        minWidth: 85,
        className: 'normal-wrap',
        sortable: false,
        // sorter: sorterNumber('units'),
    },
    ...(isDirectional
        ? [
            {
                title: t('position.columns.averageUnitCost'),
                key: 'costPrice',
                className: 'normal-wrap',
                width: 95,
                minWidth: 95,
                sortable: false,
            // sorter: sorterNumber('costPrice'),
            },
        ]
        : []
    ),
    {
        title: t('position.columns.latestPrice'),
        key: 'latestPrice',
        width: 80,
        minWidth: 80,
        className: 'normal-wrap',
        sortable: false,
        // sorter: sorterNumber('latestPrice'),
    },
    ...(isDirectional
        ? [
            {
                title: t('clientDashboard.portfolio.columns.acquisitionCost'),
                key: 'acquisitionCost',
                className: 'normal-wrap',
                sortable: false,
                // sorter: sorterNumber('acquisitionCost'),
                width: 120,
                minWidth: 120,
            },
        ]
        : []
    ),
    {
        title: t('position.columns.marketValueInSecurityCurrency'),
        // className: 'text-right',
        className: 'normal-wrap',
        key: 'valuationInSecurityCurrency',
        width: 160,
        minWidth: 160,
        hidden: true,
        sortable: false,
        // sorter: sorterNumber('valuationInSecurityCurrency'),
    },
    {
        title: t('position.columns.marketValueInPortfolioCurrency'),
        className: 'normal-wrap hide-button',
        key: 'valuation',
        width: 160,
        minWidth: 160,
        sortable: false,
        // sorter: sorterNumber('valuation'),
    },
    ...(isDirectional
        ? [
            {
                title: t('position.columns.unrealizedGainsLosses'),
                key: 'unrealizedGainLoss',
                className: 'normal-wrap',
                width: 110,
                minWidth: 110,
                sortable: false,
                // sorter: sorterNumber('unrealizedGainLoss'),
            },
        ]
        : []
    ),
    {
        title: t('position.columns.percentageWeight'),
        key: 'allocation',
        className: 'normal-wrap hide-button',
        width: 60,
        minWidth: 60,
        sorter: sorterNumber('allocation'),
    },
    {
        title: '',
        className: 'table-cell__actions',
        key: 'actions',
        render: () => [],
        width: 30,
        minWidth: 30,
        titleType: 'columns',
        headerRenderer: CellWithIcon,
        hidden: false,
    },
];

export const positionsMobileColumns = (t) => [
    {
        key: 'name',
        title: t('position.columns.security'),
        sortable: true,
        render: (data) => (data.label
            ? CellSwitch({ ...data, hideLink: isTemporaryHidden })
            : CellSwitch(data, 100)),
        width: 135,
        minWidth: 135,
        sorter: sorterString('name'),
    },
    {
        title: t('position.columns.marketValue'),
        className: 'text-right',
        key: 'valuation',
        width: 150,
        minWidth: 150,
        sorter: sorterNumber('valuation'),
    },
    {
        title: '',
        className: 'text-right table-cell__actions',
        key: 'actions',
        render: CellWithMoreActions,
        width: 40,
        minWidth: 30,
        titleType: 'columns',
        fixed: 'right',
        headerRenderer: CellWithIcon,
        hidden: true,
    },
];

export const proposalsColumns = (t) => [
    {
        key: 'Date',
        title: t('proposal.columns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'descend',
        width: 187,
    },
    {
        key: 'Name',
        title: t('proposal.columns.name'),
        width: 400,
        sortable: true,
        sorter: sorterString('Name'),
        render: CellSwitch,
        defaultSortOrder: 'descend',
    },
    {
        key: 'Status',
        title: t('proposal.columns.status'),
        sorter: sorterString('Status'),
    },
];

export const cashTransactionsColumns = (t, currency) => [
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.date'),
        sortable: true,
        // defaultSortOrder: 'ascend',
        sorter: sorterByDate('Date'),
        width: 100,
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
        width: 200,
    },
    {
        key: 'BookingText',
        title: t('clientDashboard.portfolio.columns.bookingText'),
        sortable: true,
        sorter: sorterString('BookingText'),
        defaultSortOrder: 'ascend',
    },
    {
        key: 'Debit',
        title: `${t('portfolios.columns.debit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Debit'),
    },
    {
        key: 'Credit',
        title: `${t('portfolios.columns.credit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Credit'),
    },
    {
        key: 'Balance',
        title: `${t('portfolios.columns.balance')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Balance'),
        hidden: true,
    },
];

export const cashTransactionsMobileColumns = (t, currency) => [
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.date'),
        sortable: true,
        // defaultSortOrder: 'ascend',
        sorter: sorterByDate('Date'),
        width: 100,
        hidden: true,
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.name'),
        render: CellSwitch,
        sortable: true,
        sorter: sorterString('Name'),
        width: 200,
    },
    {
        key: 'BookingText',
        title: t('clientDashboard.portfolio.columns.bookingText'),
        sortable: true,
        sorter: sorterString('BookingText'),
        defaultSortOrder: 'ascend',
    },
    {
        key: 'Debit',
        title: `${t('portfolios.columns.debit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Debit'),
    },
    {
        key: 'Credit',
        title: `${t('portfolios.columns.credit')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Credit'),
    },
    {
        key: 'Balance',
        title: `${t('portfolios.columns.balance')}${currency ? ` (${currency})` : ''}`,
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('Balance'),
        hidden: true,
    },
];

export const securityTransactionsColumns = (t) => [
    {
        key: 'SettlementDate',
        title: t('portfolios.transactionsColumns.tradeDate'),
        sortable: false,
        defaultSortOrder: 'ascend',
        sorter: sorterByDate('SettlementDate'),
        width: 125,
        minWidth: 125,
        className: 'normal-wrap',
    },
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.settlementDate'),
        sortable: false,
        // sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 100,
        minWidth: 100,
        className: 'normal-wrap',
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.security'),
        render: (data) => (data.label
            ? CellSwitch({ ...data, hideLink: true })
            : CellSwitch(data, 30)),
        sortable: false,
        // sorter: sorterString('Name'),
        width: 170,
        className: 'normal-wrap-header',
    },
    {
        key: 'Isin',
        title: t('position.columns.isin'),
        hidden: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'SubAssetClass',
        title: t('position.columns.subAssetClass'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Country',
        title: t('position.columns.country'),
        width: 250,
        minWidth: 250,
        hidden: true,
        sorter: sorterString('Country'),
    },
    {
        key: 'Region',
        title: t('position.columns.region'),
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterString('Region'),
    },
    {
        key: 'Sector',
        title: t('position.columns.sector'),
        width: 100,
        minWidth: 100,
        hidden: true,
        sorter: sorterString('Sector'),
    },
    {
        key: 'SubSector',
        title: t('position.columns.subSector'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubSector'),
    },
    {
        key: 'BookingText',
        title: t('portfolios.transactionsColumns.transaction'),
        sortable: false,
        className: 'normal-wrap',
        width: 80,
        minWidth: 80,
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterString('Status'),
        hidden: true,
    },
    {
        key: 'Number',
        title: t('portfolios.transactionsColumns.quantity'),
        className: 'normal-wrap',
        sortable: false,
        width: 70,
        minWidth: 70,
    },
    {
        key: 'Price',
        title: t('portfolios.transactionsColumns.price'),
        className: 'normal-wrap',
        sortable: false,
        // sorter: sorterNumber('Price'),
        width: 140,
        minWidth: 140,
    },
    {
        key: 'FxRate',
        title: t('portfolios.transactionsColumns.FXRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
        hidden: true,
    },
    {
        key: 'Value',
        title: `${t('portfolios.transactionsColumns.settlementAmount')}`,
        className: 'normal-wrap',
        sortable: false,
        // sorter: sorterNumber('Value'),
        width: 80,
        minWidth: 80,
    },
    {
        key: 'Icon',
        title: '',
        titleType: 'columns',
        width: 20,
        minWidth: 20,
        className: 'text-right',
        hidden: true,
    },
];

export const securityTransactionsMobileColumns = (t) => [
    {
        key: 'SettlementDate',
        title: t('portfolios.transactionsColumns.tradeDate'),
        sortable: false,
        defaultSortOrder: 'ascend',
        sorter: sorterByDate('SettlementDate'),
        width: 100,
        minWidth: 100,
        className: 'normal-wrap hide-button',
        hidden: true,
    },
    {
        key: 'Date',
        title: t('portfolios.transactionsColumns.settlementDate'),
        sortable: false,
        // sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 100,
        minWidth: 100,
        className: 'normal-wrap',
        hidden: true,
    },
    {
        key: 'Name',
        title: t('portfolios.transactionsColumns.security'),
        render: (data) => (data.label
            ? CellSwitch({ ...data, hideLink: true })
            : CellSwitch(data, 30)),
        sortable: false,
        // sorter: sorterString('Name'),
        width: 170,
    },
    {
        key: 'Isin',
        title: t('position.columns.isin'),
        hidden: true,
        sorter: sorterString('Isin'),
    },
    {
        key: 'SubAssetClass',
        title: t('position.columns.subAssetClass'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubAssetClass'),
    },
    {
        key: 'Country',
        title: t('position.columns.country'),
        width: 250,
        minWidth: 250,
        hidden: true,
        sorter: sorterString('Country'),
    },
    {
        key: 'Region',
        title: t('position.columns.region'),
        width: 150,
        minWidth: 150,
        hidden: true,
        sorter: sorterString('Region'),
    },
    {
        key: 'Sector',
        title: t('position.columns.sector'),
        width: 100,
        minWidth: 100,
        hidden: true,
        sorter: sorterString('Sector'),
    },
    {
        key: 'SubSector',
        title: t('position.columns.subSector'),
        width: 200,
        minWidth: 200,
        hidden: true,
        sorter: sorterString('SubSector'),
    },
    {
        key: 'Type',
        title: t('portfolios.transactionsColumns.type'),
        sorter: sorterString('Type'),
        hidden: true,
    },
    {
        key: 'Status',
        title: t('portfolios.columns.status'),
        sorter: sorterString('Status'),
        hidden: true,
    },
    {
        key: 'Number',
        title: t('portfolios.transactionsColumns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
        hidden: true,
    },
    {
        key: 'Price',
        title: t('portfolios.transactionsColumns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
        hidden: true,
    },
    {
        key: 'FxRate',
        title: t('portfolios.transactionsColumns.FXRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
        hidden: true,
    },
    {
        key: 'Value',
        title: `${t('portfolios.transactionsColumns.settlementAmount')}`,
        className: 'text-right',
        sortable: false,
        // sorter: sorterNumber('Value'),
    },
    {
        key: 'Icon',
        title: '',
        titleType: 'columns',
        width: 20,
        minWidth: 20,
        className: 'text-right',
        hidden: true,
    },
];

export const getOrdersColumns = (t) => ([
    {
        key: 'Name',
        title: t('confirmation.transactionColumns.name'),
        render: CellSwitch,
        width: 200,
        sortable: true,
        sorter: sorterString('Name'),
    },
    {
        key: 'Date',
        title: t('confirmation.transactionColumns.date'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 187,
    },
    {
        key: 'Type',
        title: t('confirmation.transactionColumns.type'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Number',
        title: t('confirmation.transactionColumns.number'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('confirmation.transactionColumns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
    },
    {
        key: 'FxRate',
        title: t('confirmation.transactionColumns.FXRate'),
        className: 'text-right',
        sorter: sorterNumber('FxRate'),
    },
    {
        key: 'Total',
        title: t('confirmation.transactionColumns.total'),
        className: 'text-right',
        sorter: sorterNumber('Total'),
    },
]);

// TODO: unused code below, delete after approval
export const assetClassesPieChartData = [
    {
        name: 'Cash',
        value: 11340.01,
    },
    {
        name: 'Equities',
        value: 51780.01,
    },
    {
        name: 'Bonds',
        value: 36880.01,
    },
    {
        name: 'Others',
        value: 10080.01,
    },
];

export const invPrfLineChartData = [
    {
        data: [
            [1167609600000, -1],
            [1167696000000, 1],
            [1167782400000, 2.5],
            [1167868800000, 1],
            [1167955200000, 0.5],
            [1168214400000, 3],
        ],
    },
];

export const invPrfLineChartValueFormat = {
    symbol: '%',
    precision: 0,
    thousand: ',',
    decimal: '.',
    format: '%v%s',
};

export const invPrfSelectOptions = ['YTD', 'Last 12 months', '2 Years', '5 Years', 'Max'];

export const getPendingTradeColumns = (t) => ([
    {
        key: 'Date',
        title: t('confirmation.transactionColumns.tradeDate'),
        sortable: true,
        sorter: sorterByDate('Date'),
        defaultSortOrder: 'ascend',
        width: 187,
    },
    {
        key: 'Name',
        title: t('confirmation.transactionColumns.security'),
        render: (data) => (data.label
            ? CellSwitch({ ...data, hideLink: isTemporaryHidden })
            : CellSwitch(data)),
        width: 200,
        sortable: true,
        sorter: sorterString('Name'),
    },
    {
        key: 'Type',
        title: t('confirmation.transactionColumns.transaction'),
        sortable: true,
        sorter: sorterString('Type'),
    },
    {
        key: 'Number',
        title: t('confirmation.transactionColumns.quantity'),
        className: 'text-right',
        sorter: sorterNumber('Number'),
    },
    {
        key: 'Price',
        title: t('confirmation.transactionColumns.price'),
        className: 'text-right',
        sorter: sorterNumber('Price'),
    },
    {
        key: 'Total',
        title: t('confirmation.transactionColumns.settlementAmount'),
        className: 'text-right',
        sorter: sorterNumber('Total'),
    },
]);
