import { cloneDeep, filter, find } from 'lodash/fp';
import { sortAssets } from 'utils/sortingAllocation';
import { getParent, roundAllocations, sum } from '../utils';
import {
    formatBigNumber, percentIsZero, validateData, validateNumber,
} from '../utils/formatting';
import { isLiquidity } from '../utils/portfolio';

export const groupComparedPositions = (options = {}) => {
    const {
        positions,
        modelPositions,
        portfolioCurrency,
        baseUrl,
        t,
        portfolioTotalValue,
        redirectToReadOnlyPositionDetails,
        getFormattedNumber,
        getFormattedCurrency,
        positionLink,
    } = (options || {});
    const clonedData = cloneDeep(positions) || [];

    // Check if new positions appeared
    // eslint-disable-next-line no-unused-vars,consistent-return
    const newData = filter((item) => {
        if (!find(['Security.Id', item.Security.Id], clonedData)) {
            return item;
        }
    }, modelPositions) || [];

    const data = (clonedData.concat(newData));

    const roundedAllocation = clonedData?.length ? roundAllocations(clonedData) : [];
    const roundedNewAllocation = modelPositions?.length ? roundAllocations(modelPositions) : [];
    const groupToValues = data.reduce((obj, item) => {
        const securityData = item.Security;
        const accumulator = { ...obj };
        const roundedAllocData = roundedAllocation.find((n) => n.id === securityData.Id);
        const roundedNewAllocData = roundedNewAllocation.find((n) => n.id === securityData.Id);
        const roundedAlloc = roundedAllocData ? roundedAllocData.value : 0;
        const roundedNewAlloc = roundedNewAllocData ? roundedNewAllocData.value : 0;
        const currentValueData = item?.InvestmentValuePortfolioCurrency || (roundedAlloc
            ? (roundedAlloc * portfolioTotalValue) / 100
            : 0);
        const newValueChange = roundedAlloc === roundedNewAlloc
            ? item?.InvestmentValuePortfolioCurrency : undefined;
        const newValueData = newValueChange || (roundedNewAlloc
            ? (roundedNewAlloc * portfolioTotalValue) / 100
            : 0);

        const accParent = getParent(securityData.AssetClass).Name;
        const baseUrlLink = baseUrl && `${baseUrl}/${securityData.Id}/2`;
        const positionUrlLink = positionLink && `${positionLink}/${securityData.Id}`;
        const link = isLiquidity(securityData) ? undefined : (baseUrlLink || positionUrlLink);
        const currency = validateData(securityData.Currency.CurrencyCode);
        const name = securityData.Type.Id === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name);

        accumulator[accParent] = obj[accParent] || [];
        accumulator[accParent].push({
            isin: validateData(securityData.Isin),
            securityId: validateNumber(securityData.Id),
            url: '',
            name: {
                label: name,
                link,
                maxLength: 28,
            },
            allocationData: roundedAlloc,
            allocationNewData: roundedNewAlloc,
            allocation: `${percentIsZero(roundedAlloc)}%`,
            currency,
            units: getFormattedNumber(
                validateNumber(item.Quantity),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            performance: getFormattedNumber(
                validateNumber(item.Performance),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            latestPrice: getFormattedNumber(
                validateNumber(item.ValuationPrice),
                {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            valuation: getFormattedCurrency(
                validateNumber(item.InvestmentValuePortfolioCurrency),
                {
                    currency: portfolioCurrency,
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                },
            ),
            currentValueData,
            newValueData,
            allocationD: {
                currentValue: `${getFormattedNumber(roundedAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(roundedNewAlloc, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            valueData: {
                currentValue: getFormattedCurrency(
                    currentValueData,
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    newValueData,
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            fxRate: getFormattedNumber(validateNumber(item.FxRate), {
                maximumFractionDigits: 4,
                minimumFractionDigits: 4,
            }),
            valuationData: getFormattedNumber(
                validateNumber(item.InvestmentValuePortfolioCurrency),
            ),
            performanceCCY: getFormattedNumber(validateNumber(item.MonetaryPerformance)),
            actions: isLiquidity(securityData)
                ? {}
                : {
                    value: 'action',
                    actions: [
                        {
                            text: t('clientDashboard.portfolio.showMoreDetails'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 1,
                            ),
                        },
                        {
                            text: t('clientDashboard.portfolio.showPerformance'),
                            onClick: () => redirectToReadOnlyPositionDetails(
                                baseUrl, securityData.Id, 2,
                            ),
                        },
                    ],
                },
        });

        return accumulator;
    }, {});
    const parentAssetClasses = data.map((item) => getParent(item.Security.AssetClass));

    let groups = Object.keys(groupToValues).map((key) => {
        const groupMembersData = groupToValues[key];
        const id = parentAssetClasses.find((i) => i.Name === key).Id;

        return {
            id,
            valuation: getFormattedCurrency(sum(groupMembersData, 'valuationData'), { currency: portfolioCurrency }),
            allocation: `${percentIsZero(formatBigNumber(sum(groupMembersData, 'allocationData')))}%`,
            allocationData: sum(groupMembersData, 'allocationData'),
            allocationNewData: sum(groupMembersData, 'allocationNewData'),
            allocationD: {
                currentValue: `${getFormattedNumber(sum(groupMembersData, 'allocationData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
                newValue: `${getFormattedNumber(sum(groupMembersData, 'allocationNewData'), {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                })}%`,
            },
            valueData: {
                currentValue: getFormattedCurrency(
                    sum(groupMembersData, 'currentValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
                newValue: getFormattedCurrency(
                    sum(groupMembersData, 'newValueData'),
                    {
                        currency: portfolioCurrency,
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                    },
                ),
            },
            name: key,
            children: groupMembersData,
            icon: 'action',
            actions: {},
        };
    });

    groups = groups.map((groupItem) => {
        const resultGroupItem = { ...groupItem };

        resultGroupItem.valuation = groupItem.valuation;

        resultGroupItem.children = resultGroupItem.children.map((groupMemberItem) => {
            const resultGroupMemberItem = groupMemberItem;

            resultGroupMemberItem.performance = (groupMemberItem.performance * 100);
            resultGroupMemberItem.valuation = (groupMemberItem.valuation);
            resultGroupMemberItem.icon = 'action';

            return resultGroupMemberItem;
        });

        return resultGroupItem;
    });

    return sortAssets(groups);
};
