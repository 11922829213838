import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'components/Footer';
import { isTemporaryHidden } from 'constants/constants';

export const NoHeaderTemplate = ({ children }) => (
    <Fragment>
        <main className="container grow">{children}</main>
        {!isTemporaryHidden && <Footer />}
    </Fragment>
);

NoHeaderTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

NoHeaderTemplate.defaultProps = {};

NoHeaderTemplate.displayName = 'NoHeaderTemplate';
