import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Paragraph, RadioButtonGroup, Title } from 'ui-library';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import ProductItem from 'components/ProductItem';
import {
    useOnBoardingSelector,
    onBoardingDataSelector,
    productOffersSelector,
} from 'domain/OnBoarding';
import Infobox from 'ui-library/dist/components/Infobox';
import OnBoardingBaseTemplate from '../../components/OnBoardingBaseTemplate';
import { adaptProductOffers } from './adaptors/adaptProductOffers';
import './ProductOfferSelection.css';

const ProductOfferSelection = (props) => {
    const {
        onPageChange,
    } = props;
    const { t } = useTranslation();
    const [selectedError, setSelectedError] = useState(false);
    // OnBoarding Domain
    const {
        saveProductOffer, productOffer: savedProductOffer,
        clearRiskAppetite, clearRiskCapacity, clearProduct,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        data, isLoading, error, getProductOffers,
    } = useOnBoardingSelector(productOffersSelector);

    const [selected, setSelected] = useState(savedProductOffer?.id || null);

    // Effects
    useEffect(() => {
        getProductOffers();
    }, [getProductOffers]);

    useEffect(() => {
        if (selected) {
            setSelectedError(false);
        }
    }, [selected]);
    // Data
    const productOffers = useMemo(() => adaptProductOffers(data), [data]);

    const options = useMemo(() => (productOffers || []).map((item) => ({
        value: item.id.toString(),
        label: ProductItem(item),
    })), [productOffers]);

    // Selected product offer
    const onChange = ({ target: { value } }) => {
        setSelected(value);
        clearProduct();
        clearRiskAppetite();
        clearRiskCapacity();
    };

    // Handlers/callbacks
    const onNextWithSelect = useCallback(() => {
        if (selected) {
            const option = productOffers.find(({ id }) => id.toString() === selected);
            const productOffer = { id: selected, name: option?.name };

            saveProductOffer(productOffer);
            onPageChange('product');
        } else {
            setSelectedError(true);
        }
    }, [productOffers, saveProductOffer, selected, onPageChange]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.selectProductOffer.title')}
            error={error}
            isLoading={isLoading}
            classNameContent="product-offer-selection"
            nextButton={{
                onClick: onNextWithSelect,
            }}
        >
            <Preloader isLoading={isLoading} error={error}>
                <div className="group-title">
                    <Title type={3}>{t('onBoarding.selectProductOffer.subTitle')}</Title>
                    <Paragraph type="secondary">{t('onBoarding.selectProductOffer.text')}</Paragraph>
                </div>
                <div className="product-offer-content">
                    <EmptyContent data={data} text={t('dashboard.noData')}>
                        <RadioButtonGroup
                            label=""
                            value={selected}
                            options={options}
                            onChange={onChange}
                        />
                    </EmptyContent>
                </div>
                {selectedError && (
                    <Infobox error className="validation-error">
                        <div>{t('onBoarding.selectProductOffer.validation')}</div>
                    </Infobox>
                )}
            </Preloader>
        </OnBoardingBaseTemplate>
    );
};

ProductOfferSelection.propTypes = {
    onPageChange: PropTypes.func,
};

ProductOfferSelection.defaultProps = {
    onPageChange: () => {},
};

export default ProductOfferSelection;
