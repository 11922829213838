import { useEffect, useMemo, useState } from 'react';
import { get } from 'lodash';
import AdapterError from 'errors/AdapterError';
import ServiceManager from 'services/ServiceManager';
import HandlerError from 'errors/HandlerError';
import { useTranslation } from 'react-i18next';
import { adaptTasks, adaptUrgencyFilters } from '../pages/pages/Dashboard/adapters/adaptTasks';
import handlerRequestCanceling from '../utils/handlerRequestCanceling';

export const useTasks = (tickets, quantity) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [rawData, setRawData] = useState([]);
    const [data, setData] = useState([]);
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (!tickets.isLoading) {
            ServiceManager.commonService('getEnumerations', [
                {
                    EnumerationTypes: ['SeverityLevel'],
                },
                i18n?.language,
            ])
                .then((response) => {
                    try {
                        setRawData(get(response, 'data.Enumerations[0].Values', []));
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })

                .catch(handlerRequestCanceling(
                    HandlerError({ setError, setLoading }),
                ));
        } else {
            setError(tickets.error);
            setLoading(tickets.isLoading);
        }
    }, [tickets.isLoading, i18n?.language]);

    useEffect(() => {
        if (rawData.length > 0) {
            try {
                setData(adaptTasks(tickets.data, rawData, quantity, t));

                setLoading(false);
            } catch (err) {
                throw new AdapterError(err);
            }
        }
    }, [rawData, i18n.language]);

    const urgencyFilters = useMemo(() => adaptUrgencyFilters(rawData, t), [rawData, t]);

    return {
        data, isLoading, error, urgencyFilters,
    };
};
