export const groupObjectById = (data) => {
    const traversalTree = (current, childrenArr) => {
        childrenArr.push(current.Id || current.Data.Id);

        if (current.Children) {
            current.Children.forEach((child) => {
                traversalTree(child, childrenArr);
            });
        }
    };

    return data.reduce((acc, item) => {
        const children = [];

        traversalTree(item, children);

        return ({
            ...acc,
            [item.Id || item.Data.Id]: {
                name: item.Name || item.Data.Name,
                color: item?.Color || item.Data?.Color,
                children,
            },
        });
    }, {});
};
