import {
    useCallback, useState, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import SM from 'services/ServiceManager';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';

const useClientRiskCategory = (clientId) => {
    const [error, setError] = useState();
    const [isLoading, setLoading] = useState(false);
    const [clientRiskCategory, setClientRiskCategory] = useState({});
    const [hasRiskProfile, setHasRiskProfile] = useState(true);

    const { i18n: { language } } = useTranslation();

    const getClientRiskCategory = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await SM.compliance('getClientRiskCategory', [clientId, language]);

            setClientRiskCategory(data);
            setHasRiskProfile(!!data?.ChosenRiskCategory?.Id);
            setLoading(false);
        } catch (err) {
            handlerRequestCanceling(() => {
                HandlerError({ setError, setLoading });
            })(err);
        }

        return null;
    }, [clientId, language]);

    useEffect(() => {
        getClientRiskCategory();
    }, [getClientRiskCategory]);

    return {
        clientRiskCategory,
        hasRiskProfile,
        isRiskCategoryLoading: isLoading,
        error,
    };
};

export default useClientRiskCategory;
