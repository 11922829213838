export const validate = (
    values: Record<string, string | undefined | null>,
): Record<string, string> => {
    const errors:Record<string, string> = {};

    if (values.oldPassword !== undefined && !values.oldPassword) {
        errors.oldPassword = 'resetPassword.provideOldPassword';
    }

    if (values.password !== undefined && !values.password) {
        errors.password = 'resetPassword.providePassword';
    }

    if (
        values.oldPassword
        && values.password
        && values.confirmPassword !== undefined
        && values.confirmPassword !== values.password
    ) {
        errors.confirmPassword = 'resetPassword.passwordDontMatch';
    }

    return errors;
};
