import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    formatSignPercent,
    formatValueByType,
    getColorClass,
    TYPE_TEXT,
    VALUE_TYPES,
} from 'utils/formatting';
import { useFormatting } from 'locale';
import Link from 'ui-library/dist/components/Link';
import { getClassNames } from '../../utils';
import { OVERVIEW_SIZE_LAGRE, OVERVIEW_SIZE_SMALL, OVERVIEW_SIZES } from './constants';

import './Overview.css';

function OverviewItem({
    title,
    htmlTitle,
    value,
    size,
    type,
    colored,
    percent,
    link,
    linkLabel,
    currency,
    additionalValue,
    currencySize,
    RenderValue,
    className: classNameProp,
}) {
    const { getFormattedNumber } = useFormatting();

    const overviewClassName = getClassNames('overview-item', classNameProp);
    const className = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__value'),
        `overview-item__value_${size}`,
    );

    const currencyClassName = getClassNames(
        getColorClass(colored ? value : 0, 'overview-item__currency'),
        `overview-item__currency_${currencySize}`,
    );

    const getFormattedValue = (color) => {
        if (value === undefined || value === null || value === 'N/A') {
            return 'N/A';
        }
        if (color) {
            return formatValueByType(value, type, colored, getFormattedNumber);
        }

        return formatValueByType(value, type, false, getFormattedNumber);
    };

    const LinkComponent = useMemo(() => {
        if (!link) return null;

        return typeof link === 'function' ? (
            <span
                role="link"
                className="overview-item__link"
                onClick={link}
                onKeyDown={(event) => { if (event.key === 'Enter') link(event); }}
                tabIndex={0}
            >
                {linkLabel}
            </span>
        ) : (
            <Link className="overview-item__link" to={link}>
                {linkLabel}
            </Link>
        );
    }, [link, linkLabel]);


    return (
        <div className={overviewClassName}>
            {title && (
                <dt title={htmlTitle} className="overview-item__title">
                    {title}
                </dt>
            )}
            <dd>
                {RenderValue ? <RenderValue /> : (
                    <>
                        {type !== 'percent' && currency !== null && <span className={currencyClassName}>{currency}</span>}
                        <span
                            className={className}
                            title={getFormattedValue()}
                        >
                            {getFormattedValue(colored)}
                        </span>
                        {percent !== null && (
                            <span className={getColorClass(percent, 'overview-item__percent')}>
                                {formatSignPercent(percent)}
                            </span>
                        )}
                    </>
                ) }
            </dd>
            {additionalValue && (<div className="additional-value">{additionalValue}</div>)}
            {LinkComponent}
        </div>
    );
}

OverviewItem.propTypes = {
    title: PropTypes.string,
    htmlTitle: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOf(OVERVIEW_SIZES),
    type: PropTypes.oneOf(VALUE_TYPES),
    colored: PropTypes.bool,
    percent: PropTypes.number,
    link: PropTypes.string,
    linkLabel: PropTypes.string,
    currency: PropTypes.string,
    currencySize: PropTypes.oneOf(OVERVIEW_SIZES),
    additionalValue: PropTypes.string,
    className: PropTypes.string,
    RenderValue: PropTypes.node,
};

OverviewItem.defaultProps = {
    title: '',
    htmlTitle: '',
    size: OVERVIEW_SIZE_LAGRE,
    currencySize: OVERVIEW_SIZE_SMALL,
    type: TYPE_TEXT,
    colored: false,
    percent: null,
    link: null,
    linkLabel: null,
    currency: null,
    value: undefined,
    additionalValue: null,
    className: null,
    RenderValue: undefined,
};

export default OverviewItem;
