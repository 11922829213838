import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash/fp';
import { Icon } from 'ui-library';
import ButtonWithIcon from '../ButtonWithIcon/ButtonWithIcon';
import './CellWithPlusMinusIcons.css';

const CellWithPlusMinusIcons = ({ onDecrease, onIncrease }) => (
    <div className="table-cell_icon table-cell-with-icons">
        {onDecrease
        && (
            <ButtonWithIcon className="table-cell-with-icons_button " onClick={onDecrease}>
                <Icon type="minus-button" size={18} onClick={onDecrease} />
            </ButtonWithIcon>
        )}
        {onIncrease && (
            <ButtonWithIcon className="table-cell-with-icons_button" onClick={onIncrease}>
                <Icon type="plus-button" size={18} onClick={onIncrease} />
            </ButtonWithIcon>
        )}
    </div>
);

CellWithPlusMinusIcons.propTypes = {
    onDecrease: PropTypes.func,
    onIncrease: PropTypes.func,
};

CellWithPlusMinusIcons.defaultProps = {
    onIncrease: noop,
    onDecrease: noop,
};

export default CellWithPlusMinusIcons;
