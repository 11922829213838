import React from 'react';
import PropTypes from 'prop-types';
import InstrumentContext from './context';
import { useDocuments } from '../hooks/useDocuments';
import { useInstrumentData } from '../hooks/useInstrumentData';
import { useInstrumentAccess } from '../hooks/useInstrumentAccess';

const InstrumentProvider = (options) => {
    const {
        options: {
            instrumentId,
        },
        children,
    } = options;

    const instrument = useInstrumentData({ instrumentId });
    const instrumentAccess = useInstrumentAccess({ instrumentId });
    const documents = useDocuments({ instrumentId });

    const state = {
        instrumentId,
        instrument,
        instrumentAccess,
        documents,
    };
    const getValue = (callback) => callback(state);

    return (
        <InstrumentContext.Provider
            value={{ ...state, getValue }}
        >
            {children}
        </InstrumentContext.Provider>
    );
};

InstrumentProvider.propTypes = {
    options: PropTypes.shape({
        instrumentId: PropTypes.number.isRequired,
    }).isRequired,
};

InstrumentProvider.defaultProps = {};

export default InstrumentProvider;
