import React from 'react';
import { Link } from 'react-router-dom';
import {
    ContentBox, Title, Row, Column, BreadCrumbs, Paragraph, Button,
} from 'ui-library';
import PropTypes from 'prop-types';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import PageSection from 'components/PageSection/PageSection';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useFormatting, dateTimeFormats } from 'locale';
import { OverviewItem, OverviewPanel } from 'components/Overview';
import { TYPE_PERCENT } from 'utils/formatting';
import { getRandomString } from 'utils';

import history from 'services/history';
import { useSelector } from 'react-redux';
import { memberPhoneSelector } from 'redux/auth/authSelectors';
import { isTemporaryHidden } from 'constants/constants';
import { useResearch } from '../../../hooks/useResearch';
import './ResearchDetails.css';

function ResearchDetails(props) {
    const { match: { params: { researchId } } } = props;
    const memberPhone = useSelector(memberPhoneSelector);
    const { t } = useTranslation();
    const { getFormattedDate, getFormattedPhoneNumber } = useFormatting();
    const { data, error, isLoading } = useResearch({ researchId, loadInitially: true });

    const formattedDate = getFormattedDate(data?.date, {
        hour: dateTimeFormats.TWO_DIGIT,
        minute: dateTimeFormats.TWO_DIGIT,
    });
    const onDocumentClick = () => {
        if (data?.document?.blob) {
            FileSaver.saveAs(data?.document?.blob, data?.document?.name);
        }
    };
    const onRequest = () => {
        history.push('/messages/new', {
            type: 'request',
            title: data?.instrument?.name,
            researchId,
        });
    };

    return (
        <PageSection className="research-details">
            <Preloader isLoading={isLoading} error={error}>
                <EmptyContent data={data} text={t('dashboard.noData')}>
                    <ContentBox>
                        <div>
                            <BreadCrumbs current={data?.title}>
                                <Link to="/news/research">{t('research.title')}</Link>
                            </BreadCrumbs>
                            <Title className="underlined with-mb" type={1}>
                                {data?.title}
                            </Title>
                        </div>
                        <Row className="instrument">
                            <Column size={['lg-4', '12']}>
                                <div className="research-details__image-wrapper">
                                    {data?.image ? (
                                        <img
                                            className="research-details__image"
                                            src={data?.image}
                                            alt="news-img"
                                        />
                                    ) : <span className="research-details__image-placeholder" />}
                                </div>
                                <Paragraph type="secondary" className="research-details__image-text">
                                    {data?.imageContent}
                                </Paragraph>
                            </Column>
                            <Column size={['lg-8', '12']}>
                                <Paragraph type="secondary" className="research-details__date">
                                    {formattedDate}
                                </Paragraph>
                                <Paragraph className="research-details__content">
                                    {data?.content}
                                </Paragraph>
                                <hr />
                                <Row className="research-details__instrument">
                                    <Column size={['md-12', 'sm-12']}>
                                        <Paragraph type="highlights">{t('research.keyTakeaways')}</Paragraph>
                                        <ul>
                                            {data?.keyTakeaways.map(
                                                (item) => <li key={getRandomString()}>{item}</li>,
                                            )}
                                        </ul>
                                        <Button
                                            size="small"
                                            type="secondary"
                                            onClick={onDocumentClick}
                                            className="research-details__instrument__btn"
                                        >
                                            {`${t('research.downloadDocument')} ${data?.instrument?.name || ''} (PDF)`}
                                        </Button>
                                    </Column>
                                    {!isTemporaryHidden && (
                                        <Column size={['md-6', 'sm-12']}>
                                            <Paragraph type="highlights">{t('research.instrument')}</Paragraph>
                                            <img
                                                className="research-details__instrument__image"
                                                src={data?.instrument?.logo}
                                                alt="research-details-instrument"
                                            />
                                            <Title type={3}>{`${data?.instrument?.name}${data?.instrument?.shortName ? ` (${data?.instrument.shortName})` : ''}`}</Title>
                                            <OverviewPanel>
                                                <div className="overview-row">
                                                    <OverviewItem
                                                        size="small"
                                                        title={t('research.instrument.currentPrice')}
                                                        value={`${data?.instrument?.currency || ''} ${data?.instrument?.price}`}
                                                    />
                                                    <OverviewItem
                                                        size="small"
                                                        title={t('research.instrument.performanceYTD')}
                                                        value={data?.instrument?.performance}
                                                        type={TYPE_PERCENT}
                                                        colored
                                                    />
                                                </div>
                                                <div className="overview-row">
                                                    <OverviewItem
                                                        size="small"
                                                        title={t('research.instrument.currentRating')}
                                                        value={
                                                            data?.instrument?.currentRating?.text
                                                        }
                                                        className={`overview-item_${data?.instrument?.currentRating?.type}`}
                                                    />
                                                    <OverviewItem
                                                        size="small"
                                                        title={t('research.instrument.lastRating')}
                                                        value={data?.instrument?.lastRating?.text}
                                                        className={`overview-item_${data?.instrument?.lastRating?.type}`}
                                                    />
                                                </div>
                                            </OverviewPanel>
                                        </Column>
                                    )}
                                </Row>
                                <hr />
                                <div className="research-details__invest">
                                    <Title type={3}>{data?.invest?.title}</Title>
                                    <Paragraph type={3}>{t('research.investDescription')}</Paragraph>
                                </div>
                                <div className="research-details__buttons">
                                    <Button size="small" type="secondary" onClick={onRequest}>
                                        {t('research.requestProposal')}
                                    </Button>
                                    {!isTemporaryHidden && (
                                        <a
                                            href={`tel:${getFormattedPhoneNumber(memberPhone)}`}
                                            type="secondary"
                                            className="button button-small button-secondary"
                                        >
                                            {t('research.callAdvisor')}
                                        </a>
                                    )}
                                </div>
                                <hr />
                                <Paragraph type="secondary" className="research-details__help-text">
                                    {`${t('research.helpTextPart1')} ${data?.helpTextDate} ${t('research.helpTextPart2')}`}
                                </Paragraph>
                            </Column>
                        </Row>
                    </ContentBox>
                </EmptyContent>
            </Preloader>
        </PageSection>
    );
}

ResearchDetails.propTypes = {
    location: PropTypes.shape({
        query: {
            id: PropTypes.number,
            image: PropTypes.string,
            title: PropTypes.string,
            date: PropTypes.string,
            content: PropTypes.string,
        },
    }).isRequired,
    match: PropTypes.shape({
        params: { newsId: PropTypes.string.isRequired },
    }).isRequired,
};

ResearchDetails.defaultProps = {};

export default ResearchDetails;
