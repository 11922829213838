import { checkNullData, stripHtmlTags } from 'utils';
import { atramColumnDateFormat } from 'utils/datetime';
import { getAdaptedDate, getAdaptedDateAtram } from 'utils/formatting';
import { dateTimeFormats } from 'locale';
import { getLocaleString } from 'locale/utils';

export const getNames = (data) => {
    const listOfNames = new Set();

    data.forEach((val) => listOfNames.add(val.name));

    return [...listOfNames];
};

const getPortfolio = ({
    contractId, portfolios, currentPage, currentTab,
}) => {
    const notAvailable = { label: 'N/A', link: '', disabled: true };

    if (!contractId || !portfolios) return notAvailable;

    const findPortfolio = portfolios.find(({ UId }) => UId === contractId);

    if (!findPortfolio) return notAvailable;

    const { Name } = findPortfolio;

    return {
        ...Name,
        state: {
            fromOrigin: 'documents',
            currentPage,
            currentTab,
        },
    };
};

export const adaptDocuments = (
    data,
    downloadDocument,
    t,
    i18n,
    getFormattedDate,
    portfolios,
    tab,
) => {
    if (checkNullData(data)) return [];
    const { language } = i18n;

    return (data?.results || [])
        .map((item) => {
            const {
                id, validTill,
                createDate, filename, retrieveFileAsName, contractId, documentMetadata: {
                    name, isActive, documentCategory: { name: documentTypeName },
                },
            } = item;

            return {
                id,
                nameCol: {
                    value: filename
                    || stripHtmlTags(retrieveFileAsName || getLocaleString(name, language)),
                    onDownload: downloadDocument,
                },
                valid: getFormattedDate(validTill, {
                    hour: dateTimeFormats.TWO_DIGIT,
                    minute: dateTimeFormats.TWO_DIGIT,
                    second: dateTimeFormats.TWO_DIGIT,
                }),
                isActive: t(`advisoryDashboard.documents.${isActive ? 'yes' : 'no'}`),
                filename: filename || '',
                category: getLocaleString(name, language) || '',
                type: getLocaleString(documentTypeName) || '',
                contractId: getPortfolio({
                    contractId,
                    portfolios,
                    currentPage: data?.page,
                    currentTab: tab,
                }),
                fullDate: getAdaptedDateAtram(createDate, t, false, atramColumnDateFormat),
                createDate: getFormattedDate(getAdaptedDate(createDate, t)),
                icon: '',
            };
        });
};
