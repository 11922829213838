export const adaptPositions = (data) => data?.map(
    (item) => (item?.name === 'Liquidity' || item?.id === 1
        ? {
            ...item,
            children: item?.children?.map((child) => ({
                ...child,
                units: '',
                latestPrice: '',
            })),
        }
        : item
    ),
);
