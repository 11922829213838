export const languagesAdapt = (langSources = [], t, dir = () => 'ltr') => {
    if (!Array.isArray(langSources)) {
        console.warn('langSources should be array');

        return [];
    }
    if (typeof t !== 'function') {
        console.warn('t should be function');

        return [];
    }

    return langSources.map((langSource) => ({
        ...langSource,
        label: t(langSource.label),
        dir: dir(langSource.value),
    }));
};
