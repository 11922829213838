import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import ServerError from 'errors/ServerError';
import { portfolioActions } from 'constants/constants';
import { useFormatting, useLocale } from 'locale';
import { APIVersion } from 'additiv-services';
import { getPeriodByFilter } from 'utils';
import { YTD } from 'filters/investementPerformanceFilters';
import { adaptPortfolioDetailsPerformance } from 'pages/pages/Portfolios/pages/SinglePortfolioView/adapters/adaptPortfolioDetailsPerformance';
import { formatValueByType, TYPE_PERCENT } from 'utils/formatting';
import { adaptPortfolios } from '../adapters/adaptPortfolios';

export const usePortfolios = (clientId, proposals) => {
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const { i18n, t } = useTranslation();
    const { getFormattedCurrency, getFormattedNumber } = useFormatting();
    const { locale } = useLocale();

    const getInvestmentOverview = useCallback(async () => {
        setError(null);
        setLoading(true);

        try {
            const params = { language: i18n.language };

            const { data: dataInvestment } = await ServiceManager.portfolioManagement('getInvestmentOverview', [clientId, params, APIVersion.V11]);

            const { data: allPortfolios } = await ServiceManager.portfolioManagement('getAllPortfolios', [clientId, params]);
            const {
                data: historicalPerformance,
            } = await ServiceManager.portfolioManagement('getHistoricalPerformance', [clientId]);

            const investmentDataFromEndpoint = {
                ...dataInvestment,
                Portfolios: allPortfolios,
                HistoricalPerformance: [
                    {
                        SeriesType: 'Portfolio',
                        Values: historicalPerformance.Values,
                    },
                ],
            };

            const portfoliosOverview = await Promise.all(allPortfolios
                .map(async ({ Id }) => ({
                    Id,
                    ...(await ServiceManager.portfolioManagement('getPortfolioOverview', [Id, clientId, params])).data,
                })));

            const { startDate, endDate } = getPeriodByFilter(YTD);
            const vals = startDate === undefined ? {} : {
                from: startDate,
                to: endDate,
            };
            const lastPerformances = await Promise.all(investmentDataFromEndpoint.Portfolios
                .map(async ({ Id }) => {
                    const lastPerformance = await ServiceManager.portfolioManagement('getPortfolioDetailsPerformance', [Id, clientId, {
                        language: i18n.language,
                        ...vals,
                    }]).then((response) => {
                        try {
                            const adaptedData = adaptPortfolioDetailsPerformance(
                                response.data.Values, getFormattedNumber,
                            );
                            let performance = adaptedData?.[0]?.data?.pop()?.[1];

                            if (performance) {
                                performance = formatValueByType(
                                    performance,
                                    TYPE_PERCENT,
                                    true,
                                    getFormattedNumber,
                                );
                            }

                            return performance;
                        } catch (err) {
                            throw new AdapterError(err);
                        }
                    });

                    return { Id, lastPerformance };
                }));


            try {
                setData(
                    adaptPortfolios(investmentDataFromEndpoint, {
                        proposals: proposals.data,
                        portfoliosOverview,
                        portfolioActions,
                        t,
                        getFormattedCurrency,
                        lastPerformances,
                    }),
                );
                setLoading(false);


                return data;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            setError(err.type !== undefined ? err : new ServerError(err));
            setLoading(false);

            return null;
        }
    }, [clientId, getFormattedCurrency, i18n.language, proposals.data, t]);

    useEffect(() => {
        if (proposals?.isLoading) return;

        getInvestmentOverview();
    }, [getInvestmentOverview, proposals.isLoading, locale]);

    return { data, isLoading, error };
};
