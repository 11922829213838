import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import { CHANGE_MODEL } from 'constants/constants';
import { useModelPortfolio } from 'domain/Portfolio';
import { redirectToReadOnlyPositionDetails } from 'domain/Portfolio/adapters/groupPositions';
import { groupComparedPositions } from 'adaptors/groupComparedPositions';
import { useTranslation } from 'react-i18next';
import { useFormatting } from 'locale';

export const useModelChange = ({ portfolio, portfolioPositions = [], baseUrl } = {}) => {
    const { t } = useTranslation();
    const { getFormattedNumber, getFormattedCurrency } = useFormatting();

    const [modelData, setData] = useState({});
    const [modelId, setModelPortfolioId] = useState(null);
    const modelPortfolioOptions = useMemo(() => ({
        productID: portfolio?.productId,
        productId: portfolio?.productId,
        portfolioValue: portfolio?.portfolioValue,
        comparedAllocations: portfolio?.allocationGroups,
        portfolioSecuritiesValue: portfolio?.investedAmount,
        recurringPayment: +portfolio?.recurringPayment,
        projectionYears: +portfolio?.projectionYears,
    }), [
        portfolio?.allocationGroups,
        portfolio?.portfolioValue,
        portfolio?.productId,
        portfolio?.investedAmount,
        portfolio?.recurringPayment,
        portfolio?.projectionYears,
    ]);
    const {
        data, isLoading: isLoadingModelData, error: errorModelData, getModelPortfolio,
    } = useModelPortfolio({ adaptOptions: modelPortfolioOptions });

    // Callbacks
    const onModelChange = useCallback((id) => {
        sessionStorage.setItem(CHANGE_MODEL, JSON.stringify(id));
        setModelPortfolioId(id);
        if (portfolio.modelPortfolioId === +id) {
            setData(null);
        } else {
            getModelPortfolio(id);
        }
    }, [getModelPortfolio, portfolio?.modelPortfolioId]);
    const onModelReset = () => {
        sessionStorage.removeItem(CHANGE_MODEL);
    };

    // Data
    const positions = useMemo(() => groupComparedPositions({
        positions: portfolioPositions,
        modelPositions: modelData?.Positions || portfolioPositions,
        portfolioCurrency: portfolio?.currency || portfolio?.Currency?.CurrencyCode,
        baseUrl,
        t,
        portfolioTotalValue: portfolio?.investedAmount,
        redirectToReadOnlyPositionDetails,
        getFormattedNumber,
        getFormattedCurrency,
    }), [
        portfolio?.Currency?.CurrencyCode,
        portfolio?.InvestedAmount,
        portfolio?.currency,
        portfolioPositions,
        modelData?.Positions,
        baseUrl,
        getFormattedCurrency,
        getFormattedNumber,
        t,
    ]);

    // Effects
    useEffect(() => {
        const modelIdSS = JSON.parse(sessionStorage.getItem(CHANGE_MODEL)) || null;

        if (portfolio?.modelPortfolioId !== +modelIdSS) getModelPortfolio(modelIdSS);
        setModelPortfolioId(modelIdSS);
    }, [getModelPortfolio, portfolio?.modelPortfolioId]);
    useEffect(() => {
        setData(data);
    }, [data]);

    return {
        modelData,
        modelId,
        positions,
        isLoadingModelData,
        errorModelData,
        onModelChange,
        onModelReset,
    };
};
