import { createContext } from 'react';

const initialContext = {
    token: undefined,
    // logic from use useClarityWidget
    clarityWidget: {
        data: null,
        error: null,
        isLoading: false,
        dataUpdatePortfolio: null,
        errorUpdatePortfolio: null,
        isLoadingUpdatePortfolio: false,
    },
};

const ClarityContext = createContext(initialContext);

export default ClarityContext;
