import { renderers } from 'ui-library';
import { CellSwitch } from 'components/renderers';
import { sorterNumber, sorterString } from 'utils/sorting';

const { CellWithCurrentNewValue } = renderers;

export const getComparePositionsColumns = (t) => ([
    {
        key: 'name',
        title: t('portfolios.columns.name'),
        render: CellSwitch,
        sorter: sorterString('name.value'),
    },
    {
        key: 'isin',
        title: t('position.columns.isin'),
        sortable: true,
        sorter: sorterString('isin'),
    },
    {
        title: t('Allocation.columns.allocation'),
        key: 'allocation',
        className: 'text-right',
        sortable: true,
        sorter: sorterNumber('allocation'),
    },
]);

export const getCompareColumns = (t) => ([
    {
        key: 'name',
        title: t('portfolios.columns.name'),
        sortable: true,
        sorter: sorterString('name.label'),
        width: 350,
        minWidth: 350,
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: 'descending',
    },
    {
        key: 'valueData',
        title: t('portfolios.columns.value'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('onBoarding.3rdParty'),
            new: t('onBoarding.proposed'),
        }),
        sorter: sorterNumber('valueData.currentValue'),
    },
    {
        key: 'allocationD',
        title: t('Allocation.columns.allocation'),
        className: 'text-right',
        sorter: sorterNumber('allocationD.currentValue'),
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('onBoarding.3rdParty'),
            new: t('onBoarding.proposed'),
        }),
    },
]);
