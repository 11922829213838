import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Footer } from 'components/Footer';
import { isTemporaryHidden } from 'constants/constants';
import { HeaderContainer as Header } from '../containers';

export const RootTemplate = ({ children, ...props }) => (
    <Fragment>
        <Header {...props} />
        <main className="container">{children}</main>
        {!isTemporaryHidden && <Footer />}
    </Fragment>
);

RootTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

RootTemplate.defaultProps = {};

RootTemplate.displayName = 'RootTemplate';
