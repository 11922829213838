import React, {
    useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { goalCreationSelector, onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import OnBoardingBaseTemplate from '../../../../components/OnBoardingBaseTemplate';
import './SetupPortfolio.css';
import ExoForm from '../../component/ExoForm';

function SetupPortfolio({ onPrev, onPageChange }) {
    const { t } = useTranslation();
    const [formState, setFormState] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    // OnBoarding Domain
    const {
        product, saveGoalDetails,
    } = useOnBoardingSelector(onBoardingDataSelector);
    const {
        dataCurrencies, isLoadingCurrencies, errorCurrencies, getCurrencyCodes,
    } = useOnBoardingSelector(goalCreationSelector);

    // Effects
    useEffect(() => {
        getCurrencyCodes({ productId: +product?.id });
    }, [getCurrencyCodes, product.id]);

    // Callbacks
    const onFormChange = useCallback((data) => {
        setFormState(data);
        setIsSubmitted(false);
    }, []);
    const onSubmit = (params) => {
        saveGoalDetails({ ...params });
        onPageChange('modify');
    };
    const onNextClick = useCallback(() => {
        setIsSubmitted(true);
        saveGoalDetails({ ...formState.goalState });
    }, [formState.goalState, saveGoalDetails]);

    return (
        <OnBoardingBaseTemplate
            title={t('onBoarding.portfolioSetup')}
            error={errorCurrencies}
            isLoading={isLoadingCurrencies}
            className="setup-portfolio"
            prevButton={{
                onClick: onPrev,
            }}
            nextButton={{
                onClick: onNextClick,
            }}
        >
            <ExoForm
                dataCurrencies={dataCurrencies}
                isSubmitted={isSubmitted}
                productId={+product?.id}
                onChange={onFormChange}
                onSubmit={onSubmit}
            />
        </OnBoardingBaseTemplate>
    );
}

SetupPortfolio.propTypes = {
    onPrev: PropTypes.func,
    onPageChange: PropTypes.func,
};

SetupPortfolio.defaultProps = {
    onPrev: () => {},
    onPageChange: () => {},
};

export default SetupPortfolio;
