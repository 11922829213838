import React, {
    createContext, useEffect, useMemo, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash/fp';
import { useGetClientInfo } from './hooks/useGetClientInfo';
import { useWithInvestmentOverview } from '../../pages/pages/Dashboard/hooks/useWithInvestmentOverview';
import { contactIdSelector } from '../../redux/auth/authSelectors';

const CLIENT_NAME = 'CLIENT_NAME';

export const removeClientName = () => sessionStorage.removeItem(CLIENT_NAME);

const ClientProfileContext = createContext({});
const ClientProfileProvider = (props) => {
    const {
        children,
        clientName: clientNameProp,
        withAdditionalInfo,
    } = props;
    const clientId = useSelector(contactIdSelector, isEqual);
    const clientNameState = useRef(clientNameProp);
    const clientNameCached = sessionStorage.getItem(CLIENT_NAME);
    const clientInfo = useGetClientInfo({ clientId, withAdditionalInfo });
    const clientName = useMemo(() => clientNameCached || clientNameState?.current || clientInfo.data?.Name || '',
        [clientNameCached, clientNameState, clientInfo.data?.Name]);
    const {
        data: { productIds }, isLoading: isLoadingOverview, error: errorOverview,
    } = useWithInvestmentOverview({ clientId });

    useEffect(() => {
        if (clientName?.length) sessionStorage.setItem(CLIENT_NAME, clientName);
    }, [clientName]);

    useEffect(() => () => removeClientName(), []);

    return (
        <ClientProfileContext.Provider
            value={{
                clientId, clientName, ...clientInfo, productIds, isLoadingOverview, errorOverview,
            }}
        >
            {children}
        </ClientProfileContext.Provider>
    );
};

ClientProfileProvider.propTypes = {
    clientName: PropTypes.string,
    withAdditionalInfo: PropTypes.bool,
    children: PropTypes.node,
};

ClientProfileProvider.defaultProps = {
    clientName: undefined,
    withAdditionalInfo: true,
    children: null,
};

export {
    ClientProfileContext,
    ClientProfileProvider,
};
