import React, { useMemo } from 'react';
import {
    OVERVIEW_SIZE_MEDIUM, OverviewItem, OverviewPanel,
} from 'components/Overview';
import { Column, Row } from 'ui-library';
import { TYPE_PERCENT, TYPE_RATIO } from 'utils/formatting';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EXECUTION_ONLY_ID } from '../../common/constants';

function Overview(props) {
    const {
        data,
        portfolioId,
        productId,
        disableChangeStrategy,
        disableChangeModel,
        accountAttributes,
        lastPerformanceValue,

    } = props;
    const { t } = useTranslation();

    const isChangeStrategyHidden = productId === EXECUTION_ONLY_ID || disableChangeStrategy;
    const strategyRender = useMemo(() => {
        const value = accountAttributes.accountType;

        if (isChangeStrategyHidden) {
            return (
                <OverviewItem
                    title={t('portfolios.columns.accountType')}
                    value={value}
                    size={OVERVIEW_SIZE_MEDIUM}
                />
            );
        }

        return (
            <OverviewItem
                title={t('portfolios.columns.accountType')}
                value={value}
                linkLabel={t('overview.changeStrategy')}
                link={`${portfolioId}/actions/change-strategy`}
                size={OVERVIEW_SIZE_MEDIUM}
            />
        );
    }, [
        data.strategy,
        isChangeStrategyHidden,
        portfolioId,
        t,
        productId,
        accountAttributes.accountType,
    ]);
    const modelPortfolioRender = useMemo(() => {
        if (disableChangeModel) {
            return (
                <OverviewItem
                    title={t('overview.initialContributionDate')}
                    value={accountAttributes.initialContributionDate}
                    size={OVERVIEW_SIZE_MEDIUM}
                />
            );
        }

        return (
            <OverviewItem
                title={t('overview.modelPortfolio')}
                value={accountAttributes.initialContributionDate}
                linkLabel={t('overview.changeModel')}
                link={`${portfolioId}/actions/change-model`}
                size={OVERVIEW_SIZE_MEDIUM}
            />
        );
    }, [data.modelPortfolio, disableChangeModel, portfolioId, t, accountAttributes]);

    return (
        <OverviewPanel>
            <Row>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('overview.netAssetValue')}
                        value={data.portfolioValue}
                        type={TYPE_RATIO}
                        currency={data.currency}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                    {strategyRender}
                </Column>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('overview.ytdPerformance')}
                        value={lastPerformanceValue}
                        type={TYPE_PERCENT}
                        size={OVERVIEW_SIZE_MEDIUM}
                        colored
                    />
                    <OverviewItem
                        title={t('portfolios.columns.investmentMandate')}
                        value={data.product}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                </Column>
                <Column className="overview-column" size={['md-6', 'sm-6', 'lg-4']}>
                    <OverviewItem
                        title={t('overview.trustFeeRate')}
                        value={accountAttributes.trustFeeRate}
                        size={OVERVIEW_SIZE_MEDIUM}
                    />
                    {modelPortfolioRender}
                </Column>
            </Row>
            <h3 className="disclosure">*based on preliminary data and does not take into account accrued income and expenses.</h3>
        </OverviewPanel>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        portfolioValue: PropTypes.number,
        currency: PropTypes.string,
        strategy: PropTypes.string,
        modelPortfolio: PropTypes.string,
        product: PropTypes.string,
        performance: PropTypes.number,
        sharpRatio: PropTypes.number,
        expectedReturn: PropTypes.number,
        expectedRisk: PropTypes.number,
        firstInvestment: PropTypes.string,
        offerName: PropTypes.string,
    }),
    portfolioId: PropTypes.string.isRequired,
    productId: PropTypes.number.isRequired,
    disableChangeStrategy: PropTypes.bool,
    disableChangeModel: PropTypes.bool,
    accountAttributes: PropTypes.shape({
        initialContributionDate: PropTypes.string,
        trustFeeRate: PropTypes.string,
        accountType: PropTypes.string,
    }),
    lastPerformanceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Overview.defaultProps = {
    data: {},
    disableChangeStrategy: false,
    disableChangeModel: false,
    accountAttributes: {},
    lastPerformanceValue: null,
};

export default Overview;
