import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PageVariant } from 'ui-library';
import PageVariantWrapper from 'components/PageVariantWrapper';
import history from '../../services/history';

function ConfirmationPage(props) {
    const { location } = props;
    const { t } = useTranslation();

    return (
        <PageVariantWrapper>
            <PageVariant
                type="success"
                title={location.state ? location.state.title : t('confirm.title')}
                message={location.state ? location.state.message : ''}
                actionButtonLabel={
                    location.state ? location.state.confirmButton : t('confirm.button')
                }
                onActionButtonClick={() => {
                    history.push(location.state.redirectURL);
                }}
            />
        </PageVariantWrapper>
    );
}

ConfirmationPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            title: PropTypes.string,
            message: PropTypes.string,
            redirectURL: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

ConfirmationPage.defaultProps = {};

export default ConfirmationPage;
