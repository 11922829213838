import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumberInput, Select, TextInput } from 'ui-library';
import { useLocale } from 'locale';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { adaptCurrencies } from 'adaptors/adaptCurrencies';
import useFormatting from 'locale/useFormatting';
import { useSetupPortfolioForm } from './hooks/useSetupPortfolioForm';
import { adaptProducts } from '../../../ProductSelection/adaptors/adaptProducts';
import './ExoForm.css';

function ExoForm(props) {
    const {
        active,
        dataCurrencies,
        productId,
        isSubmitted,
        onChange: onChangeProp,
        onSubmit,
    } = props;
    const { t } = useTranslation();
    const { numberSeparators } = useLocale();
    const { getFormattedNumber } = useFormatting();
    // OnBoarding Domain
    const {
        getGoalDetails, productOffer,
    } = useOnBoardingSelector(onBoardingDataSelector);

    // Data
    const goalDetails = useMemo(() => getGoalDetails() || {}, [getGoalDetails]);
    const defaultData = useMemo(() => ({
        goalName: goalDetails?.goalName,
        initialInvestment: goalDetails?.initialInvestment,
        selectedCurrency: goalDetails?.selectedCurrency,
    }), [goalDetails]);
    const listCurrency = useMemo(() => {
        const products = adaptProducts(dataCurrencies, {
            productOffer,
        });
        const currencies = (products || []).find((i) => i.id === productId)?.currencies;

        return adaptCurrencies(currencies || []);
    }, [dataCurrencies, productOffer, productId]);
    const defaultCurrency = useMemo(() => listCurrency.find((item) => item.label === 'USD'), [listCurrency]);
    const minimumInvestmentValue = useMemo(() => {
        const products = adaptProducts(dataCurrencies, { t, getFormattedNumber, productOffer });

        return (products || []).find(
            (i) => i.id === productId,
        )?.minimumInvestmentValue;
    },
    [dataCurrencies, getFormattedNumber, productOffer]);

    // Helpers hooks
    const {
        values, control, formErrors, onChange, handleSubmit,
    } = useSetupPortfolioForm({ defaultData, minimumInvestmentValue });
    const { selectedCurrency } = values;

    // Effects
    useEffect(() => {
        if (!selectedCurrency && !defaultData?.selectedCurrency) onChange('selectedCurrency')(defaultCurrency);
    }, [defaultCurrency, onChange, defaultData.selectedCurrency, selectedCurrency]);
    useEffect(() => {
        if (isSubmitted) handleSubmit(onSubmit)();
    }, [isSubmitted, handleSubmit, onSubmit]);
    useEffect(() => {
        onChangeProp({ goalState: values });
    }, [onChangeProp, values]);

    // Renderers
    const selectAfter = useMemo(() => {
        if (listCurrency.length > 0) {
            return (
                <Controller
                    name="selectedCurrency"
                    control={control}
                    defaultValue={selectedCurrency?.value}
                    render={({ value }) => (
                        <Select
                            options={listCurrency}
                            value={value}
                            error={formErrors.selectedCurrency}
                            onChange={(val) => onChange('selectedCurrency')(
                                listCurrency.find((item) => item.value === val),
                            )}
                        />
                    )}
                />

            );
        }

        return null;
    }, [listCurrency, control, selectedCurrency, formErrors.selectedCurrency, onChange]);

    if (!active) return null;

    return (
        <form className="setup-portfolio-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="goalName"
                control={control}
                defaultValue={undefined}
                render={({ value }) => (
                    <TextInput
                        name="goalName"
                        label={t('onBoarding.portfolioSetup.portfolioName')}
                        onChange={(e) => onChange('goalName')(e.currentTarget.value)}
                        error={formErrors.goalName}
                        value={value}
                    />
                )}
            />
            <Controller
                name="initialInvestment"
                control={control}
                defaultValue={undefined}
                render={({ value }) => (
                    <NumberInput
                        name="initialInvestment"
                        label={t('onBoarding.portfolioSetup.initialInvestment')}
                        addonAfter={selectAfter}
                        value={value}
                        onChange={(v) => {
                            onChange('initialInvestment')(v === '' ? undefined : v);
                        }}
                        error={formErrors.initialInvestment}
                        withSelectBox
                        validation={{ minValue: 0 }}
                        {...numberSeparators}
                    />
                )}
            />
        </form>
    );
}

ExoForm.propTypes = {
    active: PropTypes.bool,
    dataCurrencies: PropTypes.shape({
        ProductOffers: PropTypes.arrayOf(PropTypes.shape({
            Id: PropTypes.number,
            Products: PropTypes.arrayOf(PropTypes.shape({
                Id: PropTypes.number,
                Name: PropTypes.string,
                Image: PropTypes.string,
                Description: PropTypes.string,
            })),
            Currencies: PropTypes.arrayOf(PropTypes.shape({
                Id: PropTypes.number,
                CurrencyCode: PropTypes.string,
            })),
        })),
    }),
    productId: PropTypes.number,
    isSubmitted: PropTypes.bool,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
};

ExoForm.defaultProps = {
    active: true,
    dataCurrencies: null,
    productId: null,
    isSubmitted: false,
    onChange: () => {},
    onSubmit: () => {},
};

export default ExoForm;
