import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionPanel as Panel, Table } from 'ui-library';
import Preloader from 'components/Preloader';
import { getDocumentsColumns } from '../constants/columns';
import Transactions from '../pages/common/components/Transactions';

function DetailedOrdersAccordion(props) {
    const {
        orders: { isLoadingOrders, errorOrders, data: orders },
        documents: { isLoadingDocuments, errorDocuments, data: documents },
    } = props;
    const { t } = useTranslation();

    return (
        <Accordion defaultActiveKey={['1', '2']}>
            <Panel header={t('confirmation.ordersToBeExecuted')} key="1" className="table-without-last-row-padding">
                <Transactions
                    data={orders}
                    isLoading={isLoadingOrders}
                    error={errorOrders}
                    defaultExpandAllRows
                />
            </Panel>
            <Panel header={t('onBoarding.documents')} key="2" className="documents table-without-last-row-padding">
                <Preloader
                    isLoading={isLoadingDocuments}
                    error={errorDocuments}
                >
                    <Table
                        columns={getDocumentsColumns(t)}
                        data={documents}
                    />
                </Preloader>
            </Panel>
        </Accordion>
    );
}

DetailedOrdersAccordion.propTypes = {
    orders: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.oneOf([
                PropTypes.string,
                PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                    maxLength: PropTypes.number,
                }),
            ]),
            Date: PropTypes.string,
            Type: PropTypes.string,
            Number: PropTypes.number,
            Price: PropTypes.number,
            FxRate: PropTypes.number,
            Total: PropTypes.number,
        })),
        isLoadingOrders: PropTypes.bool,
        errorOrders: PropTypes.shape({
            message: PropTypes.string,
        }),
    }),
    documents: PropTypes.shape({
        data: PropTypes.arrayOf(PropTypes.shape({
            icon: PropTypes.shape({
                type: PropTypes.string,
                onClickIcon: PropTypes.func,
            }),
            name: PropTypes.shape({
                link: PropTypes.string,
                value: PropTypes.string,
                onDownload: PropTypes.func,
            }),
        })),
        isLoadingDocuments: PropTypes.bool,
        errorDocuments: PropTypes.shape({
            message: PropTypes.string,
        }),
    }),
};

DetailedOrdersAccordion.defaultProps = {
    orders: {
        data: [],
        isLoadingOrders: false,
        errorOrders: null,
    },
    documents: {
        data: [],
        isLoadingDocuments: false,
        errorDocuments: null,
    },
};

export default DetailedOrdersAccordion;
