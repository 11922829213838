import { getParent, roundAllocations, sum } from 'utils';
import { sortAssets } from 'utils/sortingAllocation';
import { validateData, validateNumber } from 'utils/formatting';
import { isLiquidity } from 'utils/portfolio';
import { LiquidityId } from 'constants/instrument';
import { getLocaleString } from 'locale/utils';

export const adaptPositions = (data, {
    portfolioValue,
    portfolioCurrency,
    nameLength,
    isNew,
    positionLink,
    getFormattedNumber,
    getFormattedCurrency,
    language,
    t,
}) => {
    if (!data) return [];
    const roundedAllocation = data.length && roundAllocations(data);
    const groupToValues = data.reduce((obj, item) => {
        const securityData = { ...item.Security, Id: item.Security.Id || item.Security.id };
        const typeId = securityData.Type?.Id || securityData.typeId;
        const accumulator = { ...obj };
        const roundedAlloc = isNew && +item.Allocation === 0
            ? 0 : roundedAllocation.find((n) => n.id === securityData.Id).value;
        const valueSecurity = portfolioValue * item.Allocation;
        const accParent = securityData.AssetClass
            ? getParent(securityData.AssetClass)
            : getParent(securityData.assetClass, 'parent');
        const accParentName = accParent.Name || getLocaleString(accParent.name, language);
        const currency = securityData.Currency?.CurrencyCode || securityData.currency?.isoCode;
        const name = typeId === 1 ? `${t('common.liquidity')} ${currency}` : validateData(securityData.Name || securityData.name);
        const nameProps = !isLiquidity(securityData) && positionLink
            ? { label: name, link: positionLink && `${positionLink}/${securityData.Id}` } : { value: name };

        accumulator[accParentName] = obj[accParentName] || [];
        accumulator[accParentName].push({
            id: securityData.Id,
            key: `${name}-${securityData.Id}`,
            parentId: accParent.Id || accParent.id,
            typeId,
            nameNew: { maxLength: nameLength, isNew, ...nameProps },
            allocationData: roundedAlloc,
            allocation: `${getFormattedNumber(roundedAlloc)}%`,
            currency,
            valuation: getFormattedCurrency(
                validateNumber(valueSecurity),
                { currency: portfolioCurrency },
            ),
            valuationData: validateNumber(valueSecurity),
            new: { value: roundedAlloc.toFixed(2) },
            newAllocationData: roundedAlloc,
            newAllocation: `${getFormattedNumber(roundedAlloc)}%`,
            icon: {},
            actions: {},
            isNew,
        });

        return accumulator;
    }, {});
    const parentsIds = Object.keys(groupToValues).map((key) => ({
        key, id: groupToValues[key]?.[0]?.parentId,
    }));

    let groups = sortAssets(parentsIds).map(({ key, id }) => {
        const groupMembersData = groupToValues[key];

        const value = sum(groupMembersData, 'valuationData');
        const allocationData = sum(groupMembersData, 'allocationData');

        return {
            id,
            key: `${key}-${groupMembersData[0].parentId}`,
            valuation: getFormattedCurrency(value, {
                currency: portfolioCurrency,
            }),
            value,
            allocation: `${getFormattedNumber(sum(groupMembersData, 'allocationData'))}%`,
            allocationData,
            newAllocationData: sum(groupMembersData, 'newAllocationRaw'),
            nameNew: { value: key, maxLength: 35 },
            name: key,
            children: groupMembersData,
            icon: '',
            actions: {},
        };
    });

    groups = groups.map((groupItem) => {
        const resultGroupItem = { ...groupItem };

        resultGroupItem.valuation = groupItem.valuation;

        resultGroupItem.children = resultGroupItem.children.map((groupMemberItem) => {
            const resultGroupMemberItem = groupMemberItem;

            resultGroupMemberItem.valuation = (groupMemberItem.valuation);
            resultGroupMemberItem.icon = 'action';

            return resultGroupMemberItem;
        });

        return resultGroupItem;
    });

    return groups;
};

const groupPositions = (options) => {
    const {
        positions = [],
        changes = [],
        portfolioValue,
        portfolioCurrency,
        onChange,
        onIncrease,
        onDecrease,
        handleDelete,
        t,
        getFormattedNumber,
        getFormattedCurrency,
    } = (options || {});
    const existingData = changes.reduce((acc, item) => ({
        ...acc, [item.Security?.Id || item?.Id]: item,
    }), {});

    return positions.map((group) => {
        const existingChildren = group.children
            .filter((item) => existingData[item.id]);

        const children = existingChildren
            .map((item) => {
                const isUnRemovable = item.typeId === LiquidityId
                    && (portfolioCurrency === item.currency || existingChildren.length === 1);
                const newAllocationData = existingData[item.id];
                const newAllocation = newAllocationData
                    ? parseFloat(newAllocationData.Allocation)
                    : item.allocationData || 0;

                return {
                    ...item,
                    newAllocationData: newAllocation,
                    valuation: getFormattedCurrency(
                        validateNumber(portfolioValue * newAllocation / 100),
                        { currency: portfolioCurrency },
                    ),
                    new: {
                        ...item.new,
                        value: newAllocation.toFixed(2),
                        onChange: onChange(item.id),
                    },
                    icon: {
                        onIncrease: () => onIncrease(item.id),
                        onDecrease: () => onDecrease(item.id),
                    },
                    actions: {
                        icon: isUnRemovable ? 'questionmark' : 'close-button',
                        action: isUnRemovable ? () => {} : () => handleDelete(item.id),
                        infoText: isUnRemovable ? t('onBoarding.deletePosition.mustBeAtLeastOne') : undefined,
                        text: isUnRemovable ? '' : t('onBoarding.remove'),
                    },
                    NameAndNewAllocation: {
                        ...item.Name,
                        input: {
                            value: newAllocation.toFixed(2),
                            onChange: onChange(item.id),
                        },
                    },
                };
            });

        return ({
            ...group,
            New: { value: '' },
            Icon: {},
            allocation: `${getFormattedNumber(sum(children, 'allocationData'))}%`,
            allocationData: sum(children, 'allocationData'),
            valuation: getFormattedCurrency(
                validateNumber(portfolioValue * sum(children, 'newAllocationData') / 100),
                { currency: portfolioCurrency },
            ),
            children,
        });
    }).filter((group) => group?.children?.length);
};

const formatFooter = (Value, Old, New, {
    t, getFormattedNumber, getFormattedCurrency, portfolioCurrency,
} = {}) => ({
    nameNew: { value: t('confirmation.transactionColumns.total') },
    NameAndNewAllocation: {
        value: t('confirmation.transactionColumns.total'),
        input: `${getFormattedNumber(New, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        })}%`,
    },
    valuation: getFormattedCurrency(
        validateNumber(Value * New / 100),
        { currency: portfolioCurrency },
    ),
    ISIN: '',
    Value: getFormattedNumber(Value, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }),
    Units: '',
    Currency: '',
    LatestPrice: '',
    Performance: undefined,
    ValuationInSecurityCurrency: '',
    UnrealizedGainLoss: '',
    allocation: `${getFormattedNumber(Old, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })}%`,
    new: `${getFormattedNumber(New, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })}%`,
    icon: {},
    actions: {},
});

export const adaptModifyPositions = (options) => {
    const {
        positions, dataSummary,
        changes, changesNonManual, portfolioCurrency,
        positionLink: baseUrl, t, getFormattedNumber, getFormattedCurrency, portfolioValue,
        onChange, onIncrease, onDecrease, handleDelete,
    } = (options || {});

    return {
        positions: groupPositions({
            positions,
            changes,
            changesNonManual,
            portfolioValue,
            portfolioCurrency,
            onChange,
            onIncrease,
            onDecrease,
            handleDelete,
            baseUrl,
            t,
            getFormattedNumber,
            getFormattedCurrency,
        }),
        footer: formatFooter(
            portfolioValue, dataSummary?.Old, dataSummary?.New, {
                t, getFormattedNumber, getFormattedCurrency, portfolioCurrency,
            },
        ),
    };
};
