import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Column } from 'ui-library';
import { OverviewItem, OverviewPanel } from 'components/Overview';
import { TYPE_CURRENCY } from 'utils/formatting';
import Preloader from 'components/Preloader';

function Overview({
    data, isLoading, error,
}) {
    const { t } = useTranslation();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <OverviewPanel>
                <Row>
                    <Column size={['sm-4']}>
                        <OverviewItem
                            title={t('overview.portfolioValue')}
                            value={data.portfolioValue}
                            currency={data.currency}
                            type={TYPE_CURRENCY}
                        />
                    </Column>
                    <Column size={['sm-4']}>
                        <OverviewItem
                            title={t('overview.strategy')}
                            value={data.strategy}
                        />
                    </Column>
                    <Column size={['sm-4']}>
                        <OverviewItem
                            title={t('overview.newStrategy')}
                            value={data.newStrategy}
                        />
                    </Column>
                </Row>
            </OverviewPanel>
        </Preloader>
    );
}

Overview.propTypes = {
    data: PropTypes.shape({
        currency: PropTypes.string,
        portfolioValue: PropTypes.number,
        strategy: PropTypes.string,
        newStrategy: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

Overview.defaultProps = {
    data: {},
    isLoading: true,
    error: null,
};

export default Overview;
