import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { positionsColumns, positionsMobileColumns } from './constants';

function PositionsCompare(props) {
    const {
        data, isLoading, error, columns, defaultExpandAllRows,
    } = props;
    const { t } = useTranslation();
    const { isMobileSize } = useDetectedMobileDevice();

    return (
        <Preloader isLoading={isLoading} error={error}>
            <EmptyContent data={data} text={t('confirmation.noTransactionsFound')}>
                <Table
                    data={data}
                    columns={columns
                    || (isMobileSize ? positionsMobileColumns(t) : positionsColumns(t))}
                    expandedColumn="name"
                    defaultExpandAllRows={defaultExpandAllRows}
                />
            </EmptyContent>
        </Preloader>
    );
}

PositionsCompare.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            AllocationD: PropTypes.shape({
                currentValue: PropTypes.string,
                newValue: PropTypes.string,
            }),
            ValueData: PropTypes.shape({
                currentValue: PropTypes.string,
                newValue: PropTypes.string,
            }),
        }),
    ),
    isLoading: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        render: PropTypes.func,
    })),
    defaultExpandAllRows: PropTypes.bool,
};

PositionsCompare.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
    columns: null,
    defaultExpandAllRows: false,

};

export default PositionsCompare;
