import { useEffect, useState } from 'react';

const WIDGET_KEY = global?.AppConfig?.REACT_APP_SURFLY_WIDGET_KEY;

export const useSurfly = () => {
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (global.window.Surfly !== undefined) {
            setLoading(true);
            const { Surfly } = global.window;
            const settings = {
                // Surfly session options can be set here, or at the Company/Plan levels.
                widget_key: WIDGET_KEY,
                private_session: true, // to make sure only logged in agents can join the call
                require_password: false,
            };

            Surfly.init(settings, (initResult) => {
                if (initResult.success) {
                    // API calls can now be made!
                    if (!Surfly.isInsideSession) {
                        global.window.Surfly.button();
                        setLoading(false);
                    }
                } else {
                    console.error('Surfly was unable to initialize properly.');
                    setError('Surfly was unable to initialize properly.');
                }
            });
        }
    }, []);

    return { isLoading, error };
};
