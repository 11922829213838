export const adaptResearches = ({ data, language, schema } = {}) => {
    const schemaAdapted = (schema || []).reduce((acc, item) => ({ ...acc, [item?.id]: item }), {});

    return (data || []).map((item) => {
        const itemData = JSON.parse(item?.data || '{}');
        const submission = itemData?.submissions?.length
            ? itemData?.submissions.find((itm) => itm.language === language)?.submission
            : itemData?.submission;

        return {
            id: item.id,
            image: item.imageUri,
            title: submission?.data?.researchTitle,
            date: schemaAdapted?.[item?.id]?.createdDate,
            content: submission?.data?.leadText,
            linkBase: 'news/research',
        };
    });
};
