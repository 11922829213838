/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Infobox, Title } from 'ui-library';
import Passcode from 'components/Passcode';
import './FuturaePasscode.css';

export const FuturaePasscode = ({
    onSubmit,
    onChange,
    isLoading,
    error,
    infoText,
    passcodeLength,
}) => {
    let intervalId;
    const [passcodeTimer, setPasscodeTimer] = useState(30);
    const [passcode, setPasscode] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        intervalId = setInterval(() => {
            const newPasscodeTime = passcodeTimer - 1;

            if (newPasscodeTime === 0) {
                clearInterval(intervalId);
            }
            setPasscodeTimer(newPasscodeTime);
        }, 1000);
    }, []);

    const changeTanHandler = (value) => {
        setPasscode(value);
        onChange(value);
    };

    const submit = (event) => {
        event.preventDefault();
        onSubmit(passcode);
    };

    return (
        <div className="FuturaePasscode content-pane">
            <form onSubmit={submit}>
                <Title type={2}>{t('login.passCode')}</Title>

                {typeof error === 'string' && (
                    <div className="validation-summary-errors">
                        <Infobox error>{t(error)}</Infobox>
                    </div>
                )}

                <p className="info-text">{t(infoText)}</p>

                <Passcode onChange={changeTanHandler} passcodeLength={passcodeLength} />

                <div className="buttons buttons-inline button-group button-group--horiz">
                    <Button
                        type="primary"
                        htmltype="submit"
                        disabled={isLoading || passcode.length !== passcodeLength}
                    >
                        {isLoading ? t('login.validating') : t('login.confirm')}
                    </Button>
                    {/* <Button
                        type="secondary"
                        disabled
                    >
                        {t('login.resendPasscode', { passcodeTimer })}
                    </Button> */}
                    <a href="javascript:void(0);">{t('login.confirmWith2FA')}</a>
                </div>
            </form>
        </div>
    );
};

FuturaePasscode.propTypes = {
    passcodeLength: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    infoText: PropTypes.string,
};

FuturaePasscode.defaultProps = {
    passcodeLength: 6,
    onChange: () => {},
    error: undefined,
    isLoading: false,
    infoText: 'login.enterTheCode',
};
