import React, {
    useState, useEffect, useMemo, useCallback,
} from 'react';
import { TabPane, Tabs, TabsExtraItem } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import Preloader from 'components/Preloader';
import EmptyContent from 'components/EmptyContent';
import PropTypes from 'prop-types';
import { allocationFormat } from 'utils/formatting';
import {
    BAR,
    LIST,
    PIE_SA,
    PIE,
    INV_ALLOC_DATA_KEYS,
} from 'constants/constants';
import ChartType from 'components/ChartType';
import { useDetectedMobileDevice } from 'hooks/useDetectedMobileDevice';
import { useFormatting } from 'locale';
import { DEFAULT_TOGGLE_STATE, SHOW_TOGGLE } from 'constants/flt';
import { AllocationToogle } from '../AllocationToogle/AllocationToogle';

function AllocationWithILT({
    data: investmentData, isLoading, error, isCustomFlow,
}) {
    const { t } = useTranslation();
    const [withLookThroughtData, setWithLookThroughtData] = useState(DEFAULT_TOGGLE_STATE);

    const data = useMemo(
        () => cloneDeep(investmentData[
            withLookThroughtData
                ? INV_ALLOC_DATA_KEYS.withLookThroughtData
                : INV_ALLOC_DATA_KEYS.withoutLookThroughtData
        ]),
        [withLookThroughtData, investmentData],
    );

    const toogleHandler = useCallback(() => setWithLookThroughtData((prev) => !prev), []);
    const { isMobileSize } = useDetectedMobileDevice();
    const { getFormattedCurrency } = useFormatting();
    const chartPieType = PIE;
    const [chartView, setChartView] = useState(chartPieType);

    const changeView = (type) => () => setChartView(type);
    const numberFormat = (number) => getFormattedCurrency(number, {
        currency: data.currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });
    const labelFormat = (label, value) => `<label class="Form-control-label"><span>${label}</span></label>${numberFormat(
        value?.y || value,
    )}`;

    useEffect(() => {
        if ([PIE, PIE_SA].includes(chartView)) {
            setChartView(chartPieType);
        }
    }, [isMobileSize]);


    const tabs = !isCustomFlow
        ? data?.chart
        : data.chart.filter(({ title }) => ['Asset Classes', 'Asset Class', 'allocationTabs.assetClasses'].includes(title));

    const tabBarExtraContent = useMemo(() => (
        <>
            <TabsExtraItem
                icon="chart-pie"
                isActive={chartView === chartPieType}
                onClick={changeView(chartPieType)}
            />
            {!isCustomFlow && (
                <TabsExtraItem
                    icon="chart-bar-horizontal"
                    isActive={chartView === BAR}
                    onClick={changeView(BAR)}
                />
            ) }
            <TabsExtraItem
                icon="list"
                isActive={chartView === LIST}
                onClick={changeView(LIST)}
            />
        </>
    ), [chartView, changeView]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <Tabs
                className="investment-allocation"
                defaultActiveKey="1"
                tabBarExtraContent={tabBarExtraContent}
            >
                {tabs?.map((item, i) => (
                    <TabPane tab={item.title === 'Asset Classes' ? 'Asset Allocation Overview' : item.title} key={(i + 1).toString()}>
                        <EmptyContent
                            data={item.data?.[chartView]?.data}
                            text={t('dashboard.noData')}
                        >
                            <ChartType
                                data={item.data?.[chartView]?.data}
                                title={item.title || item.name}
                                labels={item.data?.[chartView]?.labels}
                                currency={data.currency}
                                chartView={chartView}
                                labelFormat={labelFormat}
                                numberFormat={numberFormat}
                                percentFormat={allocationFormat}
                                barHeight={60}
                                t={t}
                            />
                            { SHOW_TOGGLE && (
                                <AllocationToogle
                                    value={withLookThroughtData}
                                    onChange={toogleHandler}
                                />
                            )}
                        </EmptyContent>
                    </TabPane>
                ))}
            </Tabs>
        </Preloader>
    );
}

AllocationWithILT.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
                data: PropTypes.shape({
                    [PropTypes.string]: PropTypes.arrayOf(
                        PropTypes.shape({
                            name: PropTypes.string,
                            value: PropTypes.number,
                        }),
                    ),
                }),
            }),
        ),
        currency: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    isCustomFlow: PropTypes.bool,
    error: PropTypes.oneOf([PropTypes.bool, PropTypes.string]),
};

AllocationWithILT.defaultProps = {
    data: { chart: [] },
    isLoading: true,
    isCustomFlow: false,
    error: null,
};

export default AllocationWithILT;
