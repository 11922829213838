import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { getfromDataObject } from 'utils/formatting';
import { useFormatting } from 'locale';
import { onBoardingDataSelector, useOnBoardingSelector } from 'domain/OnBoarding';
import { adaptModelPortfolio } from 'domain/Portfolio/adapters/adaptModelPortfolio';

export const useComparePortfolio = (contactId, productId, options) => {
    const { i18n: { language }, t } = useTranslation();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState({ overview: {}, positions: [] });
    const [, setPortfolioId] = useState(null);
    const {
        getFormattedDate,
        getFormattedNumber,
        getFormattedCurrency,
    } = useFormatting();

    // OnBoarding Domain
    const { getRiskCategory } = useOnBoardingSelector(onBoardingDataSelector);

    const getComparedPortfolioId = useCallback(async () => {
        setLoading(true);
        setError(null);

        const chosenRiskCategory = (await getRiskCategory(contactId))?.Id;
        // TODO: Remove hardcode (fixed for demos)
        const hardCodedProduct = +productId === 4 ? 6 : productId;

        const { data: modelPortfolios } = await ServiceManager.portfolioManagement('getModelPortfolios', [hardCodedProduct, { language }]);
        const modelPortfolioIdx = modelPortfolios
            .findIndex((item) => item.RiskCategory.Id === chosenRiskCategory);
        const modelPortfolio = modelPortfolios[modelPortfolioIdx !== 4 ? modelPortfolioIdx + 1 : 0];
        const modelPortfolioId = getfromDataObject(modelPortfolio, 'Id', undefined);

        setPortfolioId(modelPortfolioId);

        return modelPortfolioId;
    }, [contactId, getRiskCategory, language, productId]);

    useEffect(() => {
        setError(null);
        setLoading(true);

        getComparedPortfolioId().then(((modelPortfolioId) => {
            const params = { language };

            if (options?.currency?.value) params.currencyId = options?.currency?.value;

            ServiceManager.portfolioManagement('getModelPortfolio', [modelPortfolioId, params])
                .then((response) => {
                    try {
                        setData(adaptModelPortfolio({
                            data: response.data,
                            ...options,
                            isProposed: true,
                            t,
                            getFormattedDate,
                            getFormattedNumber,
                            getFormattedCurrency,
                        }));
                        setLoading(false);
                    } catch (err) {
                        throw new AdapterError(err);
                    }
                })
                .catch(handlerRequestCanceling(
                    HandlerError({ setLoading, setError }),
                ));
        }));
    }, [
        getComparedPortfolioId,
        getFormattedCurrency,
        getFormattedDate,
        getFormattedNumber,
        options?.currency?.value,
        language,
        t,
    ]);

    return { error, data, isLoading };
};
