import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './ReviewPageOverview.css';
import { adaptReviewPageOverview } from './adapters/adaptReviewPageOverview';
import { OverviewInformation } from '../../../../../components/OverviewInformation';

function ReviewPageOverview(props) {
    const {
        data: {
            portfolioValue, currency, modelPortfolio, strategy, product,
        },
        onStrategyChange,
        onModelChange,
    } = props;
    const { t } = useTranslation();

    const reviewPageOverviewRows = useMemo(() => adaptReviewPageOverview(
        {
            portfolioValue,
            currency,
            t,
            product,
            strategy,
            onStrategyChange,
            onModelChange,
            modelPortfolio,
        },
    ),
    [
        portfolioValue,
        currency,
        t,
        product,
        strategy,
        onStrategyChange,
        onModelChange,
        modelPortfolio,
    ]);

    return (
        <OverviewInformation panelClassName="review-page-overview" columnSize={['sm-3']} rowsData={reviewPageOverviewRows} />
    );
}

ReviewPageOverview.propTypes = {
    data: PropTypes.shape({
        portfolioValue: PropTypes.number,
        currency: PropTypes.string,
        strategy: PropTypes.string,
        modelPortfolio: PropTypes.string,
        product: PropTypes.string,
        performance: PropTypes.number,
        sharpRatio: PropTypes.number,
        expectedReturn: PropTypes.number,
        expectedRisk: PropTypes.number,
        firstInvestment: PropTypes.string,
    }),
    onStrategyChange: PropTypes.func,
    onModelChange: PropTypes.func,
};

ReviewPageOverview.defaultProps = {
    data: {},
    onStrategyChange: () => {},
    onModelChange: () => {},
};

export default ReviewPageOverview;
