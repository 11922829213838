export const CUSTOM_PROPS = {
    colors: {
        Accent: [
            'accent-default',
            'accent-lighter',
            'accent-light',
            'accent-dark',
            'accent-darker',
        ],
        Primary: [
            'primary-default',
            'primary-lighter',
            'primary-light',
            'primary-dark',
            'primary-darker',
        ],
        Secondary: [
            'secondary-default',
            'secondary-lighter',
            'secondary-light',
            'secondary-dark',
            'secondary-darker',
        ],
        Positive: [
            'positive-default',
            'positive-lighter',
            'positive-light',
            'positive-dark',
            'positive-darker',
        ],
        Negative: [
            'negative-default',
            'negative-lighter',
            'negative-light',
            'negative-dark',
            'negative-darker',
        ],
        Others: [
            'white',
            'background-base',
            'background-header',
            'header-navigation-active',
            'header-navigation-color',
        ],
    },
};
