export const adaptCashFlowsWithInflowsAndOutflows = (options = {}) => {
    const {
        KPIValues, marketValue, beginLastMonth, beginLastYear,
    } = (options || {});
    const t = options?.t || ((d) => d);
    const getFormattedNumber = options?.getFormattedNumber || ((d) => d);

    // Beginning value
    const beginYTD = beginLastYear || 0;
    const beginMonth = beginLastMonth || 0;
    const beginSI = 0;

    // Inflows
    const inflowsValueMonth = KPIValues?.LastMonthPerf?.TotalInflows || 0;
    const inflowsValueYTD = KPIValues?.YtdPerf?.TotalInflows || 0;
    const inflowsSI = KPIValues?.InceptionPerf.TotalInflows || 0;

    // Outflows
    const outflowsValueMonth = KPIValues?.LastMonthPerf?.TotalOutflows || 0;
    const outflowsValueYTD = KPIValues?.YtdPerf?.TotalOutflows || 0;
    const outflowsSI = KPIValues?.InceptionPerf.TotalOutflows || 0;

    // End amount
    const endAmount = marketValue?.Total || 0;

    // End value cash flows
    const endCashFlowsMonth = beginMonth + inflowsValueMonth - outflowsValueMonth;
    const endCashFlowsYTD = endAmount - beginYTD - inflowsValueYTD + Math.abs(outflowsValueYTD);
    const endCashFlowsSI = beginSI + inflowsSI - outflowsSI;

    // End value cash flows
    const performanceMonth = KPIValues?.LastMonthPerf?.Amount || 0;
    const performanceYTD = KPIValues?.YtdPerf?.Amount || 0;
    const performanceSI = KPIValues?.InceptionPerf?.Amount || 0;

    return [
        {
            KeyValue: t('cashFlows.beginningValue'),
            LastMonth: getFormattedNumber(beginMonth),
            YTD: getFormattedNumber(beginYTD),
            SinceInception: getFormattedNumber(beginSI),
        },
        {
            KeyValue: t('cashFlows.contributions'),
            LastMonth: getFormattedNumber(inflowsValueMonth),
            YTD: getFormattedNumber(inflowsValueYTD),
            SinceInception: getFormattedNumber(inflowsSI),
        },
        {
            KeyValue: t('cashFlows.withdrawals'),
            LastMonth: getFormattedNumber(outflowsValueMonth),
            YTD: getFormattedNumber(outflowsValueYTD),
            SinceInception: getFormattedNumber(outflowsSI),
        },
        {
            KeyValue: t('cashFlows.gainsLosses'),
            LastMonth: getFormattedNumber(endCashFlowsMonth),
            YTD: getFormattedNumber(endCashFlowsYTD),
            SinceInception: getFormattedNumber(endCashFlowsSI),
        },
        // {
        //     KeyValue: t('cashFlows.performance'),
        //     LastMonth: getFormattedNumber(performanceMonth),
        //     YTD: getFormattedNumber(performanceYTD),
        //     SinceInception: getFormattedNumber(performanceSI),
        // },
        {
            KeyValue: t('cashFlows.endingValue'),
            LastMonth: getFormattedNumber(endAmount),
            YTD: getFormattedNumber(endAmount),
            SinceInception: getFormattedNumber(endAmount),
        },
    ];
};
