import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import EmptyContent from 'components/EmptyContent';
import Preloader from 'components/Preloader';
import { getClassNames } from 'utils';
import './Ratings.css';

function Ratings(props) {
    const { isLoading, data, error } = props;
    const { t } = useTranslation();

    const countOfRows = useMemo(() => {
        const elements = data?.length;
        const defaultRows = 3;

        if (elements <= defaultRows) {
            return elements;
        }

        return defaultRows;
    }, [data]);

    const tabletLastItem = useMemo(() => {
        const elements = data?.length;

        return Math.ceil(elements / 2);
    }, [data]);

    const className = useMemo(() => getClassNames(
        'ratings',
        `ratingsRowsDesktop${countOfRows}`,
        `tabletLastItem${tabletLastItem}`,
    ),
    [countOfRows, tabletLastItem]);

    return (
        <Preloader isLoading={isLoading} error={error}>
            <div className={className}>
                <EmptyContent
                    data={data}
                    text={t('confirmation.ratings.noData')}
                >
                    {data?.map((item) => (
                        <div className="field" key={item.name}>
                            <span className="field-name">{item.name}</span>
                            <span className="field-value">{item.value}</span>
                        </div>
                    ))}
                </EmptyContent>
            </div>
        </Preloader>
    );
}

Ratings.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
        }),
    ),
    error: PropTypes.shape({
        type: PropTypes.string,
        message: PropTypes.string,
    }),
};

Ratings.defaultProps = {
    data: [],
    isLoading: true,
    error: null,
};
export default Ratings;
