import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import ServerError from 'errors/ServerError';
import { useProposals } from '../../../hooks/useProposals';

export const useAcceptProposal = (clientId, portfolioId, proposalId) => {
    const [isAccepting, setAccepting] = useState(false);
    const [errorAccepting, setError] = useState(null);
    const { t } = useTranslation();

    const {
        dataProposals, isLoadingProposals, errorProposals,
    } = useProposals(clientId, portfolioId);
    const otherProposals = useMemo(() => dataProposals
        .filter(({ Id }) => Id !== Number(proposalId)), [dataProposals, proposalId]);

    const rejectOtherProposals = useCallback(async () => {
        if (otherProposals.length === 0) return;

        try {
            await Promise.all(otherProposals.map(({ Id }) => ServiceManager.portfolioManagement('patchPortfolioProposal', [
                clientId,
                Number(Id),
                { PortfolioProposalStatusId: { DoUpdate: true, Value: 4 } },
            ])));
        } catch (errReject) {
            setAccepting(false);
            setError(errReject);
            throw new ServerError(errReject);
        }
    }, [clientId, otherProposals]);
    const onAccept = useCallback(async () => {
        setAccepting(true);
        setError(null);

        try {
            await ServiceManager.portfolioManagement('postPortfolioRebalance', [
                clientId,
                portfolioId,
                { PortfolioProposalId: Number(proposalId), ForceOrderGeneration: true },
            ]);

            await ServiceManager.portfolioManagement('patchPortfolioProposal', [
                clientId,
                Number(proposalId),
                { PortfolioProposalStatusId: { DoUpdate: true, Value: 3 } },
            ]);

            try {
                await rejectOtherProposals();

                setAccepting(false);
                history.push('/success-page', {
                    title: t('confirm.title'),
                    message: t('confirmation.acceptExecuteContent'),
                    confirmButton: t('confirmation.backToPortfolio'),
                    redirectURL: `/portfolios/${portfolioId}`,
                });
            } catch (errPatch) {
                setAccepting(false);
                setError(errPatch);
                throw new AdapterError(errPatch);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({ setError, setLoading: setAccepting }),
            )(err);

            throw err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, portfolioId, proposalId, rejectOtherProposals, t]);

    return {
        onAccept,
        isAccepting: isAccepting || isLoadingProposals,
        errorAccepting: errorAccepting || errorProposals,
    };
};
