import React, { useEffect, useMemo, useState } from 'react';
import {
    ContentBox, Modal, Accordion, AccordionPanel as Panel, Infobox,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import history from 'services/history';
import Preloader from 'components/Preloader';
import PageHeader from 'components/PageHeader';
import ButtonsBlockRow from 'components/ButtonsBlockRow';
import ChangeModelOverview from 'components/ChangeModelOverview';
import {
    usePortfolioSelector,
    portfolioSelector,
    orderBookSelector,
} from 'domain/Portfolio';
import Analysis from 'components/Analysis/Analysis';
import PositionsCompare from 'components/PositionsCompare';
import { useRebalanceAndChangeModelAllocationData } from 'pages/pages/Portfolios/hooks/useRebalanceAndChangeModelAllocationData';
import { AllocationCompareWithITL } from 'components/AllocationCompareWithITL/AllocationCompareWithITL';
import { useModelList } from 'hooks/useModelList';
import { useBenchmarks } from 'hooks/useBenchmarks';
import { useModelChange } from 'hooks/useModelChange';
import { useAnalysisDetails } from 'hooks/useAnalysisDetails';
import { contactIdSelector } from '../../../../../../redux/auth/authSelectors';
import { onPreventRedirect } from '../../common/utils';

function ChangeModelAdvisory(props) {
    const { match: { params: { portfolioId } } } = props;
    const { t } = useTranslation();
    const [errorSameStrategy, setErrorSameStrategy] = useState(false);

    const clientId = useSelector(contactIdSelector);

    // Portfolio Domain
    const {
        data, dataRaw, isLoading, error,
    } = usePortfolioSelector(portfolioSelector);
    const {
        data: orderBook, isLoading: isLoadingOrderBook, error: errorOrderBook, getOrderBook,
    } = usePortfolioSelector(orderBookSelector);

    useEffect(() => {
        getOrderBook();
    }, [getOrderBook]);

    // Hooks
    const baseUrl = `/portfolios/${portfolioId}/actions/change-model/position/${data?.productId}`;
    const {
        data: strategyList, isLoading: isLoadingModelList, error: errorModelList,
    } = useModelList({ productId: data?.productId, riskCategoryId: data?.riskCategoryId });
    const {
        modelData, positions, modelId,
        isLoadingModelData, errorModelData, onModelChange, onModelReset,
    } = useModelChange({ portfolio: data, portfolioPositions: dataRaw?.Positions, baseUrl });
    const {
        benchmark, benchmarkOptions, benchmarkSelected, onBenchmarkChange, isLoadingBenchmark,
    } = useBenchmarks(data, isLoading, true);
    const strategyForAnalysis = useMemo(() => (modelData?.overview ? modelData
        : null), [modelData]);

    const {
        analysisData, isLoadingModel, errorModel, onPerformanceChange, performanceSelected,
    } = useAnalysisDetails({
        clientId,
        portfolio: data,
        strategy: strategyForAnalysis,
        positions,
        isLoading,
        benchmark,
    });

    const {
        alocationsData,
        isAllocationLoading,
        allocationError,
    } = useRebalanceAndChangeModelAllocationData({
        clientId,
        portfolioId,
        modelPortfolioId: modelId,
        isLoading,
        currency: modelData?.currency,
        showNewAllocations: !!modelId,
    });

    // Callbacks
    const onModelChangeFunc = (val) => {
        onModelChange(val);
        setErrorSameStrategy(false);
    };
    const onCancel = (url) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onModelReset();
                history.push(url);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onDiscardChanges = () => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                onModelReset();
                history.push(`/portfolios/${portfolioId}`);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };
    const onConfirm = () => {
        if (data?.modelPortfolioId === +modelId || !modelId) {
            setErrorSameStrategy(true);

            return;
        }

        history.push(`/portfolios/${portfolioId}/actions/change-model/orders`);
    };

    // Renderers
    const AllocationRender = useMemo(
        () => (
            <AllocationCompareWithITL
                data={alocationsData}
                isLoading={isLoading || isAllocationLoading}
                error={error || allocationError}
            />

        ),
        [
            alocationsData,
            isAllocationLoading,
            allocationError,
            isLoading,
            error,
        ],
    );

    return (
        <ContentBox className="change-model change-model-portfolio">
            <Preloader
                isLoading={isLoadingModelList || isLoadingOrderBook}
                error={errorModelList || errorOrderBook}
            >
                <PageHeader
                    title={t('overview.changeModel')}
                    breadCrumbsCurrent={t('overview.changeModel')}
                    breadCrumbsChildren={[
                        {
                            to: '/portfolios',
                            label: t('portfolios.title'),
                            onClick: (e) => onPreventRedirect(e, '/portfolios', onCancel),
                        },
                        {
                            to: `/portfolios/${portfolioId}`,
                            label: data.title,
                            onClick: (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel),
                        },
                    ]}
                />
                <ChangeModelOverview
                    data={data.overview}
                    selectedModel={modelId}
                    isLoading={isLoadingModelList}
                    error={errorModelList}
                    modelList={strategyList}
                    onModelChange={onModelChangeFunc}
                />
                <Preloader isLoading={isLoadingModelData} error={errorModelData}>
                    <Accordion defaultActiveKey={['1', '2', '3']}>
                        <Panel header={t('confirmation.allocation')} key="1" className="allocation">
                            {AllocationRender}
                        </Panel>
                        <Panel header={t('confirmation.analysis')} key="2" className="analysis">
                            <Analysis
                                data={analysisData}
                                onFilterChange={onPerformanceChange}
                                benchmarkOptions={benchmarkOptions}
                                benchmarkSelected={benchmarkSelected}
                                onBenchmarkChange={onBenchmarkChange}
                                isLoadingBenchmarks={isLoadingBenchmark}
                                performanceSelected={performanceSelected}
                                isLoading={isLoadingModel}
                                error={errorModel}
                            />
                        </Panel>
                        <Panel header={t('confirmation.positions')} className="positions" key="3">
                            <PositionsCompare
                                data={positions}
                                isLoading={isLoading}
                                defaultExpandAllRows
                            />
                        </Panel>
                    </Accordion>
                </Preloader>
                <ButtonsBlockRow
                    leftButton={{
                        type: 'danger',
                        text: t('confirmation.cancel'),
                        onClick: onDiscardChanges,
                    }}
                    primaryButton={{
                        text: t('confirmation.continue'),
                        loading: isLoadingModelData,
                        disabled: orderBook,
                        onClick: onConfirm,
                    }}
                >
                    {errorSameStrategy && <Infobox error>{t('onBoarding.errorChangeSameModel')}</Infobox>}
                </ButtonsBlockRow>
            </Preloader>
        </ContentBox>
    );
}

ChangeModelAdvisory.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            dfsClientId: PropTypes.string.isRequired,
            portfolioId: PropTypes.string.isRequired,
        }),
        path: PropTypes.string,
    }).isRequired,
};

ChangeModelAdvisory.defaultProps = {
};

export default ChangeModelAdvisory;
