import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PortfolioOverviewProvider } from 'domain/Portfolio';
import { contactIdSelector } from 'redux/auth/authSelectors';
import ChangeStrategyRouter from './pages/ChangeStrategy/Router';
import PositionDetailsOverview from './pages/SinglePortfolioView/pages/PositionDetails/PositionDetailsOverview';
import Rebalance from './pages/Rebalance/Router';
import ModifyPortfolioRouter from './pages/ModifyPortfolio/Router';
import QuickEditRouter from './pages/QuickEdit/Router';
import ChangeModelRouter from './pages/ChangeModel/Router';
import { PortfoliosActionsTemplate } from './templates/PortfoliosActionsTemplate';
import { adaptCashFlowsWithInflowsAndOutflows } from '../../../domain/Portfolio/adapters/adaptCashFlowsWithInflowsAndOutflows';
import { CommonAllocationProvider } from './context/CommonAllocationDataProvider';

const PortfoliosActionsRouter = (props) => {
    const {
        match: {
            path,
            params: { portfolioId },
        },
    } = props;
    const clientId = useSelector(contactIdSelector);

    return (
        <PortfoliosActionsTemplate portfolioId={portfolioId}>
            <PortfolioOverviewProvider
                options={{
                    id: Number(portfolioId),
                    clientId: Number(clientId),
                    portfolio: { loadInitially: true },
                    cashFlows: {
                        adaptData: adaptCashFlowsWithInflowsAndOutflows,
                    },
                }}
            >
                <CommonAllocationProvider>
                    <Switch>
                        <Route path={`${path}/modify`} component={ModifyPortfolioRouter} />

                        <Route
                            exact
                            path={`${path}/quick-edit/position/:productId/:positionId`}
                            component={PositionDetailsOverview}
                        />
                        <Route
                            exact
                            path={`${path}/quick-edit/position/:productId/:positionId/:tabId`}
                            component={PositionDetailsOverview}
                        />
                        <Route path={`${path}/quick-edit`} component={QuickEditRouter} />
                        <Route
                            exact
                            path={`${path}/change-strategy/position/:productId/:positionId`}
                            component={PositionDetailsOverview}
                        />
                        <Route
                            exact
                            path={`${path}/change-strategy/position/:productId/:positionId/:tabId`}
                            component={PositionDetailsOverview}
                        />
                        <Route path={`${path}/change-strategy`} component={ChangeStrategyRouter} />
                        <Route
                            exact
                            path={`${path}/change-model/position/:productId/:positionId/:tabId`}
                            component={PositionDetailsOverview}
                        />
                        <Route path={`${path}/change-model`} component={ChangeModelRouter} />
                        <Route path={`${path}/rebalance`} component={Rebalance} />
                        <Redirect from="*" to="/404" />
                    </Switch>
                </CommonAllocationProvider>
            </PortfolioOverviewProvider>
        </PortfoliosActionsTemplate>
    );
};

PortfoliosActionsRouter.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
        }),
    }).isRequired,
};

PortfoliosActionsRouter.defaultProps = {};

PortfoliosActionsRouter.displayName = 'Router';

export default PortfoliosActionsRouter;
