import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    Accordion, AccordionPanel as Panel, ContentBox, Modal,
} from 'ui-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { contactIdSelector } from 'redux/auth/authSelectors';
import withInstrumentAccess from 'hocs/withInstrumentAccess';
import Preloader from 'components/Preloader';
import PageSection from 'components/PageSection';
import useFormatting from 'locale/useFormatting';
import { useDocuments, useInstrumentData } from 'domain/Instrument';
import { adaptInstrumentDocs } from 'adaptors/adaptInstrumentDocs';
import InstrumentDocumentsTable from 'components/InstrumentDocumentsTable';
import AccordionWrapper from 'components/AccordionWrapper';
import PageHeader from 'components/PageHeader';
import {
    BondId, INTEGER, PERCENTAGE, StructuredProductId,
    mapActionTypeName, FundId,
} from 'constants/instrument';
import PositionOverview from 'components/PositionOverview';
import KeyData from 'components/KeyData';
import history from 'services/history';
import { tradingCardSelector, usePortfolioSelector } from 'domain/Portfolio';
import { useFundLookThrough } from 'domain/Instrument/hooks/useFundLookThrough';
import DetailsPerformance from 'components/PositionDetailsPerformace';
import TradeCard from './components/TradeCard';
import { adaptPortfolioDetails } from './adapters/adaptPortfolioDetails';
import { adaptTradingCardPosition } from './adapters/adaptTradingCardPosition';
import { usePortfolioDetails } from '../../../common/hooks/usePortfolioDetails';
import { MODIFY } from './constants';
import { onPreventRedirect } from '../../../common/utils';
import { useTradingCard } from '../../../common/hooks/useTradingCard';
import './PositionDetails.css';
import { FundLookThrough } from './components/FundLookThrough';

function PositionDetails(props) {
    const {
        match: {
            params: {
                positionId, portfolioId, tabId, extraTabId, productId,
            },
            url,
        },
    } = props;
    const clientId = useSelector(contactIdSelector);

    const { t, i18n: { language } } = useTranslation();
    const { getFormattedDate } = useFormatting();

    // Instrument Domain
    const {
        data: dataDocs, isLoading: isLoadingDocs, error: errorDocs, getDocuments,
        errorDocument, getDocumentById,
    } = useDocuments({ instrumentId: positionId });
    const documents = useMemo(() => adaptInstrumentDocs({
        data: dataDocs, getFormattedDate, t, getDocumentById, positionId, language,
    }), [dataDocs, getFormattedDate, t, getDocumentById, positionId, language]);
    const {
        data, isLoading, error, getCommon,
        dataPerformance, isLoadingPerformance, errorPerformance, getPerformance,
        dataBenchmark, isLoadingBenchmark, errorBenchmark,
    } = useInstrumentData({ instrumentId: positionId });

    // Portfolio data
    const {
        dataPortfolioDetails,
        isLoadingPortfolioDetails,
        errorPortfolioDetails,
    } = usePortfolioDetails({
        adaptPortfolioDetails, clientId, portfolioId, positionId, securityTypeId: data?.typeId,
    });

    // Fund Lookthrough
    const {
        data: dataFLT,
        isLoading: isLoadingFLT,
        error: errorFLT,
        changePage: changePageFLT,
    } = useFundLookThrough({
        instrumentId: positionId,
        portfolioId,
        productId,
        tabId,
        language,
    });

    // Trading card
    const { dataTradingCard, isLoadingTradingCard } = useTradingCard(
        adaptTradingCardPosition, clientId, portfolioId, positionId,
    );

    // Data
    const additionalBenchmark = useMemo(() => ({
        name: dataBenchmark.name,
        tradingPriceOfUnderlying: dataBenchmark.tradingPrice,
        calculatedRiskOfUnderlying: dataBenchmark.risk,
    }), [dataBenchmark]);
    const currencyID = data?.currency?.id || dataPortfolioDetails.currencyId || undefined;
    const urlArr = url.split('/');
    const prevPage = urlArr[urlArr.includes('actions') ? 4 : 3];
    const actionType = mapActionTypeName(prevPage, t);
    const priceType = useMemo(() => ([BondId, StructuredProductId].includes(data.typeId)
        ? PERCENTAGE : INTEGER), [data.typeId]);
    const defaultActiveKeyList = useMemo(() => {
        if (extraTabId === '3') return ['1', '3', '4', '6'];
        if (tabId === '1') return ['1'];
        if (tabId === '2') return ['1', '2'];

        return ['1', '2', '3', '6'];
    }, [tabId, extraTabId]);
    const portfolioData = useMemo(() => ({
        price: dataPortfolioDetails.additionalPrice,
        units: dataPortfolioDetails.units,
        quantity: dataPortfolioDetails.quantity,
        currency: dataPortfolioDetails.priceCurrency,
        portfolioCurrency: dataPortfolioDetails.positionCurrency,
        costPrice: dataPortfolioDetails.costPrice,
        costPriceInCurrency: dataPortfolioDetails.additionalCostPrice,
        totalValue: dataPortfolioDetails.totalValueTop,
        totalValueInCurrency: dataPortfolioDetails.additionalTotalValue,
        allocation: dataPortfolioDetails.portfolioAllocation,
        performanceSi: dataPortfolioDetails.performanceSi,
        performanceAbsolute: dataPortfolioDetails.absolutePerformance,
    }), [dataPortfolioDetails]);
    const currencyIso = data?.currency?.isoCode || portfolioData.currency || undefined;

    // Effects
    useEffect(() => {
        getCommon();
    }, [getCommon]);
    useEffect(() => {
        if (data.currency?.id) getPerformance({ currencyId: data.currency?.id });
    }, [data.currency, getPerformance]);
    useEffect(() => {
        if (data.uId) getDocuments({ instrumentUId: data.uId });
    }, [data.uId, getDocuments]);

    const {
        deleteTradingCard,
    } = usePortfolioSelector(tradingCardSelector);

    const onCancel = (u) => {
        Modal.confirm({
            title: t('confirmation.discardChanges'),
            content: t('confirmation.discardChangesContent'),
            okText: t('confirmation.discardChanges'),
            onOk: () => {
                if (prevPage === MODIFY) {
                    sessionStorage.removeItem(MODIFY);
                    deleteTradingCard(clientId, portfolioId);
                }
                history.push(u);
            },
            cancelText: t('confirmation.cancel'),
            className: 'discard-changes',
            okType: 'danger',
        });
    };

    const isHelpTextHidden = useMemo(() => {
        const { currency, portfolioCurrency } = portfolioData;

        return currency === portfolioCurrency;
    }, [portfolioData]);

    const calculatedTradingPrice = useMemo(() => data.tradingPrice
            * (0.01 ** +(data.isPricePercentage || 0)) * (data.factor || 1),
    [data.tradingPrice, data.isPricePercentage, data.factor]);

    return (
        <PageSection className="position-details">
            <Preloader
                isLoading={isLoadingPortfolioDetails || isLoading
                    || isLoadingBenchmark || isLoadingTradingCard || isLoadingPerformance}
                error={errorPortfolioDetails || error || errorBenchmark}
            >
                <ContentBox underline={false} className="position-details-content">
                    <PageHeader
                        breadCrumbsCurrent={data?.name}
                        title={data?.name}
                        breadCrumbsChildren={[
                            {
                                to: '/portfolios/',
                                onClick: actionType
                                    ? (e) => onPreventRedirect(e, '/portfolios/', onCancel)
                                    : undefined,
                                label: t('portfolios.title'),
                            },
                            {
                                to: `/portfolios/${portfolioId}`,
                                onClick: actionType
                                    ? (e) => onPreventRedirect(e, `/portfolios/${portfolioId}`, onCancel)
                                    : undefined,
                                label: dataPortfolioDetails?.portfolioName,
                            },
                            {
                                to: `/portfolios/${portfolioId}/actions/modify`,
                                label: t('portfolios.modify'),
                            },
                        ]}
                    />
                    <AccordionWrapper>
                        <Accordion defaultActiveKey={defaultActiveKeyList}>
                            <Panel header={t('position.overview')} key="1" className="overview">
                                <PositionOverview
                                    data={data}
                                    priceType={priceType}
                                    positionId={positionId}
                                    performance={dataPerformance}
                                    portfolioData={portfolioData}
                                    hideHelpText={isHelpTextHidden}
                                />
                            </Panel>
                            <Panel
                                header={t('position.performance')}
                                key="2"
                                className="performance"
                            >
                                <DetailsPerformance
                                    positionId={positionId}
                                    currencyId={currencyID}
                                    currency={currencyIso}
                                    priceType={priceType}
                                    error={errorPerformance}
                                />
                            </Panel>
                            <Panel header={t('position.keyData')} key="3" className="key-data">
                                <KeyData
                                    positionId={positionId}
                                    additionalData={{
                                        ...additionalBenchmark,
                                        calculatedRisk: dataPerformance?.Volatility,
                                        calculatedExpectedReturn: dataPerformance?.ExpectedReturn,
                                        sharpeRatio: dataPerformance?.SharpeRatio,
                                    }}
                                />
                            </Panel>
                            {data.typeId === FundId && (
                                <Panel header={t('position.fundLookThrough')} key="6" className="table-without-last-row-padding table-without-head-row-padding">
                                    <FundLookThrough
                                        data={dataFLT}
                                        isLoading={isLoadingFLT}
                                        error={errorFLT}
                                        changePage={changePageFLT}
                                    />
                                </Panel>
                            )}
                            <Panel header={t('position.documents')} key="5" className="documents table-without-last-row-padding table-without-head-row-padding">
                                <InstrumentDocumentsTable
                                    data={documents}
                                    error={errorDocs}
                                    isLoading={isLoadingDocs}
                                    errorDocument={errorDocument}
                                />
                            </Panel>
                            <Panel header={t('position.trade')} key="4" className="trade">
                                <TradeCard
                                    clientId={clientId}
                                    portfolioId={portfolioId}
                                    portfolioName={dataPortfolioDetails.portfolioName}
                                    positionId={positionId}
                                    productId={productId}
                                    quantity={dataPortfolioDetails.quantity}
                                    price={calculatedTradingPrice}
                                    tradingUnit={data?.tradingUnit}
                                    roundingLot={data?.roundingLot}
                                    defaultData={dataTradingCard}
                                    portfolioCurrency={
                                        data?.currency?.isoCode || dataPortfolioDetails.currency
                                    }
                                    currency={dataPortfolioDetails.positionCurrency}
                                />
                            </Panel>
                        </Accordion>
                    </AccordionWrapper>
                </ContentBox>
            </Preloader>
        </PageSection>
    );
}

PositionDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            portfolioId: PropTypes.string.isRequired,
            positionId: PropTypes.string.isRequired,
            productId: PropTypes.string.isRequired,
            tabId: PropTypes.string.isRequired,
            extraTabId: PropTypes.string,
        }),
        path: PropTypes.string,
        url: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
        search: PropTypes.string,
    }).isRequired,
};

PositionDetails.defaultProps = {};

export default withInstrumentAccess(PositionDetails);
