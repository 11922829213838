import { renderers } from 'ui-library';
import { SORT_DIRECTION_DESC, sorterNumber, sorterString } from 'utils/sorting';
import { CellSwitch } from '../../renderers';

const { CellWithCurrentNewValue } = renderers;

export const positionsColumns = (t) => [
    {
        key: 'name',
        title: t('confirmation.positionsColumns.name'),
        sortable: true,
        sorter: sorterString('name'),
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: SORT_DIRECTION_DESC,
    },
    {
        key: 'valueData',
        title: t('confirmation.positionsColumns.value'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        minWidth: 220,
        sorter: sorterNumber('valueData.currentValue'),
    },
    {
        key: 'allocationD',
        title: t('confirmation.positionsColumns.allocation'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        minWidth: 120,
        sorter: sorterNumber('allocationD.currentValue'),
    },
];

export const positionsMobileColumns = (t) => [
    {
        key: 'name',
        title: t('position.columns.name'),
        sortable: true,
        sorter: sorterString('name'),
        width: 110,
        minWidth: 110,
        className: 'with-link',
        render: CellSwitch,
        defaultSortOrder: SORT_DIRECTION_DESC,
    },
    {
        key: 'allocationD',
        title: t('proposal.allocation'),
        className: 'text-right',
        render: (data) => CellWithCurrentNewValue(data, {
            current: t('confirmation.current'),
            new: t('confirmation.new'),
        }),
        sorter: sorterNumber('allocationD.currentValue'),
    },
];
