import { createContext } from 'react';

const initialContext = {
    clientId: undefined,
    // logic from useProductOffers hook
    productOffers: {
        data: {},
        error: null,
        isLoading: false,
    },
    // logic from useProducts hook
    products: {
        data: {},
        globalSettings: {},
        error: null,
        isLoading: false,
    },
    // logic from useKnowledgeExperience hook
    knowledgeExperience: {
        data: { Groups: [{ Steps: [{ Questions: [] }] }] },
        error: null,
        isLoading: false,
        errorSending: null,
        isSending: false,
        results: { Groups: [{ Steps: [{ Questions: [] }] }] },
        errorResults: null,
        isLoadingResults: false,
    },
    // logic from useGoalCreation hook
    goalCreation: {
        data: {},
        error: null,
        isLoading: false,
        dataGoalTemplates: null,
        dataGoalPictures: [],
        errorGoalTemplates: null,
        isLoadingGoalTemplates: false,
        dataCurrencies: {},
        errorCurrencies: null,
        isLoadingCurrencies: false,
        dataRecurrences: {},
        errorRecurrences: null,
        isLoadingRecurrences: false,
        dataOptimize: null,
        errorOptimize: null,
        isLoadingOptimize: false,
    },
    // logic from useGoalOptimize hook
    goalOptimize: {
        data: {},
        error: null,
        isLoading: false,
    },
    // logic from useGoalSummary hook
    goalSummary: {
        data: null,
        error: null,
        isLoading: false,
        dataSave: null,
        errorSave: null,
        isLoadingSave: false,
        dataUpdate: null,
        errorUpdate: null,
        isLoadingUpdate: false,
        dataAccept: null,
        errorAccept: null,
        isLoadingAccept: false,
    },
    // logic from useProductFee hook
    productFee: {
        data: {},
        error: null,
        isLoading: false,
    },
};

const OnBoardingContext = createContext(initialContext);

export default OnBoardingContext;
