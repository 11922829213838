import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContentBox from 'ui-library/dist/components/ContentBox';
import { Modal, Title } from 'ui-library';
import RadioButtonsGroup from 'components/RadioBlocks/RadioBlocks';
import { useAccountsSearch } from 'hooks/useAccountsSearch';
import Preloader from 'components/Preloader';
import { useListCurrencies } from 'hooks/useListCurrencies';
import { getClassNames } from 'utils';
import history from 'services/history';
import { adaptListCurrenciesByCode } from 'adaptors/adaptListCurrenciesByCode';
import { useListCountries } from 'hooks/useListCountries';
import InternationalForm from '../../components/InternationalForm/InternationalForm';
import DomesticForm from '../../components/DomesticForm/DomesticForm';
import AccountFrom from '../../components/AccountForm/AccountFrom';
import { usePaymentsData } from '../../hooks/usePaymentsData';

const SetupPayment = (props) => {
    const {
        clientId, onNext,
    } = props;

    const { savePaymentCode, getPaymentCode } = usePaymentsData();

    const defaultPayment = getPaymentCode();
    const [payment, setPayment] = useState(Object.keys(defaultPayment).length > 0 ? defaultPayment : '0');

    useEffect(() => {
        if (typeof savePaymentCode === 'function') savePaymentCode(payment);
    }, [payment, savePaymentCode]);

    const { t } = useTranslation();
    const { clearPayments } = usePaymentsData();
    const paymentOptions = [
        {
            value: '0',
            label: {
                name: t('payments.internationalPayments'),
            },
        },
        {
            value: '1',
            label: {
                name: t('payments.domesticPayments'),
            },
        },
        {
            value: '2',
            label: {
                name: t('payments.accountTransfers'),
            },
        },
    ];

    const costOptions = {
        iban: [{ label: t('payments.sharedCosts'), value: 'SLEV' }],
        noIban: [
            { label: t('payments.sharedCosts'), value: 'SHAR' },
            { label: t('payments.allCostsPayee'), value: 'CRED' },
            { label: t('payments.allCostsClient'), value: 'DEBT' },
        ],
    };

    const { isLoading: isLoadingCurrencies, listCurrencies } = useListCurrencies(
        { adapter: adaptListCurrenciesByCode },
    );
    const { data: cashAccounts, isLoading: isLoadingCashAccounts } = useAccountsSearch(clientId);

    const { data: listCountries, isLoading: isLoadingCountries } = useListCountries();

    const classNames = useMemo(() => getClassNames('payments',
        { 'international-active': payment === '0' },
        { 'domestic-active': payment === '1' },
        { 'account-active': payment === '2' }), [payment]);


    const onCancel = useCallback(() => {
        Modal.confirm({
            title: t('payments.cancelPaymentTitle'),
            content: t('payments.cancelPaymentContent'),
            okText: t('payments.cancelPayment'),
            onOk: () => { clearPayments(); history.push('/dashboard'); },
            okType: 'danger',
            cancelText: t('payments.back'),
            className: 'close-client-modal',
        });
    }, []);

    return (
        <Preloader isLoading={isLoadingCurrencies || isLoadingCashAccounts || isLoadingCountries}>
            <ContentBox className={classNames}>
                <div className="payments-header">
                    <Title type={2}>{t('payments')}</Title>
                </div>
                <div className="payment-selection">
                    <RadioButtonsGroup
                        value={payment}
                        options={paymentOptions}
                        onChange={setPayment}
                        withoutImg
                    />
                </div>
                <div className="payment">
                    <div className="international-form">
                        <InternationalForm
                            listCurrencies={listCurrencies}
                            listCountries={listCountries}
                            listCosts={costOptions}
                            cashAccounts={cashAccounts}
                            onCancel={onCancel}
                            onNext={onNext}
                        />
                    </div>
                    <div className="domestic-form">
                        <DomesticForm
                            listCurrencies={listCurrencies}
                            listCountries={listCountries}
                            cashAccounts={cashAccounts}
                            onCancel={onCancel}
                            onNext={onNext}
                        />
                    </div>
                    <div className="account-form">
                        <AccountFrom
                            listCurrencies={listCurrencies}
                            cashAccounts={cashAccounts}
                            onCancel={onCancel}
                            onNext={onNext}
                        />
                    </div>
                </div>
            </ContentBox>
        </Preloader>
    );
};

SetupPayment.propTypes = {
    clientId: PropTypes.number.isRequired,
    onNext: PropTypes.func.isRequired,
};

SetupPayment.defaultProps = {
};

export default SetupPayment;
