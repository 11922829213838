import React, { useCallback, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import history from 'services/history';
import { OnBoardingProvider, CommonAllocationProvider } from 'domain/OnBoarding';
import { contactIdSelector } from 'redux/auth/authSelectors';
import OnBoardingRouterTemplate from './components/OnBoardingRouterTemplate';
import Start from './pages/Start';
import ProductSelection from './pages/ProductSelection';
import ProductOfferSelection from './pages/ProductOfferSelection';
import RiskProfile from './pages/RiskProfile';
import KnowledgeExperience from './pages/KnowledgeExperience';
import GoalAndPortfolioSetup from './pages/GoalAndPortfolioSetup';
import ReviewPortfolio from './pages/ReviewPortfolio';
import Completion from './pages/Completion';

const Router = (props) => {
    const {
        match: { url, path }, location: { pathname },
    } = props;
    const clientId = useSelector(contactIdSelector);
    const params = /step\/([0-9])/g.exec(pathname);
    const step = params ? Number(params[1]) : 0;

    // Callbacks
    const changeStep = useCallback((stepValue, pageKey) => {
        history.push(`${url}/step/${stepValue}${pageKey ? `/${pageKey}` : ''}`);
    }, [url]);
    const onPrev = useCallback(() => {
        changeStep(step - 1);
    }, [changeStep, step]);
    const onNext = useCallback(() => {
        if (step === 6) history.push('/dashboard');
        else changeStep(step + 1);
    }, [changeStep, step]);
    const onPageChange = (pageKey) => {
        history.push(`${pathname[pathname.length - 1] === '/' ? pathname : `${pathname}/`}${pageKey}`);
    };

    // Page props
    const pageProps = useMemo(() => ({
        onPrev,
        onNext,
        clientId,
        changeStep,
        onPageChange,
        currentStep: step,
    }), [onPrev, onNext, clientId, changeStep, step, onPageChange]);
    const withProps = (Component) => (defProps) => <Component {...pageProps} {...defProps} />;
    const withPropsRiskProfile = (Component) => (defProps) => (
        <Component {...pageProps} onPrev={() => changeStep(1, 'product')} {...defProps} />
    );

    return (
        <OnBoardingProvider options={{ clientId }}>
            <CommonAllocationProvider>
                <OnBoardingRouterTemplate step={step} clientId={clientId} changeStep={changeStep}>
                    <Switch>
                        <Route exact path={`${path}/start`} component={withProps(Start)} />
                        <Route exact path={`${path}/step/1`} render={withProps(ProductOfferSelection)} />
                        <Route exact path={`${path}/step/1/product`} render={withProps(ProductSelection)} />
                        <Route path={`${path}/step/2`} render={withPropsRiskProfile(RiskProfile)} />
                        <Route path={`${path}/step/3`} render={withProps(KnowledgeExperience)} />
                        <Route path={`${path}/step/4`} render={withProps(GoalAndPortfolioSetup)} />
                        <Route path={`${path}/step/5`} render={withProps(ReviewPortfolio)} />
                        <Route exact path={`${path}/step/6`} render={withProps(Completion)} />
                        <Route path={`${path}/*`} render={withProps(Completion)} />
                        <Redirect exact from={`${path}`} to={`${path}/start`} />
                        <Redirect from="*" to="/404" />
                    </Switch>
                </OnBoardingRouterTemplate>
            </CommonAllocationProvider>
        </OnBoardingProvider>
    );
};

Router.propTypes = {
    match: PropTypes.shape({
        url: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

Router.defaultProps = {};

Router.displayName = 'Router';

export default Router;
