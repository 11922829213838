import { get } from 'lodash/fp';

export const SORT_DIRECTION_ASC = 'asc';
export const SORT_DIRECTION_DESC = 'desc';

export const sorterByDateCommon = (column) => (previous, current) => {
    const previousValue = get(column, previous);
    const currentValue = get(column, current);

    if (previousValue === undefined || currentValue === undefined) {
        return 0;
    }

    return new Date(previousValue) - new Date(currentValue);
};

export const sorterByDate = (column) => (...props) => {
    if (props.length === 3) return sorterByDateCommon(column)(...props);

    const { direction } = props[1];

    return props[0].sort((previous, current) => {
        const previousValue = get(column, previous);
        const currentValue = get(column, current);

        if (previousValue === undefined || currentValue === undefined) {
            return 0;
        }

        if (direction === SORT_DIRECTION_ASC) {
            return new Date(previousValue) - new Date(currentValue);
        }

        if (direction === SORT_DIRECTION_DESC) {
            return new Date(currentValue) - new Date(previousValue);
        }

        return 0;
    });
};

const sortObjectString = (key, stringObj) => {
    const keyExists = Object.keys(stringObj).includes(key);

    if (!keyExists) {
        return undefined;
    }

    return stringObj[key].toLowerCase();
};

export const sortStrings = (previous, current, direction = SORT_DIRECTION_ASC) => {
    let previousValue = previous;
    let currentValue = current;

    if (previousValue === undefined || currentValue === undefined) {
        return 0;
    }

    if (typeof previousValue === 'string') {
        previousValue = previousValue.toLowerCase();
    }

    if (typeof currentValue === 'string') {
        currentValue = currentValue.toLowerCase();
    }

    if (typeof previousValue === 'object') {
        const label = sortObjectString('label', previousValue);
        const value = sortObjectString('value', previousValue);

        previousValue = label || value;
    }

    if (typeof currentValue === 'object') {
        const label = sortObjectString('label', currentValue);
        const value = sortObjectString('value', currentValue);

        currentValue = label || value;
    }

    if (direction === SORT_DIRECTION_ASC) {
        return previousValue.localeCompare(currentValue);
    }

    if (direction === SORT_DIRECTION_DESC) {
        return currentValue.localeCompare(previousValue);
    }

    return 0;
};

export const sorterStringCommon = (column) => (previous, current) => {
    const previousValue = get(column, previous);
    const currentValue = get(column, current);

    return sortStrings(previousValue, currentValue);
};

export const sorterString = (column) => (...props) => {
    if (props.length === 3) return sorterStringCommon(column)(...props);

    const { direction } = props[1];

    return props[0].sort((previous, current) => {
        const previousValue = get(column, previous);
        const currentValue = get(column, current);

        return sortStrings(previousValue, currentValue, direction);
    });
};

export const sorterNumberCommon = (column) => (previous, current) => {
    let previousValue = get(column, previous);
    let currentValue = get(column, current);

    if (typeof previousValue === 'string') {
        previousValue = Number(previousValue.replace(/[^\d.-]/g, ''));
    }

    if (typeof currentValue === 'string') {
        currentValue = Number(currentValue.replace(/[^\d.-]/g, ''));
    }

    return previousValue - currentValue;
};

export const sorterNumber = (column) => (...props) => {
    if (props.length === 3) return sorterNumberCommon(column)(...props);

    const { direction } = props[1];

    return props[0].sort((previous, current) => {
        let previousValue = get(column, previous);
        let currentValue = get(column, current);

        if (typeof previousValue === 'string') {
            previousValue = Number(previousValue.replace(/[^\d.-]/g, ''));
        }

        if (typeof currentValue === 'string') {
            currentValue = Number(currentValue.replace(/[^\d.-]/g, ''));
        }

        if (previousValue === undefined || currentValue === undefined) {
            return 0;
        }

        if (direction === SORT_DIRECTION_ASC) {
            return previousValue - currentValue;
        }

        if (direction === SORT_DIRECTION_DESC) {
            return currentValue - previousValue;
        }

        return 0;
    });
};

export const sorterStatusColor = (column) => (...props) => {
    const colorOrder = {
        green: 1,
        red: 2,
        yellow: 3,
        black: 4,
    };

    const { direction } = props[1];

    return props[0].sort((previous, current) => {
        const previousValue = get(column, previous);
        const currentValue = get(column, current);

        if (previousValue === undefined || currentValue === undefined) {
            return 0;
        }

        if (previousValue.color === currentValue.color) {
            return sortStrings(previousValue.text, currentValue.text, direction);
        }

        const previousColor = (typeof previousValue.color === 'string')
            ? previousValue.color.toLowerCase()
            : previousValue.color;

        const currentColor = (typeof currentValue.color === 'string')
            ? currentValue.color.toLowerCase()
            : currentValue.color;

        if (direction === SORT_DIRECTION_ASC) {
            return colorOrder[previousColor] - colorOrder[currentColor];
        }

        if (direction === SORT_DIRECTION_DESC) {
            return colorOrder[currentColor] - colorOrder[previousColor];
        }

        return 0;
    });
};
