import React, {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    Checkbox, Infobox, FormControlMessage, FormGroup, Paragraph, Select, Modal,
} from 'ui-library';
import './ChangeRiskModal.css';

const ChangeRiskModal = (props) => {
    const {
        visible,
        defaultRisk,
        selectedRisk,
        availableRisks,
        onChangeRisk,
        onOk,
        onCancel,
    } = props;
    const { t } = useTranslation();
    const [currentRisk, setCurrentRisk] = useState(defaultRisk);
    const [previousRisk, setPreviousRisk] = useState(defaultRisk);
    const [acceptRiskChanging, setAcceptRiskChanging] = useState(false);
    const [errorAcceptRiskChanging, setErrorAcceptRiskChanging] = useState(false);

    const availableRisksOptions = useMemo(() => availableRisks
        .map(({ Id, Name }) => ({ value: Id, label: Name })), [availableRisks]);
    const showError = useMemo(() => (currentRisk && defaultRisk !== currentRisk),
        [currentRisk, defaultRisk]);

    // Callbacks
    const onAcceptChangeRisk = useCallback((value) => {
        setAcceptRiskChanging(value);
        setErrorAcceptRiskChanging(false);
    }, []);
    const onOkClick = useCallback(() => {
        if (currentRisk !== defaultRisk && currentRisk !== previousRisk && !acceptRiskChanging) {
            setErrorAcceptRiskChanging(true);

            return;
        }

        onChangeRisk(currentRisk);
        onOk(currentRisk);
    }, [acceptRiskChanging, currentRisk, defaultRisk, onChangeRisk, onOk, previousRisk]);

    useEffect(() => {
        if (!visible) {
            setCurrentRisk(selectedRisk);
            setPreviousRisk(selectedRisk);
            setAcceptRiskChanging(false);
            setErrorAcceptRiskChanging(false);
        }
    }, [selectedRisk, visible]);

    return (
        <Modal
            title={t('riskProfile.changeRiskModalTitle')}
            okText={t('confirmation.confirm')}
            visible={visible}
            className="change-risk-profile"
            cancelText={t('confirmation.cancel')}
            onOk={onOkClick}
            onCancel={onCancel}
        >
            <div className="ChangeRiskModal">
                <Paragraph type="secondary">
                    {t('riskProfile.changeRisk.description')}
                </Paragraph>
                <Select
                    label={t('riskProfile.riskProfile')}
                    value={currentRisk || previousRisk || defaultRisk}
                    options={availableRisksOptions}
                    onChange={setCurrentRisk}
                />
                {showError && (
                    <Infobox error>
                        {t('riskProfile.changeRisk.error')}
                    </Infobox>
                )}
                <FormGroup error={errorAcceptRiskChanging}>
                    <Checkbox
                        name="riskChangeAccept"
                        label={t('riskProfile.changeRisk.checkboxLabel')}
                        error={errorAcceptRiskChanging}
                        checked={acceptRiskChanging}
                        onChange={(e) => onAcceptChangeRisk(e.target.checked)}
                    />
                    {errorAcceptRiskChanging && (
                        <FormControlMessage>
                            {t('riskProfile.changeRisk.checkboxRequired')}
                        </FormControlMessage>
                    )}
                </FormGroup>
            </div>
        </Modal>
    );
};

ChangeRiskModal.propTypes = {
    availableRisks: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    defaultRisk: PropTypes.number,
    selectedRisk: PropTypes.number,
    visible: PropTypes.bool,
    onChangeRisk: PropTypes.func,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
};

ChangeRiskModal.defaultProps = {
    availableRisks: [],
    defaultRisk: undefined,
    selectedRisk: undefined,
    visible: false,
    onChangeRisk: () => {},
    onOk: () => {},
    onCancel: () => {},
};

export default ChangeRiskModal;
