import React from 'react';
import './KeyData.css';
import PropTypes from 'prop-types';
import { Tabs, TabPane } from 'ui-library';
import { useTranslation } from 'react-i18next';
import Ratings from './Ratings/Ratings';
import Metrics from './Metrics/Metrics';
import { useRatings } from './Ratings/useRatings';

function KeyData(props) {
    const { positionId, additionalData } = props;
    const { t } = useTranslation();
    const { isLoading, data, error } = useRatings(positionId);

    return (
        <div className="key-data ppp">
            <Tabs defaultActiveKey="1">
                <TabPane tab={t('instrument.metrics')} key="1">
                    <Metrics
                        positionId={positionId}
                        additionalData={additionalData}
                    />
                </TabPane>
                {/* todo: Fix Tabs (old ui-library) React.Children.map(children, getNewChild) */}
                {data.length !== 0 ? (
                    <TabPane tab={t('instrument.ratings')} key="2">
                        <Ratings
                            isLoading={isLoading}
                            data={data}
                            error={error}
                        />
                    </TabPane>
                ) : (<div /> /* hot fix */)}
            </Tabs>
        </div>
    );
}

KeyData.propTypes = {
    additionalData: PropTypes.shape({
        calculatedRisk: PropTypes.number,
        calculatedExpectedReturn: PropTypes.number,
        sharpeRatio: PropTypes.number,
        tradingPriceOfUnderlying: PropTypes.number,
        calculatedRiskOfUnderlying: PropTypes.number,
        name: PropTypes.string,
    }).isRequired,
    positionId: PropTypes.number.isRequired,
};

export default KeyData;
