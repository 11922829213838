
import { useCallback, useEffect, useReducer } from 'react';
import SM from 'services/ServiceManager';
import HandlerError from 'errors/HandlerError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import ServerError from 'errors/ServerError';

const initialState = {
    data: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useContactFlatPortfolio = ({
    clientId,
    loadInitially = false,
} = {}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // Callbacks
    const getData = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        try {
            const { data: flatPortfolioData } = await SM.dataService('getFlatPortfolioData', [clientId]);
            const flatPortfolio = JSON.parse(flatPortfolioData.Data);

            dispatch({ type: 'setData', payload: flatPortfolio });

            return flatPortfolio;
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        } finally {
            dispatch({ type: 'setIsLoading', payload: false });
        }
    }, [clientId]);


    // Effects
    useEffect(() => {
        dispatch({ type: 'setIsLoading', payload: loadInitially });
        if (loadInitially) getData();
    }, [loadInitially]);

    return {
        data: state.data,
        error: state.error,
        isLoading: state.isLoading,
        getData,
    };
};
