import {
    useCallback, useEffect, useMemo, useState,
} from 'react';
import Bonds from 'assets/documents/instruments/Bonds.pdf';
import Commodity from 'assets/documents/instruments/Commodity.pdf';
import Equity from 'assets/documents/instruments/Equity.pdf';
import Funds from 'assets/documents/instruments/Funds.pdf';
import FutureOption from 'assets/documents/instruments/FutureOption.pdf';
import MoneyMarkets from 'assets/documents/instruments/MoneyMarkets.pdf';

const documentByIds = {
    458: MoneyMarkets,
    459: Bonds,
    460: Funds,
    508: Equity,
    509: Commodity,
    510: FutureOption,
};

export const useExpandUniverse = (instruments) => {
    const [disabledToggle, setDisabledToggle] = useState({});
    const [toggledValues, setToggledValues] = useState({});

    // Callbacks
    const onDownload = useCallback((item) => () => {
        const pdf = documentByIds[item?.Id];

        window.open(pdf, '_blank');
        setDisabledToggle({ ...disabledToggle, [item?.Id]: false });
    }, [disabledToggle]);
    const onToggle = useCallback((item) => (value) => {
        setToggledValues({ ...toggledValues, [item?.Id]: value });
    }, [toggledValues]);

    // Effects
    useEffect(() => {
        setDisabledToggle((instruments || [])
            .reduce((acc, item) => ({ ...acc, [item.Id]: true }), {}));
    }, [instruments]);

    // Memo
    const values = useMemo(() => Object.keys(toggledValues)
        .filter((key) => toggledValues[key]), [toggledValues]);

    return {
        disabledToggle, toggledValues, values, onDownload, onToggle,
    };
};
