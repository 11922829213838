import { getTimestamp } from 'utils/datetime';
import { formatWithDate } from 'utils/formatting';

export const adaptPortfolioDetailsPerformance = (data, getFormattedNumber, getFormattedDate) => [
    {
        data: data.map(({ Value, Date }) => [getTimestamp(Date), Math.round(Value * 10000) / 100]),
        tooltipFormat: (number, date) => formatWithDate(
            formatWithDate(`${getFormattedNumber(number)}%`, date, getFormattedDate),
        ),
    },
];
