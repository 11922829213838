import Service from 'additiv-services';
import queryString from 'query-string';
import {
    getClientServiceHeader,
} from 'additiv-services/dist/setup';

const CUSTOM_API_URL = `${process.env.REACT_APP_GATEWAY_API_URL}/cs/api/v1.0`;

export default class CustomServiceLayer extends Service {
    static getPersonTasks(personId) {
        const url = `${CUSTOM_API_URL}/persons/${personId}/tickets`;

        return Service.getRequest(
            `${url}`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static deleteTask(taskId) {
        return Service.deleteRequest(
            `${CUSTOM_API_URL}/tickets/${taskId}`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static getContactDetails(contactId, data) {
        const url = `${CUSTOM_API_URL}/clientservices/contacts/${contactId}/details`;

        return Service.postRequest(
            `${url}`,
            data,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    // gets all members by role
    static async getMembersByRole(roleKey) {
        let url = `${CUSTOM_API_URL}/members/MembersByRole`;

        if (roleKey) {
            const params = queryString.stringify({
                roleKey,
            });

            url = `${url}?${params}`;
        }

        return Service.getRequest(
            `${url}`,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }

    static async downloadPortfoliosReport(contactId, data) {
        return Service.postRequest(
            `${CUSTOM_API_URL}/contacts/${contactId}/portfolios/reporting/download`,
            data,
            { isSecure: true, axiosSetup: { headers: getClientServiceHeader() } },
        );
    }
}
