import { useCallback, useState } from 'react';
import history from 'services/history';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';

export const useAcceptTradeCard = (backLink) => {
    const [isAccepting, setAccepting] = useState(false);
    const [errorAccepting, setError] = useState(null);

    const onAccept = useCallback((clientId, portfolioId, params) => {
        setAccepting(true);
        setError(null);
        ServiceManager.portfolioManagement('patchTradingCard', [
            clientId,
            portfolioId,
            {
                Trades: [
                    {
                        InstrumentId: Number(params.positionId),
                        InstrumentTradeType: params.order,
                        OrderType: params.orderType,
                        Quantity: parseFloat(params.tradeQuantity) || null,
                        LimitPrice: params.limit,
                        StopPrice: params.stop,
                        FundingAccountId: params.fundingAccount,
                        OrderValidity: params.validity,
                        ValidityDate: params.validityDate,
                    },
                ],
            },
        ])
            .then(() => {
                try {
                    setTimeout(() => {
                        setAccepting(false);
                        if (backLink) history.push(backLink);
                        else history.goBack();
                    }, 1000);
                } catch (err) {
                    setAccepting(false);
                    setError(err);
                    throw new AdapterError(err);
                }
            })
            .catch((err) => {
                setAccepting(false);
                setError(err);
            });
    }, []);

    return { onAccept, isAccepting, errorAccepting };
};
