import { UNEXPECTED_ERROR_MESSAGE } from 'error/commonErrors';

export const EMAIL_USER_IDENTITY = 0;
export const MTAN = 1;
export const EMAIL_SENT = 2;
export const AUTHENTICATION_ERROR = 3;
export const RESET_PASSWORD_ERROR = 4;

const viewToMessageXref = {
    [EMAIL_SENT]: {
        title: 'forgotPassword.checkYourMailbox',
        type: 'success',
        message: 'forgotPassword.checkYourMailboxMessage',
    },
    [AUTHENTICATION_ERROR]: {
        title: 'forgotPassword.authenticationError',
        type: 'error',
        message: 'forgotPassword.authenticationErrorMessage',
    },
    [RESET_PASSWORD_ERROR]: {
        title: 'forgotPassword.resetPasswordError',
        type: 'error',
        message: 'forgotPassword.resetPasswordErrorMessage',
    },
};

export const getViewToMessageXref = (view) => viewToMessageXref[view] || {
    title: 'forgotPassword.unexpectedError',
    type: 'error',
    message: UNEXPECTED_ERROR_MESSAGE,
};
