import {
    useCallback, useEffect, useReducer,
} from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import handlerRequestCanceling from 'utils/handlerRequestCanceling';
import HandlerError from 'errors/HandlerError';
import { adaptAccountsSearchData } from 'adaptors/adaptAccountsSearch';
import ServerError from '../errors/ServerError';

const initialState = {
    data: [],
    dataRaw: {},
    error: null,
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'setData':
            return { ...state, data: action.payload };
        case 'setDataRaw':
            return { ...state, dataRaw: action.payload };
        case 'setError':
            return { ...state, error: action.payload };
        case 'setIsLoading':
            return { ...state, isLoading: action.payload };
        default:
            return state;
    }
};

export const useAccountsSearch = (clientId) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    const { i18n } = useTranslation();

    const getAccounts = useCallback(async () => {
        dispatch({ type: 'setError', payload: null });
        dispatch({ type: 'setIsLoading', payload: true });

        const accountParams = {
            Page: 1,
            PageSize: 1000,
            AccountTypeIds: [1],
        };

        try {
            const { data: accounts } = await ServiceManager.accountsService('postAccountsSearch', [clientId, i18n.language, accountParams]);

            dispatch({ type: 'setDataRaw', payload: accounts });
            try {
                const adapted = adaptAccountsSearchData(accounts);

                dispatch({ type: 'setData', payload: adapted });
                dispatch({ type: 'setIsLoading', payload: false });

                return adapted;
            } catch (err) {
                throw new AdapterError(err);
            }
        } catch (err) {
            handlerRequestCanceling(
                HandlerError({
                    setError: (val) => dispatch({ type: 'setError', payload: val }),
                    setLoading: (val) => dispatch({ type: 'setIsLoading', payload: val }),
                }),
            )(err);

            return err.type !== undefined ? err : new ServerError(err);
        }
    }, [clientId, i18n.language]);

    useEffect(() => {
        getAccounts();
    }, [getAccounts]);

    return {
        getAccounts,
        data: state.data,
        dataRaw: state.dataRaw,
        isLoading: state.isLoading,
        error: state.error,
    };
};
