import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Header, Modal } from 'ui-library';
import { useTranslation } from 'react-i18next';
import { Footer } from 'components/Footer';
import { Logo } from 'components/Logo';
import { isTemporaryHidden } from 'constants/constants';
import { useHistory } from 'react-router-dom';
import { usePaymentsData } from '../hooks/usePaymentsData';
import '../Payments.css';

export const PaymentsTemplate = ({ children }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { clearPayments } = usePaymentsData();

    useEffect(() => history.listen((location) => {
        if (!location.pathname.includes('payments')) {
            clearPayments();
        }
    }), [history, clearPayments]);

    const paymentsClose = useCallback(() => {
        Modal.confirm({
            title: t('payments.cancelPaymentTitle'),
            content: t('payments.cancelPaymentContent'),
            okText: t('payments.cancelPayment'),
            onOk: () => { clearPayments(); history.push('/dashboard'); },
            okType: 'danger',
            cancelText: t('payments.back'),
            className: 'close-client-modal',
        });
    }, [t]);
    const headerProps = useMemo(() => ({
        leftButton: { type: 'close', label: t('confirm.closeProcess') },
        onLeftButtonClick: paymentsClose,
        rightButton: '',
    }), [paymentsClose, t]);

    return (
        <>
            <Header
                {...headerProps}
                title={t('navigation.title')}
                content={<Logo />}
            />
            <main className="container">
                {children}
            </main>
            {!isTemporaryHidden && <Footer />}
        </>
    );
};

PaymentsTemplate.propTypes = {
    children: PropTypes.node.isRequired,
};

PaymentsTemplate.defaultProps = {};

PaymentsTemplate.displayName = 'PaymentsTemplate';
