import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ServiceManager from 'services/ServiceManager';
import AdapterError from 'errors/AdapterError';
import { adaptResearches as adaptList } from 'pages/pages/News/adapters/adaptResearches';
import { CURRENT_NEWS_CATEGORY } from 'constants/constants';

export const useNewsResearch = (params) => {
    const { researchNews, currentPage } = (params || {});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [newsList, setNewsList] = useState([]);
    const { i18n: { language }, t } = useTranslation();

    const getResearches = async ({ pageSize, page }) => {
        try {
            setLoading(true);
            setError(null);
            const { data: schemas } = await ServiceManager.engagementSchema('postAll', [{ pageNumber: 0, pageSize: 10 }]);

            const researchSchemaId = (schemas?.schemaMinimalResults || [])
                .find(({ name, isActive }) => name === 'Research' && isActive)?.id;

            if (!researchSchemaId) {
                setLoading(false);

                return [];
            }

            const { data: contents } = await ServiceManager.engagementContent('postSchemaLatest', [
                { schemaId: researchSchemaId, pageNumber: 0, pageSize: 0 },
            ]);
            const ids = (contents?.contentMinimalResults || []).map((item) => item?.id);

            let contentFor = (contents?.contentMinimalResults || [])
                .map((item) => item?.contentFor?.[CURRENT_NEWS_CATEGORY]).flat()
                .filter((item) => !!item);

            contentFor = [...new Set(contentFor)];
            let imagesResponses = [];

            try {
                imagesResponses = await Promise.allSettled(ids.map(async (id) => ({
                    id,
                    ...(await ServiceManager.engagementContent('getContentLatestImage', [id, language])).data,
                })));
            } catch (err) {
                // No need to display error
            }

            const images = imagesResponses.reduce((acc, item) => (item?.value
                ? { ...acc, [item.value?.id]: item.value?.content } : acc), {});
            const response = await ServiceManager.engagementContent('contentSearch', [{
                contentFor,
                categories: [CURRENT_NEWS_CATEGORY],
                schemaIds: [researchSchemaId],
                status: true,
                isPublished: true,
                validDateFrom: new Date(new Date().setUTCHours(0, 0, 0, 0)),
                page: page || 1,
                pageSize: pageSize || 6,
            }, language]);

            try {
                const adapted = adaptList({
                    data: (response.data?.contentMinimalResults || []).map((item) => ({
                        ...item, imageUri: images[item.id],
                    })),
                    schema: contents?.contentMinimalResults,
                    t,
                    language,
                });

                setNewsList(adapted);
                setLoading(false);

                return adapted;
            } catch (err) {
                setLoading(false);
                setError(err);
                throw new AdapterError(err);
            }
        } catch (err) {
            setLoading(false);
            setError(err);
        }

        return newsList;
    };

    useEffect(() => {
        getResearches({ pageSize: researchNews, page: currentPage });
    }, [researchNews, currentPage]);

    return {
        data: newsList, isLoading, error, getResearches,
    };
};
