import { createSelector } from 'reselect/lib/index';
import { get } from 'lodash/fp';
import { isUserActiveSelector, userSelector } from '../auth/authSelectors';

export const enrollSelector = (state) => state.enroll;
export const enrollQRCodeSelector = createSelector(enrollSelector, (enroll) => get('qrCode', enroll));
export const isLoadingSelector = createSelector(
    enrollSelector,
    (enroll) => get('isLoading', enroll) !== 0,
);
export const isUnenrollUnsuccessSelector = createSelector(
    enrollSelector,
    (enroll) => get('isUnenrollUnsuccess', enroll) === true,
);
export const isEnrollUnsuccessSelector = createSelector(
    enrollSelector,
    (enroll) => get('isEnrollUnsuccess', enroll) === true,
);
export const errorSelector = createSelector(enrollSelector, (enroll) => get('error', enroll));
export const isEnrollSuccessSelector = createSelector(enrollSelector, (enroll) => get('isEnrollSuccess', enroll));
export const isEnrollDeviceSuccessSelector = createSelector(enrollSelector, (enroll) => get('isEnrollDeviceSuccess', enroll));
export const isUnenrollSuccessSelector = createSelector(enrollSelector, (enroll) => get('isUnenrollSuccess', enroll));
export const isDeviceEnrolledSelector = createSelector(userSelector, (user) => get('isDeviceEnrolled', user));
export const isActiveUserNotEnrolledSelector = createSelector(
    isDeviceEnrolledSelector,
    isUserActiveSelector,
    (isDeviceEnrolled, isUserActive) => isDeviceEnrolled && isUserActive,
);
